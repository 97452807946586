import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const ArrowDownIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      d="m8.607 14.717 4.846-4.473a.73.73 0 0 0 0-1.1c-.334-.308-.874-.308-1.192 0l-3.416 3.153v-10.5c0-.425-.381-.777-.842-.777s-.842.352-.842.777v10.486L3.745 9.144c-.333-.308-.874-.308-1.191 0a.77.77 0 0 0-.255.558c0 .205.08.396.255.557l4.861 4.488a.9.9 0 0 0 .604.234c.207-.029.43-.117.588-.264"
    />
  </svg>
);

export { ArrowDownIcon };
