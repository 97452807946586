import { TDrawnBoundingBox } from 'hooks/useDrawBoundingBoxController';
import { PartialExcept } from './general';
import { EGradientTypes } from './heatmap';
import { MeasurementAggregation, TResolution } from './measurement';

export const annotationTypes = [
  'event',
  'time_range_annotation',
  'single_image_annotation',
  'heatmap_annotation',
  'heatmap_aggregate_annotation',
] as const;

export type TAnnotationType = (typeof annotationTypes)[number];

export const discussionTypes = ['comment', 'insight'] as const;

export type TDiscussionType = (typeof discussionTypes)[number];

export const eventCategories = [
  'equipment-adjustment',
  'equipment-failure',
  'foliar-spray',
  'root-drench',
  'defoliation',
  'other',
] as const;

export const singleImageAnnotationCategories = [
  'symptom-identification',
  'symptom-update',
  'testing-request',
  'diagnosis',
  'other',
] as const;

export const heatMapAnnotationCategories = [
  'microclimate',
  'equipment-failure',
  'other',
] as const;

export const heatMapAggregateAnnotationCategories = heatMapAnnotationCategories;

export const timeRangeAnnotationCategories = [
  'parameter-out-of-range',
  'changing-trend',
  'other',
] as const;

type TEventCategory = (typeof eventCategories)[number];
type TSingleImageAnnotationCategory =
  (typeof singleImageAnnotationCategories)[number];
type TTimeRangeAnnotationCategory =
  (typeof timeRangeAnnotationCategories)[number];
type THeatMapAnnotationCategory = (typeof heatMapAnnotationCategories)[number];
type THeatMapAggregateAnnotationCategory =
  (typeof heatMapAggregateAnnotationCategories)[number];

export type TDiscussionCategory =
  | TEventCategory
  | TSingleImageAnnotationCategory
  | THeatMapAnnotationCategory
  | THeatMapAggregateAnnotationCategory
  | TTimeRangeAnnotationCategory;

interface TAuthor {
  authorId: number;
  authorOrganizationCode: string;
  authorName?: string;
}

export type RegularCommentContent = {
  content: string;
};

export enum InsightSeverity {
  High = 'high',
  Moderate = 'moderate',
  Mild = 'mild',
  None = 'none',
}

export enum InsightType {
  Environment = 'environment',
  Health = 'health',
}

export type InsightCommentContent = {
  content: string;
  recommendation: string;
  occurrence: string;
  severity: InsightSeverity;
  type: InsightType;
  title: string;
};

export type TComment = {
  uid: string;
  discussionUid: string;
  firstCommentInDiscussion: boolean;
  content: RegularCommentContent | InsightCommentContent;
  organizationCode: string;
  zoneUid: string;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  isRead?: boolean;
} & TAuthor;

export type TCommentDraft = PartialExcept<
  TComment,
  'firstCommentInDiscussion' | 'content' | 'organizationCode' | 'zoneUid'
>;

const _discussionStatuses = ['published', 'draft', 'deleted'] as const;

export type TDiscussionStatus = (typeof _discussionStatuses)[number];

export type TArea = {
  shape?: 'rectangle';
  points?: [{ x: number; y: number }, { x: number; y: number }];
  aspectRatio?: number;
  gridPosition?: TPosition;
  signalIds?: string[];
  gradientType?: EGradientTypes;
  viewType?: MeasurementAggregation;
};

type TPreview = {
  previewHealth?: { url: string; resolution: TResolution };
};

export type TDiscussion = {
  uid: string;
  type: TDiscussionType;
  annotationType: TAnnotationType;
  zoneUid: string;
  zoneId: number;
  organizationCode: string;
  category: TDiscussionCategory;
  status: TDiscussionStatus;
  startTime: Date;
  displayLabel: string;
  endTime: Date;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  measurementId?: number;
  heatMapId?: number;
  heatMapAggregateUid?: string;
  area?: TArea;
  firstComment: TComment;
  timeZone: string;
} & TAuthor &
  TPreview;

export type TDiscussionDraft = MakeOptional<
  TDiscussion,
  | 'uid'
  | 'category'
  | 'zoneUid'
  | 'zoneId'
  | 'authorId'
  | 'authorOrganizationCode'
  | 'organizationCode'
  | 'status'
  | 'displayLabel'
  | 'firstComment'
>;

export type TDiscussionMetadata = { discussion: TDiscussion };
export type TBoundingBoxWithDiscussion = TDrawnBoundingBox<TDiscussionMetadata>;

export type TMention = {
  email: string;
  name: string;
  user_id?: number;
  user_group_id?: number;
};
