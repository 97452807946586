import { useGetEnumerationByCodeAndType } from 'api/enumeration';
import {
  useGetUserNotifications,
  useRemoveUserNotifications,
  useUpsertUserNotifications,
} from 'api/user';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { Disclosure } from 'components/common/Disclosure/Disclosure';
import { Link } from 'components/common/Link';
import { Switch } from 'components/common/Switch/Switch';
import * as toast from 'components/common/Toast/Toast';
import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import {
  EEnumerationCodes,
  EEnumerationTypes,
  EUserNotificationTypeCodes,
} from 'shared/interfaces/enumeration';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';

const { NOTIFICATION_SETTING } = EEnumerationTypes;
const { DASHBOARD_AND_EMAIL } = EEnumerationCodes;
const CRITICAL_ENVIRONMENT_LABEL = {
  notificationType: EUserNotificationTypeCodes.CRITICAL_ENVIRONMENT,
  label: 'Environmental updates',
};
const SPYDER_STATUS_LABEL = {
  notificationType: EUserNotificationTypeCodes.SPYDER_STATUS,
  label: 'Spyder Status',
};
const INSIGHT_REPORT_LABEL = {
  notificationType: EUserNotificationTypeCodes.INSIGHT_REPORT,
  label: 'Insight Report',
};

interface UserNotificationTypesProps extends ComponentPropsWithoutRef<'div'> {
  userId: number;
  zones: TZone[];
}

export const UserNotificationSettings = forwardRef<
  HTMLDivElement,
  UserNotificationTypesProps
>(function NotificationTypes({ className, userId, zones, ...props }, ref) {
  const sortedZones = useMemo(
    () =>
      zones.sort((zoneA, zoneB) => {
        const zoneAName = `${zoneA.locationName} - ${zoneA.label}`;
        const zoneBName = `${zoneB.locationName} - ${zoneB.label}`;
        return zoneAName.localeCompare(zoneBName);
      }),
    [zones]
  );
  const zoneIds = useMemo(() => sortedZones.map(({ id }) => id), [sortedZones]);
  const { enumerations } = useGetEnumerationByCodeAndType(
    NOTIFICATION_SETTING,
    DASHBOARD_AND_EMAIL
  );
  const notificationMethodEnum = enumerations[0];
  const notificationTypes = [
    CRITICAL_ENVIRONMENT_LABEL,
    INSIGHT_REPORT_LABEL,
    SPYDER_STATUS_LABEL,
  ];

  const { userNotifications } = useGetUserNotifications({
    userId,
    zoneIds,
  });
  const { upsert } = useUpsertUserNotifications();
  const { remove } = useRemoveUserNotifications({
    userId,
    zoneIds,
  });
  const [customizeAllZones, setCustomizeAllZones] = useState(
    userNotifications.length !== zoneIds.length
  );
  const handleToggleAllRooms = () => {
    setCustomizeAllZones((prev) => !prev);
  };
  interface HandleCheckSettingProps {
    notificationMethodId?: number;
    notificationType: EUserNotificationTypeCodes;
    zoneId?: number;
  }
  const handleCheckSetting =
    ({
      notificationMethodId,
      notificationType,
      zoneId,
    }: HandleCheckSettingProps): ChangeEventHandler<HTMLInputElement> =>
    async (event) => {
      try {
        const zoneIdsToModify = zoneId ? [zoneId] : zoneIds;
        if (event.target.checked && notificationMethodId) {
          await upsert({
            userId,
            zoneIds,
            notificationZoneIds: zoneIdsToModify,
            notificationSettingId: notificationMethodId,
            notificationType,
          });
        } else {
          await remove(zoneIdsToModify, notificationType);
        }
        toast.success(
          { content: 'Change successfully saved.' },
          { autoClose: 3000, toastId: 'update-user-notification-success' }
        );
      } catch (_error) {
        toast.error(
          {
            content:
              'Something went wrong while trying to save your changes. Please try again.',
          },
          { toastId: 'update-user-notification-error' }
        );
      }
    };

  if (zones.length === 0) {
    return null;
  }

  return (
    <div
      ref={ref}
      {...props}
      className={cn('flex flex-col gap-8 w-full sm:max-w-[632px]', className)}
    >
      <section>
        <p className="prose-sm font-semibold">Critical environmental alerts:</p>
        <p className="prose-sm pl-4">
          Receive alerts whenever environment parameters go into a Critical
          Range, as defined on the{' '}
          <Link
            text="Critical Environment Settings"
            to={PATH_PATTERNS[ERoutePath.SETTINGS_CRITICAL_ENV_SETTINGS]}
          />{' '}
          page.
        </p>
      </section>

      <section className="flex flex-col gap-4">
        <div className="flex gap-2">
          <p className="font-semibold">Customize each room</p>
          <Switch
            aria-label="Customize each room"
            checked={customizeAllZones}
            onChange={handleToggleAllRooms}
          />
        </div>

        {!customizeAllZones && (
          <Disclosure keepOpen summary="All rooms">
            {notificationTypes.map(({ notificationType, label }, index) => {
              const isFirst = index === 0;
              const settingSize = userNotifications.filter(
                (userNotification) =>
                  userNotification.notificationType === notificationType
              ).length;
              const isChecked = zones.length === settingSize;
              const isIndeterminate =
                settingSize > 0 && settingSize < zones.length;

              return (
                <div key={index} className={cn(isFirst && 'pt-2')}>
                  <Checkbox.Input
                    labelPlacement="left"
                    labelClassName="text-base justify-between"
                    label={label}
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    onChange={handleCheckSetting({
                      notificationMethodId: notificationMethodEnum?.id,
                      notificationType,
                    })}
                  />
                </div>
              );
            })}
          </Disclosure>
        )}

        {customizeAllZones && (
          <ul className="flex flex-col gap-2" aria-label="Available rooms">
            {sortedZones.map((zone) => {
              return (
                <Disclosure
                  keepOpen
                  key={zone.label}
                  summary={`${zone.locationName} - ${zone.label}`}
                  role="listitem"
                >
                  {notificationTypes.map(
                    ({ notificationType, label }, index) => {
                      const isFirst = index === 0;
                      const notiticationId = userNotifications.find(
                        (userNotification) =>
                          userNotification.zoneId === zone.id &&
                          userNotification.notificationType === notificationType
                      );
                      const isChecked = !!notiticationId;

                      return (
                        <div
                          key={`${zone.label}-${notificationType}`}
                          className={cn(isFirst && 'pt-2')}
                        >
                          <Checkbox.Input
                            labelPlacement="left"
                            labelClassName="text-base justify-between"
                            label={label}
                            checked={isChecked}
                            onChange={handleCheckSetting({
                              notificationMethodId: notificationMethodEnum?.id,
                              notificationType,
                              zoneId: zone.id,
                            })}
                          />
                        </div>
                      );
                    }
                  )}
                </Disclosure>
              );
            })}
          </ul>
        )}
      </section>
    </div>
  );
});
