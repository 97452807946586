import { useQuery } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';
import { compareAsc } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  GetComputedMeasurementQuery,
  GetLimitedZoneMeasurementsQuery,
  GetMeasurementsByIdsQuery,
  useGetComputedMeasurementQuery,
  useGetEnumerationByCodeAndTypeQuery,
  useGetImageFeedMeasurementRunsQuery,
  useGetLimitedZoneMeasurementsQuery,
  useGetMeasurementRunsBetweenDatesByZoneQuery,
  useGetMeasurementsByIdsQuery,
  useGetMeasurementsByRunIdsAndPositionQuery,
  useGetMeasurementsByZoneIdAndSensorTypeAndSensorModelIdQuery,
  useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery,
  useGetUnlimitedZoneMeasurementsQuery,
} from 'graphql/generated/react_apollo';
import { useCurrentZone } from 'hooks/useCurrentZone';
import isNil from 'lodash.isnil';
import keyBy from 'lodash.keyby';
import { useMemo } from 'react';
import { EEnumerationTypes } from 'shared/interfaces/enumeration';
import { EImageTypes, ImageTypeToSensorCodeMap } from 'shared/interfaces/image';
import {
  EMeasurementGroup,
  EMeasurementStatisticsTypesV2,
  EMeasurementTypes,
  GetLineChartDataInput,
  GetMeasurementTypeFunction,
  MeasurementAggregation,
  MeasurementTypeConfig,
  TCurrentZoneMeasurements,
} from 'shared/interfaces/measurement';
import { TMeasurementRun } from 'shared/interfaces/measurementRun';
import { useGetDailyHealthLabelsByZoneIdTypeCode } from './labels';

const { AIR_VPD, LEAF_VPD } = EMeasurementStatisticsTypesV2;
export type TMeasurementById = ArrayElement<
  GetMeasurementsByIdsQuery['measurement']
>;

export const useGetComputedMeasurement = ({
  parameters: { zoneId, zoneTimeZone, start, end, typeConfig },
  skip,
}: GetLineChartDataInput) => {
  const { statisticsKeyV2, statisticsKey, convertFromUnit } = typeConfig;

  const { data, ...result } = useGetComputedMeasurementQuery({
    skip,
    variables: {
      zoneId,
      startTime: start,
      endTime: end,
      computedMetricTypeCode: statisticsKeyV2,
    },
  });

  const { dates, values } = useMemo(() => {
    if (!data) {
      return { dates: [], values: [] };
    }

    const dates = data.computed_measurement.map(({ time }) =>
      utcToZonedTime(time, zoneTimeZone)
    );

    const values = data.computed_measurement.map(({ data }) =>
      convertFromUnit(Number(data[statisticsKey]))
    );

    return { dates, values };
  }, [convertFromUnit, data, statisticsKey, zoneTimeZone]);

  return { dates, values, ...result };
};
export const useGetMeasurementRunsBetweenDatesByZone = ({
  parameters: { zoneId, zoneTimeZone, start, end, typeConfig },
  skip,
}: GetLineChartDataInput) => {
  const { data, ...result } = useGetMeasurementRunsBetweenDatesByZoneQuery({
    skip,
    variables: {
      zone_id: Number(zoneId),
      start,
      end,
    },
  });

  const { statisticsKey, convertFromUnit } = typeConfig;

  const { dates, values } = useMemo(() => {
    if (!data) {
      return { dates: [], values: [] };
    }

    const filteredMeasurementRunData = data.measurement_run.filter(
      ({ metadata }) => !isNil(metadata.statistics?.[statisticsKey])
    );

    const dates = filteredMeasurementRunData.map((measurementRun) =>
      utcToZonedTime(measurementRun.start_time, zoneTimeZone)
    );

    const values = filteredMeasurementRunData.map(({ metadata }) => {
      const mean = metadata.statistics?.[statisticsKey]?.mean;
      return convertFromUnit(Number(mean));
    });

    return { dates, values };
  }, [convertFromUnit, data, statisticsKey, zoneTimeZone]);

  return { dates, values, ...result };
};
export const useGetUnlimitedZoneMeasurements = ({
  parameters: { zoneId, zoneTimeZone, start, end, typeConfig },
  skip,
}: GetLineChartDataInput) => {
  const { convertFromUnit, statisticsKeyV2 } = typeConfig;

  const { data, ...result } = useGetUnlimitedZoneMeasurementsQuery({
    variables: {
      zone_id: zoneId,
      measurement_type: statisticsKeyV2,
      start,
      end,
    },
    skip,
  });

  const { dates, values } = useMemo(() => {
    if (!data) {
      return { dates: [], values: [] };
    }
    const dates = data.measurement_view.map(({ time }) =>
      utcToZonedTime(time, zoneTimeZone)
    );
    const values = data.measurement_view.map(({ data }) =>
      convertFromUnit(Number(data))
    );
    return { dates, values };
  }, [convertFromUnit, data, zoneTimeZone]);

  return { dates, values, ...result };
};

export const useGetMeasurementsByZoneIdAndSensorTypeAndSensorModelId = ({
  parameters: { zoneId, zoneTimeZone, start, end, typeConfig },
  skip,
}: GetLineChartDataInput) => {
  const sensorModelEnumerationData = useGetEnumerationByCodeAndTypeQuery({
    variables: {
      type: 'SENSOR_MODEL',
      code: [
        EMeasurementTypes.SoilEc,
        EMeasurementTypes.SoilPercentMoistureEc,
        EMeasurementTypes.SoilTemperatureEc,
      ].includes(typeConfig.type)
        ? 'BGT_SEC'
        : 'BGT_PH1',
    },
    skip,
  });

  const soilSensorType = sensorModelEnumerationData.data?.enumeration[0]?.id;

  const measurementTypeEnumerationData = useGetEnumerationByCodeAndTypeQuery({
    variables: {
      type: 'MEASUREMENT_TYPE',
      code: typeConfig.statisticsKeyV2,
    },
    skip,
  });

  const measurementTypeId =
    measurementTypeEnumerationData.data?.enumeration[0]?.id;

  const { data: soilSensorData, ...result } =
    useGetMeasurementsByZoneIdAndSensorTypeAndSensorModelIdQuery({
      variables: {
        zone_id: Number(zoneId),
        type_id: measurementTypeId!,
        sensor_model_id: soilSensorType!,
        start: new Date(start!),
        end: new Date(end!),
      },
      skip: skip || isNil(soilSensorType) || isNil(measurementTypeId),
    });

  const sortedSoilSensorData = useMemo(() => {
    return (soilSensorData?.measurement || [])
      .slice()
      .sort((a, b) => new Date(a.time).valueOf() - new Date(b.time).valueOf());
  }, [soilSensorData]);

  const dates = useMemo(
    () =>
      sortedSoilSensorData.map((measurement) =>
        utcToZonedTime(new Date(measurement.time), zoneTimeZone)
      ),
    [sortedSoilSensorData, zoneTimeZone]
  );

  const values = useMemo(
    () =>
      sortedSoilSensorData.map((measurement) =>
        typeConfig.convertFromUnit(
          Number(measurement.data[typeConfig.statisticsKey])
        )
      ),
    [sortedSoilSensorData, typeConfig]
  );

  return { dates, values, ...result };
};

export const useGetZoneMeasurements = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  getMeasurementType,
}: {
  zoneId: number;
  zoneTimeZone: string;
  start: Date;
  end: Date;
  getMeasurementType: GetMeasurementTypeFunction;
}) => {
  const measurementsResult = useGetLimitedZoneMeasurementsQuery({
    variables: {
      zone_id: zoneId,
      start,
      end,
      limit: 10,
    },
  });

  const airVpdResult = useGetComputedMeasurementQuery({
    variables: {
      zoneId,
      startTime: start,
      endTime: end,
      computedMetricTypeCode: AIR_VPD,
    },
  });

  const leafVpdResult = useGetComputedMeasurementQuery({
    variables: {
      zoneId,
      startTime: start,
      endTime: end,
      computedMetricTypeCode: LEAF_VPD,
    },
  });

  return useMemo(() => {
    const measurements = (measurementsResult.data?.measurement_view ?? []).map(
      (item) => ({
        ...item,
        time: utcToZonedTime(item.time, zoneTimeZone),
      })
    );
    // air vpd
    const firstAirVpd = airVpdResult.data?.computed_measurement[0];
    const airVpd = firstAirVpd && {
      ...firstAirVpd,
      time: utcToZonedTime(firstAirVpd.time, zoneTimeZone),
    };
    // leaf vpd
    const firstLeafVpd = leafVpdResult.data?.computed_measurement[0];
    const leafVpd = firstLeafVpd && {
      ...firstLeafVpd,
      time: utcToZonedTime(firstLeafVpd.time, zoneTimeZone),
    };

    return {
      currentZoneMeasurements: {
        ...getStatisticsFromMeasurementsView(getMeasurementType, measurements),
        ...getStatisticsFromComputedMeasurement(airVpd, AIR_VPD),
        ...getStatisticsFromComputedMeasurement(leafVpd, LEAF_VPD),
      },
      measurements,
      loading:
        measurementsResult.loading ||
        airVpdResult.loading ||
        leafVpdResult.loading,
    };
  }, [
    airVpdResult.data?.computed_measurement,
    airVpdResult.loading,
    getMeasurementType,
    leafVpdResult.data?.computed_measurement,
    leafVpdResult.loading,
    measurementsResult.data?.measurement_view,
    measurementsResult.loading,
    zoneTimeZone,
  ]);
};

/** */
function getStatisticsFromMeasurementsView(
  getMeasurementType: GetMeasurementTypeFunction,
  measurementsView: GetLimitedZoneMeasurementsQuery['measurement_view']
) {
  return measurementsView.reduce((zoneMeasurements, measurement) => {
    if (isNil(measurement.measurement_type)) {
      return zoneMeasurements;
    }

    const key = measurement.measurement_type;
    if (zoneMeasurements[key]) {
      return zoneMeasurements;
    }

    const { convertFromUnit } = getMeasurementType(
      measurement.measurement_type
    );

    const mean = convertFromUnit(Number(measurement.data));

    return { ...zoneMeasurements, [key]: { mean } };
  }, {} as TCurrentZoneMeasurements);
}

/** */
function getStatisticsFromComputedMeasurement(
  vpdMeasurement: Optional<
    ArrayElement<GetComputedMeasurementQuery['computed_measurement']>
  >,
  vpdCode: EMeasurementStatisticsTypesV2
) {
  if (!vpdMeasurement) return {};

  const measurement = vpdMeasurement!;
  const zoneMeasurements: TCurrentZoneMeasurements = {};

  if (!isNil(measurement.data)) {
    zoneMeasurements[vpdCode] = {
      mean: measurement.data[vpdCode.toLocaleLowerCase()],
    };
  }

  return zoneMeasurements;
}

export type GetMeasurementsOnGridByRunIdAndSensorTypeIdData = {
  measurementIds: Nullable<number>[];
  numberOfRows: number;
  numberOfColumns: number;
};

/** */
export function useGetMeasurementsOnGridByRunIdAndSensorTypeId({
  measurementRun,
  imageType,
  options,
}: {
  measurementRun?: Nullable<TMeasurementRun>;
  imageType: EImageTypes;
  options: {
    skip?: boolean;
    onCompleted?: (
      data: Optional<GetMeasurementsOnGridByRunIdAndSensorTypeIdData>
    ) => void;
  };
}) {
  const { data: enumerationData, loading: enumerationLoading } =
    useGetEnumerationByCodeAndTypeQuery({
      variables: {
        code: ImageTypeToSensorCodeMap[imageType],
        type: EEnumerationTypes.MEASUREMENT_TYPE,
      },
    });

  const {
    data: gridViewData,
    loading: gridViewLoading,
    ...result
  } = useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery({
    variables: {
      run_id: measurementRun?.id as number,
      sensor_type_id: enumerationData?.enumeration?.[0]?.id as number,
    },
    skip:
      !enumerationData?.enumeration?.length ||
      !measurementRun?.id ||
      options?.skip,
    onCompleted: (data) =>
      options?.onCompleted?.(
        data?.get_measurements_on_grid?.[0]
          ? {
              measurementIds:
                data.get_measurements_on_grid[0].grid_measurements,
              numberOfRows: data.get_measurements_on_grid[0].grid_shape_y || 0,
              numberOfColumns:
                data.get_measurements_on_grid[0].grid_shape_x || 0,
            }
          : undefined
      ),
  });

  const measurementIds = useMemo(
    () =>
      (gridViewData?.get_measurements_on_grid?.[0]
        ?.grid_measurements as Nullable<number>[]) || [],
    [gridViewData]
  );

  return {
    ...result,
    data: {
      measurementIds,
      numberOfRows: gridViewData?.get_measurements_on_grid?.[0]?.grid_shape_y,
      numberOfColumns:
        gridViewData?.get_measurements_on_grid?.[0]?.grid_shape_x,
    },
    loading: gridViewLoading || enumerationLoading,
  };
}

/** */
export function useGetMeasurementsByIds({
  measurementIds,
  options,
}: {
  measurementIds: Nullable<number>[];
  options?: {
    skip?: boolean;
  };
}) {
  const { zoneTimeZone } = useCurrentZone();
  const { data, ...result } = useGetMeasurementsByIdsQuery({
    variables: { ids: measurementIds.filter((id) => !isNil(id)) },
    skip: measurementIds.length === 0 || options?.skip,
  });

  const measurements = useMemo(
    () =>
      (data?.measurement &&
        data.measurement.map((measurement) => ({
          ...measurement,
          time: utcToZonedTime(measurement.time, zoneTimeZone),
        }))) ??
      [],
    [data, zoneTimeZone]
  );

  const measurementsById: Record<string, TMeasurementById> = useMemo(
    () => keyBy(measurements ?? {}, 'id'),
    [measurements]
  );

  return { measurements, measurementsById, ...result };
}

export const useGetMeasurementsByRunIdsAndPosition = ({
  measurementRunIds,
  position,
  gridSize,
  imageType,
}: {
  measurementRunIds: number[];
  position: TPosition;
  gridSize: TGridSize;
  imageType: EImageTypes;
}) => {
  const { data: enumerationData } = useGetEnumerationByCodeAndTypeQuery({
    variables: {
      code: ImageTypeToSensorCodeMap[imageType],
      type: EEnumerationTypes.MEASUREMENT_TYPE,
    },
  });
  const sensorTypeId = enumerationData?.enumeration?.[0]?.id as number;
  const { data: measurementData, ...result } =
    useGetMeasurementsByRunIdsAndPositionQuery({
      variables: {
        measurementRunIds,
        xIndex: position.x + 1,
        yIndex: gridSize.row - position.y,
        sensorTypeId,
      },
      skip: isNil(sensorTypeId),
    });

  const measurementIds = useMemo(() => {
    return (measurementData?.measurements_by_grid_index ?? []).map(
      (result) => result.measurement_id as Nullable<number>
    );
  }, [measurementData]);

  return { measurementIds, ...result };
};

export const useGetImageFeedMeasurementRuns = (
  zoneTimeZone: string,
  startTime?: number,
  endTime?: number,
  zoneId?: string
) => {
  const { data: measurementRunData, ...result } =
    useGetImageFeedMeasurementRunsQuery({
      variables: {
        end: endTime && zonedTimeToUtc(new Date(endTime), zoneTimeZone),
        start: startTime && zonedTimeToUtc(new Date(startTime), zoneTimeZone),
        zone_id: Number(zoneId),
      },
      skip: isNil(endTime) || isNil(startTime) || isNil(zoneId),
    });

  const measurementRuns = useMemo(() => {
    const runs = measurementRunData?.measurement_run || [];
    return runs
      .slice()
      .map((run) => ({
        ...run,
        start_time: utcToZonedTime(new Date(run.start_time), zoneTimeZone),
        end_time: utcToZonedTime(new Date(run.end_time), zoneTimeZone),
      }))
      .sort(
        (a, b) =>
          new Date(a.start_time).valueOf() - new Date(b.start_time).valueOf()
      );
  }, [measurementRunData, zoneTimeZone]);

  return { measurementRuns, ...result };
};

// Shouldn't leak from this module
interface MeasurementsResponse {
  measurementValues: { timestamp: number; value: number }[];
}

export const useMeasurementsQuery = ({
  zoneUid,
  zoneTimeZone,
  start,
  end,
  aggregation,
  signalId,
  source,
  enabled,
}: {
  zoneUid: string;
  zoneTimeZone: string;
  start: Date;
  end: Date;
  aggregation: Optional<MeasurementAggregation>;
  signalId: string;
  source: string;
  enabled: boolean;
}) => {
  const { apiUrl, httpClient } = useApi();
  const params = new URLSearchParams({
    zoneUid: zoneUid,
    start: String(start.valueOf() / 1000),
    end: String(end.valueOf() / 1000),
    aggregation: aggregation ? aggregation.toLowerCase() : '',
    signalId,
    source,
  });
  const url = new URL(
    `dashboard/v1/measurements?${params.toString()}`,
    apiUrl
  ).toString();

  return useQuery<
    MeasurementsResponse,
    Error,
    { dates: number[]; values: number[] }
  >({
    queryKey: ['measurements', params.toString()],
    queryFn: () => httpClient.get(url).json<MeasurementsResponse>(),
    enabled:
      enabled &&
      !!zoneUid &&
      !!start &&
      !!end &&
      !!aggregation &&
      !!signalId &&
      !!source,
    select: (data) => {
      const dates = [];
      const values = [];

      for (const { timestamp, value } of data.measurementValues) {
        const date = utcToZonedTime(timestamp * 1000, zoneTimeZone).valueOf();
        dates.push(date);
        values.push(value);
      }

      return { dates, values };
    },
  });
};

export const useGetMeasurementsByTypeAndTimeRange = ({
  typeConfig,
  zoneUid,
  zoneId,
  zoneTimeZone,
  start,
  end,
  aggregation,
}: {
  typeConfig: MeasurementTypeConfig;
  zoneUid: string;
  zoneId: number;
  zoneTimeZone: string;
  start: Date;
  end: Date;
  aggregation: Optional<MeasurementAggregation>;
}) => {
  const { Environmental, Labels, Soil } = EMeasurementGroup;
  const isContinuous = aggregation === MeasurementAggregation.CONTINUOUS;

  const baseParams = {
    typeConfig,
    zoneTimeZone,
    zoneId,
    start: zonedTimeToUtc(start, zoneTimeZone),
    end: zonedTimeToUtc(end, zoneTimeZone),
  };

  const environmental = useGetMeasurementRunsBetweenDatesByZone({
    parameters: baseParams,
    skip: typeConfig.group !== Environmental || !!isContinuous,
  });

  const environmentalContinuous = useGetUnlimitedZoneMeasurements({
    parameters: baseParams,
    skip: typeConfig.group !== Environmental || !isContinuous,
  });

  const environmentalComputed = useGetComputedMeasurement({
    parameters: baseParams,
    skip:
      typeConfig.group !== Environmental ||
      !isContinuous ||
      !typeConfig.computed,
  });

  const labelCount = useGetDailyHealthLabelsByZoneIdTypeCode({
    parameters: baseParams,
    skip: typeConfig.group !== Labels,
  });

  const soil = useGetMeasurementsByZoneIdAndSensorTypeAndSensorModelId({
    parameters: baseParams,
    skip: typeConfig.group !== Soil,
  });

  const other = useMeasurementsQuery({
    enabled: typeConfig.source === 'aranet',
    source: typeConfig.source,
    signalId: typeConfig.type,
    zoneUid,
    zoneTimeZone,
    start,
    end,
    aggregation,
  });

  const error =
    environmental.error ||
    environmentalContinuous.error ||
    environmentalComputed.error ||
    labelCount.error ||
    soil.error ||
    other.error;

  const loading =
    environmental.loading ||
    environmentalContinuous.loading ||
    environmentalComputed.loading ||
    labelCount.loading ||
    soil.loading ||
    other.isFetching;

  const called =
    environmental.called ||
    environmentalContinuous.called ||
    environmentalComputed.called ||
    labelCount.called ||
    soil.called ||
    other.isFetched;

  const [dates = [], values = []] = useMemo(() => {
    return ([
      [environmental.dates, environmental.values],
      [environmentalContinuous.dates, environmentalContinuous.values],
      [environmentalComputed.dates, environmentalComputed.values],
      [labelCount.dates, labelCount.values],
      [soil.dates, soil.values],
      [other.data?.dates, other.data?.values],
    ].find(
      ([dates, values]) =>
        dates && dates.length > 0 && values && values.length > 0
    ) ?? []) as [Date[], number[]];
  }, [
    environmental.dates,
    environmental.values,
    environmentalComputed.dates,
    environmentalComputed.values,
    environmentalContinuous.dates,
    environmentalContinuous.values,
    labelCount.dates,
    labelCount.values,
    soil.dates,
    soil.values,
    other.data?.dates,
    other.data?.values,
  ]);

  const data = useMemo(() => {
    if (!loading && called && dates && values) {
      return dates
        .toSorted(compareAsc)
        .map((date, i) => [date.valueOf(), values[i]]);
    }
    return [];
  }, [called, dates, loading, values]);

  return {
    error,
    loading,
    called,
    dates,
    values,
    data,
    minDate: dates.at(0),
    maxDate: dates.at(-1),
    minValue: Math.min(...values),
    maxValue: Math.max(...values),
  };
};
