import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { VisibilityIcon } from 'icons/VisibilityIcon';
import { VisibilityOffIcon } from 'icons/VisibilityOffIcon';
import { forwardRef, useState } from 'react';

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    textField: {
      label: {
        fontSize: 14,
      },

      input: {
        padding: spacing(1.5, 0),
        fontSize: 18,
      },

      '& .MuiInput-underline::before': {
        borderBottom: `1px solid ${palette.orange3.main}`,
      },

      '& .MuiInput-underline::after': {
        borderBottom: `2px solid ${palette.orange3.main}`,
      },
    },

    iconButton: {
      padding: spacing(0.5),
    },
  })
);

export type TTextInputProps = TextFieldProps;

/**
 * TextInput presentation component
 * Currently it's using Material-UI TextField under the hood.
 */
export const TextInput = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ type, ...props }, ref) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordShow = () => setShowPassword((show) => !show);

    const endAdornment =
      type === 'password' ? (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleTogglePasswordShow}
            className={classes.iconButton}
          >
            {showPassword ? (
              <VisibilityOffIcon className="h-6 w-6 text-neutral-600" />
            ) : (
              <VisibilityIcon className="h-6 w-6 text-neutral-600" />
            )}
          </IconButton>
        </InputAdornment>
      ) : null;

    return (
      <TextField
        className={classes.textField}
        ref={ref}
        variant="standard"
        InputProps={{
          endAdornment,
          inputProps: {
            'aria-autocomplete': 'none',
            autoComplete: 'off',
            autoCapitalize: 'off',
          },
        }}
        type={type === 'password' && showPassword ? 'default' : type}
        {...props}
      />
    );
  }
);

TextInput.displayName = 'TextInput';
