import { TextInput, TTextInputProps } from 'components/common/TextInput';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export type TTextInputFormFieldProps = TTextInputProps & {
  /** Force the field to have the name to be registered on the react hook form. */
  name: string;
};

/**
 * TextInput form field component.
 * TextInputFormField is used as a field of react-hook-form.
 *
 * @returns {JSX} - The controlled text input field.
 */
export const TextInputFormField: FC<TTextInputFormFieldProps> = ({
  name,
  required,
  ...props
}) => {
  const { register } = useFormContext();

  return (
    <TextInput
      required={required}
      {...props}
      {...register(name as any, { required })}
    />
  );
};
