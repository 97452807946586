import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const InfoIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <g clipPath="url(#info-dark_svg__a)">
      <mask
        id="info-dark_svg__b"
        width={18}
        height={18}
        x={-1}
        y={-1}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="white" d="M-1-1h18v18H-1z" />
      </mask>
      <g fill={fill} mask="url(#info-dark_svg__b)">
        <path d="M8 6.875a.75.75 0 0 1 .75.75v4.125a.75.75 0 0 1-1.5 0V7.625a.75.75 0 0 1 .75-.75M8 5.375A.937.937 0 1 0 8 3.5a.937.937 0 0 0 0 1.875" />
        <path
          fillRule="evenodd"
          d="M-.062 8a8.062 8.062 0 1 1 16.124 0A8.062 8.062 0 0 1-.063 8M8 1.438a6.562 6.562 0 1 0 0 13.124A6.562 6.562 0 0 0 8 1.438"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath id="info-dark_svg__a">
        <path fill="white" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { InfoIcon };
