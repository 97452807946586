import { useQuery } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';
import { MeasurementAggregation, Signal } from 'shared/interfaces/measurement';

interface SignalsResponse {
  signals: Signal[];
}

export const useSignalsQuery = ({
  enabled,
  zoneUid,
  start,
  end,
  aggregation,
}: {
  enabled: boolean;
  zoneUid: Maybe<string>;
  start?: Maybe<number>;
  end?: Maybe<number>;
  aggregation?: Maybe<MeasurementAggregation>;
}) => {
  const { apiUrl, httpClient } = useApi();
  const params = new URLSearchParams({
    zoneUid: String(zoneUid),
    ...(start && { start: String(start / 1000) }),
    ...(end && { end: String(end / 1000) }),
    ...(aggregation && { aggregation: aggregation.toLowerCase() }),
  });
  const url = new URL(
    `dashboard/v1/signals?${params.toString()}`,
    apiUrl
  ).toString();

  return useQuery<SignalsResponse, Error, Signal[]>({
    queryKey: ['signals', params.toString()],
    queryFn: () => httpClient.get(url).json<SignalsResponse>(),
    enabled: enabled && !!zoneUid,
    select: (data) => data.signals,
  });
};
