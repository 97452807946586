import { createStyles, makeStyles } from '@material-ui/core';
import { useScreenSize } from 'hooks/useScreenSize';
import { CheckIcon } from 'icons/CheckIcon';
import { WarningIcon } from 'icons/WarningIcon';
import { FC, ReactNode } from 'react';
import { Button } from './Button/Button';
import { Dialog, DialogActions, DialogContent, TDialogProps } from './Dialog';
import { Typography } from './Typography';

const useStyles = makeStyles((theme) =>
  createStyles({
    mobileScrollPaper: {
      alignItems: 'flex-end',
    },
    mobilePaper: {
      margin: 0,
      width: '100vw',
      overflowX: 'hidden',
    },
    dialog: {
      '.MuiDialog-paper': {
        borderRadius: theme.spacing(4),
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
      overflow: 'hidden',
      minWidth: 300,
    },
    actions: {
      display: 'flex',
      alignItems: 'stretch',
      padding: theme.spacing(4),
    },
  })
);

export type TConfirmDialogVariant = 'success' | 'error';

interface IConfirmDialogProps extends Omit<TDialogProps, 'title'> {
  /** The custom actions. */
  actions?: ReactNode;
  /** The button text. */
  buttonText?: string;
  /** The message to be shown in the dialog. */
  message?: string;
  /** The dialog title to be shown in the dialog. */
  title?: string;
  /** The dialog variant among success and error. */
  variant?: TConfirmDialogVariant;
  /** Callback to be called when the dialog closes. */
  onClose: VoidFunction;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  actions,
  buttonText = 'Close',
  message,
  open,
  title,
  variant = 'success',
  onClose,
}) => {
  const { isMobile } = useScreenSize();
  const classes = useStyles();
  const dialogTitle =
    title ?? (variant === 'success' ? 'Success!' : 'Oops, an error occurred!');

  return (
    <Dialog
      aria-label={dialogTitle}
      className={classes.dialog}
      classes={{
        scrollPaper: isMobile ? classes.mobileScrollPaper : undefined,
        paper: isMobile ? classes.mobilePaper : undefined,
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent className={classes.content}>
        {variant === 'success' ? (
          <CheckIcon className="w-16 h-16 text-green-500" />
        ) : (
          <WarningIcon className="w-16 h-16 text-red-500" />
        )}
        <Typography variant="h6">{dialogTitle}</Typography>
        {message ? (
          <Typography variant="body1" color="gray">
            {message}
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {actions ? (
          actions
        ) : (
          <Button
            variant="secondary"
            className="h-12 w-full text-base"
            onClick={onClose}
          >
            {buttonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
