import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import * as Sentry from '@sentry/react';
import { Button } from 'components/common/Button/Button';
import { FC } from 'react';

export const ErrorBoundary: FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(event) => (
        <div className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <Alert>
            <AlertTitle>Oops, something went wrong!</AlertTitle>
            <div>{event?.error?.message}</div>
            <br />
            If the error continues, please email support@neatleaf.com
            <br />
            <br />
            <Button
              variant="secondary"
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Go to home page
            </Button>
          </Alert>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
