import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LogoFullIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement> & { title?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 138 24"
    aria-hidden="true"
    className={cn('h-6 w-[138px]', className)}
    title="Neatleaf"
    {...props}
  >
    <g clipPath="url(#clip0_6577_74857)">
      <path
        d="M41.8503 10.0277C40.7748 8.8917 39.3268 8.3125 37.5515 8.3125C36.2946 8.3125 35.1673 8.6293 34.2052 9.2501C33.4277 9.7525 32.8252 10.4277 32.4073 11.2629V8.5557H29.0156V23.6693H32.4041V15.6405C32.4041 14.1749 32.7669 13.0549 33.4828 12.3029C34.1955 11.5541 35.1608 11.1765 36.3497 11.1765C37.503 11.1765 38.4197 11.5285 39.0806 12.2229C39.7414 12.9205 40.0783 13.9253 40.0783 15.2085V23.6661H43.4668V14.8853C43.47 12.7989 42.9258 11.1605 41.8503 10.0277Z"
        fill="black"
      />
      <path
        d="M60.0558 16.7416C60.1303 16.3544 60.1659 15.9032 60.1659 15.3976C60.1659 14.0888 59.8614 12.8824 59.2621 11.8168C58.6628 10.748 57.7914 9.89679 56.6738 9.28559C55.5562 8.67439 54.2377 8.36719 52.7476 8.36719C51.2769 8.36719 49.9584 8.68079 48.8214 9.29839C47.6843 9.91919 46.7805 10.8248 46.1359 11.9928C45.4912 13.1576 45.1641 14.5368 45.1641 16.0984C45.1641 17.676 45.4912 19.068 46.1359 20.2296C46.7805 21.3976 47.6843 22.3032 48.8214 22.924C49.9552 23.5416 51.2736 23.8552 52.7476 23.8552C54.0142 23.8552 55.1804 23.6216 56.2138 23.1544C57.2472 22.6904 58.0991 22.0376 58.747 21.2152C59.3949 20.3928 59.8258 19.4424 60.0266 18.3832L60.059 18.2168H56.447L56.4243 18.332C56.2689 19.1544 55.8413 19.8232 55.1577 20.3192C54.4677 20.8184 53.6125 21.0744 52.6115 21.0744C51.4713 21.0744 50.5059 20.716 49.7381 20.0088C48.9963 19.3272 48.5817 18.268 48.5072 16.86H60.0331L60.0558 16.7416ZM49.7932 12.1624C50.5804 11.4552 51.5749 11.0936 52.7508 11.0936C53.4862 11.0936 54.1762 11.2472 54.8014 11.5512C55.4169 11.852 55.9093 12.3224 56.2656 12.9496C56.5442 13.4392 56.6965 14.0312 56.7386 14.7064H48.5622C48.7145 13.612 49.1291 12.7608 49.7932 12.1624Z"
        fill="black"
      />
      <path
        d="M74.1789 11.4584C73.7578 10.6264 73.1456 9.93199 72.3584 9.38799C71.3736 8.70959 70.175 8.36719 68.7885 8.36719C67.4604 8.36719 66.2585 8.68079 65.2122 9.30159C64.1659 9.92239 63.3366 10.8312 62.747 11.996C62.1574 13.1576 61.8594 14.54 61.8594 16.0984C61.8594 17.676 62.1574 19.0648 62.747 20.2264C63.3366 21.3944 64.1659 22.3 65.2122 22.9208C66.2553 23.5416 67.4571 23.8552 68.7885 23.8552C70.175 23.8552 71.3768 23.5128 72.3584 22.8344C73.1488 22.2904 73.7578 21.596 74.1789 20.764V23.6696H77.5674V8.55599H74.1789V11.4584ZM73.5958 18.6136C73.2104 19.324 72.6791 19.8808 72.0118 20.268C71.3477 20.6584 70.5832 20.8536 69.7409 20.8536C68.4063 20.8536 67.3275 20.428 66.5339 19.5928C65.737 18.7544 65.3321 17.5768 65.3321 16.0952C65.3321 14.6136 65.737 13.436 66.5339 12.5976C67.3275 11.7592 68.4095 11.3368 69.7409 11.3368C70.5832 11.3368 71.3477 11.5352 72.0118 11.9224C72.6758 12.3096 73.2071 12.8728 73.5958 13.5896C73.9813 14.3128 74.1789 15.1544 74.1789 16.0952C74.1789 17.0552 73.9813 17.9032 73.5958 18.6136Z"
        fill="black"
      />
      <path
        d="M93.1463 4.85938H89.7578V23.6626H93.1463V4.85938Z"
        fill="black"
      />
      <path
        d="M109.744 16.8568L109.767 16.7416C109.841 16.3544 109.877 15.9032 109.877 15.3976C109.877 14.0888 109.572 12.8824 108.973 11.8168C108.374 10.748 107.502 9.89679 106.385 9.28559C105.267 8.67439 103.949 8.36719 102.459 8.36719C100.988 8.36719 99.6694 8.68078 98.5323 9.29838C97.3953 9.91918 96.4915 10.8248 95.8468 11.9928C95.2022 13.1576 94.875 14.5368 94.875 16.0984C94.875 17.676 95.2022 19.068 95.8468 20.2296C96.4915 21.3976 97.3953 22.3032 98.5323 22.924C99.6661 23.5416 100.985 23.8552 102.459 23.8552C103.725 23.8552 104.891 23.6216 105.925 23.1544C106.958 22.6904 107.81 22.0376 108.458 21.2152C109.106 20.3928 109.537 19.4424 109.738 18.3832L109.77 18.2168H106.158L106.135 18.332C105.98 19.1544 105.552 19.8232 104.869 20.3192C104.179 20.8184 103.323 21.0744 102.322 21.0744C101.182 21.0744 100.217 20.716 99.4491 20.0088C98.7072 19.3272 98.2926 18.268 98.2181 16.86H109.744V16.8568ZM99.5009 12.1656C100.288 11.4584 101.283 11.0968 102.459 11.0968C103.194 11.0968 103.884 11.2504 104.509 11.5544C105.125 11.8552 105.617 12.3256 105.973 12.9528C106.252 13.4424 106.404 14.0344 106.446 14.7096H98.2699C98.4254 13.6152 98.8368 12.764 99.5009 12.1656Z"
        fill="black"
      />
      <path
        d="M123.89 11.4584C123.469 10.6264 122.857 9.93199 122.069 9.38799C121.085 8.70959 119.886 8.36719 118.499 8.36719C117.171 8.36719 115.969 8.68079 114.923 9.30159C113.877 9.92239 113.048 10.8312 112.458 11.996C111.868 13.1576 111.57 14.54 111.57 16.0984C111.57 17.676 111.868 19.0648 112.458 20.2264C113.048 21.3944 113.877 22.3 114.923 22.9208C115.966 23.5416 117.168 23.8552 118.499 23.8552C119.886 23.8552 121.085 23.5128 122.069 22.8344C122.86 22.2904 123.469 21.596 123.89 20.764V23.6696H127.278V8.55599H123.89V11.4584ZM123.307 18.6136C122.921 19.324 122.39 19.8808 121.723 20.268C121.059 20.6584 120.294 20.8536 119.452 20.8536C118.117 20.8536 117.038 20.428 116.245 19.5928C115.448 18.7544 115.043 17.5768 115.043 16.0952C115.043 14.6136 115.448 13.436 116.245 12.5976C117.038 11.7592 118.12 11.3368 119.452 11.3368C120.294 11.3368 121.059 11.5352 121.723 11.9224C122.387 12.3096 122.918 12.8728 123.307 13.5896C123.692 14.3128 123.89 15.1544 123.89 16.0952C123.89 17.0552 123.696 17.9032 123.307 18.6136Z"
        fill="black"
      />
      <path
        d="M137.587 5.86483L137.736 5.87123V2.89843L137.603 2.89203C135.429 2.78323 133.774 3.19603 132.679 4.12403C131.578 5.05523 131.021 6.46323 131.021 8.30963V8.55283H128.977V11.4712H131.021V23.6664H134.438V11.4712H137.736V8.55283H134.438C134.438 7.54163 134.671 6.81843 135.128 6.40883C135.585 5.99603 136.411 5.81043 137.587 5.86483Z"
        fill="black"
      />
      <path
        d="M84.8375 4.85938H81.4198V8.54897H79.2656V11.4386H81.4198V23.6626H84.8375V11.4386H88.038V8.54897H84.8375V4.85938Z"
        fill="black"
      />
      <path
        d="M15.5428 20.4189C17.7683 22.6173 20.7875 23.8525 23.933 23.8525V13.2029C23.933 6.02853 18.0437 0.210938 10.7808 0.210938H7.55437H6.69268C6.69268 0.210938 8.23465 0.393338 10.3208 2.45094C10.3241 2.45414 10.3273 2.45733 10.3306 2.45733L19.401 11.4173C19.997 12.0061 20.3307 12.8029 20.3307 13.6381V15.4397L8.39014 3.64454C6.16465 1.44614 3.14549 0.210938 0 0.210938V10.8605C0 18.0349 5.8893 23.8525 13.1521 23.8525H16.3786H17.2403C17.2403 23.8525 15.6983 23.6701 13.6121 21.6125C13.6089 21.6093 13.6056 21.6061 13.6024 21.6061L4.53197 12.6461C3.93592 12.0573 3.60225 11.2605 3.60225 10.4253V8.62374L15.5428 20.4189Z"
        fill="url(#paint0_linear_6577_74857)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6577_74857"
        x1="0.037253"
        y1="0.24697"
        x2="25.3656"
        y2="25.8875"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0977" stopColor="#DD382F" />
        <stop offset="1" stopColor="#FFB94C" />
      </linearGradient>
      <clipPath id="clip0_6577_74857">
        <rect width="138" height="24" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

export { LogoFullIcon };
