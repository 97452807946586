export enum EZoneDetailTabs {
  HOMEPAGE = 'homepage-tab',
  LINE_CHART = 'line-chart-tab',
  HEAT_MAP = 'heat-map-tab',
  IMAGE_FEED = 'image-feed-tab',
  GROWTH_CYCLE_SETTINGS = 'growth-cycle-settings',
  INSIGHTS = 'insights',
}

export enum EHeaderMenuLinks {
  LOGOUT = 'logout',
  SETTINGS = 'settings',
}

export type HLink = EZoneDetailTabs | EHeaderMenuLinks;

export type TZoneMetadata = {
  image_url?: string;
  label_thresholds?: Record<string, number>;
};

export type TZone = {
  code: string;
  id: number;
  label: string;
  locationId: number;
  locationName: string;
  metadata: TZoneMetadata;
  organizationCode: string;
  timeZone: string;
  uid: string;
};
