import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const SendIcon = ({
  fill = 'white',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.65037 1.25708C4.12004 1.00214 3.57423 0.739754 3.10989 0.59481C2.62561 0.44365 1.71985 0.254154 0.891795 0.867546C0.0637468 1.48094 -0.00823698 2.39471 0.00157902 2.89735C0.010987 3.37928 0.111211 3.97174 0.208595 4.5474L0.863411 8.42624L0.206499 12.3392C0.109499 12.9158 0.00970697 13.509 0.000706978 13.9914C-0.00866102 14.4942 0.064059 15.409 0.893883 16.0217C1.72371 16.6344 2.63009 16.4426 3.11414 16.2903C3.57855 16.1442 4.12438 15.8804 4.65493 15.6239L12.3459 11.9093C13.2143 11.49 14.019 11.1015 14.5932 10.7134C15.1746 10.3205 16.0013 9.61096 16 8.42552C15.9987 7.24008 15.1704 6.53233 14.5882 6.1406C14.0131 5.75375 13.2076 5.36702 12.3382 4.94963L4.65037 1.25708ZM2.34122 7.66056H8.01816C8.45584 7.66056 8.81064 8.01184 8.81064 8.4452C8.81064 8.87856 8.45584 9.22984 8.01816 9.22984H2.33534L1.78551 12.505C1.56473 13.8202 1.45434 14.4778 1.84153 14.7637C2.22872 15.0496 2.83386 14.7574 4.04413 14.1728L11.551 10.547C13.4608 9.62472 14.4158 9.16352 14.4149 8.4272C14.4141 7.69088 13.4582 7.23176 11.5464 6.31354L4.04271 2.70947C2.83281 2.12835 2.22787 1.83779 1.84139 2.12407C1.45493 2.41035 1.56577 3.06694 1.78744 4.3801L2.34122 7.66056Z"
      fill={fill}
    />
  </svg>
);

export { SendIcon };
