import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import {
  LABEL_SELECTION_COLOR,
  LABEL_SELECTION_HOVER_COLOR,
} from 'shared/constants/label';
import { TBoundingBox } from 'shared/interfaces/general';
import { mapRange } from 'shared/utils/miscellaneous';

const MIN_BOX_AREA = 24 * 24;

const CIRCLES = [
  [80, 320, 0.3],
  [36, 144, 0.6],
  [12, 48, 1],
] as [minSize: number, maxSize: number, opacity: number][];

const getCircleSize = (scale: number, minSize: number, maxSize: number) => {
  const scaleThreshold = 0.025;
  const referenceScale = 0.6;
  return mapRange(scale, scaleThreshold, referenceScale, maxSize, minSize);
};

const getAriaLabel = ({
  comment,
  index,
  x,
  y,
}: {
  comment: string;
  index: number;
  x: number;
  y: number;
}) => `${comment} #${index} at ${Math.round(x)},${Math.round(y)}`;

interface IStylesProps {
  imageSize: TSize;
}

const useStyles = makeStyles<Theme, IStylesProps>(() =>
  createStyles({
    container: {
      position: 'relative',
      maxWidth: '100%',
      height: ({ imageSize }) => imageSize.height,
      width: ({ imageSize }) => imageSize.width,
    },
    fitImage: {
      objectFit: 'contain',
      height: ({ imageSize }) => imageSize.height,
      width: ({ imageSize }) => imageSize.width,
    },
    panLayer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'grid',
    },
    pulsatingCircle: {
      position: 'absolute',
      background: LABEL_SELECTION_COLOR,
      borderRadius: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      animation: '$pulse 3s 3',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    rectangle: {
      position: 'absolute',
      border: `3px solid ${LABEL_SELECTION_COLOR}`,
      '&:hover': {
        cursor: 'pointer',
        borderColor: LABEL_SELECTION_HOVER_COLOR,
      },
    },
    '@keyframes pulse': {
      from: { transform: 'translateX(-50%) translateY(-50%) scale(1)' },
      '50%': { transform: 'translateX(-50%) translateY(-50%) scale(0.8)' },
      to: { transform: 'translateX(-50%) translateY(-50%) scale(1)' },
    },
  })
);

interface IImageCaptureProps extends ComponentPropsWithoutRef<'img'> {
  /** The bounding boxes to display */
  boundingBoxes: TBoundingBox[];
  /** The image url */
  imageSrc: Optional<string>;
  /** The image size */
  imageSize: TSize;
  /** Current image scale  */
  scale: number;
  /** Called when the outer overlay circle is clicked */
  onClickOverlay: (element: HTMLElement) => void;
}

/**
 *  Depending on the provided scale it can display either 3 overlapping
 *  circles or a transparent rectangle identifying the overlay spot.
 * */
export const ImageCapture: FC<IImageCaptureProps> = ({
  boundingBoxes,
  imageSrc,
  imageSize,
  scale,
  onClickOverlay,
  ...props
}) => {
  const classes = useStyles({ imageSize });
  if (!imageSrc) return null;
  return (
    <div className={classes.container}>
      <img {...props} className={classes.fitImage} src={imageSrc} />

      {boundingBoxes.map(
        ({ x: left, y: top, width, height, comment, id }, bbIndex) => {
          const realBoxArea = width * height * scale;
          const displayCircles = realBoxArea < MIN_BOX_AREA;

          if (displayCircles) {
            // Display 3 overlapping circles with a pulse animation

            const boxMiddle = {
              left: left + width / 2,
              top: top + height / 2,
            };

            return CIRCLES.map(([minSize, maxSize, opacity], index) => {
              const circleSize = getCircleSize(scale, minSize, maxSize);
              const a11yAttributes =
                index === 0
                  ? {
                      role: 'mark',
                      'aria-label': getAriaLabel({
                        comment,
                        index: bbIndex + 1,
                        x: boxMiddle.left,
                        y: boxMiddle.top,
                      }),
                    }
                  : {};

              return (
                <span
                  key={`${boxMiddle.left}-${boxMiddle.top}-${circleSize}-${comment}`}
                  className={classes.pulsatingCircle}
                  onClick={(evt) => onClickOverlay(evt.currentTarget)}
                  {...a11yAttributes}
                  style={{
                    ...boxMiddle,
                    width: circleSize,
                    height: circleSize,
                    opacity,
                  }}
                />
              );
            });
          }

          return (
            // Display a rectangle
            <span
              key={id}
              className={clsx(classes.rectangle)}
              onClick={(evt) => onClickOverlay(evt.currentTarget)}
              style={{
                left,
                top,
                width,
                height,
              }}
              role="mark"
              aria-label={getAriaLabel({
                comment,
                index: bbIndex + 1,
                x: left,
                y: left,
              })}
            />
          );
        }
      )}
    </div>
  );
};
