import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LightningIcon = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 20"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      stroke={stroke}
      strokeWidth={0.5}
      d="M6.37 19a.449.449 0 0 1-.452-.505l.912-7.153H3.956a.46.46 0 0 1-.39-.215.44.44 0 0 1-.015-.44l4.943-9.444c.1-.19.322-.285.531-.225a.45.45 0 0 1 .327.47L8.77 8.056h3.274c.162 0 .311.084.393.221a.44.44 0 0 1 .006.446L6.768 18.77a.46.46 0 0 1-.398.23Zm-1.667-8.556h2.643a.46.46 0 0 1 .342.152.45.45 0 0 1 .11.353l-.683 5.36 4.155-7.355H8.273a.46.46 0 0 1-.335-.146.45.45 0 0 1-.118-.342l.424-4.786z"
    />
  </svg>
);

export { LightningIcon };
