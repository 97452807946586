import { createStyles, makeStyles } from '@material-ui/core';
import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography';
import { IUserFilterForm, UserFilterBar } from 'components/user/UserFilterBar';
import { UserTable } from 'components/user/UserTable';
import { useAuth } from 'contexts/AuthProvider';
import {
  useSettingsURL,
  useSettingsUsersURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { useUsers } from 'hooks/useUsers';
import { FC, useCallback, useMemo, useState } from 'react';
import { ALL } from 'shared/constants/general';
import { getUserName } from 'shared/utils/user';

interface IStyleProps {
  isMobile: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: ({ isMobile }: IStyleProps) => (isMobile ? '50vh' : 400),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    information: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
    inactiveCount: {
      color: theme.palette.dark6.main,
    },
    addUserButton: {
      padding: ({ isMobile }: IStyleProps) =>
        isMobile ? theme.spacing(1.5, 2) : theme.spacing(2, 2.5),
      borderRadius: 50,
      fontSize: ({ isMobile }: IStyleProps) => (isMobile ? 15 : undefined),
      fontWeight: ({ isMobile }: IStyleProps) => (isMobile ? 600 : undefined),
    },
  })
);

export const UserList: FC = () => {
  const { isMobile } = useScreenSize();
  const classes = useStyles({ isMobile });

  const { navigateToCreateNewUserForm, navigateToEditUserForm } =
    useSettingsURL();
  const { page, setPage } = useSettingsUsersURL();

  const [filterForm, setFilterForm] = useState<IUserFilterForm>({
    search: '',
    role: ALL,
  });

  const { users } = useUsers();
  const { currentlySelectedOrganization } = useAuth();

  const totalCount = users.length;
  const inactiveCount = useMemo(
    () => users.filter((user) => !user.active).length,
    [users]
  );

  const filteredUsers = useMemo(() => {
    const lowerSearch = filterForm.search.toLowerCase();

    return users
      .filter((user) => {
        // Filter by role.
        if (filterForm.role == ALL) {
          return true;
        }
        return user.user_organization_roles.find(
          (userOrganizationRole) =>
            userOrganizationRole.role.code === filterForm.role &&
            (userOrganizationRole.organization_id ===
              currentlySelectedOrganization?.id ||
              userOrganizationRole.location?.organization_id ===
                currentlySelectedOrganization?.id ||
              userOrganizationRole.zone?.location.organization_id ===
                currentlySelectedOrganization?.id)
        );
      })
      .filter((user) => {
        // Filter by search.
        if (filterForm.search === '') {
          return true;
        }
        return getUserName(user).toLowerCase().includes(lowerSearch);
      });
  }, [
    filterForm.search,
    filterForm.role,
    users,
    currentlySelectedOrganization?.id,
  ]);

  const handleClickAddUser = useCallback(() => {
    navigateToCreateNewUserForm();
  }, [navigateToCreateNewUserForm]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.information}>
          <Typography variant="h6">{totalCount} total</Typography>
          <span>·</span>
          <Typography
            variant="h6"
            fontWeight="normal"
            className={classes.inactiveCount}
          >
            {inactiveCount} inactive
          </Typography>
        </div>

        <Button className={classes.addUserButton} onClick={handleClickAddUser}>
          + Add User
        </Button>
      </div>

      <UserFilterBar form={filterForm} onChange={setFilterForm} />

      <UserTable
        page={page}
        users={filteredUsers}
        onClick={navigateToEditUserForm}
        onPageChange={setPage}
      />
    </div>
  );
};
