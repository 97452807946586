import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const ErrorIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <g clipPath="url(#error-dark_svg__a)">
      <path
        fill={fill}
        d="M13.658 2.335a8.003 8.003 0 1 0 0 11.33 8.025 8.025 0 0 0 0-11.33m-.999.998a6.61 6.61 0 0 1 .47 8.819L3.854 2.864a6.607 6.607 0 0 1 8.804.469m-9.333 9.333a6.61 6.61 0 0 1-.47-8.818l9.278 9.277a6.614 6.614 0 0 1-8.808-.459"
      />
    </g>
    <defs>
      <clipPath id="error-dark_svg__a">
        <path fill={fill} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { ErrorIcon };
