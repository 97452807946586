import {
  ComponentPropsWithoutRef,
  ReactElement,
  cloneElement,
  forwardRef,
  useMemo,
} from 'react';
import { cn } from 'shared/utils/cn';
import { v4 as uuidv4 } from 'uuid';

interface CardButtonProps extends ComponentPropsWithoutRef<'button'> {
  icon: ReactElement<HTMLElement>;
  title: string;
  subtitle: string;
}

export const CardButton = forwardRef<HTMLButtonElement, CardButtonProps>(
  function CardButton({ icon, title, subtitle, className, ...props }, ref) {
    const titleId = useMemo(() => uuidv4(), []);
    return (
      <button
        ref={ref}
        {...props}
        aria-labelledby={titleId}
        className={cn(
          'group/card items-left flex flex-col justify-center gap-2 p-6 text-left',
          'md:items-center md:text-center',
          'rounded border border-transparent bg-neutral-200',
          'hover:border-orange-500 hover:bg-transparent',
          'disabled:bg-neutral-200 disabled:text-neutral-400 disabled:hover:border-transparent',
          className
        )}
      >
        <div className="flex w-full flex-row-reverse items-center justify-between md:flex-col">
          {cloneElement(icon, {
            ...icon.props,
            className: cn(
              'mb-auto h-6 w-6 text-black md:mb-8 md:h-16 md:w-16',
              'group-disabled/card:text-neutral-400',
              icon.props.className
            ),
          })}
          <p id={titleId} className="font-semibold md:text-lg">
            {title}
          </p>
        </div>
        <p className="text-sm md:text-base">{subtitle}</p>
      </button>
    );
  }
);
