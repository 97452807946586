import { useAvailableMentions } from 'api/user';
import { Tag, TagProps } from 'components/common/Tag/Tag';
import { TextEditor } from 'components/common/TextEditor/TextEditor';
import { usePermissions } from 'hooks/usePermissions';
import { CalendarIcon } from 'icons/CalendarIcon';
import { SparklesIcon } from 'icons/SparklesIcon';
import { WarningIcon } from 'icons/WarningIcon';
import { ComponentPropsWithoutRef, ReactNode, forwardRef } from 'react';
import {
  InsightCommentContent,
  InsightSeverity,
  TDiscussion,
} from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { formatDateInMMMd } from 'shared/utils/date';
import { hasText } from 'shared/utils/textEditor';
import { CommentThread } from './CommentThread';

const severityTag: Record<InsightSeverity, TagProps['variant']> = {
  high: 'danger',
  moderate: 'warning',
  mild: 'info',
  none: 'tip500',
};

interface InsightContentProps extends ComponentPropsWithoutRef<'div'> {
  discussion: TDiscussion;
  showTime?: boolean;
  showStatus: boolean;
  preview?: ReactNode;
}

export const InsightContent = forwardRef<HTMLDivElement, InsightContentProps>(
  function InsightContent(
    { className, discussion, showTime = false, showStatus, preview, ...props },
    ref
  ) {
    const insight = discussion.firstComment.content as InsightCommentContent;
    const availableMentions = useAvailableMentions(discussion.zoneUid);
    const permissions = usePermissions();

    return (
      <div
        ref={ref}
        {...props}
        className={cn('flex flex-col gap-4 @container', className)}
      >
        <div className="flex gap-2 flex-wrap">
          {insight.severity !== InsightSeverity.None && (
            <Tag variant={severityTag[insight.severity]} className="capitalize">
              <WarningIcon />
              {insight.severity}
            </Tag>
          )}
          {showTime && (
            <Tag variant="tip900">
              <CalendarIcon />
              {formatDateInMMMd(discussion.endTime)}
            </Tag>
          )}
          {insight.occurrence && (
            <Tag variant="tip500">{insight.occurrence}</Tag>
          )}
          <Tag variant="tip500" className="capitalize">
            {insight.type}
          </Tag>
          {showStatus && (
            <Tag variant="tip500" className="capitalize">
              {discussion.status}
            </Tag>
          )}
        </div>

        <TextEditor
          key={insight.content}
          initialEditorState={insight.content}
          editable={false}
          availableMentions={availableMentions}
        />

        {preview}

        {hasText(insight.recommendation) && (
          <div className="flex flex-col gap-1 p-4 @sm:p-6 text-green-900 bg-green-100 rounded-sm whitespace-pre-wrap">
            <p className="flex gap-2 items-center font-bold">
              <SparklesIcon />
              Recommendation
            </p>
            <TextEditor
              key={insight.recommendation}
              initialEditorState={insight.recommendation}
              editable={false}
              availableMentions={availableMentions}
            />
          </div>
        )}

        {permissions.comments.canView && (
          <CommentThread discussion={discussion} />
        )}
      </div>
    );
  }
);
