import {
  ApolloCache,
  DefaultContext,
  LazyQueryExecFunction,
  MutationFunctionOptions,
} from '@apollo/client';
import { Auth, Hub } from 'aws-amplify';
import {
  Exact,
  GetUserByEmailQuery,
  UpdateUserPasswordByEmailMutation,
  useGetUserByEmailLazyQuery,
  useGetUserByEmailQuery,
  useUpdateUserPasswordByEmailMutation,
} from 'graphql/generated/react_apollo';
import Cookies from 'js-cookie';
import isNil from 'lodash.isnil';
import pick from 'lodash.pick';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAnalytics } from 'shared/analytics/useAnalytics';
import { EAuthStatus } from 'shared/interfaces/auth';
import {
  NEATLEAF_ORGANIZATION_CODE,
  TOrganization,
} from 'shared/interfaces/organization';
import { TUser, TUserInfo, UserWithMetadata } from 'shared/interfaces/user';
import { stringifyJSON } from 'shared/utils/getters';
import { getDefaultOrganization } from 'shared/utils/organization';
import {
  createHashedPassword,
  getUserFirstName,
  getUserLastName,
  getUserOrganizations,
} from 'shared/utils/user';

export interface AuthProviderProps {
  /** Shows whether the user is logged in or not. */
  authStatus: EAuthStatus;
  /** The user's email. */
  email: string;
  /** The status of the first load of the client */
  everLoaded: boolean;
  /** The user's first name. */
  firstName: string;
  /** `true` if the user is neatleaf employee. */
  isNeatleafOrganizationMember: boolean;
  /** The user's last name. */
  lastName: string;
  /** The user's name initials. */
  nameInitials: string;
  /** The currently selected organization */
  currentlySelectedOrganization: TOrganization | null;
  /** The user's organization list. */
  organizations: TOrganization[];
  /** The user information from db. */
  user: Maybe<TUser>;
  /** The user default organization code. */
  defaultOrganizationCode: string | undefined;
  /** UserInfo that has the authenticated user information. */
  userInfo: any;
  /** The user's phone number. */
  phoneNumber: string;
  /** Callback to login with email and password. */
  signIn: (email: string, password: string) => Promise<any>;
  /** Callback to sign out from the application. */
  signOut: () => Promise<void>;
  /** Callback to change the password. */
  changeInitialPassword: (
    email: string,
    oldPassword: string,
    newPassword: string
  ) => Promise<any>;
  /** Callback to change the current organization. */
  onChangeOrganization: (organization: TOrganization | null) => void;
  confirmInitialPasswordChange: () => void;
}

const AuthContext = createContext<AuthProviderProps>(null!);

const getSerializableUserInfo = (userInfo: any) =>
  pick(userInfo, ['username', 'attributes', 'firstName', 'familyName']);

export interface ISignInResponse {
  userInfo: TUserInfo;
  user: Maybe<TUser>;
}

type TUpdatePasswordFn = (
  options?:
    | MutationFunctionOptions<
        UpdateUserPasswordByEmailMutation,
        Exact<{
          email: string;
          password: string;
        }>,
        DefaultContext,
        ApolloCache<any>
      >
    | undefined
) => Promise<any>;

export interface ISignInProps {
  email: string;
  password: string;
  fetchUser: LazyQueryExecFunction<GetUserByEmailQuery, { email: string }>;
  updateUserPasswordByEmail: TUpdatePasswordFn;
}

/**
 * Returns a function that logs a note and a JSON stringified object and returns a value.
 *
 * @param {string} note - The note to be logged.
 * @param {any} value - The value to be returned.
 * @returns {function} A function that logs a note and a JSON stringified object.
 */
function logNoteAndObjectReturnValue(note: string, value: any) {
  function logNoteAndObjectHelper(obj: any) {
    console.log(`${note}: ${stringifyJSON(obj)}`);
    return value;
  }
  return logNoteAndObjectHelper;
}

/**
 * Updates the password for a user in Cognito.
 *
 * @param {any} user - The user object to update the password for.
 * @param {string} oldPassword - The old password for the user.
 * @param {string} newPassword - The new password.
 * @returns {Promise<boolean>} A promise that resolves to true if the password was successfully
 * changed and false otherwise.
 */
function updateCognitoPassword(
  user: any,
  oldPassword: string,
  newPassword: string
) {
  return Auth.changePassword(user, oldPassword, newPassword)
    .then(
      logNoteAndObjectReturnValue(
        '[updateCognitoPassword] Cognito password changed successfully.',
        true
      )
    )
    .catch(
      logNoteAndObjectReturnValue(
        '[updateCognitoPassword] Cognito password change failed.',
        false
      )
    );
}

/**
 * Updates the password for a user in the database using their email as identifier.
 *
 * @param {string} email - The email of the user whose password is being updated.
 * @param {string} hashedPassword - The new hashed password for the user.
 * @param {TUpdatePasswordFn} updateUserPasswordByEmail - A function that updates a user's password given their email.
 * @returns {Promise<boolean>} A promise that resolves to true if the password was successfully
 * changed and false otherwise.
 */
function updateDatabasePassword(
  email: string,
  hashedPassword: string,
  updateUserPasswordByEmail: TUpdatePasswordFn
) {
  return updateUserPasswordByEmail({
    variables: {
      email: email,
      password: hashedPassword,
    },
  })
    .then(
      logNoteAndObjectReturnValue(
        '[updateDatabasePassword] Database password changed successfully.',
        true
      )
    )
    .catch(
      logNoteAndObjectReturnValue(
        '[updateDatabasePassword]Database password change failed.',
        false
      )
    );
}

/**
 * Asynchronously performs a password hash migration for the given email and password,
 * updating the password in both the Cognito user pool and database to use the hashed value.
 *
 * @param {string} email - The email associated with the user account to update.
 * @param {string} password - The password associated with the user account to update.
 * @param {TUpdatePasswordFn} updateUserPasswordByEmail - A function that updates the user's password in the database.
 * @returns {Promise<TUserInfo>} A promise that resolves to the updated user information upon successful login.
 */
async function doPaswordMigration(
  email: string,
  password: string,
  updateUserPasswordByEmail: TUpdatePasswordFn
): Promise<TUserInfo> {
  let userInfo;
  const hashedPassword = await createHashedPassword(password);
  if (!hashedPassword) {
    // Creating hashed password failed. Do normal login.
    console.log(
      `[signIn] Creating hashed password failed. Doing normal login.`
    );
    userInfo = await Auth.signIn(email, password);
  } else {
    // Try login with hashed password.
    Auth.signIn(email, hashedPassword).catch(async () => {
      // Login with hashedPassword failed.
      console.log(
        `[signIn] Password is not hashed. Updating password to be hashed.`
      );

      // Try login with unhashed password.
      userInfo = await Auth.signIn(email, password);

      // Update user password to be hashed on cognito.
      const isUpdateCognitoPasswordSuccessful = await updateCognitoPassword(
        userInfo,
        password,
        hashedPassword
      );
      if (isUpdateCognitoPasswordSuccessful) {
        // Update user password to be hashed on database.
        updateDatabasePassword(
          email,
          hashedPassword,
          updateUserPasswordByEmail
        );
      }
    });
  }
  return userInfo;
}

/** Signs the user in. */
async function signIn({
  email,
  password,
  fetchUser,
  updateUserPasswordByEmail,
}: ISignInProps) {
  try {
    let userInfo;
    // TODO: remove this to enable password hash migration https://neatleaf.atlassian.net/browse/NEA-3517
    const DO_PASSWORD_MIGRATION = false;
    if (DO_PASSWORD_MIGRATION) {
      // Password migration.
      userInfo = await doPaswordMigration(
        email,
        password,
        updateUserPasswordByEmail
      );
    } else {
      // Normal sign in.
      userInfo = await Auth.signIn(email, password);
    }

    const { data } = await fetchUser({ variables: { email } });
    if (data?.user?.[0]?.active === false) {
      await Auth.signOut();
    }

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    Cookies.set('token', token, {
      domain: window.location.hostname,
      path: '/',
    });

    return {
      userInfo: getSerializableUserInfo(userInfo),
      user: data?.user?.[0],
    };
  } catch (error) {
    console.log(`[signIn] Error: ${JSON.stringify(error)}`);
    throw error;
  }
}

export const AuthProvider = ({
  children,
  testUser,
}: {
  children: ({ user }: { user: Maybe<TUser> }) => ReactNode;
  testUser?: Maybe<TUser>;
}) => {
  const { resetUser } = useAnalytics();
  const [authStatus, setAuthStatus] = useState<EAuthStatus>(
    EAuthStatus.UNDETERMINED
  );

  const [
    isPerformingInitialPasswordChange,
    setIsPerformingInitialPasswordChange,
  ] = useState<boolean>(false);

  const [fetchUser] = useGetUserByEmailLazyQuery();
  const [updateUserPasswordByEmail] = useUpdateUserPasswordByEmailMutation();

  const handleSignIn = useCallback(
    (email: string, password: string) =>
      signIn({ email, password, fetchUser, updateUserPasswordByEmail }),
    [fetchUser, updateUserPasswordByEmail]
  );

  const handleSignOut = useCallback(async () => {
    await Auth.signOut();
    setUserInfo(null);
    setCurrentlySelectedOrganization(null);
    resetUser();
    Cookies.remove('token');
  }, [resetUser]);

  const [userInfo, setUserInfo] =
    useState<Nullable<ReturnType<typeof getSerializableUserInfo>>>(null);
  const [currentlySelectedOrganization, setCurrentlySelectedOrganization] =
    useState<Nullable<TOrganization>>(null);

  const handleChangeOrganization = useCallback(
    (organization: TOrganization | null) => {
      everLoaded.current = true;
      setCurrentlySelectedOrganization(organization);
    },
    []
  );
  const handleInitialPasswordChange = useCallback(
    async (email: string, oldPassword: string, newPassword: string) => {
      setIsPerformingInitialPasswordChange(true);
      await Auth.signIn(email, oldPassword);
      const user = await Auth.currentAuthenticatedUser();
      return Auth.changePassword(user, oldPassword, newPassword);
    },
    []
  );

  const confirmInitialPasswordChange = useCallback(() => {
    setIsPerformingInitialPasswordChange(false);
  }, []);

  const email = userInfo?.attributes?.email ?? userInfo?.username;
  const { data: userData } = useGetUserByEmailQuery({
    variables: { email },
    skip: isNil(email),
  });

  const user = userData?.user?.[0] ?? null;

  const phoneNumber =
    user?.phone_number ?? userInfo?.attributes?.phone_number ?? '';
  const organizations = useMemo(() => getUserOrganizations(user), [user]);

  const isNeatleafOrganizationMember = organizations.some(
    ({ code }) => code === NEATLEAF_ORGANIZATION_CODE
  );

  const defaultZones = (user as UserWithMetadata)?.metadata?.default_zone ?? [];

  const defaultOrganizationCode = defaultZones[0]?.organization;

  useEffect(() => {
    if (organizations.length && isNil(currentlySelectedOrganization?.id)) {
      const defaultOrganization = getDefaultOrganization({
        organizations,
        defaultOrganizationCode,
      });

      if (defaultOrganization) {
        setCurrentlySelectedOrganization(defaultOrganization);
      }
    }
  }, [
    defaultOrganizationCode,
    organizations,
    currentlySelectedOrganization?.id,
  ]);

  const everLoaded = useRef(false);

  const firstName = getUserFirstName(user, userInfo);
  const lastName = getUserLastName(user, userInfo);
  const nameInitials = [firstName, lastName]
    .map((each) => each?.charAt(0))
    .filter((each) => each)
    .join('');

  const authenticate = useCallback(() => {
    if (isPerformingInitialPasswordChange) {
      // Dirty Hack:
      // We can't update any auth provider state when we change the initial password as
      // that would trigger a re-render of the auth provider which in turn prevents
      // the default flow (i.e. show the success modal) from succeeding
      return;
    }
    if (testUser) {
      setUserInfo(getSerializableUserInfo(testUser));
      setAuthStatus(EAuthStatus.LOGGED_IN);
      return;
    }
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUserInfo(getSerializableUserInfo(currentUser));
        setAuthStatus(EAuthStatus.LOGGED_IN);
      })
      .catch(() => {
        setUserInfo(null);
        setAuthStatus(EAuthStatus.LOGGED_OUT);
      });
  }, [isPerformingInitialPasswordChange, testUser]);

  useEffect(() => {
    authenticate();
    const listener = async (data: any) => {
      if (data.payload.event === 'signIn' || data.payload.event === 'signOut') {
        authenticate();
      } else if (data.payload.event === 'tokenRefresh') {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        Cookies.set('token', token, {
          domain: window.location.hostname,
          path: '/',
        });
      }
    };
    return Hub.listen('auth', listener); // returns a function to unsubscribe
  }, [authenticate]);

  /**
   * When the user is not active, we try to sign out.
   */
  useEffect(() => {
    if (user?.active === false) {
      handleSignOut();
    }
  }, [user?.active, handleSignOut]);

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        user,
        userInfo,
        firstName,
        lastName,
        everLoaded: everLoaded.current,
        phoneNumber,
        nameInitials,
        defaultOrganizationCode,
        currentlySelectedOrganization,
        isNeatleafOrganizationMember,
        organizations,
        email,
        signIn: handleSignIn,
        signOut: handleSignOut,
        changeInitialPassword: handleInitialPasswordChange,
        confirmInitialPasswordChange: confirmInitialPasswordChange,
        onChangeOrganization: handleChangeOrganization,
      }}
    >
      <>{children({ user })}</>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
