import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const BellNotificationsIcon = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 18"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M17.0598 14.6201L17.0634 14.614L17.0662 14.6074C17.1467 14.4202 17.1528 14.1917 17.0322 14.0114L17.0315 14.0104C15.5251 11.8065 14.7136 9.26394 14.6009 6.26638L14.6009 6.26627C14.4848 3.24829 12.0464 0.878906 9.02697 0.878906C6.00654 0.878906 3.56905 3.27248 3.45303 6.31149L3.45303 6.31156C3.34028 9.31082 2.5518 11.8287 0.979108 14.0077L0.979026 14.0077L0.976253 14.0119C0.864797 14.1802 0.83232 14.4088 0.945339 14.6137C1.03788 14.8216 1.2451 14.9332 1.46627 14.9332H1.46729H6.24599C6.52229 16.2111 7.63878 17.1805 9.00489 17.1805C10.3687 17.1805 11.509 16.2125 11.7655 14.9332H16.5425C16.7489 14.9332 16.9477 14.8162 17.0598 14.6201ZM13.444 6.34399C13.555 9.1632 14.2276 11.621 15.5055 13.7969L2.52946 13.7977C3.82935 11.6235 4.52266 9.18709 4.61204 6.38913L4.61209 6.38914L4.6121 6.38564L4.61218 6.36478C4.70125 3.95725 6.64511 2.0599 9.02805 2.0599C11.4125 2.0599 13.3561 3.936 13.444 6.34364L13.444 6.34399ZM9.00489 16.0232C8.28265 16.0232 7.68591 15.572 7.44962 14.934H10.5822C10.3441 15.5712 9.72974 16.0232 9.0271 16.0232H9.00489Z"
      fill={fill}
      stroke={stroke}
      strokeWidth="0.25"
    />
  </svg>
);

export { BellNotificationsIcon };
