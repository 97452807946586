import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { TextInput } from 'components/common/TextInput';
import { useScreenSize } from 'hooks/useScreenSize';
import { useUsers } from 'hooks/useUsers';
import { FC } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },

    searchInput: {
      width: 300,
    },

    roleList: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },

    role: {
      cursor: 'pointer',
      color: theme.palette.grey30.main,
    },

    activeRole: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.8,
      color: theme.palette.orange2.main,
    },
  })
);

export interface IUserFilterForm {
  search: string;
  role: string;
}

interface IUserFilterBar {
  form: IUserFilterForm;
  onChange: (form: IUserFilterForm) => void;
}

export const UserFilterBar: FC<IUserFilterBar> = ({ form, onChange }) => {
  const { isMobile } = useScreenSize();
  const classes = useStyles();
  const { userRoleTypesWithAll } = useUsers();

  return (
    <div className={classes.container}>
      <div className={classes.searchInput}>
        <TextInput
          name="search"
          label="Search"
          value={form.search}
          fullWidth
          onChange={(event) =>
            onChange({ ...form, search: event.target.value })
          }
        />
      </div>

      {!isMobile && (
        <div className={classes.roleList}>
          {userRoleTypesWithAll.map(({ code: role, filterLabel }) => (
            <span
              role="button"
              key={role}
              className={clsx(
                classes.role,
                form.role === role && classes.activeRole
              )}
              onClick={() => onChange({ ...form, role })}
            >
              {filterLabel}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
