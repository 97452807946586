import { TabGroup } from 'components/common/TabGroup';
import { Header, HeaderProps, THeaderLink } from 'components/header/Header';
import { useAuth } from 'contexts/AuthProvider';
import {
  useSettingsURL,
  useURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useLogOut } from 'hooks/useLogOut';
import { usePermissions } from 'hooks/usePermissions';
import { OrganizationNotFoundBoundary } from 'pages/not_found/OrganizationNotFoundBoundary';
import { useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { ESettingsTabs } from 'shared/interfaces/settings';
import {
  EHeaderMenuLinks,
  EZoneDetailTabs,
  HLink,
} from 'shared/interfaces/zone';

const { HOMEPAGE } = EZoneDetailTabs;
const { LOGOUT, SETTINGS } = EHeaderMenuLinks;

export const SettingsPage = () => {
  const { canEditUsers } = usePermissions();
  const { organizations, onChangeOrganization, currentlySelectedOrganization } =
    useAuth();
  const {
    organization: organizationCode,
    tab,
    navigateToTab,
    setOrganization,
  } = useSettingsURL();
  const { logOut } = useLogOut();
  const { navigateToHome } = useURL();

  const headerLinks: THeaderLink<HLink>[] = useMemo(
    () => [
      {
        id: SETTINGS,
        label: 'User Settings',
      },
      {
        id: HOMEPAGE,
        label: 'Zone Details',
        onClick: navigateToHome,
      },
      {
        id: LOGOUT,
        label: 'Logout',
        isMenuItem: true,
        onClick: logOut,
      },
    ],
    [logOut, navigateToHome]
  );

  const tabs = useMemo(() => {
    return [
      {
        label: 'Profile',
        flag: true,
        id: ESettingsTabs.PROFILE,
      },
      {
        label: 'Users',
        flag: canEditUsers,
        id: ESettingsTabs.USERS,
      },
      {
        label: 'Email Notification Settings',
        flag: true,
        id: ESettingsTabs.NOTIFICATIONS,
      },
      {
        label: 'Critical Environment Settings',
        flag: true,
        id: ESettingsTabs.CRITICAL_ENV_SETTINGS,
      },
    ].filter((tab) => tab.flag);
  }, [canEditUsers]);

  const handleChangeTab = useCallback(
    (tab: ESettingsTabs) => {
      navigateToTab(tab);
    },
    [navigateToTab]
  );

  const handleChangeOrg: HeaderProps<HLink>['onChangeOrg'] = (org) => {
    setOrganization(org.code);
  };

  useEffect(() => {
    if (
      organizations.length &&
      !organizations.find((org) => org.code === organizationCode)
    ) {
      if (currentlySelectedOrganization) {
        setOrganization(currentlySelectedOrganization.code, {
          replace: true,
        });
      }
    } else {
      const selectedOrganization = organizations.find(
        (org) => org.code === organizationCode
      );
      if (selectedOrganization) {
        onChangeOrganization(selectedOrganization);
      }
    }
  }, [
    organizationCode,
    onChangeOrganization,
    organizations,
    currentlySelectedOrganization,
    setOrganization,
  ]);

  return (
    <OrganizationNotFoundBoundary organization={currentlySelectedOrganization}>
      <Header
        className="pt-4 px-4 lg:px-8"
        orgs={organizations}
        links={headerLinks}
        selectedOrg={currentlySelectedOrganization}
        selectedLink={headerLinks.at(0)!}
        onChangeOrg={handleChangeOrg}
        onClickHome={navigateToHome}
      />

      <main className="flex flex-col gap-4 px-4 lg:gap-6 lg:px-8">
        <TabGroup
          tabs={tabs}
          value={tab}
          onChange={handleChangeTab as (tab: string) => void}
        />

        <div className="flex flex-1 flex-col gap-4 py-4 lg:gap-6 lg:py-6">
          <Outlet />
        </div>
      </main>
    </OrganizationNotFoundBoundary>
  );
};
