import { utcToZonedTime } from 'date-fns-tz';
import { useGetDailyHealthLabelsByZoneIdTypeCodeQuery } from 'graphql/generated/react_apollo';
import { useMemo } from 'react';
import { GetLineChartDataInput } from 'shared/interfaces/measurement';

export const useGetDailyHealthLabelsByZoneIdTypeCode = ({
  parameters: { zoneId, zoneTimeZone, start, end, typeConfig },
  skip,
}: GetLineChartDataInput) => {
  const { statisticsKey } = typeConfig;
  const { data, ...result } = useGetDailyHealthLabelsByZoneIdTypeCodeQuery({
    skip: skip,
    variables: {
      zoneId: zoneId,
      start: start,
      end: end,
      typeCode: typeConfig.statisticsKeyV2,
    },
  });
  const { dates, values } = useMemo(() => {
    if (!data) {
      return { dates: [], values: [] };
    }

    const dates = data.computed_measurement.map(({ time }) =>
      utcToZonedTime(time, zoneTimeZone)
    );

    const values = data.computed_measurement.map(({ data }) => {
      const value = Number(data[statisticsKey]);
      return value * 100;
    });

    return { dates, values };
  }, [data, statisticsKey, zoneTimeZone]);

  return { dates, values, ...result };
};
