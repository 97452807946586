import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { Typography } from 'components/common/Typography';
import { useAuth } from 'contexts/AuthProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { useUsers } from 'hooks/useUsers';
import { ChangeEvent, FC } from 'react';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import {
  NEATLEAF_PLANT_SCIENTIST_CODE,
  NEATLEAF_PLANT_SCIENTIST_PUBLISHER_CODE,
} from 'shared/interfaces/role';
import { EUserRole, TUserRoleType } from 'shared/interfaces/user';

interface IStyleProps {
  isMobile: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    selector: {
      padding: theme.spacing(1, 0),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },

    roleList: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },

    role: {
      display: 'flex',
      justifyContent: 'center',
      background: theme.palette.white.main,
      border: `2px solid ${theme.palette.grey4.main}`,
      borderRadius: theme.spacing(2.5),
      padding: theme.spacing(3),
      width: ({ isMobile }: IStyleProps) => (isMobile ? 105 : 200),
      '& .MuiFormControlLabel-label': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 14,
      },
      '& .MuiFormControlLabel-root': {
        marginRight: ({ isMobile }: IStyleProps) => (isMobile ? 2 : undefined),
      },
    },

    checkedRole: {
      background: theme.palette.grey2.main,
    },

    checkbox: {
      fontSize: ({ isMobile }: IStyleProps) => (isMobile ? 10 : undefined),
      padding: ({ isMobile }: IStyleProps) => (isMobile ? 5 : undefined),
      '&.Mui-checked': {
        color: theme.palette.orange3.main,
      },
    },
  })
);

interface IUserRoleSelectorProps {
  /** The user roles. */
  roles: string[];
  /** The callback to change user roles. */
  onChange: (roles: string[]) => void;
}

export const UserRoleSelector: FC<IUserRoleSelectorProps> = ({
  roles,
  onChange,
}) => {
  const { isMobile } = useScreenSize();
  const classes = useStyles({ isMobile });
  const { userRoleTypes } = useUsers();
  const { currentlySelectedOrganization } = useAuth();
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const role = event.target.name;
    if (checked) {
      onChange([...roles, role]);
    } else {
      onChange(roles.filter((each) => each !== role));
    }
  };

  const roleSet = new Set(roles);

  const renderRoleType = (roleType: TUserRoleType) => {
    const isCultivatorRole = roleType.code === EUserRole.CULTIVATOR;
    const checked = roleSet.has(roleType.code) || isCultivatorRole;

    return (
      <div
        key={roleType.code}
        className={clsx(classes.role, checked && classes.checkedRole)}
      >
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              name={roleType.code}
              checked={checked}
              onChange={handleChange}
              disabled={isCultivatorRole}
            />
          }
          label={roleType.label}
        />
      </div>
    );
  };

  return (
    <div className={classes.selector}>
      <Typography color="gray" variant="body2">
        Role
      </Typography>

      <div className={classes.roleList}>
        {userRoleTypes
          .filter((userRoleType) => {
            // eslint-disable-next-line sonarjs/prefer-single-boolean-return
            if (
              currentlySelectedOrganization &&
              currentlySelectedOrganization.code !==
                NEATLEAF_ORGANIZATION_CODE &&
              (userRoleType.code === NEATLEAF_PLANT_SCIENTIST_CODE ||
                userRoleType.code === NEATLEAF_PLANT_SCIENTIST_PUBLISHER_CODE)
            ) {
              return false;
            }
            return true;
          })
          .map(renderRoleType)}
      </div>
    </div>
  );
};
