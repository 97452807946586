import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const NewCycleIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      d="M15.192 0a.42.42 0 0 0-.26.077c-1.95 1.376-4.174 1.835-6.338 2.343-2.164.507-4.29 1.07-5.878 2.77l-.01.01C1.529 6.55 1.04 8.13 1.17 9.687c.102 1.202.568 2.385 1.335 3.463-.793.32-1.512.507-2.088.515a.42.42 0 1 0 .012.843c.797-.01 1.692-.252 2.632-.654q.096.112.199.22c2.486 2.665 6.523 2.53 8.892-.185l.001-.002c3.144-3.641 3.902-8.33 3.442-13.502A.42.42 0 0 0 15.192 0m-.397 1.13c.325 4.762-.46 8.936-3.279 12.203-2.072 2.374-5.468 2.492-7.64.165l-.017-.02c.438-.221.882-.477 1.329-.753 1.341.284 2.856.186 4.446-.064a.422.422 0 0 0-.13-.833c-1.165.182-2.254.259-3.225.18.097-.067.194-.13.29-.2a28 28 0 0 0 2.148-1.706c.981.243 2.065.41 3.241-.067a.42.42 0 1 0-.317-.782c-.745.303-1.433.27-2.14.134.994-.946 1.87-1.929 2.523-2.868a.422.422 0 0 0-.693-.482c-.527.759-1.233 1.57-2.037 2.368.153-.907.21-1.896.026-3.442a.422.422 0 1 0-.838.1c.254 2.135.07 3.015-.23 4.323a27 27 0 0 1-2.172 1.736c-.172.122-.346.237-.519.353.148-.77.282-1.602.347-2.424.09-1.128.08-2.186-.261-2.971a.422.422 0 0 0-.774.337c.222.509.28 1.497.194 2.567-.083 1.05-.284 2.185-.484 3.114-.438.261-.871.497-1.291.7-.752-1.008-1.19-2.101-1.281-3.183-.113-1.336.289-2.661 1.328-3.854 1.393-1.489 3.304-2.017 5.448-2.52 1.942-.455 4.05-.925 6.008-2.112"
    />
  </svg>
);

export { NewCycleIcon };
