import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react';

import { cn } from 'shared/utils/cn';
import { Label, type LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';

const _variants = ['outline', 'flat'] as const;

type Variant = (typeof _variants)[number];

interface RadioInputProps extends ComponentPropsWithoutRef<'input'> {
  /** The radio variant. Optional. Defaults to `flat`.  */
  variant?: Variant;
  /**
   * Sets a label on the radio. When set the radio is wrapped in a HTML label element.
   * When not set the Radio is rendered without a HTML label element */
  label?: LabelProps['value'];
  /**
   * Sets the placement of the radio label.
   * Defaults to `right` when variant is `flat` or `right` when variant is `flat`.
   */
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
  /** An optional status text displayed below the field  */
  statusText?: React.ReactNode;
}

/** Radio button is rendered as a small circle and highlighted when selected. */
const Input = forwardRef<HTMLInputElement, RadioInputProps>(function Input(
  {
    variant = 'flat',
    label,
    labelPlacement = variant === 'flat' ? 'right' : 'left',
    labelClassName,
    className,
    id,
    disabled,
    ...props
  },
  ref
) {
  return (
    <Label
      value={label}
      placement={labelPlacement}
      disabled={disabled}
      className={cn(
        'px-1 font-normal text-neutral-900 rounded-xs',
        'hover:bg-neutral-200 active:text-orange-500',
        'has-[:focus]:outline outline-2 outline-blue-500 -outline-offset-2',
        variant === 'outline' && [
          'w-full justify-between rounded-full border border-neutral-400 px-4',
          'hover:border-neutral-500 hover:bg-white',
          'aria-disabled:border-neutral-400 aria-disabled:bg-neutral-200',
          'has-[:invalid]:border-red-500 has-[:invalid]:bg-red-100',
          (props.defaultChecked || props.checked) &&
            'border-orange-500 bg-orange-100 hover:border-orange-500 hover:bg-orange-100',
        ],
        labelClassName
      )}
    >
      <input
        ref={ref}
        {...props}
        id={id}
        disabled={disabled}
        role="radio"
        type="radio"
        className={cn(
          'relative h-9 w-4 appearance-none rounded-full bg-transparent',
          'before:absolute before:left-0 before:top-[10px] before:h-4 before:w-4 before:rounded-md before:border',
          'before:border-neutral-900 before:bg-white before:hover:bg-neutral-200 before:active:border-orange-500',
          'after:left-1 after:top-[14px] after:h-2 after:w-2 after:rounded-sm',
          'after:bg-neutral-900 after:active:bg-orange-500',
          'checked:before:border-neutral-900 checked:after:absolute',
          'disabled:bg-neutral-200 disabled:bg-transparent disabled:before:border-neutral-400',
          'disabled:before:bg-neutral-200 disabled:after:bg-neutral-400',
          'invalid:before:border-red-500 invalid:before:bg-red-100 invalid:after:bg-red-500',
          'invalid:checked:before:border-red-500 invalid:before:hover:bg-red-100',
          label && 'outline-none',
          variant === 'outline' && [
            'before:hover:bg-white',
            (props.defaultChecked || props.checked) && [
              'checked:before:border-orange-500',
              'before:bg-orange-100 after:bg-orange-500 before:hover:bg-orange-100',
            ],
          ],
          className
        )}
      />
    </Label>
  );
});

type RadioGroupProps = Omit<
  RadioInputProps,
  'checked' | 'defaultChecked' | 'value' | 'defaultValue' | 'children'
> & {
  children: ReactElement<RadioInputProps>[];
  /** Sets the orientation of the child radio inputs */
  orientation?: 'horizontal' | 'vertical';
  /** The radio variant. Optional. Defaults to `flat`.  */
  variant?: Variant;
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** An optional field label */
  label?: LabelProps['value'];
  /** Sets the placement of the label. Defaults to `top` */
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
} & Exclusive<
    /** `defaultValue` and `value` are mutually exclusive */
    {
      /**
       * Sets an initial value on the group. In keeping with defaultChecked on radio inputs.
       * Best for use when the component is uncontrolled - for controlled components use `value` prop
       */
      defaultValue?: string;
    },
    {
      /**
       * Sets a value on the group. In keeping with checked on radio inputs.
       * Best for use when the component is controlled - for uncontrolled components use `defaultValue` prop
       */
      value?: string;
    }
  >;

/**
 * A radio group is a set of checkable buttons, known as radio buttons,
 * where no more than one of the buttons can be checked at a time.
 */
const Group = forwardRef<HTMLDivElement, RadioGroupProps>(function Group(
  {
    className,
    children,
    orientation = 'vertical',
    variant = 'flat',
    defaultValue,
    value,
    id,
    helperText,
    errorText,
    label,
    labelPlacement = 'top',
    labelClassName,
    ...props
  },
  ref
) {
  return (
    <fieldset className="group/radio flex flex-col gap-1">
      <Label
        as="div"
        value={label}
        placement={labelPlacement}
        className={labelClassName}
      >
        <div
          id={id}
          ref={ref}
          className={cn(
            'flex',
            variant === 'outline' && 'gap-1',
            orientation === 'vertical' ? 'flex-col' : 'flex-row',
            className
          )}
          aria-label={typeof label === 'string' ? label : undefined}
          role="radiogroup"
        >
          {Children.map(children, (child: React.ReactElement) => {
            const checkedProps = {
              defaultChecked:
                defaultValue && child.props.value === defaultValue
                  ? true
                  : undefined,
              checked:
                value !== undefined ? child.props.value === value : undefined,
            };

            return cloneElement(child, {
              ...props,
              ...checkedProps,
              ...child.props,
              variant,
            });
          })}
        </div>
      </Label>
      {helperText && (
        <StatusText className="pl-2 group-valid/radio:block">
          {helperText}
        </StatusText>
      )}
      {errorText && (
        <StatusText className="pl-2 text-red-500 group-invalid/radio:block">
          {errorText}
        </StatusText>
      )}
    </fieldset>
  );
});

export const Radio = {
  Group,
  Input,
};
