import { Section } from 'components/image_feed/Section';
import { getSectionHighlightOffset } from 'components/image_feed/utils';
import isNil from 'lodash.isnil';
import { FC, memo, useCallback, useMemo } from 'react';
import { Group } from 'react-konva';
import { LOADING_IMAGE, NO_IMAGE } from 'shared/constants/image';
import {
  EImageLoadStatus,
  ISectionInformation,
  TImagesGrid,
  TLabelsByMeasurementId,
} from 'shared/interfaces/image';
import { generateSequentialArray } from 'shared/utils/array';
import useImage from 'use-image';
import { NOT_LOADED_IMAGE_SIZE_INDEX } from './constants';
import { useImageSizeIndexMap } from './hooks/useImageSizeIndexMap';

const SECTION_FONT_SIZE = 16;
interface IRect {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface ISectionsLayerProps {
  /** Current stage scale. */
  scale: TScale;
  /** Show debug information. */
  debugImageFeedFlag: boolean;
  /** Show debug information. */
  debugImageSizeIndexFlag: boolean;
  /** The grid images information. */
  imagesGrid: TImagesGrid;
  /** The labels by measurement id. */
  labelsByMeasurementId: TLabelsByMeasurementId;
  /** List of sections to be highlighted */
  sectionsHighlights: Record<number, string> | undefined;
  /** `true` if we need to show the grid info. */
  showGridInfo: boolean;
  /** Callback to be called when the section is clicked. */
  onClick: (row: number, column: number) => void;
  /** Sorted image sizes from small to large  */
  sortedImageSizes: TSize[];
  /** Small image size index */
  imageSizeIndex: number;
  /** Largest small image index */
  largestSmallImageIndex: number;
  /** Callback to be called when all images are loaded */
  onImageLoaded: () => void;
  /** Measurement run id */
  measurementRunId: Nullable<number>;
  /** Visible area */
  visibleArea: IRect | undefined;
}

const isInsideVisibleArea = (shape: IRect, visibleArea: IRect) => {
  const shapeRight = shape.x + shape.width;
  const shapeBottom = shape.y + shape.height;

  const visibleRight = visibleArea.x + visibleArea.width;
  const visibleBottom = visibleArea.y + visibleArea.height;

  return (
    shape.x < visibleRight &&
    shapeRight > visibleArea.x &&
    shape.y < visibleBottom &&
    shapeBottom > visibleArea.y
  );
};

const SectionsLayerComponent: FC<ISectionsLayerProps> = ({
  scale,
  debugImageFeedFlag,
  debugImageSizeIndexFlag,
  imagesGrid,
  labelsByMeasurementId,
  sectionsHighlights,
  showGridInfo,
  onClick,
  sortedImageSizes,
  imageSizeIndex,
  largestSmallImageIndex,
  onImageLoaded,
  measurementRunId,
  visibleArea,
}) => {
  const rows = generateSequentialArray(imagesGrid.length);
  const columns = generateSequentialArray(imagesGrid[0]?.length ?? 0);
  const sectionHighlightOffset = getSectionHighlightOffset(scale.x);
  const [noImage, noImageStatus] = useImage(NO_IMAGE.url);
  const [loadingImage, loadingImageStatus] = useImage(LOADING_IMAGE.url);
  const { getCurrentImageSizeIndex, updateImageSizeIndexMap } =
    useImageSizeIndexMap();
  const sectionSize = sortedImageSizes[largestSmallImageIndex]!;
  const maxFontSize = sectionSize.width / 20;
  const scaledFontSize = SECTION_FONT_SIZE / scale.x;
  const fontSize = Math.min(scaledFontSize, maxFontSize);

  const gridSize = useMemo(
    () => ({ row: rows.length, column: columns.length }),
    [rows.length, columns.length]
  );
  const handleImageLoaded = useCallback(
    (
      measurementRunId: number,
      row: number,
      column: number,
      imageSizeIndex: number
    ) => {
      updateImageSizeIndexMap(
        measurementRunId,
        imageSizeIndex,
        gridSize,
        row,
        column
      );
      onImageLoaded();
    },
    [updateImageSizeIndexMap, onImageLoaded, gridSize]
  );
  const renderSectionRow = (row: number) => {
    return columns.map((column) => {
      const sectionInformation = imagesGrid[row]?.[column];
      const measurementId = sectionInformation?.measurementId;

      if (
        measurementRunId === null ||
        measurementId === undefined ||
        !sectionInformation
      ) {
        return null;
      }
      const currentImageSizeIndex = getCurrentImageSizeIndex(
        measurementRunId,
        gridSize,
        row,
        column
      );

      const shapeFromSectionInformation = (image: ISectionInformation) => {
        return {
          x: image.cellX * sectionSize.width,
          y: image.cellY * sectionSize.height,
          width: sectionSize.width,
          height: sectionSize.height,
        };
      };

      const isSectionVisible =
        visibleArea &&
        isInsideVisibleArea(
          shapeFromSectionInformation(sectionInformation),
          visibleArea
        );
      let renderedImageSizeIndex = currentImageSizeIndex;
      if (isSectionVisible && imageSizeIndex > currentImageSizeIndex) {
        renderedImageSizeIndex = currentImageSizeIndex + 1;
      }

      // make sure we do not render the highest resolution image
      renderedImageSizeIndex = Math.min(
        renderedImageSizeIndex,
        largestSmallImageIndex
      );

      return (
        (currentImageSizeIndex !== NOT_LOADED_IMAGE_SIZE_INDEX ||
          isSectionVisible) && (
          <Section
            key={column}
            fontSize={fontSize}
            debugImageFeedFlag={debugImageFeedFlag}
            debugImageSizeIndexFlag={debugImageSizeIndexFlag}
            labels={
              isNil(measurementId)
                ? undefined
                : labelsByMeasurementId?.[measurementId]
            }
            sectionHighlightColor={
              isNil(measurementId)
                ? undefined
                : sectionsHighlights?.[measurementId]
            }
            sectionInformation={sectionInformation}
            showGridInfo={showGridInfo}
            onClick={() => onClick(row, column)}
            imageSizeIndex={renderedImageSizeIndex}
            sortedImageSizes={sortedImageSizes}
            largestSmallImageIndex={largestSmallImageIndex}
            onLoaded={() => {
              handleImageLoaded(measurementRunId, row, column, imageSizeIndex);
            }}
            sectionHighlightOffset={sectionHighlightOffset}
            currentImageSizeIndex={currentImageSizeIndex}
            visible={isSectionVisible}
            measurementRunId={measurementRunId}
            noImage={noImage}
            noImageStatus={noImageStatus as EImageLoadStatus}
            loadingImage={loadingImage}
            loadingImageStatus={loadingImageStatus as EImageLoadStatus}
          />
        )
      );
    });
  };
  return (
    <Group>
      {rows.map((row) => (
        <Group key={row}>{renderSectionRow(row)}</Group>
      ))}
    </Group>
  );
};

export const SectionsLayer = memo(
  SectionsLayerComponent,
  (prevProps, nextProps) => {
    const sameProps = (
      Object.keys(prevProps) as (keyof ISectionsLayerProps)[]
    ).reduce((prev, curr) => prev && prevProps[curr] === nextProps[curr], true);

    const measurementRunMismatch =
      nextProps.measurementRunId !== nextProps.imagesGrid.at(0)?.at(0)?.runId;

    // We reuse a previously rendered component if:
    // - we have the same props (like as usual) OR
    // - if there is a mismatch between selected measurement run and image grid measurement run.
    // This is because we update the image grid only after successfully having
    // fetched its information, so while fetching we have a mismatch between
    // the newly selected run and the images grid run. During that time we do not
    // want to render this component (e.g it would use the wrong imageSizeIndex map, ..).
    return sameProps || measurementRunMismatch;
  }
);
