import { Wrap } from 'components/common/Wrap/Wrap';
import { WarningIcon } from 'icons/WarningIcon';
import { ReactNode } from 'react';

const DEFAULT_LABEL = 'No data available';
const DEFAULT_MESSAGE =
  'There is no data available for this time range. Please, select a different one to continue.';

interface INoDataViewProps {
  label?: ReactNode;
  message?: ReactNode;
}

export const NoDataView = ({
  label = DEFAULT_LABEL,
  message = DEFAULT_MESSAGE,
}: INoDataViewProps) => {
  return (
    <div className="w-full h-full flex justify-center">
      <div className="max-w-sm flex flex-col gap-2 items-center justify-center">
        <WarningIcon className="w-24 h-24 text-orange-500" />

        <Wrap
          condition={typeof label === 'string'}
          with={(children) => (
            <div className="text-center text-lg font-bold">{children}</div>
          )}
        >
          {label}
        </Wrap>

        <Wrap
          condition={typeof message === 'string'}
          with={(children) => <div className="text-center">{children}</div>}
        >
          {message}
        </Wrap>
      </div>
    </div>
  );
};
