import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const AirTempIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M4.03906 1.94444C4.03906 0.870557 4.87853 0 5.91406 0C6.9496 0 7.78906 0.870557 7.78906 1.94444V10.0157C8.49229 10.5997 8.91406 11.4855 8.91406 12.4444C8.91406 14.1627 7.57092 15.5556 5.91406 15.5556C4.25721 15.5556 2.91406 14.1627 2.91406 12.4444C2.91406 11.4855 3.33584 10.5997 4.03906 10.0157V1.94444ZM4.78906 10.2102C4.78906 10.3373 4.72914 10.4564 4.6286 10.5291C4.02856 10.9632 3.66406 11.6717 3.66406 12.4444C3.66406 13.7331 4.67142 14.7778 5.91406 14.7778C7.1567 14.7778 8.16406 13.7331 8.16406 12.4444C8.16406 11.6717 7.79957 10.9632 7.19953 10.5291C7.09898 10.4564 7.03906 10.3373 7.03906 10.2102V1.94444C7.03906 1.30011 6.53538 0.777778 5.91406 0.777778C5.29274 0.777778 4.78906 1.30011 4.78906 1.94444V10.2102ZM5.53906 10.9379V5.31481C5.53906 5.10004 5.70696 4.92593 5.91406 4.92593C6.12117 4.92593 6.28906 5.10004 6.28906 5.31481V10.9379C6.93602 11.1106 7.41406 11.7196 7.41406 12.4444C7.41406 13.3036 6.74249 14 5.91406 14C5.08564 14 4.41406 13.3036 4.41406 12.4444C4.41406 11.7196 4.8921 11.1106 5.53906 10.9379ZM5.91406 13.2222C6.32828 13.2222 6.66406 12.874 6.66406 12.4444C6.66406 12.0149 6.32828 11.6667 5.91406 11.6667C5.49985 11.6667 5.16406 12.0149 5.16406 12.4444C5.16406 12.874 5.49985 13.2222 5.91406 13.2222Z"
      fill={fill}
    />
    <path
      d="M13.0859 3C13.3621 3 13.5859 2.77614 13.5859 2.5C13.5859 2.22386 13.3621 2 13.0859 2V3ZM9.08594 3H13.0859V2H9.08594V3Z"
      fill={fill}
    />
    <path
      d="M11 5C11.2761 5 11.5 4.77614 11.5 4.5C11.5 4.22386 11.2761 4 11 4V5ZM9 5H11V4H9V5Z"
      fill={fill}
    />
    <path
      d="M13 7C13.2761 7 13.5 6.77614 13.5 6.5C13.5 6.22386 13.2761 6 13 6V7ZM9 7H13V6H9V7Z"
      fill={fill}
    />
  </svg>
);

export { AirTempIcon };
