import { alpha, useTheme } from '@material-ui/core';
import ImageSectionInfoDebug from 'components/debug/ImageSectionInfoDebug';
import { getCoordinatesLabel } from 'components/image_feed/utils';
import { getImageLabelDetails } from 'components/image_label/utils';
import Konva from 'konva';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Rect, Text } from 'react-konva';
import { NO_IMAGE } from 'shared/constants/image';
import {
  EImageLoadStatus,
  ISectionInformation,
  TLabelByCategory,
} from 'shared/interfaces/image';
import { NOT_LOADED_IMAGE_SIZE_INDEX } from './constants';
import useQueuedImage from './hooks/useQueuedImage';
import { ISectionImage } from './types';

const HIGHLIGHTED_STROKE_WIDTH = 5;
const NORMAL_STROKE_WIDTH = 1;
const TEXT_STYLE = {
  marginX: 20,
  marginY: 20,
  offsetRatio: 1.25,
};

const shadow = {
  shadowBlur: 2,
  shadowColor: 'black',
  shadowOffsetX: 1,
  shadowOffsetY: 1,
  shadowOpacity: 1,
};

export interface IGetSectionImageToDisplayInput {
  imageUrl: string;
  fetchedImage: HTMLImageElement | undefined;
  fetchedImageStatus: EImageLoadStatus;
  loadingImage: HTMLImageElement | undefined;
  noImage: HTMLImageElement | undefined;
  sectionInformation: ISectionInformation;
  sectionSize: TSize;
}

export const getSectionImageToDisplay = ({
  imageUrl,
  fetchedImage,
  fetchedImageStatus,
  loadingImage,
  noImage,
  sectionInformation,
  sectionSize,
}: IGetSectionImageToDisplayInput) => {
  const { cellX, cellY } = sectionInformation;
  const { height, width } = sectionSize;
  const offsetX = cellX * width;
  const offsetY = cellY * height;

  const placeholderScale = 0.2;
  const scaledWidth = Math.round(width * placeholderScale);
  const placeholderImage: ISectionImage = {
    image: undefined,
    x: Math.round(offsetX + (width - scaledWidth) * 0.5),
    y: Math.round(offsetY + (height - height * placeholderScale) * 0.5),
    // Keep aspect ratio square.
    width: scaledWidth,
    height: scaledWidth,
  };

  let imageResult: ISectionImage | undefined = undefined;
  let imageExists = false;

  if (EImageLoadStatus.LOADING === fetchedImageStatus) {
    imageResult = {
      ...placeholderImage,
      image: loadingImage,
    };
    imageExists = true;
  } else if (
    EImageLoadStatus.FAILED === fetchedImageStatus ||
    NO_IMAGE.url === imageUrl
  ) {
    imageResult = {
      ...placeholderImage,
      image: noImage,
    };
    imageExists = false;
  } else {
    imageResult = {
      image: fetchedImage,
      x: offsetX,
      y: offsetY,
      width,
      height,
    };
    imageExists = true;
  }

  return {
    imageResult,
    imageExists,
  };
};

export interface ISectionProps {
  /** Font size */
  fontSize: number;
  /** Show debug information. */
  debugImageFeedFlag: boolean;
  /** Show debug information for image size index */
  debugImageSizeIndexFlag: boolean;
  /** The labels. */
  labels?: TLabelByCategory[];
  /** The section highlight color */
  sectionHighlightColor: string | undefined;
  /** Current section information */
  sectionInformation: ISectionInformation;
  /** `true` if we need to show the grid info. */
  showGridInfo: boolean;
  /** Callback to be called when the section is clicked */
  onClick: VoidFunction;
  /** Size index of image to use */
  imageSizeIndex: number;
  /** Sorted image sizes */
  sortedImageSizes: TSize[];
  /** Largest small image index */
  largestSmallImageIndex: number;
  /** Handle image loaded  */
  onLoaded: () => void;
  /** Offset for section highlight to help hide black border around images */
  sectionHighlightOffset: number;
  /** Current image size index */
  currentImageSizeIndex: number;
  /** Whether the image is visible */
  visible?: boolean;
  /** Measurement run id */
  measurementRunId: number;
  /** noImage html image */
  noImage: Optional<HTMLImageElement>;
  /** noImageStatus */
  noImageStatus: EImageLoadStatus;
  /** loadingImage html image */
  loadingImage: Optional<HTMLImageElement>;
  /** loadingImageStatus */
  loadingImageStatus: EImageLoadStatus;
}

export const Section: FC<ISectionProps> = ({
  fontSize,
  labels = [],
  sectionHighlightColor,
  sectionInformation,
  showGridInfo,
  onClick,
  debugImageFeedFlag,
  debugImageSizeIndexFlag,
  imageSizeIndex,
  sortedImageSizes,
  largestSmallImageIndex,
  onLoaded,
  sectionHighlightOffset,
  currentImageSizeIndex,
  visible,
  measurementRunId,
  noImage,
  noImageStatus,
  loadingImage,
  loadingImageStatus,
}) => {
  const skipQueue = imageSizeIndex === currentImageSizeIndex;
  const skipQueueLowerResolution =
    NOT_LOADED_IMAGE_SIZE_INDEX !== currentImageSizeIndex;
  const imageUrl = sectionInformation.sortedImageUrls[imageSizeIndex]!;
  const imageUrlLowerResolution =
    sectionInformation.sortedImageUrls[Math.max(currentImageSizeIndex, 0)]!;
  const sectionSize = sortedImageSizes[largestSmallImageIndex]!;
  const theme = useTheme();

  const [fetchedImageLowerResolution, fetchedImageLowerResolutionStatus] =
    useQueuedImage({
      url: imageUrlLowerResolution,
      parentId: measurementRunId,
      isVisible: visible,
      skipQueue: skipQueueLowerResolution,
    });

  const [fetchedImage, fetchedImageStatus] = useQueuedImage({
    url: imageUrl,
    parentId: measurementRunId,
    isVisible: visible,
    skipQueue,
  });

  // Image dimmensions and positions
  const [imageExists, setImageExists] = useState(false);
  const [sectionImage, setSectionImage] = useState<Maybe<ISectionImage>>();
  const [sectionImageLowerResolution, setSectionImageLowerRes] =
    useState<Maybe<ISectionImage>>();

  const { cellX, cellY, measurementId } = sectionInformation;
  const { height, width } = sectionSize;
  const metaDetails = useMemo(() => {
    return [
      getCoordinatesLabel({ x: cellX, y: cellY }),
      ...getImageLabelDetails(labels),
    ];
  }, [labels, cellX, cellY]);

  const offsetX = Math.round(cellX * width);
  const offsetY = Math.round(cellY * height);

  useEffect(() => {
    if (
      EImageLoadStatus.LOADING === fetchedImageLowerResolutionStatus &&
      currentImageSizeIndex !== NOT_LOADED_IMAGE_SIZE_INDEX
    ) {
      return;
    }

    if (
      EImageLoadStatus.LOADED === fetchedImageStatus ||
      EImageLoadStatus.FAILED === fetchedImageStatus
    ) {
      onLoaded();
    }

    if (!visible) return;

    const fetchedImageLowerResolutionSrcIsNew =
      fetchedImageLowerResolution?.src === undefined ||
      sectionImageLowerResolution?.image?.src !==
        fetchedImageLowerResolution?.src;

    const firstRenderForThisSection =
      currentImageSizeIndex === NOT_LOADED_IMAGE_SIZE_INDEX &&
      !fadedInAlready.current;

    const shouldRenderFetchedImageLowerResolution =
      (fetchedImageLowerResolutionStatus === EImageLoadStatus.LOADED ||
        firstRenderForThisSection) &&
      fetchedImageLowerResolutionSrcIsNew;

    if (shouldRenderFetchedImageLowerResolution) {
      const { imageResult: imageResultLowerResolution, imageExists } =
        getSectionImageToDisplay({
          imageUrl: imageUrlLowerResolution,
          fetchedImage: fetchedImageLowerResolution,
          fetchedImageStatus:
            fetchedImageLowerResolutionStatus as EImageLoadStatus,
          loadingImage,
          noImage,
          sectionInformation,
          sectionSize,
        });

      setSectionImageLowerRes(imageResultLowerResolution);
      setImageExists(imageExists);
    }

    const fetchedImageSourceIsNew =
      sectionImage?.image?.src === undefined ||
      sectionImage?.image?.src !== fetchedImage?.src;

    const shouldRenderFetchedImage =
      fetchedImageLowerResolutionStatus === EImageLoadStatus.LOADED &&
      fetchedImageStatus === EImageLoadStatus.LOADED &&
      fetchedImageSourceIsNew;

    if (shouldRenderFetchedImage) {
      const { imageResult } = getSectionImageToDisplay({
        imageUrl,
        fetchedImage,
        fetchedImageStatus: fetchedImageStatus as EImageLoadStatus,
        loadingImage: fetchedImageLowerResolution,
        noImage,
        sectionInformation,
        sectionSize,
      });

      setSectionImage(imageResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedImage,
    fetchedImageLowerResolution,
    fetchedImageStatus,
    fetchedImageLowerResolutionStatus,
    loadingImage,
    loadingImageStatus,
    noImage,
    noImageStatus,
    visible,
  ]);

  const imageRef = useRef<Konva.Image>(null);
  const imageRefLowerResolution = useRef<Konva.Image>(null);
  const fadedInAlready = useRef<boolean>(false);

  useEffect(() => {
    if (
      visible &&
      imageRefLowerResolution.current &&
      currentImageSizeIndex === NOT_LOADED_IMAGE_SIZE_INDEX &&
      !fadedInAlready.current &&
      sectionImageLowerResolution?.image?.src !== undefined &&
      sectionImageLowerResolution?.image?.src != loadingImage?.src
    ) {
      fadedInAlready.current = true;
      imageRefLowerResolution.current.opacity(0);
      const fadeIn = new Konva.Tween({
        node: imageRefLowerResolution.current,
        duration: 0.5,
        opacity: 1,
      });
      fadeIn.play();
    }
  }, [
    sectionImageLowerResolution,
    fetchedImageLowerResolutionStatus,
    loadingImage,
    currentImageSizeIndex,
    visible,
  ]);

  useEffect(() => {
    if (visible && imageRef.current) {
      imageRef.current.opacity(0);
      const fadeIn = new Konva.Tween({
        node: imageRef.current,
        duration: 0.5,
        opacity: 1,
      });
      fadeIn.play();
    }
  }, [sectionImage, visible]);

  const metaTitle = metaDetails[0] ?? '';
  const metaInfo = metaDetails
    .slice(1)
    .reduce((prev, next) => prev + '\n' + next, '');
  return visible ? (
    <>
      {sectionImageLowerResolution && (
        <Image
          key={'low-res'}
          ref={imageRefLowerResolution}
          image={sectionImageLowerResolution.image}
          height={sectionImageLowerResolution.height}
          width={sectionImageLowerResolution.width}
          x={sectionImageLowerResolution.x}
          y={sectionImageLowerResolution.y}
        />
      )}
      {sectionImageLowerResolution && sectionImage && (
        <Image
          key={'high-res'}
          ref={imageRef}
          image={sectionImage.image}
          height={sectionImage.height}
          width={sectionImage.width}
          x={sectionImage.x}
          y={sectionImage.y}
        />
      )}
      {debugImageFeedFlag && (
        <ImageSectionInfoDebug
          sectionImage={sectionImage}
          cellX={cellX}
          cellY={cellY}
          measurementId={measurementId}
          runId={sectionInformation.runId}
        />
      )}
      {debugImageSizeIndexFlag && (
        <>
          <Rect
            key={cellY + '-info-1'}
            height={height}
            width={width}
            x={offsetX}
            y={offsetY}
            fill={alpha(
              ['#800080', '#FF0000', '#FFA500', '#FFFF00', '#008000'][
                imageSizeIndex
              ]!,
              0.3
            )}
          />
          <Rect
            key={cellY + '-info-2'}
            height={height / 2}
            width={width / 2}
            x={offsetX + width / 4}
            y={offsetY + height / 4}
            fill={alpha(
              [
                '#800080',
                '#FF0000',
                '#FFA500',
                '#FFFF00',
                '#008000',
                '#000',
              ].at(currentImageSizeIndex)!,
              0.3
            )}
          />
        </>
      )}
      <Rect
        id={`section-higlight-${cellY}-${cellX}`}
        key={cellY}
        height={height + sectionHighlightOffset}
        width={width + sectionHighlightOffset}
        x={offsetX - sectionHighlightOffset / 2}
        y={offsetY - sectionHighlightOffset / 2}
        cornerRadius={sectionHighlightColor ? HIGHLIGHTED_STROKE_WIDTH : 0}
        stroke={alpha(sectionHighlightColor ?? theme.palette.common.white, 1)}
        fill={sectionHighlightColor}
        strokeWidth={
          sectionHighlightColor ? HIGHLIGHTED_STROKE_WIDTH : NORMAL_STROKE_WIDTH
        }
        onClick={imageExists ? onClick : undefined}
        onTap={imageExists ? onClick : undefined}
      />
      {showGridInfo && (
        <>
          <Text
            key={'meta-title'}
            x={offsetX + TEXT_STYLE.marginX + fontSize}
            y={offsetY + TEXT_STYLE.marginY + fontSize}
            text={metaTitle}
            fontSize={fontSize * 2}
            fontFamily={'Poppins'}
            fontStyle={'bold'}
            fill="white"
            width={width - TEXT_STYLE.marginX * 2}
            listening={false}
            {...shadow}
          />
          <Text
            key={'meta-label'}
            x={offsetX + TEXT_STYLE.marginX + fontSize}
            y={offsetY + TEXT_STYLE.marginY + 2 * fontSize}
            text={metaInfo}
            fontSize={fontSize}
            fontFamily={'Poppins'}
            fill="white"
            width={width - TEXT_STYLE.marginX * 2}
            listening={false}
            {...shadow}
          />
        </>
      )}
    </>
  ) : null;
};
