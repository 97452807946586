import { createStyles, makeStyles } from '@material-ui/core/styles';
import { UserStatusIcon } from 'components/user/UserStatusIcon';
import { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    statusContainer: {
      position: 'relative',
      right: 20,
    },
  })
);

interface IStatusCellProps {
  isActive: boolean;
}

export const StatusCell: FC<IStatusCellProps> = ({ isActive }) => {
  const classes = useStyles();

  return (
    <div className={classes.statusContainer}>
      <UserStatusIcon active={isActive} />
    </div>
  );
};
