import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const CalendarIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 20"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      d="M14.856 7.376H1.142a.54.54 0 0 1-.404-.192.7.7 0 0 1-.168-.465.7.7 0 0 1 .168-.464.54.54 0 0 1 .404-.192h13.714c.152 0 .297.069.404.192a.7.7 0 0 1 .168.465.7.7 0 0 1-.168.464.54.54 0 0 1-.404.192"
    />
    <path
      fill={fill}
      d="M14.286 19.195H1.714c-.454 0-.89-.207-1.212-.577A2.13 2.13 0 0 1 0 17.224V4.084c0-.522.18-1.024.502-1.393.322-.37.758-.578 1.212-.578h12.572c.454 0 .89.208 1.212.578S16 3.56 16 4.084v13.14a2.13 2.13 0 0 1-.502 1.394c-.322.37-.758.577-1.212.577M1.714 3.427a.54.54 0 0 0-.404.193.7.7 0 0 0-.167.464v13.14c0 .174.06.341.167.465a.54.54 0 0 0 .404.192h12.572c.151 0 .297-.07.404-.192a.7.7 0 0 0 .167-.465V4.084a.7.7 0 0 0-.167-.464.54.54 0 0 0-.404-.193z"
    />
    <path
      fill={fill}
      d="M3.43 4.747a.54.54 0 0 1-.403-.193.7.7 0 0 1-.168-.464V1.462a.7.7 0 0 1 .168-.465.54.54 0 0 1 .404-.192c.151 0 .297.069.404.192a.7.7 0 0 1 .167.465V4.09a.7.7 0 0 1-.167.464.54.54 0 0 1-.404.193M12.571 4.747a.54.54 0 0 1-.404-.193A.7.7 0 0 1 12 4.09V1.462a.7.7 0 0 1 .167-.465.54.54 0 0 1 .404-.192c.152 0 .297.069.405.192a.7.7 0 0 1 .167.465V4.09a.7.7 0 0 1-.168.464.54.54 0 0 1-.404.193"
    />
  </svg>
);

export { CalendarIcon };
