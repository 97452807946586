import { FC, forwardRef, useEffect, useRef } from 'react';
import { cn } from 'shared/utils/cn';

export interface ITab {
  /** The tab id. */
  id: string;
  /** The tab label. */
  label: string;
}

interface ITabProps {
  /** The class to be applied. */
  className?: string;
  /** The tab to render. */
  tab: ITab;
  /** `true` if selected, `false` otherwise. */
  selected: boolean;
  /** Callback to be called when user clicks. */
  onClick: VoidFunction;
}

export const Tab = forwardRef<HTMLButtonElement, ITabProps>(
  ({ tab: { id, label }, selected, className, onClick }, ref) => {
    return (
      <button
        ref={ref}
        tabIndex={selected ? 0 : -1}
        className={cn(
          'cursor-pointer py-2 text-xs text-neutral-900 border-b border-transparent h-9',
          selected ? 'border-orange-500 text-orange-500' : '',
          className
        )}
        aria-selected={selected}
        id={id}
        role="tab"
        onClick={onClick}
      >
        {label}
      </button>
    );
  }
);

Tab.displayName = 'Tab';

interface ITabGroupProps {
  /** The class to be applied. */
  className?: string;
  /** The tabs to be displayed in the group. */
  tabs: ITab[];
  /** The selected tab. */
  value: Nullable<string>;
  /** The callback to be called when current tab changes. */
  onChange: (tab: string) => void;
  /** If yes, scrolls the current tab into view when clicked */
  shouldScrollTabIntoView?: boolean;
}

export const TabGroup: FC<ITabGroupProps> = ({
  className,
  children,
  shouldScrollTabIntoView = true,
  tabs,
  value,
  onChange,
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current && shouldScrollTabIntoView) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  }, [shouldScrollTabIntoView, value]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!ref.current?.contains(document.activeElement)) return;

      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        const currentIndex = tabs.findIndex((tab) => tab.id === value);
        const nextIndex =
          e.key === 'ArrowLeft' ? currentIndex - 1 : currentIndex + 1;
        const nextTab = tabs[(nextIndex + tabs.length) % tabs.length];
        if (nextTab) {
          onChange(nextTab.id);
          ref.current?.focus();
        }
      }
    };
    const handleKeyDownEvent = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        handleKeyDown(e);
      }
    };
    document.addEventListener('keydown', handleKeyDownEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [onChange, tabs, value]);

  return (
    <div
      className={cn(
        'flex h-full w-fit max-w-full items-center justify-between gap-4',
        'overflow-hidden overflow-x-auto no-scrollbar',
        className
      )}
      role="tablist"
    >
      {tabs.map((tab) => (
        <Tab
          ref={value === tab.id ? ref : undefined}
          className="shrink-0"
          key={tab.id}
          selected={value === tab.id}
          tab={tab}
          onClick={() => onChange(tab.id)}
        />
      ))}
      {children}
    </div>
  );
};
