import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const ChevronForwardIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m5.5 13 5-5.263L5.5 3"
    />
  </svg>
);

export { ChevronForwardIcon };
