import { useUpdateUserMetadata } from 'api/user';
import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Radio } from 'components/common/Radio/Radio';
import { useAuth } from 'contexts/AuthProvider';
import { useDisclosure } from 'hooks/useDisclosure';
import { useCallback, useEffect, useState } from 'react';
import { EAuthStatus } from 'shared/interfaces/auth';
import {
  EUserRole,
  PublicRole,
  UserWithMetadata,
} from 'shared/interfaces/user';

const PUBLIC_ROLE_OPTIONS = [
  { role: PublicRole.Consultant, label: 'Consultant' },
  { role: PublicRole.CultivationLead, label: 'Cultivation Lead' },
  { role: PublicRole.CultivationManager, label: 'Cultivation Manager' },
  { role: PublicRole.CultivationTech, label: 'Cultivation Tech' },
  { role: PublicRole.DirectorOfCultivation, label: 'Director of Cultivation' },
  { role: PublicRole.DirectorOfOperations, label: 'Director of Operations' },
  { role: PublicRole.Fertigation, label: 'Fertigation' },
  { role: PublicRole.Maintenance, label: 'Maintenance' },
  { role: PublicRole.PestManagement, label: 'Pest Management' },
];

const optionToRadio = (option: { role: PublicRole; label: string }) => {
  return (
    <Radio.Input
      key={option.role}
      value={option.role}
      label={option.label}
      labelClassName="w-full"
    />
  );
};

function hasKioskRole(user: UserWithMetadata): boolean {
  return user.user_organization_roles.some(
    (r) => r.role.code === EUserRole.KIOSK
  );
}

function hasPublicRole(user: UserWithMetadata): boolean {
  return !!user.metadata?.publicRole;
}

export const PublicRolePrompt = () => {
  const [publicRole, setPublicRole] = useState<string | undefined>(undefined);
  const { authStatus, isNeatleafOrganizationMember, user } = useAuth();
  const userWithMetadata = user as UserWithMetadata;
  const { update, loading } = useUpdateUserMetadata();
  const [
    publicRolePromptDisclosureClosed,
    setIsPublicRolePromptDisclosureClosed,
  ] = useState(false);
  const disclosure = useDisclosure({
    onClose: () => setIsPublicRolePromptDisclosureClosed(true),
  });
  const savePublicRole = useCallback(async () => {
    if (user && publicRole) {
      await update(user.id, {
        ...user.metadata,
        publicRole,
      });

      disclosure.close();
    }
  }, [disclosure, publicRole, update, user]);

  useEffect(() => {
    if (
      userWithMetadata &&
      authStatus === EAuthStatus.LOGGED_IN &&
      !hasPublicRole(userWithMetadata) &&
      !hasKioskRole(userWithMetadata) &&
      !isNeatleafOrganizationMember &&
      !publicRolePromptDisclosureClosed
    ) {
      disclosure.open();
    }
  }, [
    authStatus,
    disclosure,
    isNeatleafOrganizationMember,
    publicRolePromptDisclosureClosed,
    userWithMetadata,
  ]);

  return disclosure.isOpen ? (
    <Modal open={disclosure.isOpen}>
      <Modal.Header
        title="Which of the following best describes your role?"
        closeButtonProps={{ onClick: disclosure.close }}
        dialogTitleProps={{
          className: 'font-bold text-2xl normal-case',
        }}
      ></Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-4">
          <p className="text-sm">
            Get the most out of Neatleaf and help us tailor a custom experience
            for you
          </p>
          <Radio.Group
            value={publicRole || ''}
            onChange={(e) => setPublicRole(e.target.value)}
            variant="outline"
            name="PublicRole"
          >
            {PUBLIC_ROLE_OPTIONS.map(optionToRadio)}
          </Radio.Group>
        </div>
      </Modal.Content>
      <Modal.Footer className="self-center">
        <Button
          loading={loading}
          onClick={savePublicRole}
          disabled={!publicRole}
        >
          Show the Dashboard
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};
