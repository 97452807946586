import { PasswordRules } from 'components/auth/PasswordRules';
import { Button } from 'components/common/Button/Button';
import { ConfirmDialog } from 'components/common/ConfirmDialog';
import { Grid } from 'components/common/Grid';
import { TextInputFormField } from 'components/common/TextInputFormField';
import { useScreenSize } from 'hooks/useScreenSize';
import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PASSWORD_RULES } from 'shared/constants/password';

export interface IChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface IChangePasswordFormProps {
  /** Callback to be called when user closes the password form. */
  onClose: VoidFunction;
  /** Callback to be called when the user submits the form. */
  onSubmit: (data: IChangePasswordForm) => void;
}

/**
 * ChangePasswordForm component.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const ChangePasswordForm: FC<IChangePasswordFormProps> = ({
  onClose,
  onSubmit,
}) => {
  const { isMobile } = useScreenSize();

  const [error, setError] = useState<Nullable<string>>(null);
  const [shouldShowRules, setShouldShowRules] = useState(false);
  const [showChangedDialog, setShowChangedDialog] = useState(false);

  const methods = useForm<IChangePasswordForm>();
  const { handleSubmit, watch } = methods;
  const values = watch();

  const isUpdateDisabled = !values.newPassword && !values.currentPassword;

  const checkPasswordRules = (newPassword: string, confirmPassword: string) =>
    PASSWORD_RULES.every((rule) => rule.check(newPassword, confirmPassword));

  const handleSubmitForm = handleSubmit(async (values: IChangePasswordForm) => {
    const isValid = checkPasswordRules(
      values.newPassword,
      values.confirmPassword
    );

    if (!isValid) {
      setShouldShowRules(true);
      return;
    }

    try {
      await onSubmit(values);
      setShowChangedDialog(true);
    } catch (error) {
      setError((error as Error).message);
    }
  });

  const handleCloseConfirmDialog = useCallback(() => {
    setShowChangedDialog(false);
    onClose();
  }, [onClose]);

  const handleCloseErrorDialog = useCallback(() => {
    setError(null);
  }, []);

  return (
    <FormProvider {...methods}>
      <form role="form" onSubmit={handleSubmitForm}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInputFormField
              fullWidth
              name="currentPassword"
              label="Current Password"
              required
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputFormField
              fullWidth
              name="newPassword"
              label="New Password"
              required
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputFormField
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              required
              type="password"
            />
          </Grid>

          {(shouldShowRules || isMobile) && (
            <Grid item xs={12}>
              <PasswordRules
                confirmPassword={values.confirmPassword}
                password={values.newPassword}
                rules={PASSWORD_RULES}
              />
            </Grid>
          )}

          <Grid item xs={12} className="flex flex-col gap-2 md:flex-row">
            <Button
              variant="secondary"
              className="w-full md:w-[200px]"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              disabled={isUpdateDisabled}
              className="w-full md:w-[200px]"
              type="submit"
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>

      {showChangedDialog ? (
        <ConfirmDialog
          title="Password changed!"
          open
          onClose={handleCloseConfirmDialog}
        />
      ) : null}

      {error ? (
        <ConfirmDialog
          message={error}
          open
          variant="error"
          onClose={handleCloseErrorDialog}
        />
      ) : null}
    </FormProvider>
  );
};
