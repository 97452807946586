import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LockIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M4.04072 16H13.2155C14.0176 16 14.6702 15.3474 14.6702 14.5452V7.0521C14.6702 6.24996 14.0176 5.59732 13.2155 5.59732H4.04072C3.23857 5.59732 2.58594 6.24996 2.58594 7.0521V14.5452C2.58612 15.3474 3.23875 16 4.04072 16ZM3.47502 7.0521C3.47502 6.7401 3.72889 6.48623 4.0409 6.48623H13.2156C13.5276 6.48623 13.7815 6.7401 13.7815 7.0521V14.5452C13.7815 14.8572 13.5276 15.1111 13.2156 15.1111H4.04072C3.72871 15.1111 3.47484 14.8572 3.47484 14.5452L3.47502 7.0521Z"
      fill={fill}
    />
    <path
      d="M6.25545 3.26157C6.25545 1.95328 7.32 0.888905 8.62811 0.888905C9.93623 0.888905 11.0008 1.95328 11.0008 3.26157V5.01538H11.8897V3.26157C11.8897 1.46314 10.4265 0 8.62811 0C6.82968 0 5.36654 1.46314 5.36654 3.26157V5.01538H6.25545V3.26157Z"
      fill={fill}
    />
    <path
      d="M8.18326 10.7819V12.2224H9.07217V10.7819C9.76338 10.5874 10.2722 9.95271 10.2722 9.20017C10.2722 8.29331 9.53457 7.55569 8.62771 7.55569C7.72085 7.55569 6.98324 8.29331 6.98324 9.20017C6.98324 9.95271 7.49205 10.5874 8.18326 10.7819ZM8.62771 8.4446C9.04443 8.4446 9.38328 8.78363 9.38328 9.20017C9.38328 9.61671 9.04443 9.95574 8.62771 9.95574C8.21099 9.95574 7.87214 9.61671 7.87214 9.20017C7.87214 8.78363 8.21099 8.4446 8.62771 8.4446Z"
      fill={fill}
    />
  </svg>
);

export { LockIcon };
