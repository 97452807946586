import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const CO2Icon = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <circle cx="3.5" cy="12.5" r="3.5" fill={fill} />
    <circle cx="3.5" cy="3.5" r="3" stroke={stroke} />
    <circle cx="12.5" cy="8" r="3" stroke={stroke} />
  </svg>
);

export { CO2Icon };
