import { Placement } from '@floating-ui/dom';
import { Float } from '@headlessui-float/react';
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { EEventKeyCodes } from 'shared/interfaces/keys';
import { cn } from 'shared/utils/cn';
import { v4 as uuidv4 } from 'uuid';

export const topPlacements = [
  'top',
  'top-start',
  'top-end',
] satisfies Placement[];

export const leftPlacements = [
  'left-start',
  'left',
  'left-end',
] satisfies Placement[];

export const bottomPlacements = [
  'bottom-start',
  'bottom',
  'bottom-end',
] satisfies Placement[];

export const rightPlacements = [
  'right-start',
  'right',
  'right-end',
] satisfies Placement[];

export const verticalPlacements = [
  ...topPlacements,
  ...bottomPlacements,
] satisfies Placement[];

export const horizontalPlacements = [
  ...rightPlacements,
  ...leftPlacements,
] satisfies Placement[];

export const allPlacements: Placement[] = [
  ...verticalPlacements,
  ...horizontalPlacements,
];

export interface TooltipProps {
  /** When true the tooltip message  is forced to always appear */
  alwaysShow?: boolean;
  /** When true ensures the tooltip message is dismissed on click */
  hideOnClick?: boolean;
  /** When true the tooltip message will be displayed but `aria-labelledby` will not be added to the child component */
  noAriaLabelledby?: boolean;
  /** Allows the placement of the tooltip message to be automatically selected from the range of placements specified.
   * For example, you could pass `['top-start', 'top', 'top-end']` and only those placements will be used.
   * When this is set the placement prop will be ignored, and you will not get automatic flipping behaviour. The placement, from those specified, that
   * has the most available space in the browser window will be selected. Presets of placements are provided by with the
   * `Tooltip` such as `topPlacements`, `bottomPlacements`, `rightPlacements`, `leftPlacements`, `verticalPlacements`,
   * `horizontalPlacements` and `allPlacements` */
  allowedPlacements?: Placement[];
  /** When true the tooltip message is disabled and will not be shown. This behaviour will override `alwaysShow` */
  disabled?: boolean;
  /** Allows styling of the tooltip message container */
  tooltipClassName?: string;
  /** The content that will be displayed in the `Tooltip` when it is shown */
  label: ReactNode;
  /** When true displays an arrow tooltip */
  withArrow?: boolean;
  children: ReactElement<HTMLElement>;
}

/** The `Tooltip` component provides a way to add descriptive text to interactive elements on hover and on focus. */
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  function Tooltip(
    {
      disabled,
      label,
      alwaysShow,
      hideOnClick = true,
      noAriaLabelledby = false,
      allowedPlacements = allPlacements,
      children,
      tooltipClassName,
      withArrow,
      ...props
    },
    ref
  ) {
    if (Children.toArray(children).length !== 1) {
      throw new Error('A single child is required');
    }

    const child = Children.only(children);
    const [isVisible, setIsVisible] = useState(false);
    const showTooltip = disabled ? false : alwaysShow || isVisible;
    const tooltipId = useMemo(() => `tooltip-${uuidv4()}`, []);

    const handlers: HTMLAttributes<'div'> = useMemo(
      () => ({
        onPointerEnter: (e) => {
          !disabled && setIsVisible(true);
          child.props.onPointerEnter?.(e);
        },
        onClick: (e) => {
          !disabled && hideOnClick && setIsVisible(false);
          child.props.onClick?.(e);
        },
        onFocus: (e) => {
          !disabled && setIsVisible(true);
          child.props.onFocus?.(e);
        },
        onBlur: (e) => {
          !disabled && setIsVisible(false);
          child.props.onBlur?.(e);
        },
        onPointerLeave: (e) => {
          !disabled && setIsVisible(false);
          child.props.onPointerLeave?.(e);
        },
        onWheel: (e) => {
          !disabled && setIsVisible(false);
          child.props.onWheel?.(e);
        },
      }),
      [child, disabled, hideOnClick, setIsVisible]
    );

    useHotkeys(
      EEventKeyCodes.ESCAPE,
      () => {
        if (isVisible && !disabled) {
          setIsVisible(false);
        }
      },
      [disabled, isVisible, setIsVisible]
    );

    return (
      <Float
        {...props}
        show={showTooltip}
        autoPlacement={{ allowedPlacements }}
        portal
        {...(withArrow ? { offset: 8, arrow: 4 } : { offset: 4 })}
        enter="transition duration-200 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        {cloneElement(child, {
          ...child.props,
          ...handlers,
          ref,
          'aria-labelledby':
            !noAriaLabelledby && showTooltip ? tooltipId : undefined,
        })}
        <div
          id={tooltipId}
          role="tooltip"
          className={cn(
            'h-full max-w-xs p-2 text-xs rounded-xs bg-neutral-900 text-neutral-100',
            tooltipClassName
          )}
        >
          {withArrow && (
            <Float.Arrow
              offset={5}
              className="absolute w-3 h-3 rotate-45 bg-neutral-900"
            />
          )}

          {label}
        </div>
      </Float>
    );
  }
);
