import {
  useGetPresetsByLocationId,
  useInsertEnvironmentalPreset,
  useInsertGrowthCycleWithPresets,
  useInsertLightCyclesPreset,
  useUpdateGrowthCycleWithPresets,
} from 'api/growth-cycle';
import {
  ActionableList,
  ActionableListProps,
} from 'components/common/ActionableList/ActionableList';
import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import * as toast from 'components/common/Toast/Toast';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { addDays, differenceInDays, endOfDay, startOfDay } from 'date-fns';
import * as Stepper from 'headless-stepper';
import { Disclosure, useDisclosure } from 'hooks/useDisclosure';
import { ChevronBackwardIcon } from 'icons/ChevronBackwardIcon';
import { ChevronForwardIcon } from 'icons/ChevronForwardIcon';
import { LightningIcon } from 'icons/LightningIcon';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  EnvironmentalPreset,
  LightCyclesPreset,
  Preset,
  TGrowthCycle,
  TGrowthCycleEdit,
  TPlantInfo,
} from 'shared/interfaces/growthCycle';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { TOrganization } from 'shared/interfaces/organization';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import {
  computePresetName,
  getEmptyLightCycle,
  overlapsCycle,
  sanitize,
} from 'shared/utils/growthCycle';
import { EditCultivars } from './EditCultivars';
import { DEFAULT_TOTAL_DAYS, EditCycleDuration } from './EditCycleDuration';
import { EditPresetEnvironmental } from './EditPresetEnvironmental';
import { EditPresetLightCycles } from './EditPresetLightCycles';
import { Success } from './Success';
import { checkCycleStepValidity } from './validation';

/**
 * Displays a warning notification when `cycle` interval overlaps
 * any one of the provided `cycles`
 */
function useWarningCycleOverlap(cycle: TGrowthCycle, cycles: TGrowthCycle[]) {
  useEffect(() => {
    if (overlapsCycle(cycle, cycles)) {
      toast.warning(
        {
          content: `This growth cycle overlaps at least one other cycle`,
        },
        { toastId: 'overlap-warning' }
      );
    } else {
      toast.dismiss('overlap-warning');
    }
  }, [cycle, cycles]);
}

/** */
function emptyLightCyclePreset(
  name: string,
  organizationId: number,
  locationId: number
) {
  return {
    name,
    organizationId,
    locationId,
    lastUpdated: new Date(),
    isActive: true,
    lightCycles: [getEmptyLightCycle()],
  };
}

/** */
function emptyEnvironmentPreset(
  name: string,
  organizationId: number,
  locationId: number,
  presetTypes: MeasurementTypeConfig[]
) {
  return {
    name,
    organizationId,
    locationId,
    lastUpdated: new Date(),
    isActive: true,
    settings: sanitize(presetTypes),
  };
}

type Step = Stepper.Steps & {
  skippable?: boolean;
  finish?: boolean;
  presetKey: 'none' | 'lightCyclesPreset' | 'environmentalPreset';
};

const presetTexts = {
  lightCyclesPreset: {
    title: 'Shared Light Cycle presets',
    subtitle:
      'Use and share or use and make a copy from previously created light cycle presets.',
    confirmationMessage:
      'This operation will overwrite the current settings. Do you want to continue?',
    presetListKey: 'lightCycles',
  },
  environmentalPreset: {
    title: 'Shared Environmental presets',
    subtitle:
      'Use and share or use and make a copy from previously created environmental presets.',
    confirmationMessage:
      'This operation will overwrite the current settings. Do you want to continue?',
    presetListKey: 'settings',
  },
} as Record<
  Step['presetKey'],
  {
    title: string;
    subtitle: string;
    confirmationMessage: string;
    presetListKey: 'lightCycles' | 'settings';
  }
>;

type SourcePreset = {
  action: 'share' | 'clone';
  id: number;
};

export type EditCycleProps = {
  mode: 'create' | 'update';
  cycle?: TGrowthCycle;
  zone: TZone;
  organization: TOrganization;
  today: Date;
  disclosure: Disclosure;
  cycles: TGrowthCycle[];
};

export const EditCycle: FC<EditCycleProps> = ({
  mode,
  cycle,
  zone,
  organization,
  today,
  cycles,
  disclosure,
}) => {
  const { presetTypes } = useTypeConfig();
  const { update, ...updateState } = useUpdateGrowthCycleWithPresets({
    zone,
    presetTypes,
  });
  const { insert, ...insertState } = useInsertGrowthCycleWithPresets({
    organization,
    zone,
    presetTypes,
  });
  const lightCyclePresetMudation = useInsertLightCyclesPreset();
  const environmentalPresetMutation = useInsertEnvironmentalPreset(presetTypes);
  const { environmentalPresets, lightCyclesPresets } =
    useGetPresetsByLocationId({ locationId: zone.locationId, presetTypes });
  const defaultPresetName = computePresetName(zone, today);
  const [localCycle, setLocalCycle] = useState<TGrowthCycleEdit>(() => {
    const emptyLightCyclesPreset = emptyLightCyclePreset(
      defaultPresetName,
      organization.id,
      zone.locationId
    );
    const emptyEnvironmentalPreset = emptyEnvironmentPreset(
      defaultPresetName,
      organization.id,
      zone.locationId,
      presetTypes
    );

    if (cycle) {
      const environmentalPreset =
        cycle.environmentalPreset ?? emptyEnvironmentalPreset;

      return {
        ...cycle,
        start_time: startOfDay(cycle.start_time).valueOf(),
        end_time: endOfDay(cycle.end_time).valueOf(),
        cultivars: cycle.cultivars ?? [],
        lightCyclesPreset: cycle.lightCyclesPreset ?? emptyLightCyclesPreset,
        environmentalPreset: {
          ...environmentalPreset,
          settings: sanitize(presetTypes, environmentalPreset.settings),
        },
      };
    }

    return {
      id: -1,
      start_time: startOfDay(today).valueOf(),
      end_time: endOfDay(addDays(today, DEFAULT_TOTAL_DAYS)).valueOf(),
      zone_id: zone.id,
      cultivars: [],
      lightCyclesPreset: emptyLightCyclesPreset,
      environmentalPreset: emptyEnvironmentalPreset,
    };
  });
  const [sourcePreset, setSourcePreset] = useState<SourcePreset | undefined>();
  // const isDirty = Object.keys(localCycle).length > 0;
  const cycleTotalDays =
    differenceInDays(localCycle.end_time, localCycle.start_time) + 1;
  const firstStep: Step = useMemo(
    () => ({
      label: 'Step 1 of 4',
      presetKey: 'none',
      isValid: checkCycleStepValidity({
        step: 'duration',
        cycle: localCycle,
        presetTypes,
      }),
    }),
    [localCycle, presetTypes]
  );
  const steps = useMemo<Step[]>(
    () => [
      firstStep,
      {
        label: 'Step 2 of 4',
        presetKey: 'none',
        skippable: true,
        isValid: checkCycleStepValidity({
          step: 'cultivars',
          cycle: localCycle,
          presetTypes,
        }),
      },
      {
        label: 'Step 3 of 4',
        presetKey: 'lightCyclesPreset',
        isValid: checkCycleStepValidity({
          step: 'light-cycles',
          cycle: localCycle,
          presetTypes,
        }),
      },
      {
        label: 'Step 4 of 4',
        presetKey: 'environmentalPreset',
        finish: true,
        isValid: checkCycleStepValidity({
          step: 'environmental',
          cycle: localCycle,
          presetTypes,
        }),
      },
      { label: '', presetKey: 'none' },
    ],
    [firstStep, localCycle, presetTypes]
  );
  const {
    state: { currentStep, hasPreviousStep, hasNextStep, totalSteps },
    prevStep,
    nextStep,
  } = Stepper.useStepper({ steps });
  const isLastStep = currentStep === totalSteps - 1;
  const step = steps[currentStep] ?? firstStep;
  const presets = (
    step.presetKey === 'lightCyclesPreset'
      ? lightCyclesPresets
      : environmentalPresets
  ) as Preset[];
  const items = presets.map(({ id, name, lastUpdated, count }) => ({
    id: id!,
    name,
    lastUpdated,
    count,
  }));
  const hasPresets = presets.length > 0 && step.presetKey !== 'none';
  const disclosePresetSelection = useDisclosure();
  const disclosePresetConfirmation = useDisclosure();

  const handleOnClose = () => {
    disclosure.close();
  };

  const handleFinish = async () => {
    try {
      if (mode === 'create') {
        await insert(localCycle);
      } else {
        await update(localCycle);
      }
      nextStep();
    } catch (_error) {
      toast.error({
        content:
          'Something went wrong while trying to save your growth cycle. Please try again.',
      });
    }
  };

  const handleCycleDuration = (startDate: Date, endDate: Date) =>
    setLocalCycle((prev) => {
      const startTime = startOfDay(startDate);
      const endTime = startOfDay(endDate);
      const presetName = computePresetName(zone, startTime);
      const lightCyclesPreset =
        prev.lightCyclesPreset &&
        prev.lightCyclesPreset.name === defaultPresetName
          ? { ...prev.lightCyclesPreset, name: presetName }
          : prev.lightCyclesPreset;
      const environmentalPreset =
        prev.environmentalPreset &&
        prev.environmentalPreset.name === defaultPresetName
          ? { ...prev.environmentalPreset, name: presetName }
          : prev.environmentalPreset;

      return {
        ...prev,
        start_time: startTime.valueOf(),
        end_time: endTime.valueOf(),
        lightCyclesPreset,
        environmentalPreset,
      };
    });

  const handleCultivarInfo = (cultivars: TPlantInfo[]) =>
    setLocalCycle((prev) => ({ ...prev, cultivars }));

  const handlePresetLightCycles = (lightCyclesPreset: LightCyclesPreset) =>
    setLocalCycle((prev) => ({ ...prev, lightCyclesPreset }));

  const handlePresetEnviromental = (environmentalPreset: EnvironmentalPreset) =>
    setLocalCycle((prev) => ({ ...prev, environmentalPreset }));

  const handlePresetSelect =
    (action: SourcePreset['action']): ActionableListProps['onShare'] =>
    ({ id }) => {
      setSourcePreset({ action, id });
      disclosePresetConfirmation.open();
    };

  const handleOverwriteCancel = () => {
    setSourcePreset(undefined);
    disclosePresetConfirmation.close();
  };

  const handleOverwriteConfirm = async () => {
    if (!sourcePreset) {
      return;
    }
    const cycle = { ...localCycle };
    const isLightCycle =
      step.presetKey === 'lightCyclesPreset' && cycle.lightCyclesPreset;
    const isEnvironmental =
      step.presetKey === 'environmentalPreset' && cycle.environmentalPreset;

    try {
      if (isLightCycle) {
        const preset = lightCyclesPresets.find(
          (preset) => preset?.id === sourcePreset.id
        )!;
        if (sourcePreset.action === 'share') {
          /** Just assign the existing preset. Should INSERT the cycle and UPDATE the preset */
          cycle.lightCyclesPreset = {
            ...preset,
            count: (preset.count ?? 0) + 1,
          };
        } else {
          /**
           * Create a copy means INSERT a new PRESET. It's not possible to UPDATE the cycle and INSERT the preset so,
           * always INSERT a new preset when copying but set IS_ACTIVE to false.
           * If the user finishes the cycle's wizard then UPDATE IS_ACTIVE to true while INSERTING or UPDATING the cycle.
           */
          cycle.lightCyclesPreset = {
            ...emptyLightCyclePreset(
              preset.name,
              organization.id,
              zone.locationId
            ),
            isActive: false,
            lightCycles: preset.lightCycles,
          };
          const id = await lightCyclePresetMudation.insert(
            cycle.lightCyclesPreset
          );

          cycle.lightCyclesPreset.id = id;
        }
      } else if (isEnvironmental) {
        const preset = environmentalPresets.find(
          (preset) => preset?.id === sourcePreset.id
        )!;

        if (sourcePreset.action === 'share') {
          /** Just assign the existing preset. Should INSERT the cycle and UPDATE the preset */
          cycle.environmentalPreset = {
            ...preset,
            count: (preset.count ?? 0) + 1,
          };
        } else {
          /**
           * Create a copy means INSERT a new PRESET. It's not possible to UPDATE the cycle and INSERT the preset so,
           * always INSERT a new preset when copying but set IS_ACTIVE to false.
           * If the user finishes the cycle's wizard then UPDATE IS_ACTIVE to true while INSERTING or UPDATING the cycle.
           */
          cycle.environmentalPreset = {
            ...emptyEnvironmentPreset(
              preset.name,
              organization.id,
              zone.locationId,
              presetTypes
            ),
            isActive: false,
            settings: preset.settings,
          };
          const id = await environmentalPresetMutation.insert(
            cycle.environmentalPreset
          );

          cycle.environmentalPreset.id = id;
        }
      }
      disclosePresetSelection.close();
      disclosePresetConfirmation.close();
      setLocalCycle(cycle);
    } catch (_error) {
      toast.error({
        content:
          'Something went wrong while trying to copy the selected preset. Please try again.',
      });
    }
  };

  useWarningCycleOverlap(localCycle, cycles);

  return (
    <>
      {disclosure.isOpen ? (
        <Modal
          open={disclosure.isOpen}
          className={cn(isLastStep && 'bg-green-200')}
        >
          <Modal.Header
            closeButtonProps={{
              onClick: handleOnClose,
              variant: isLastStep ? 'success' : 'secondary',
            }}
            title={steps[currentStep]?.label}
            leadingElement={
              <Button
                variant="secondary"
                size="icon"
                onClick={prevStep}
                aria-label={`Return to ${steps[currentStep - 1]?.label}`}
                className={cn((!hasPreviousStep || isLastStep) && 'invisible')}
              >
                <ChevronBackwardIcon />
              </Button>
            }
          />

          <Modal.Content className={cn(isLastStep && 'flex-grow items-center')}>
            {currentStep === 0 && (
              <EditCycleDuration
                startDate={new Date(localCycle.start_time)}
                endDate={new Date(localCycle.end_time)}
                onChange={handleCycleDuration}
              />
            )}

            {currentStep === 1 && (
              <EditCultivars
                cultivars={localCycle.cultivars!}
                onChange={handleCultivarInfo}
              />
            )}

            {currentStep === 2 && (
              <EditPresetLightCycles
                namePlacement="bottom"
                totalDays={cycleTotalDays}
                preset={localCycle.lightCyclesPreset!}
                onChange={handlePresetLightCycles}
              />
            )}

            {currentStep === 3 && (
              <EditPresetEnvironmental
                namePlacement="bottom"
                totalDays={cycleTotalDays}
                preset={localCycle.environmentalPreset!}
                onChange={handlePresetEnviromental}
              />
            )}

            {currentStep === 4 && <Success onClose={handleOnClose} />}

            {disclosePresetSelection.isOpen && (
              <Modal open={disclosePresetSelection.isOpen}>
                <Modal.Header
                  closeButtonProps={{
                    onClick: disclosePresetSelection.close,
                  }}
                  leadingElement={
                    <Button
                      variant="secondary"
                      size="icon"
                      onClick={disclosePresetSelection.close}
                      aria-label="Close preset list"
                    >
                      <ChevronBackwardIcon />
                    </Button>
                  }
                />
                <Modal.Content>
                  <ActionableList
                    title={presetTexts[step.presetKey].title}
                    subtitle={presetTexts[step.presetKey].subtitle}
                    mode="select"
                    items={items}
                    sortBy={['most-recent', 'oldest', 'most-used']}
                    onShare={handlePresetSelect('share')}
                    onClone={handlePresetSelect('clone')}
                  />

                  {disclosePresetConfirmation.isOpen && (
                    <Alert
                      open={disclosePresetConfirmation.isOpen}
                      loading={environmentalPresetMutation.loading}
                      onCancel={handleOverwriteCancel}
                      onConfirm={handleOverwriteConfirm}
                    >
                      {presetTexts[step.presetKey].confirmationMessage}
                    </Alert>
                  )}
                </Modal.Content>
              </Modal>
            )}
          </Modal.Content>

          <Modal.Footer
            className={cn(
              'flex w-full flex-col justify-between gap-3 self-center sm:w-[592px] md:flex-row md:px-0',
              !hasPreviousStep && 'sm:justify-end',
              !hasNextStep && 'sm:justify-start',
              isLastStep && 'invisible',
              currentStep === 3 && 'sm:w-[632px]'
            )}
          >
            {hasPreviousStep && (
              <Button
                variant="secondary"
                onClick={prevStep}
                leadingIcon={<ChevronBackwardIcon />}
                className="hidden sm:inline-flex"
              >
                Back
              </Button>
            )}

            {hasPresets && (
              <Button
                variant="inverted"
                onClick={disclosePresetSelection.open}
                leadingIcon={<LightningIcon />}
              >
                Finish faster with a preset
              </Button>
            )}

            {step.skippable && (
              <Button
                variant="tertiary"
                onClick={nextStep}
                trailingIcon={<ChevronForwardIcon />}
                className="sm:ml-auto"
              >
                Skip
              </Button>
            )}

            {hasNextStep && (
              <Button
                onClick={step.finish ? handleFinish : nextStep}
                trailingIcon={!step.finish ? <ChevronForwardIcon /> : undefined}
                loading={insertState.loading || updateState.loading}
                disabled={!step.isValid}
              >
                {step.finish ? 'Finish' : 'Next'}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
