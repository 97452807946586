import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const HumidityIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 32"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <g clipPath="url(#clip0_2365_9474)">
      <path
        d="M15.7557 8.84552C15.7557 5.50667 10.8455 0 10.8455 0C10.8455 0 5.9375 5.49646 5.9375 8.84552C5.9375 11.4034 7.50958 13.7565 10.8484 13.7565C14.1873 13.7565 15.7557 11.208 15.7557 8.84552ZM10.8346 11.7943C9.45792 11.7943 7.88583 11.2109 7.88583 8.84552C7.88583 6.88333 10.8346 3.14417 10.8346 3.14417C10.8346 3.14417 13.7833 6.87312 13.7833 8.84552C13.7833 11.208 12.2083 11.7935 10.8353 11.7935L10.8346 11.7943Z"
        fill={fill}
      />
      <path
        d="M10.4847 26.1788C10.4847 22.8399 5.57452 17.3333 5.57452 17.3333C5.57452 17.3333 0.666504 22.8297 0.666504 26.1788C0.666504 28.7367 2.23859 31.0897 5.57744 31.0897C8.91629 31.0897 10.4847 28.5413 10.4847 26.1788ZM5.56359 29.1275C4.18692 29.1275 2.61484 28.5442 2.61484 26.1788C2.61484 24.2166 5.56359 20.4774 5.56359 20.4774C5.56359 20.4774 8.51234 24.2064 8.51234 26.1788C8.51234 28.5413 6.93734 29.1268 5.56432 29.1268L5.56359 29.1275Z"
        fill={fill}
      />
      <path
        d="M21.5774 12C21.5774 12 16.6665 17.4965 16.6665 20.8455C16.6665 23.4034 18.2386 25.7565 21.5774 25.7565C24.9163 25.7565 26.4855 23.1985 26.4855 20.8455C26.4855 17.4965 21.5774 12 21.5774 12ZM21.5774 23.7833C20.2008 23.7833 18.6287 23.2 18.6287 20.8346C18.6287 18.8724 21.5774 15.1332 21.5774 15.1332C21.5774 15.1332 24.5262 18.8622 24.5262 20.8346C24.5262 23.1985 22.9541 23.7841 21.5774 23.7841V23.7833Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2365_9474">
        <rect
          width="26.6667"
          height="32"
          fill={fill}
          transform="translate(0.666504)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { HumidityIcon };
