import { Switch as MaterialSwitch, withStyles } from '@material-ui/core';

interface IStyleProps {
  itemProp?: string;
}

export const Switch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    display: 'flex',
    overflow: 'visible',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.black.main,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.black.main,
    transform: 'translate(2px, 1px)',

    '&$checked': {
      transform: 'translate(17px, 1px)',
      color: theme.palette.orange3.main,
      borderColor: theme.palette.orange3.main,

      '& + $track': {
        opacity: 1,
        borderColor: theme.palette.orange3.main,
      },
    },
  },
  thumb: (props: IStyleProps) => ({
    width: 20,
    height: 20,
    boxShadow: 'none',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'italic',
    fontFamily: 'serif',
    fontWeight: 'bold',
    ...(props.itemProp
      ? {
          '&:before': {
            content: `"${props.itemProp}"`,
            color: theme.palette.white.main,
          },
        }
      : {}),
  }),
  track: {
    border: `1px solid ${theme.palette.black.main}`,
    borderRadius: 20,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(MaterialSwitch);
