import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const WarningIcon = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth={0.5}
      d="M7.543 1.224 1.118 13.218a1.655 1.655 0 0 0 1.457 2.434h12.85a1.654 1.654 0 0 0 1.457-2.434L10.457 1.224a1.653 1.653 0 0 0-2.914 0Zm.972.52a.55.55 0 0 1 .97 0l6.426 11.994a.552.552 0 0 1-.486.812H2.575a.552.552 0 0 1-.486-.812zM9 11.795a.827.827 0 1 1 0 1.654.827.827 0 0 1 0-1.654ZM8.449 4.63v5.511a.551.551 0 0 0 1.102 0v-5.51a.551.551 0 0 0-1.102 0Z"
      clipRule="evenodd"
    />
  </svg>
);

export { WarningIcon };
