import { ReactNode } from 'react';

export type TAlignment = 'left' | 'center' | 'right';

export interface ITableRow {
  id: string | number;
}

export interface ITableColumn<T extends ITableRow> {
  field: string;
  header?: string;
  headerAlign?: TAlignment;
  align?: TAlignment;
  sort?: (a: T, b: T) => number;
  sortDisabled?: boolean;
  renderHeader?: () => ReactNode;
  renderCell?: (row: T) => ReactNode;
}

export interface IPaginationProps {
  page: number;
  pageSize: number;
  pageCount: number;
  onPageChange: (page: number) => void;
}

export interface ITableProps<T extends ITableRow>
  extends Omit<IPaginationProps, 'pageCount'> {
  className?: string;
  rows: T[];
  columns: ITableColumn<T>[];
  onRowClick: (row: T['id']) => void;
}

export enum ESortDirection {
  NONE = 'none',
  ASC = 'asc',
  DESC = 'desc',
}
