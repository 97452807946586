import { ReactNode } from 'react';
import { TResolution } from './measurement';
import { IMeasurementRunFrame } from './measurementRun';

export enum EImageTypes {
  'RGB' = 'RGB',
  'NDVI' = 'NDVI',
}

export const ImageTypeToSensorCodeMap = {
  [EImageTypes.RGB]: 'RGB_IMAGE',
  [EImageTypes.NDVI]: 'NDVI_IMAGE',
} as const;

export enum EImageViewTypes {
  GRID = 'grid',
  SINGLE_IMAGE = 'single-image',
}

export enum EImageLabelCategory {
  OBJECT = 'OBJECT',
  YELLOWING = 'YELLOWING',
  NECROSIS = 'NECROSIS',
  ABNORMAL_SHAPE = 'ABNORMAL_SHAPE',
  OTHER = 'OTHER',
}

export const IMAGE_LABEL_CATEGORY_TITLES = {
  [EImageLabelCategory.OBJECT]: 'Object',
  [EImageLabelCategory.YELLOWING]: 'Yellowing',
  [EImageLabelCategory.NECROSIS]: 'Necrosis',
  [EImageLabelCategory.ABNORMAL_SHAPE]: 'Abnormal Shape',
  [EImageLabelCategory.OTHER]: 'Other',
} as const;

export type TLabelMetadataLocation = {
  box: [number, number, number, number]; // [left, top, right, bottom]
};

export type TLabelMetadata = {
  version: string;
  location: TLabelMetadataLocation;
  created_time: DateTime;
  score?: number;
};

export enum EImageRightPanelType {
  COMMENT = 'COMMENT',
  INFORMATION = 'INFORMATION',
  LABELS = 'LABELS',
  NONE = 'NONE',
}

export type TLabelByCategory = {
  enumerationId: number;
  code: string;
  category: EImageLabelCategory;
  subcategory: string;
  description: string;
  metadata?: TLabelMetadata;
  measurementId?: number;
  measurementRunId?: number;
  labelCount?: number;
  labelIds?: number[];
};

export type TLabelsByCategory = Record<EImageLabelCategory, TLabelByCategory[]>;

export type TLabelsByMeasurementId = Record<number, TLabelByCategory[]>;

export type TSelectedLabelCode = string;

export interface ISectionPoseInformation {
  /** The X position of the section in the zone */
  cellX: number;
  /** The Y position of the section in the zone */
  cellY: number;
}

/**
 * An interface that has the section capture information.
 */
export interface ISectionCapture
  extends Omit<IMeasurementRunFrame, 'startTime' | 'endTime'>,
    IImageUrlInformation {
  /** Current measurement id. */
  measurementId: number;
  /** The x position of section. */
  cellX: number;
  /** The y position of section. */
  cellY: number;
  /** The time of the measurement. */
  time: Date;
}

/**
 * An interface that represents the cell position and visit/capture information.
 */
export interface ISectionInformation
  extends ISectionPoseInformation,
    IImageUrlInformation {
  /** The measurement id. */
  measurementId: Nullable<number>;
  /** The measuremnt run id. */
  runId: number;
}

export type TImagesGrid = Array<Array<ISectionInformation>>;

export type TImageGridView = Array<ISectionInformation>;

export type TImageLabelWithIcon = {
  category: EImageLabelCategory;
  title: string;
  defaultIcon: ReactNode;
  selectedIcon: ReactNode;
  inactiveIcon: ReactNode;
};

export enum EImageLoadStatus {
  LOADING = 'loading',
  LOADED = 'loaded',
  FAILED = 'failed',
}

export enum ELabelOverviewUpperCountBoundType {
  DEFAULT = 'default',
  AUTOSCALE = 'autoscale',
  CLOSEST_DAY = 'closest_day',
}

export type TLabelOverviewUpperCountBound = Nullable<
  Record<string, number> & {
    type: ELabelOverviewUpperCountBoundType;
  }
>;

export interface IResolutionInformation {
  minResolution: TResolution;
  maxResolution: TResolution;
  /** Image sizes sorted from smallest to largest by resolution */
  sortedResolutions: TResolution[];
}

export interface IImageUrlInformation {
  minImageUrl: string;
  maxImageUrl: string;
  /** Image urls sorted from smallest to largest by size */
  sortedImageUrls: string[];
}

export type TLabelStatistics = {
  min: number;
  max: number;
};
export type TCode = string;
export type TRunId = number;
export type TImageFeedLabelStats = Map<TCode, TLabelStatistics>;
export type TRunIdToStats = Map<TRunId, TImageFeedLabelStats>;

export type TScaleAndPosition = {
  scale: number;
  positionX: number;
  positionY: number;
};
