import { InView } from 'react-intersection-observer';
import { InsightSeverity } from 'shared/interfaces/discussion';
import { TNotification } from 'shared/interfaces/notifications';
import { cn } from 'shared/utils/cn';
import { formatDateEEEMMMMdhmmaa } from 'shared/utils/date';

const { High, Mild, Moderate } = InsightSeverity;

const NR_NOTIFICATIONS_UNTIL_MORE_TRIGGER = 3;

function NotificationItem<T>({
  notification,
  onClick,
  children,
}: {
  notification: TNotification<T>;
  onClick?: (data: T) => void;
  children?: React.ReactNode;
}) {
  const { data, date, read, title, footnote, severity } = notification;

  return (
    <div
      onClick={() => onClick && onClick(data)}
      className={cn(
        'p-4 border-b-[1px] border-neutral-300 hover:bg-neutral-100 hover:cursor-pointer',
        read && 'hover:bg-neutral-200',
        !read && 'bg-neutral-200',
        !read && severity === High && 'bg-red-100 ',
        !read && severity === Moderate && 'bg-orange-100 ',
        !read && severity === Mild && 'bg-blue-100'
      )}
    >
      <div className="flex gap-2 cursor-pointer">
        <div className="w-2 flex-shrink-0 pt-[6px] -translate-x-1">
          {severity && (
            <div
              className={cn(
                'w-2 h-2 rounded-full',
                severity === High && 'bg-red-500',
                severity === Moderate && 'bg-orange-500',
                severity === Mild && 'bg-blue-500'
              )}
            />
          )}
        </div>
        <div className="flex flex-col gap-2 ">
          <div className="flex gap-2 cursor-pointer items-baseline">
            <span className="font-semibold">{title}</span>{' '}
            <span className="text-neutral-500 text-xs">
              {formatDateEEEMMMMdhmmaa(date)}
            </span>
          </div>
          {children}
          {footnote && <p className="text-neutral-500 text-xs">{footnote}</p>}
        </div>
      </div>
    </div>
  );
}

const InViewTrigger = ({ onTrigger }: { onTrigger: () => any }) => {
  return (
    <InView
      as="div"
      onChange={(inView) => {
        if (inView) {
          onTrigger();
        }
      }}
    >
      <div />
    </InView>
  );
};

export function Notifications<T>({
  items,
  onClickItem,
  onReachEnd,
  renderContent,
}: {
  items: TNotification<T>[];
  onClickItem?: (data: T) => void;
  onReachEnd?: () => void;
  renderContent: (notification: TNotification<T>) => React.ReactNode;
}) {
  const renderNotification = (notification: TNotification<T>) => (
    <NotificationItem<T>
      key={notification.id}
      notification={notification}
      onClick={onClickItem}
    >
      {renderContent(notification)}
    </NotificationItem>
  );
  return (
    <div className="border-t-[1px] border-neutral-300 w-full">
      {items
        .slice(0, items.length - NR_NOTIFICATIONS_UNTIL_MORE_TRIGGER)
        .map(renderNotification)}
      {onReachEnd && <InViewTrigger onTrigger={onReachEnd} />}
      {items
        .slice(items.length - NR_NOTIFICATIONS_UNTIL_MORE_TRIGGER, items.length)
        .map(renderNotification)}
    </div>
  );
}
