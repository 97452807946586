import { CheckIcon } from 'icons/CheckIcon';
import {
  ButtonHTMLAttributes,
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  forwardRef,
  useRef,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import { cn } from 'shared/utils/cn';
import { CircularProgress } from '../CircularProgress/CircularProgress';

const _variants = [
  'primary',
  'secondary',
  'inverted',
  'tertiary',
  'error',
  'success',
  'info',
  'warning',
  'flat',
  'outline',
] as const;

type Variant = (typeof _variants)[number];

type Size = 'normal' | 'icon' | 'responsive';

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
> & {
  /** The button label, text or content. Required. */
  children: ReactNode;
  /** The button variant. Optional. Defaults to `primary`.  */
  variant?: Variant;
  /** The button size. Optional. Defaults to `normal`.
   *  - `normal`: Use it to render a regular button with text and optionally a icon through `leadingIcon` or `trailingIcon`
   *  - `icon`: Use it to render a button with an icon and no text.
   *  - `responsive`: Use it to render a button with text and an icon but only renders the icon in smaller viewports
   */
  size?: Size;
  /** A leading element placed after the provided children. Optional. */
  leadingIcon?: ReactElement<HTMLElement>;
  /** A trailing element placed after the provided children. Optional. */
  trailingIcon?: ReactElement<HTMLElement>;
  /** Whether the button should show a loading state. Also disables the button when `true`. Optional. */
  loading?: boolean;
  /** Whether the button is selected. Optional. */
  selected?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ref: React.RefObject<HTMLButtonElement>
  ) => void;
};

/** Displays a button or a component that looks like a button. */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      variant = 'primary',
      size = 'normal',
      loading = false,
      disabled = false,
      leadingIcon,
      trailingIcon,
      className,
      children,
      selected,
      ...props
    },
    ref
  ) {
    if (
      size === 'icon' &&
      (Children.toArray(children).length !== 1 || leadingIcon || trailingIcon)
    ) {
      throw new Error(
        'A button with `size === icon` requires a single child element and no leading or trailing icons are permitted'
      );
    }

    if (
      size === 'responsive' &&
      (Children.toArray(children).length !== 1 ||
        (!leadingIcon && !trailingIcon))
    ) {
      throw new Error(
        'A button with `size === responsive` requires a single child element and one of the leading or trailing icons'
      );
    }

    const title =
      size === 'icon' && !props.title && props['aria-label']
        ? props['aria-label']
        : undefined;
    const ariaPressed = props['aria-pressed'] ?? selected;
    const leadingElement =
      leadingIcon ??
      (loading ? <CircularProgress size="sm" variant={variant} /> : undefined);

    const buttonRef = useRef<HTMLButtonElement>(null);

    return (
      <button
        ref={mergeRefs([ref, buttonRef])}
        {...props}
        title={title}
        disabled={disabled || loading}
        aria-pressed={ariaPressed}
        className={cn(
          'inline-flex h-9 min-w-9 flex-row items-center justify-center gap-2 rounded-full px-4 text-sm font-semibold',
          'disabled:pointer-events-none disabled:touch-none',
          size === 'icon' && 'px-1',
          size === 'responsive' && 'px-1 sm:px-4',
          variant === 'primary' && [
            'bg-orange-500 text-neutral-100 hover:bg-orange-400 active:bg-orange-600 disabled:bg-orange-200',
            'aria-pressed:bg-orange-100 aria-pressed:focus:bg-orange-100 aria-pressed:active:bg-orange-100 aria-pressed:text-orange-500',
          ],
          variant === 'secondary' && [
            'bg-neutral-200 text-neutral-900 hover:bg-neutral-300 active:bg-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400',
            'aria-pressed:bg-orange-100 aria-pressed:focus:bg-orange-100 aria-pressed:active:bg-orange-100 aria-pressed:hover:bg-orange-100 aria-pressed:text-orange-500',
          ],
          variant === 'inverted' && [
            'bg-neutral-900 text-neutral-100 hover:bg-neutral-600 active:bg-neutral-500 disabled:text-neutral-500',
            'aria-pressed:bg-orange-100 aria-pressed:focus:bg-orange-100 aria-pressed:active:bg-orange-100 aria-pressed:text-orange-500',
          ],
          variant === 'tertiary' && [
            'bg-transparent text-orange-500 hover:bg-neutral-200 active:bg-neutral-300 disabled:text-orange-300',
            'aria-pressed:bg-orange-100 aria-pressed:focus:bg-orange-100 aria-pressed:active:bg-orange-100 aria-pressed:text-orange-500',
          ],
          variant === 'error' && [
            'bg-red-500 text-neutral-100 hover:bg-red-400 active:bg-red-600 disabled:bg-red-200',
            'aria-pressed:bg-red-100 aria-pressed:focus:bg-red-100 aria-pressed:active:bg-red-100 aria-pressed:text-red-500',
          ],
          variant === 'success' && [
            'bg-green-800 text-neutral-100 hover:bg-green-700 active:bg-green-900 disabled:bg-green-400',
            'aria-pressed:bg-green-100 aria-pressed:focus:bg-green-100 aria-pressed:active:bg-green-100 aria-pressed:text-green-900',
          ],
          variant === 'info' && [
            'bg-blue-600 text-neutral-100 hover:bg-blue-500 active:bg-blue-700 disabled:bg-blue-200',
            'aria-pressed:bg-blue-100 aria-pressed:focus:bg-blue-100 aria-pressed:active:bg-blue-100 aria-pressed:text-blue-500',
          ],
          variant === 'warning' && [
            'bg-yellow-500 text-neutral-900 hover:bg-yellow-400 active:bg-yellow-600 disabled:bg-yellow-200 disabled:text-neutral-300',
            'aria-pressed:bg-yellow-100 aria-pressed:focus:bg-yellow-100 aria-pressed:active:bg-yellow-100 aria-pressed:text-yellow-900',
          ],
          variant === 'flat' && [
            'bg-transparent text-neutral-900 hover:bg-neutral-200 active:bg-neutral-300 disabled:text-neutral-400',
            'aria-pressed:text-orange-500',
          ],
          variant === 'outline' && [
            'bg-neutral-200 text-neutral-900 border border-neutral-400 hover:bg-neutral-300 active:bg-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400',
            'aria-pressed:bg-orange-100 aria-pressed:focus:bg-orange-100 aria-pressed:active:bg-orange-100 aria-pressed:hover:bg-orange-100 aria-pressed:text-orange-500 aria-pressed:border-orange-500',
          ],
          className
        )}
        onClick={(event) => props.onClick?.(event, buttonRef)}
      >
        {!selected &&
          leadingElement &&
          cloneElement(leadingElement, {
            ...leadingElement.props,
            className: cn('flex-shrink-0', leadingElement.props.className),
          })}

        {selected && (
          <CheckIcon
            className={cn('flex-shrink-0', leadingElement?.props.className)}
          />
        )}

        {(size === 'normal' || size === 'responsive') && (
          <span
            className={cn(
              'truncate',
              size === 'responsive' && 'hidden sm:block'
            )}
          >
            {children}
          </span>
        )}

        {size === 'icon' && children}

        {trailingIcon &&
          cloneElement(trailingIcon, {
            ...trailingIcon.props,
            className: cn('flex-shrink-0', trailingIcon.props.className),
          })}
      </button>
    );
  }
);
