import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const ArrowRightIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('flex h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      d="M14.713 7.393 10.24 2.547a.73.73 0 0 0-1.1 0c-.308.334-.308.874 0 1.192l3.154 3.416H1.793c-.425 0-.777.38-.777.842 0 .46.352.842.777.842h10.486L9.14 12.255c-.308.333-.308.874 0 1.191a.77.77 0 0 0 .558.255c.205 0 .396-.08.557-.255l4.488-4.861a.9.9 0 0 0 .234-.604 1.1 1.1 0 0 0-.264-.588"
    />
  </svg>
);

export { ArrowRightIcon };
