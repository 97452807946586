import { Button, ButtonProps } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { StatusText } from 'components/common/StatusText/StatusText';
import { TimePicker } from 'components/common/TimePicker';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { ArrowDownIcon } from 'icons/ArrowDownIcon';
import { CheckIcon } from 'icons/CheckIcon';
import { CloseIcon } from 'icons/CloseIcon';
import { GarbageIcon } from 'icons/GarbageIcon';
import { LockIcon } from 'icons/LockIcon';
import isNil from 'lodash.isnil';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import {
  CycleParameters,
  EnvironmentalPreset,
  Parameters,
  Setting,
} from 'shared/interfaces/growthCycle';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { getEmptySetting, sanitize } from 'shared/utils/growthCycle';
import { v4 as uuidv4 } from 'uuid';
import { PresetName } from './PresetName';
import { useWarningSharedPreset } from './hooks/useWarningSharedPreset';
import { areEnviromentSettingsValid } from './validation';

const PARAMETERS = ['setPoint', 'ideal', 'critical'] as const;

type ManagedPresetFields = keyof Pick<EnvironmentalPreset, 'name' | 'settings'>;

type ManagedSettingFields = keyof Pick<Setting, 'day' | 'light' | 'dark'>;

/** Returns the previous and next types */
function getTypeSiblings(
  presetTypes: MeasurementTypeConfig[],
  type: Setting['type']
) {
  const typeIndex = presetTypes.findIndex(
    (fmt) => fmt.statisticsKeyV2 === type
  );
  const clampedIndex = (index: number) =>
    index <= presetTypes.length - 1 ? index : 0;

  return {
    previous: presetTypes.at(clampedIndex(typeIndex - 1))!,
    next: presetTypes.at(clampedIndex(typeIndex + 1))!,
  };
}

const rangeHeaders = (
  <>
    <div className="col-span-1  text-xs truncate">Set point</div>
    <div className="col-span-1 text-green-700 text-xs truncate">Ideal +/-</div>
    <div className="col-span-1 text-red-500 text-xs truncate">Critical +/-</div>
  </>
);

/** Assembles an iD to be used on each type's root DOM element  */
function typeElementId(type: string) {
  return `type:${type}`;
}

/** A setting is considered empty when every field either empty or 0 */
function areParametersEmpty(parameters: Parameters) {
  return PARAMETERS.every((key) => !parameters[key] || parameters[key] === 0);
}

/** Whether the provided settings only have unique days */
function hasOnlyUniqueDays(settings: Setting[]) {
  const days = settings.map(({ day }) => day);

  return new Set(days).size === days.length;
}

/** Scrolls the document to the element that represents the config type */
function scrollToType(type: string) {
  document
    .getElementById(typeElementId(type))
    ?.scrollIntoView({ behavior: 'smooth' });
}

export interface EditPresetEnvironmentalProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /** Determines where the preset name input is placed */
  namePlacement?: 'top' | 'bottom';
  /** An optional subtitle  */
  subtitle?: string;
  /** The total days of the growth cycle */
  totalDays: number;
  /** The preset to manage */
  preset: EnvironmentalPreset;
  /** Called whenever the preset is updated */
  onChange: (preset: EnvironmentalPreset) => void;
}

/**
 * List, add, edit, and remove environmental settings.
 */
export const EditPresetEnvironmental = forwardRef<
  HTMLDivElement,
  EditPresetEnvironmentalProps
>(function EditPresetEnvironmental(
  {
    namePlacement = 'top',
    className,
    totalDays,
    preset,
    title,
    subtitle,
    onChange,
    ...props
  },
  ref
) {
  useWarningSharedPreset(preset);
  const { presetTypes } = useTypeConfig();
  const sanitizedSettings = sanitize(presetTypes, preset?.settings);
  const handleChangePreset = (
    fieldName: ManagedPresetFields,
    value: EnvironmentalPreset['name'] | EnvironmentalPreset['settings']
  ) => {
    onChange({ ...preset, [fieldName]: value });
  };

  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        'relative flex w-full flex-col items-center gap-4 text-sm sm:max-w-[632px]',
        className
      )}
    >
      <h3 className="text-center text-xl font-bold" id="env-settings-title">
        {title ?? 'Environmental Settings'}
      </h3>

      <p className="text-center">
        {subtitle ?? 'Set your leaf temperature, CO2, and more.'}
      </p>

      {namePlacement === 'top' && (
        <PresetName
          value={preset.name}
          onChange={(event) => handleChangePreset('name', event.target.value)}
        />
      )}

      <div
        role="list"
        aria-labelledby="env-settings-title"
        className="mt-6 flex w-full flex-col items-start justify-center gap-4"
      >
        <ContextProvider
          totalDays={totalDays}
          settings={sanitizedSettings}
          onChange={(settings) =>
            handleChangePreset('settings', sanitize(presetTypes, settings))
          }
        >
          {presetTypes.map((type) => (
            <ConfigType key={type.label} type={type} />
          ))}
        </ContextProvider>
      </div>

      {namePlacement === 'bottom' && (
        <PresetName
          value={preset.name}
          onChange={(event) => handleChangePreset('name', event.target.value)}
          helperText="For your convenience, we’ll save this data for your next growth cycle. Find it with ease by using a custom name."
        />
      )}
    </div>
  );
});

interface ContextData {
  settings: Setting[];
  focusedSetting: Setting | null;
  totalDays: number;
  setFocusedSetting: (setting: Setting | null) => void;
  onChange: (settings: Setting[]) => void;
}

const Context = createContext<ContextData>({
  settings: [],
  focusedSetting: null,
  totalDays: 0,
  setFocusedSetting: () => null,
  onChange: () => null,
});

type ContextProviderProps = Omit<
  ContextData,
  'settings' | 'focusedSetting' | 'setFocusedSetting'
> & {
  settings: Setting[];
  children: ReactNode;
};

/** */
function ContextProvider({ children, ...props }: ContextProviderProps) {
  const [focusedSetting, setFocusedSetting] =
    useState<ContextData['focusedSetting']>(null);

  return (
    <Context.Provider
      value={{
        ...props,
        focusedSetting,
        setFocusedSetting,
      }}
    >
      {children}
    </Context.Provider>
  );
}

/**
 * Renders the config type title, unit, and the table with the days to list
 * Allows adding and duplicating day rows and clearing the table.
 * */
const ConfigType = ({ type }: { type: MeasurementTypeConfig }) => {
  const navRef = useRef(null);
  const lastDayInputRef = useRef<HTMLInputElement>(null);
  const { totalDays, settings, setFocusedSetting, onChange } =
    useContext(Context);
  const { presetTypes } = useTypeConfig();
  const {
    label,
    unit,
    statisticsKeyV2,
    preset: { cloneFrom, readOnly, contextualInfo } = {},
  } = type;
  const { previous, next } = getTypeSiblings(presetTypes, statisticsKeyV2);
  const typeSettings = settings.filter(
    (setting) => setting.type === statisticsKeyV2
  );
  const lastSetting = typeSettings.at(-1);
  const cloneFromTypeConfig = presetTypes.find(
    ({ statisticsKeyV2 }) => statisticsKeyV2 === cloneFrom
  );
  const cloneFromSettings = cloneFromTypeConfig
    ? settings.filter(({ type }) => type === cloneFrom)
    : [];
  const cloningEnabled =
    cloneFrom &&
    cloneFromTypeConfig &&
    cloneFromSettings.length > 1 &&
    // the source type as valid entries
    hasOnlyUniqueDays(cloneFromSettings) &&
    areEnviromentSettingsValid(cloneFromSettings) &&
    // and this type is empty
    typeSettings.length === 1;
  const editable = hasOnlyUniqueDays(typeSettings);
  const canAddMore =
    hasOnlyUniqueDays(typeSettings) && areEnviromentSettingsValid(typeSettings);
  const lastDay =
    typeSettings.length > 0
      ? [...typeSettings].sort((a, b) => a.day - b.day).at(-1)!.day
      : 0;
  const handleAddRow = (type: Setting['type']) => {
    setFocusedSetting(null);
    const empty = getEmptySetting(type);
    onChange([...settings, { ...empty, day: lastDay + 7 }]);
    requestAnimationFrame(() => {
      lastDayInputRef.current?.focus();
    });
  };
  const handleClearType = (type: Setting['type']) => {
    document
      .getElementById(typeElementId(statisticsKeyV2))
      ?.scrollIntoView({ behavior: 'smooth' });

    onChange([...settings.filter((s) => s.type !== type)]);
  };
  const handleCopyDay = ({ type, light, dark }: Setting) => {
    setFocusedSetting(null);
    onChange([
      ...settings,
      {
        id: uuidv4(),
        type,
        day: lastDay + 1,
        light,
        dark,
      },
    ]);
  };
  const handleCloneType = (
    sourceType: Setting['type'],
    targetType: Setting['type'],
    offset: number
  ) => {
    const clonedSettings = settings.reduce<Setting[]>((subset, setting) => {
      if (setting.type === targetType) {
        // prune any existing empty entry in target type
        return subset;
      }
      if (setting.type === sourceType) {
        // keep existing source setting and clone it by applying the offset
        subset.push(
          ...[
            setting,
            {
              id: uuidv4(),
              type: targetType,
              day: setting.day,
              light: {
                ...setting.light,
                setPoint: (setting.light.setPoint ?? 0) + offset,
              },
              dark: {
                ...setting.dark,
                setPoint: (setting.dark.setPoint ?? 0) + offset,
              },
            },
          ]
        );
      } else {
        subset.push(setting);
      }

      return subset;
    }, []);

    onChange(clonedSettings);
  };

  return (
    <div
      key={statisticsKeyV2}
      className="group/envtype w-full flex flex-col gap-2"
      id={typeElementId(statisticsKeyV2)}
      role="listitem"
      aria-label={`${readOnly ? '' : 'Edit '}settings of type ${label}`}
    >
      <nav
        ref={navRef}
        aria-label={label}
        className={cn(
          'sticky top-0 z-10 py-4 bg-white font-semibold flex justify-between items-center text-lg',
          'outline outline-1 outline-white'
        )}
      >
        <h4>
          {label}
          <sup className="text-sm">{` ${unit}`}</sup>
        </h4>

        <div className="sm:hidden">
          <Button
            variant="flat"
            size="icon"
            aria-label={`Jump to ${previous.label}`}
            onClick={() => scrollToType(previous.statisticsKeyV2)}
          >
            <ArrowDownIcon className="-rotate-180 transform" />
          </Button>
          <Button
            variant="flat"
            size="icon"
            aria-label={`Jump to ${next.label}`}
            onClick={() => scrollToType(next.statisticsKeyV2)}
          >
            <ArrowDownIcon />
          </Button>
        </div>
      </nav>

      {contextualInfo && (
        <p className="py-4 text-neutral-600">{contextualInfo}</p>
      )}

      {cloningEnabled && (
        <div className="py-4">
          <CopyWithOffset
            className="bg-blue-100 p-2 rounded-sm"
            message={`Create from ${cloneFromTypeConfig.label}?`}
            unit={unit}
            onConfirm={(offset) =>
              handleCloneType(cloneFrom, statisticsKeyV2, offset)
            }
          />
        </div>
      )}

      <div
        className={cn(
          'w-full grid grid-cols-4 bg-neutral-200 rounded-md',
          'sm:gap-y-1 sm:p-0 sm:bg-transparent sm:grid-cols-[minmax(120px,_1fr)_repeat(6,_1fr)]'
        )}
      >
        <div className="hidden sm:grid col-span-8 grid-cols-subgrid gap-x-2 py-1 font-semibold">
          <div className="">Time</div>
          <div className="col-span-3">Day</div>
          <div className="col-span-3">Night</div>
        </div>

        <div className="hidden sm:grid col-span-8 grid-cols-subgrid gap-x-2 py-1">
          <div className="col-span-1">Day #</div>
          <div className="col-span-3 grid grid-cols-subgrid gap-2">
            {rangeHeaders}
          </div>
          <div className="col-span-3 grid grid-cols-subgrid gap-2">
            {rangeHeaders}
          </div>
        </div>

        {typeSettings.map((setting, index) => {
          const previousSetting = typeSettings[index - 1];
          const startTimeNotUnique =
            previousSetting && previousSetting.day === setting.day;
          const notSorted = typeSettings
            .slice(0, index)
            .some((item) => item.day > setting.day);
          const firstDay = index === 0 && setting.day > 0;
          const moreThanTotalDays = setting.day > totalDays;
          const dayCriticalSmallerThanIdeal =
            (setting.light.criticalUpper ?? 0) <
            (setting.light.idealUpper ?? 0);
          const nightCriticalSmallerThanIdeal =
            (setting.dark.criticalUpper ?? 0) < (setting.dark.idealUpper ?? 0);
          const statuses = (
            !readOnly
              ? [
                  firstDay && ['text-red-500', 'First day must be 1w 1d'],
                  startTimeNotUnique && [
                    'text-red-500',
                    'Start time must be unique',
                  ],
                  notSorted && [
                    'text-red-500',
                    'Days must be in ascending order',
                  ],
                  moreThanTotalDays && [
                    'text-orange-500',
                    `The growth cycle has ${totalDays} days but the last setting is defined for the day #${setting.day}.`,
                  ],
                  dayCriticalSmallerThanIdeal && [
                    'text-orange-500',
                    `The Day Critical range of ${setting.light.criticalUpper ?? 0} is smaller than the Ideal range of ${setting.light.idealUpper ?? 0}`,
                  ],
                  nightCriticalSmallerThanIdeal && [
                    'text-orange-500',
                    `The Night Critical range of ${setting.dark.criticalUpper ?? 0} is smaller than the Ideal range of ${setting.dark.idealUpper ?? 0}`,
                  ],
                ].filter(Boolean)
              : []
          ) as [string, string][];

          return (
            <DayRow
              key={`${type}-${setting.id}`}
              type={type}
              setting={setting}
              isLast={index === typeSettings.length - 1}
              isFirst={index === 0}
              deletable={index !== 0}
              editable={editable}
              onChange={onChange}
              lastDayInputRef={lastDayInputRef}
              typeSettings={typeSettings}
            >
              {statuses.length > 0 && (
                <div className="col-span-full mt-1 sm:mt-0">
                  {statuses.filter(Boolean).map(([className, status]) => (
                    <StatusText
                      key={status}
                      className={cn('block px-1', className)}
                    >
                      {status}
                    </StatusText>
                  ))}
                </div>
              )}
            </DayRow>
          );
        })}
      </div>

      {!readOnly && (
        <div className="w-full flex flex-row gap-1 justify-between ml-auto mr-auto py-2">
          <Button
            variant="tertiary"
            onClick={() => {
              handleAddRow(statisticsKeyV2);
            }}
            disabled={!canAddMore}
          >
            Add more
          </Button>

          {canAddMore && lastSetting && (
            <Button
              variant="tertiary"
              onClick={() => {
                handleCopyDay(lastSetting);
              }}
            >
              Duplicate last day
            </Button>
          )}

          <Button
            variant="tertiary"
            onClick={() => handleClearType(statisticsKeyV2)}
          >
            Clear all
          </Button>
        </div>
      )}
    </div>
  );
};

type DayRowProps = {
  type: MeasurementTypeConfig;
  setting: Setting;
  isLast: boolean;
  isFirst: boolean;
  deletable: boolean;
  editable: boolean;
  onChange: (settings: Setting[]) => void;
  lastDayInputRef: React.RefObject<HTMLInputElement>;
  typeSettings: Setting[];
};

/** Renders a table row with the values for each config type's day */
const DayRow: FC<DayRowProps> = ({
  type,
  setting,
  isLast,
  isFirst,
  deletable,
  editable,
  onChange,
  lastDayInputRef,
  typeSettings,
  children,
}) => {
  const { totalDays, settings, focusedSetting, setFocusedSetting } =
    useContext(Context);
  const {
    label,
    unit,
    statisticsKeyV2,
    preset: { readOnly, isAverage } = {},
  } = type;
  // the list of days with settings
  const lightHasValues = PARAMETERS.some(
    (key) => (setting.light[key] ?? 0) > 0
  );
  const darkIsEmpty = areParametersEmpty(setting.dark);
  const copyFromLightEnabled = editable && lightHasValues && darkIsEmpty;
  const rowAriaLabel = `${readOnly ? '' : 'Edit '}day #${setting.day + 1} of type ${label}`;
  const lockedDay = setting.day === 0;

  const handleDelete = () => {
    setFocusedSetting(null);
    onChange(settings.filter(({ id }) => id !== setting.id));
  };
  const removeSettingCTA = (
    <>
      {deletable ? (
        <Button
          variant="flat"
          size="icon"
          aria-label={`Remove day #${setting.day + 1}`}
          onClick={() => {
            handleDelete();
          }}
          className="text-inherit"
        >
          <GarbageIcon />
        </Button>
      ) : (
        <Button
          variant="flat"
          size="icon"
          aria-hidden
          disabled
          className="text-inherit disabled:text-inherit"
        >
          <LockIcon />
        </Button>
      )}
    </>
  );

  const handleChange = (
    id: Setting['id'],
    field: ManagedSettingFields,
    value: Parameters | number | null
  ) => {
    setFocusedSetting(null);
    const modified = settings.map((setting) =>
      setting.id === id ? { ...setting, [field]: value } : setting
    );

    onChange(modified);
  };

  const handleCopyLightToDark = (id: Setting['id'], offset: number) => {
    const modified = settings.map((setting) => {
      if (setting.id === id) {
        return {
          ...setting,
          dark: {
            ...setting.light,
            setPoint: (setting.light.setPoint ?? 0) + offset,
          },
        };
      }
      return setting;
    });

    onChange(modified);
  };

  const handleWeekAndDaysRef = (
    element: Nullable<HTMLInputElement>,
    type: Setting['type'],
    isFirst: boolean
  ) => {
    if (isNil(element?.value)) {
      return;
    }
    const [weeks, days] = element.value
      .replace(/(\d+)w (\d+)d/, '$1:$2')
      .split(':')
      .map((value) => Number(value) - 1) as [number, number];
    const selectedDay = weeks * 7 + days;
    const startTimeNotUnique = typeSettings.find(
      (setting) =>
        setting.day === selectedDay &&
        `${type}-${setting.id}` !== element.dataset.id &&
        type === setting.type
    );
    const notSorted = typeSettings
      .slice(
        0,
        typeSettings.findIndex(
          (setting) => `${type}-${setting.id}` === element.dataset.id
        )
      )
      .some((setting) => setting.day > selectedDay);

    if (isFirst && setting.day > 0) {
      element.setCustomValidity('The first day should be 1');
    } else if (notSorted) {
      element.setCustomValidity('Days must be in ascending order');
    } else if (startTimeNotUnique) {
      element.setCustomValidity(`Day #${selectedDay} already exists`);
    } else {
      element.setCustomValidity('');
    }
  };
  const handleOnChangeDay = (value: string) => {
    const [weeks, days] = value.split(':').map(Number) as [number, number];
    const transformedValue = days + weeks * 7;
    handleChange(setting.id, 'day', transformedValue);
  };

  const selectedDay = setting.day ?? 0;
  const week = Math.floor(selectedDay / 7);
  const day = selectedDay % 7;

  return (
    <div
      role="form"
      aria-label={rowAriaLabel}
      className={cn(
        'col-span-4 grid grid-cols-subgrid gap-2 p-4 focused-row',
        'sm:col-span-8 sm:p-1 sm:rounded-xs',
        isFirst && 'rounded-t-md',
        isLast && 'rounded-b-md',
        !isLast && 'border-b-[1px] border-b-neutral-400 sm:border-0'
      )}
    >
      <div
        className={cn(
          'col-span-4 grid grid-cols-subgrid items-center py-2',
          'sm:block sm:col-span-1 sm:p-0'
        )}
      >
        <div className="sm:hidden">Day #</div>

        <TimePicker
          key={`${statisticsKeyV2}-${setting.id}-day`}
          name={`${statisticsKeyV2}:${setting.id}:day`}
          aria-label={`Day #${selectedDay + 1}`}
          ref={mergeRefs([
            (element) =>
              handleWeekAndDaysRef(element, statisticsKeyV2, isFirst),
            lastDayInputRef,
          ])}
          data-id={`${statisticsKeyV2}-${setting.id}`}
          type="weeksAndDays"
          firstValue={week}
          secondValue={day}
          firstMaxValue={99}
          secondMaxValue={Math.min(totalDays, 7)}
          onChange={handleOnChangeDay}
          readOnly={readOnly || (isFirst && lockedDay)}
          disabled={readOnly || (isFirst && lockedDay)}
          required
          className="col-span-2"
        />

        {!readOnly && (
          <div className="col-start-4 text-right sm:hidden">
            {removeSettingCTA}
          </div>
        )}
      </div>

      <div className="col-span-4 grid grid-cols-subgrid col-start-2 pb-2 sm:hidden">
        {rangeHeaders}
      </div>

      {(['light', 'dark'] as CycleParameters[]).map(
        function RenderLightCycle(cycle) {
          const title = cycle === 'light' ? 'Day' : 'Night';
          const handleOnFocusParameter = () => {
            setFocusedSetting(setting);
          };

          return (
            <div
              key={`${statisticsKeyV2}-${setting.id}-${cycle}`}
              role="group"
              aria-label={`${title} cycle`}
              className="col-span-4 grid grid-cols-subgrid gap-2 items-center sm:col-span-3"
            >
              <div className="col-span-1 sm:hidden capitalize">{title}</div>

              {PARAMETERS.map(function RenderRange(fieldName) {
                const rawValue = setting[cycle][fieldName];
                const value = `${!isNil(rawValue) ? rawValue : ''}`;
                return (
                  <Input
                    className="col-span-1"
                    key={`${setting.id}-${title}-${fieldName}`}
                    name={`${setting.id}-${title}-${fieldName}`}
                    aria-label={`${fieldName} value`}
                    type="number"
                    inputMode="numeric"
                    min={0}
                    step="any"
                    value={value}
                    required
                    readOnly={readOnly || !editable}
                    onFocus={
                      copyFromLightEnabled && cycle === 'dark'
                        ? handleOnFocusParameter
                        : undefined
                    }
                    onChange={(event) =>
                      handleChange(setting.id, cycle, {
                        ...setting[cycle],
                        [fieldName]:
                          event.target.value && event.target.value.length > 0
                            ? event.target.valueAsNumber
                            : null,
                      })
                    }
                    {...(isAverage && {
                      value: `${value.length > 0 ? `~${value}` : ''}`,
                      type: 'text',
                    })}
                  />
                );
              })}
            </div>
          );
        }
      )}

      {!readOnly && (
        <div className="hidden col-span-1 sm:block">{removeSettingCTA}</div>
      )}

      {focusedSetting?.type === statisticsKeyV2 &&
        focusedSetting?.day === setting.day && (
          <div className="col-span-full py-2 ">
            <CopyWithOffset
              message={`Day #${setting.day + 1}: Copy day settings?`}
              unit={unit}
              className="justify-center"
              onClose={() => setFocusedSetting(null)}
              onConfirm={(offset) => {
                setFocusedSetting(null);
                handleCopyLightToDark(setting.id, offset);
              }}
            />
          </div>
        )}

      {children}
    </div>
  );
};

/** Renders a small component with an input type number and a couple of buttons */
const CopyWithOffset: FC<
  ComponentProps<'div'> & {
    message: string;
    unit: string;
    variant?: ButtonProps['variant'];
    onConfirm: (offset: number) => void;
    onClose?: () => void;
  }
> = ({ message, unit, className, onConfirm, onClose, ...props }) => {
  const [offset, setOffset] = useState<number>(0);

  return (
    <div
      {...props}
      className={cn(
        'w-full flex flex-wrap gap-2 items-center justify-center',
        className
      )}
    >
      <p className="font-medium">{message}</p>
      <div className="flex gap-2 items-center">
        <p className="text-nowrap" id="copy-offset">
          Offset ({unit})
        </p>
        <div className="max-w-24">
          <Input
            type="number"
            inputMode="numeric"
            step="any"
            defaultValue={0}
            aria-labelledby="copy-offset"
            onChange={(event) => setOffset(event.target.valueAsNumber)}
          />
        </div>
        <Button
          variant="inverted"
          size="icon"
          aria-label={`Copy settings with offset ${offset} ${unit}`}
          onClick={() => onConfirm(offset)}
        >
          <CheckIcon />
        </Button>
        {onClose && (
          <Button
            variant="inverted"
            size="icon"
            aria-label="Hide copy settings"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </Button>
        )}
      </div>
    </div>
  );
};
