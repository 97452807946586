import React, { FC } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

interface ILinkProps extends LinkProps {
  /**
   * Text to be rendered inside the component.
   */
  text: React.ReactNode;
}

export const Link: FC<ILinkProps> = ({ text, to, ...props }) => {
  return (
    <RouterLink
      className="text-sm font-semibold text-orange-700 hover:text-orange-500"
      to={to}
      {...props}
    >
      {text}
    </RouterLink>
  );
};
