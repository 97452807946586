import { DayPicker, type DayPickerProps } from 'react-day-picker';

import { ChevronBackwardIcon } from 'icons/ChevronBackwardIcon';
import { ChevronForwardIcon } from 'icons/ChevronForwardIcon';
import { cn } from 'shared/utils/cn';
import { formatShortWeekday } from 'shared/utils/date';

const classNames: DayPickerProps['classNames'] = {
  root: cn('flex flex-col items-center', 'rounded text-sm text-neutral-900'),
  months: 'w-full p-4',
  month: 'flex flex-col gap-1',
  button: '',
  caption: 'flex items-center justify-between',
  caption_label: 'text-lg',
  button_reset: '',
  caption_start: '',
  caption_end: '',
  nav: 'flex gap-2',
  nav_button: cn(
    'inline-flex h-9 min-w-9 items-center justify-center rounded-full bg-neutral-200 px-1 text-neutral-900',
    'hover:bg-neutral-300 active:bg-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400'
  ),
  nav_button_next: '',
  nav_button_previous: '',
  nav_icon: '',
  table: 'flex flex-col gap-1',
  head: '',
  head_row: 'flex gap-2 justify-between py-1',
  head_cell:
    'flex items-center justify-center uppercase aspect-square w-full min-w-8 p-0 font-medium',
  tbody: 'flex flex-col gap-2',
  row: 'flex gap-2 justify-between',
  cell: 'aspect-square w-full min-w-8 p-0',
  tfoot: 'flex items-center justify-center pt-1',
  day: cn(
    'flex items-center justify-center rounded-xs',
    'h-full w-full',
    'bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-400'
  ),
  day_outside: 'text-neutral-900',
  day_today: cn(
    'border border-neutral-900',
    'aria-selected:border-orange-500 aria-selected:active:bg-orange-500 aria-selected:active:text-white',
    'aria-selected:hover:border-0'
  ),
  day_disabled:
    'bg-white disabled:text-neutral-900 disabled:bg-white hover:disabled:bg-white active:disabled:bg-white',
  day_selected: cn(
    'bg-orange-200 hover:bg-orange-400 active:bg-orange-500',
    ''
  ),
  day_hidden: '',
  day_range_end: '',
  day_range_middle: '',
  day_range_start: '',
};
export type CalendarProps = DayPickerProps;

/** */
export const Calendar = (props: CalendarProps) => {
  return (
    <DayPicker
      showOutsideDays
      fixedWeeks
      formatters={{
        formatWeekdayName: formatShortWeekday,
      }}
      {...props}
      classNames={{ ...classNames, ...props.classNames }}
      components={{
        IconLeft: () => <ChevronBackwardIcon />,
        IconRight: () => <ChevronForwardIcon />,
      }}
    />
  );
};
