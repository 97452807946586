import { Auth } from 'aws-amplify';
import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { PasswordRules } from 'components/auth/PasswordRules';
import { Button } from 'components/common/Button/Button';
import { ConfirmDialog } from 'components/common/ConfirmDialog';
import { Grid } from 'components/common/Grid';
import { Link } from 'components/common/Link';
import { TextInput } from 'components/common/TextInput';
import { Typography } from 'components/common/Typography';
import { useResetPasswordURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PASSWORD_RULES } from 'shared/constants/password';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';

type TResetPasswordForm = {
  code: string;
  password: string;
  confirmPassword: string;
};

export const ResetPasswordPage: FC = () => {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldShowRules, setShouldShowRules] = useState(false);
  const [showChangedDialog, setShowChangedDialog] = useState(false);

  const { isMobile } = useScreenSize();
  const { handleSubmit, register, watch } = useForm<TResetPasswordForm>();

  const { navigateToLogin, username } = useResetPasswordURL();
  const values = watch();

  const checkPasswordRules = (password: string, confirmPassword: string) =>
    PASSWORD_RULES.every((rule) => rule.check(password, confirmPassword));

  const onSubmit = handleSubmit(async (values) => {
    const isValid = checkPasswordRules(values.password, values.confirmPassword);
    if (!isValid) {
      setShouldShowRules(true);
      return;
    }

    setIsSubmitting(true);

    try {
      await Auth.forgotPasswordSubmit(
        username || '',
        values.code,
        values.password
      );
      // show password changed dialog
      showPasswordChangedDialog();
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  });

  const showPasswordChangedDialog = () => {
    setShowChangedDialog(true);
  };

  const handleGoToLogIn = () => {
    setShowChangedDialog(false);
    navigateToLogin();
  };

  return (
    <NonAuthLayout formClassName="min-h-[600px]">
      <NonAuthForm
        instructionHeader={
          <>
            <Typography fontWeight="normal" variant={isMobile ? 'h3' : 'h2'}>
              Reset
            </Typography>
            <Typography variant={isMobile ? 'h3' : 'h2'}>Password</Typography>
          </>
        }
        instructions={
          <Typography color="gray" lineHeight="30px" variant="body1">
            Enter the reset code you received and the new password you would
            like to use.
          </Typography>
        }
        footer={
          <>
            <Typography variant="body2">Remember password?</Typography>
            <Link to={PATH_PATTERNS[ERoutePath.LOGIN]} text="Login" />
          </>
        }
      >
        <form role="form" onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="Verify code"
                data-testid="verify-code"
                {...register('code', { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="New Password"
                type="password"
                data-testid="new-password"
                {...register('password', { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="Confirm Password"
                type="password"
                data-testid="confirm-password"
                {...register('confirmPassword', { required: true })}
              />
            </Grid>

            {shouldShowRules && (
              <Grid item xs={12}>
                <PasswordRules
                  confirmPassword={values.confirmPassword}
                  password={values.password}
                  rules={PASSWORD_RULES}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button disabled={isSubmitting} type="submit" className="w-full">
                Change password
              </Button>
            </Grid>

            {error && (
              <Grid item xs={12}>
                {error}
              </Grid>
            )}
          </Grid>
        </form>
      </NonAuthForm>

      {showChangedDialog ? (
        <ConfirmDialog
          title="Password changed!"
          open
          buttonText="Continue"
          onClose={handleGoToLogIn}
        />
      ) : null}
    </NonAuthLayout>
  );
};
