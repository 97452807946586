import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { SectionsLayer } from 'components/image_feed/SectionsLayer';
import {
  getDistanceBetweenTwoPoints,
  getMinScale,
  getNewPositionRelativeToCurrent,
  getNewPositionWithBoundingBox,
  getNewScaleWithBoundingBox,
  getPositionRelativeToPointer,
  getScaleCenterPosition,
} from 'components/image_feed/utils';
import { ImageQueueProvider } from 'contexts/ImageQueueProvider';
import { useStageProvider } from 'contexts/StageProvider';
import {
  useImageFeedURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePermissions } from 'hooks/usePermissions';
import Konva from 'konva';
import isNil from 'lodash.isnil';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Layer, Stage } from 'react-konva';
import { TDiscussion } from 'shared/interfaces/discussion';
import {
  EImageTypes,
  ISectionInformation,
  TImagesGrid,
} from 'shared/interfaces/image';
import { EEventKeyCodes } from 'shared/interfaces/keys';
import { cn } from 'shared/utils/cn';
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce';
import { ImageMainAnnotations } from './ImageMainAnnotations';
import { CANVAS_INTERACTION_THROTTLE_MILLISECONDS } from './constants';
import { useGetSectionsHighlights } from './hooks/useGetSectionsHighlights';
import { useImageFeedLabelStats } from './hooks/useImageFeedLabelStats';
import { useImageFeedLabelsCounts } from './hooks/useImageFeedLabelsCounts';
import { useImageSizeIndexMap } from './hooks/useImageSizeIndexMap';
import { useMissingImagesAlert } from './hooks/useMissingImagesAlert';
interface IStyleProps {
  minimapVisible: boolean;
}

type ViewPort = {
  topLeft: TPosition;
  bottomRight: TPosition;
};

const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    hoverLayer: {
      position: 'absolute',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none',
    },
    stageContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      overflow: 'hidden',
    },
    stage: {
      width: '100%',
      height: '100%',
    },
    minimapContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 1,
      transform: 'translate(-50%, -50%)',
      opacity: (props) => (props.minimapVisible ? 1 : 0),
      transition: 'opacity 200ms ease-in-out',
      pointerEvents: 'none',
    },
    labelDetail: {
      padding: theme.spacing(1),
    },
    debugImageSizeIndex: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 2,
      transform: 'translate(-50%, -50%)',
      opacity: 1,
      pointerEvents: 'none',
      fontSize: 100,
      color: 'white',
    },
  })
);

export interface IImageMainProps {
  /** The grid images information. */
  imagesGrid: TImagesGrid;
  /** `true` if the NDVI images are available. */
  isNDVIAvailable: boolean;
  /** `false` if the RGB images are available. */
  hasNoRGBImages: boolean;
  /** `true` if we need to show the grid info. */
  showGridInfo: boolean;
  /** The index of the small image size. */
  imageSizeIndex: number;
  /** Callback to reset the image size index. */
  resetImageSizeIndex: (newIndex?: number) => void;
  /** The small to large image sizes. */
  sortedImageSizes: TSize[];
  /** The total image size. */
  totalImageSize: TSize;
  /** Minimap component */
  miniMap: React.ReactNode;
  /** The index of the largest small image. */
  largestSmallImageIndex: number;
  /** set image index */
  advanceImageSizeIndex: (scale: TScale, newIndex?: number) => void;
  /** measurement run id */
  measurementRunId: Nullable<number>;
  /** cultivars layer */
  cultivarsLayer: (
    scale: number,
    sectionWidth: number,
    sectionHeight: number
  ) => React.ReactNode;
}
const VISIBLE_AREA_EXTENSION_PERCENT = 0.3;

export const ImageMain: FC<IImageMainProps> = ({
  totalImageSize,
  imagesGrid,
  hasNoRGBImages,
  isNDVIAvailable,
  showGridInfo,
  miniMap,
  sortedImageSizes,
  imageSizeIndex,
  resetImageSizeIndex,
  largestSmallImageIndex,
  advanceImageSizeIndex,
  measurementRunId,
  cultivarsLayer,
}) => {
  const { setSingleImageLocation, showComments } = useImageFeedURL();

  const smallImageSize = sortedImageSizes[imageSizeIndex]!;
  const [minimapDisplay, setMinimapDisplay] = useState(false);

  // NOTE: this is crucial for disabling dragging
  const [isPinching, setIsPinching] = useState(false);

  // NOTE: we can't have this as a state to avoid re-renders
  // but at the same time we need to know last point (distance between fingers)
  // in order to know the direction and magnitude of pinch action (in/out + fast/slow)
  const lastPointDistance = useRef<number>(0);
  const toggleMinimapOffDebounced = useDebouncedCallback(
    () => setMinimapDisplay(false),
    300
  );

  const classes = useStyles({
    minimapVisible: minimapDisplay,
  });

  const {
    stage,
    scale,
    position,
    size,
    initStage,
    setStage,
    setPosition,
    setPositionAndScale,
  } = useStageProvider();
  const minScale = getMinScale(size, totalImageSize);

  const nrRows = imagesGrid.length;
  const nrColumns = imagesGrid[0]?.length ?? 0;
  const gridSize = useMemo(
    () => ({ row: nrRows, column: nrColumns }),
    [nrRows, nrColumns]
  );

  /**
   * This method calculates initial position when the scale changes.
   */
  useEffect(() => {
    if (!minScale) {
      return;
    }

    const defaultScale = { x: minScale, y: minScale };
    const defaultPosition = getScaleCenterPosition(
      defaultScale,
      size,
      totalImageSize
    );

    initStage(defaultScale, defaultPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    minScale,
    size,
    totalImageSize.height,
    totalImageSize.width,
    nrRows,
    nrColumns,
  ]);

  const { labelsCountsByMeasurementId, labelsCounts } =
    useImageFeedLabelsCounts();
  const { zoneTimeZone } = useCurrentZone();
  const { imageLabelCode, imageType } = useImageFeedURL();
  const { getMeasurementRunStartTime } = useZoneDetailsPageURL();

  const { updateImageFeedLabelStats } = useImageFeedLabelStats();
  useEffect(() => {
    updateImageFeedLabelStats(labelsCounts);
  }, [labelsCounts, updateImageFeedLabelStats]);

  const sectionsHighlights = useGetSectionsHighlights(
    imageLabelCode,
    labelsCountsByMeasurementId,
    new Date(getMeasurementRunStartTime(zoneTimeZone) || 0),
    labelsCounts[0]?.measurementRunId || 0
  );

  useMissingImagesAlert({
    hasNoRGBImages,
    isNDVIAvailable,
    imageCount: imagesGrid.length * (imagesGrid[0]?.length || 0),
  });

  const movePositionByArrow = (directionX: number, directionY: number) => {
    if (!stage) {
      return;
    }

    setPosition(
      getNewPositionWithBoundingBox(
        {
          x: position.x + smallImageSize.width * scale.x * directionX,
          y: position.y + smallImageSize.height * scale.y * directionY,
        },
        scale,
        size,
        totalImageSize
      )
    );
  };

  useHotkeys(EEventKeyCodes.LEFT_ARROW, () => movePositionByArrow(1, 0), [
    position,
    scale,
    smallImageSize,
  ]);

  useHotkeys(EEventKeyCodes.RIGHT_ARROW, () => movePositionByArrow(-1, 0), [
    position,
    scale,
    smallImageSize,
  ]);

  useHotkeys(EEventKeyCodes.UP_ARROW, () => movePositionByArrow(0, 1), [
    position,
    scale,
    smallImageSize,
  ]);

  useHotkeys(EEventKeyCodes.DOWN_ARROW, () => movePositionByArrow(0, -1), [
    position,
    scale,
    smallImageSize,
  ]);
  const handleOnWheel = (event: Konva.KonvaEventObject<WheelEvent>) => {
    event.evt.preventDefault();
    // NOTE: this is a workaround (a feature) to handle continuous zooming
    // if user continues to zoom the minimap will keep updating the state toggle
    // which will keep the minimap displayed
    // the debounced toggle minimap callback will be called once
    setMinimapDisplay(true);
    toggleMinimapOffDebounced();

    const pointer = stage?.getPointerPosition();

    if (!stage || !pointer || event.evt.deltaY === 0) {
      return;
    }

    // --------------
    // Zoom amount
    // --------------
    const scaleFactor = 1.1;
    const scaleTo =
      event.evt.deltaY < 0 ? scale.x * scaleFactor : scale.x / scaleFactor;
    const newScale = getNewScaleWithBoundingBox(
      { x: scaleTo, y: scaleTo },
      minScale
    );

    // ---------------------
    // Zoom reference point
    // ---------------------
    const currentPositionRelativeToPointer = getPositionRelativeToPointer(
      pointer,
      position,
      scale
    );
    const newPositionRelativeToPointer = getNewPositionRelativeToCurrent(
      pointer,
      currentPositionRelativeToPointer,
      newScale
    );
    const newPosition = getNewPositionWithBoundingBox(
      newPositionRelativeToPointer,
      newScale,
      size,
      totalImageSize
    );

    // --------------------------------
    // Update stage scale and position
    // --------------------------------
    setPositionAndScale(newPosition, newScale);
  };
  const sectionSize = sortedImageSizes[largestSmallImageIndex]!;

  const columns = imagesGrid[0]?.length;
  const rows = imagesGrid.length;

  const { visibleArea, viewport } = useMemo(() => {
    if (
      !stage ||
      !position ||
      !stage.width() ||
      !stage.height() ||
      isNil(columns)
    ) {
      return { visibleArea: undefined, viewport: null };
    }
    const visibleWidth = stage.width() / scale.x;
    const visibleHeight = stage.height() / scale.y;
    const x = -position.x / scale.x;
    const y = -position.y / scale.y;
    const area = {
      x: x - VISIBLE_AREA_EXTENSION_PERCENT * visibleWidth,
      y: y - VISIBLE_AREA_EXTENSION_PERCENT * visibleHeight,
      width: (1 + 2 * VISIBLE_AREA_EXTENSION_PERCENT) * visibleWidth,
      height: (1 + 2 * VISIBLE_AREA_EXTENSION_PERCENT) * visibleHeight,
    };
    const cells = {
      topLeft: {
        x: Math.floor(
          Math.min(columns - 1, Math.max(0, area.x / sectionSize.width))
        ),
        y: Math.floor(
          Math.min(rows - 1, Math.max(0, area.y / sectionSize.height))
        ),
      },
      bottomRight: {
        x: Math.floor(
          Math.min(columns - 1, (area.x + area.width) / sectionSize.width)
        ),
        y: Math.floor(
          Math.min(rows - 1, (area.y + area.height) / sectionSize.height)
        ),
      },
    };
    return { visibleArea: area, viewport: cells };
  }, [
    position,
    stage,
    scale.x,
    scale.y,
    sectionSize.width,
    sectionSize.height,
    columns,
    rows,
  ]);

  const { getLowestImageSizeIndexWithinViewport } = useImageSizeIndexMap();

  const determineAndSetNextImageIndex = useCallback(
    (
      viewport: Nullable<ViewPort>,
      measurementRunId: Nullable<number>,
      scale: TScale,
      reset: boolean = true
    ) => {
      if (!measurementRunId || !viewport) return;
      const highestImageLoadedIndex = getLowestImageSizeIndexWithinViewport(
        measurementRunId,
        gridSize,
        viewport
      );
      if (highestImageLoadedIndex >= imageSizeIndex) {
        advanceImageSizeIndex(scale, highestImageLoadedIndex);
      } else if (reset) {
        resetImageSizeIndex(highestImageLoadedIndex);
      }
    },
    [
      getLowestImageSizeIndexWithinViewport,
      advanceImageSizeIndex,
      imageSizeIndex,
      resetImageSizeIndex,
      gridSize,
    ]
  );

  const determineAndSetNextImageIndexFromEffect = useThrottledCallback(
    determineAndSetNextImageIndex,
    CANVAS_INTERACTION_THROTTLE_MILLISECONDS
  );

  const determineAndSetNextImageIndexFromCallback = useThrottledCallback(
    determineAndSetNextImageIndex,
    CANVAS_INTERACTION_THROTTLE_MILLISECONDS
  );

  useEffect(() => {
    if (!viewport) return;
    determineAndSetNextImageIndexFromEffect(viewport, measurementRunId, scale);
  }, [
    viewport,
    determineAndSetNextImageIndexFromEffect,
    measurementRunId,
    scale,
    position,
  ]);

  const handleImageLoaded = useCallback(() => {
    determineAndSetNextImageIndexFromCallback(
      viewport,
      measurementRunId,
      scale,
      false
    );
  }, [
    determineAndSetNextImageIndexFromCallback,
    viewport,
    measurementRunId,
    scale,
  ]);

  const handleTouchMove = (event: Konva.KonvaEventObject<TouchEvent>) => {
    if (!stage) return;

    event.evt.preventDefault();

    const touch1 = event.evt.touches[0];
    const touch2 = event.evt.touches[1];

    if (!touch1 || !touch2) {
      return;
    }

    const point1 = { x: touch1.clientX, y: touch1.clientY };
    const point2 = { x: touch2.clientX, y: touch2.clientY };

    // NOTE: disable dragging
    setIsPinching(true);

    // --------------
    // Zoom amount
    // --------------
    const pointDistance = getDistanceBetweenTwoPoints(point1, point2);

    // NOTE: this essentially skips first 'touchmove' event
    // no impact on UX/UI whatsoever since the resulting newScale
    // increases by 100-th of a fraction
    if (!lastPointDistance.current) {
      lastPointDistance.current = pointDistance;
    }

    const scaleTo = scale.x * (pointDistance / lastPointDistance.current);
    const newScale = getNewScaleWithBoundingBox(
      { x: scaleTo, y: scaleTo },
      minScale
    );
    // NOTE: keeping last point distance updated between previous and current 'touchmove' events
    lastPointDistance.current = pointDistance;

    // ---------------------
    // Zoom reference point
    // ---------------------

    // NOTE: x,y center between touch points
    const pointer = {
      x: (point1.x + point2.x) / 2,
      y: (point1.y + point2.y) / 2,
    };
    const currentPositionRelativeToPointer = getPositionRelativeToPointer(
      pointer,
      position,
      scale
    );
    const newPositionRelativeToPointer = getNewPositionRelativeToCurrent(
      pointer,
      currentPositionRelativeToPointer,
      newScale
    );
    const newPosition = getNewPositionWithBoundingBox(
      newPositionRelativeToPointer,
      newScale,
      size,
      totalImageSize
    );

    // --------------------------------
    // Update stage scale and position
    // --------------------------------
    setPositionAndScale(newPosition, newScale);
  };

  const handleTouchEnd = () => {
    if (isPinching) {
      // NOTE: delaying this to avoid mis-click on single cell to go into single image view
      // when fingers are lifted from screen at different times once pinching action is performed
      setTimeout(() => {
        setIsPinching(false);
      }, 100);

      // NOTE: last point distance must be reset each time fingers are lifted from screen
      // we're interested in a single pinch action instead of between pinches
      // otherwise scaling would start changing depending on how far apart the fingers are
      lastPointDistance.current = 0;
    }
  };

  const handleDragMove = useCallback(() => {
    if (!stage) return;

    const newPosition = stage?.getPosition();

    setPosition(
      getNewPositionWithBoundingBox(
        {
          x: newPosition.x,
          y: newPosition.y,
        },
        scale,
        size,
        totalImageSize
      )
    );
  }, [stage, setPosition, scale, size, totalImageSize]);

  const handleDragStart = () => setMinimapDisplay(true);
  const handleDragEnd = () => toggleMinimapOffDebounced();

  // NOTE: To avoid mis-clicks, allow clicks on single image section
  // only when
  // - pinching has stopped
  // - no cluster is selected
  const handleOnSectionClick = (row: number, column: number) => {
    if (!isPinching) {
      setSingleImageLocation({ row, column });
    }
  };

  const handleAnnotationMouseEvent = useCallback(
    (e: MouseEvent | WheelEvent) => {
      stage?.dispatchEvent(e);
    },
    [stage]
  );

  const handleAnnotationClick = useCallback(
    (sectionInformation: ISectionInformation, discussion: TDiscussion) =>
      setSingleImageLocation({
        row: sectionInformation.cellY,
        column: sectionInformation.cellX,
        discussionUid: discussion.uid,
      }),
    [setSingleImageLocation]
  );

  const { canDebugImageFeed, canDebugImageSizeIndex } = usePermissions();

  if (hasNoRGBImages && imageType === EImageTypes.RGB) {
    return null;
  }

  return (
    <div
      className={cn(classes.stageContainer, 'bg-neutral-200')}
      data-testid="image-main"
    >
      <Stage
        ref={setStage}
        scale={scale}
        width={size.width}
        height={size.height}
        x={position.x}
        y={position.y}
        draggable={!isPinching}
        className={classes.stage}
        onWheel={handleOnWheel}
        onDragMove={handleDragMove}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        role="figure"
      >
        <Layer id="sections-layer">
          <ImageQueueProvider
            scale={scale.x}
            imageSizeIndex={imageSizeIndex}
            parentId={measurementRunId || 0}
          >
            <SectionsLayer
              scale={scale}
              imagesGrid={imagesGrid}
              labelsByMeasurementId={labelsCountsByMeasurementId}
              sectionsHighlights={sectionsHighlights}
              showGridInfo={showGridInfo}
              onClick={handleOnSectionClick}
              debugImageFeedFlag={canDebugImageFeed}
              debugImageSizeIndexFlag={canDebugImageSizeIndex}
              imageSizeIndex={imageSizeIndex}
              sortedImageSizes={sortedImageSizes}
              largestSmallImageIndex={largestSmallImageIndex}
              onImageLoaded={handleImageLoaded}
              measurementRunId={measurementRunId}
              visibleArea={visibleArea}
            />
            {cultivarsLayer(scale.x, sectionSize.width, sectionSize.height)}
          </ImageQueueProvider>
        </Layer>
      </Stage>
      {showComments && (
        <ImageMainAnnotations
          imagesGrid={imagesGrid}
          sectionSize={sectionSize}
          scale={scale}
          position={position}
          onMouseEvent={handleAnnotationMouseEvent}
          onAnnotationClick={handleAnnotationClick}
        />
      )}

      {canDebugImageSizeIndex && (
        <div className={classes.debugImageSizeIndex}>{imageSizeIndex}</div>
      )}
      <div className={classes.minimapContainer}>{miniMap}</div>
    </div>
  );
};
