import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const NoPresetIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.003 12.5731V5.33501C16.003 4.28358 15.1504 3.43025 14.0982 3.43025H7.62202C7.20145 3.43025 6.86012 3.08967 6.86012 2.66834C6.86012 1.8272 6.17821 1.14453 5.33631 1.14453H4.19345C3.98317 1.14453 3.8125 1.3152 3.8125 1.52548C3.8125 1.73577 3.98317 1.90644 4.19345 1.90644H5.33631C5.75688 1.90644 6.09821 2.24777 6.09821 2.66834C6.09821 3.51025 6.78012 4.19215 7.62202 4.19215H14.0982C14.7291 4.19215 15.2411 4.70415 15.2411 5.33501V12.5731C15.2411 12.7834 15.4117 12.9541 15.622 12.9541C15.8323 12.9541 16.003 12.7834 16.003 12.5731Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.54514 1.14529L1.52229 1.14453C0.681905 1.14453 0 1.82644 0 2.66682V12.9541C0 14.0062 0.852571 14.8588 1.90476 14.8588H14.4762C14.6865 14.8588 14.8571 14.6882 14.8571 14.4779C14.8571 14.2676 14.6865 14.0969 14.4762 14.0969H1.90476C1.2739 14.0969 0.761905 13.5857 0.761905 12.9541V2.66682C0.761905 2.24701 1.10248 1.90644 1.52229 1.90644H1.52381L1.54438 1.87367C1.632 1.73044 1.952 1.1651 1.54514 1.14529Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.67001 12.5705H5.71763C5.92792 12.5705 6.09859 12.3998 6.09859 12.1895C6.09859 11.9793 5.92792 11.8086 5.71763 11.8086H2.67001C2.45973 11.8086 2.28906 11.9793 2.28906 12.1895C2.28906 12.3998 2.45973 12.5705 2.67001 12.5705Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.877054 1.41181L14.5913 15.1261C14.7407 15.2747 14.9814 15.2747 15.1308 15.1261C15.2793 14.9775 15.2793 14.736 15.1308 14.5874L1.41648 0.873147C1.26715 0.724576 1.02639 0.724576 0.877054 0.873147C0.728482 1.02172 0.728482 1.26324 0.877054 1.41181Z"
      fill={fill}
    />
  </svg>
);

export { NoPresetIcon };
