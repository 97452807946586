import { FloatingPortal } from '@floating-ui/react';
import {
  Placement,
  autoPlacement,
  autoUpdate,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react-dom';
import isNil from 'lodash.isnil';
import { cloneElement, forwardRef, useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { cn } from 'shared/utils/cn';
import { CircularInfiniteList } from './CircularInfiniteList';

export type ListOptions = {
  ariaLabel?: string;
  nrItems: number;
  selectedIndex?: number;
  renderItem: (index: number) => React.ReactNode;
};

type InfiniteListsPickerProps = {
  className?: string;
  lists: ListOptions[];
  onChange?: (indexes: number[]) => void;
  children?: React.ReactNode;
  tabbable?: boolean;
  dropdownPlacement?: Placement;
};

export const InfiniteListsPicker = forwardRef<
  HTMLDivElement,
  InfiniteListsPickerProps
>(function InfiniteListsPicker(
  {
    className,
    lists,
    onChange,
    tabbable = true,
    dropdownPlacement = 'bottom-start',
    children,
  },
  ref
) {
  const [open, setOpen] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const listsRefs = useRef<Nullable<HTMLDivElement>[]>(
    Array(lists.length).fill(null)
  );

  const dropdownRef = useRef<Nullable<HTMLDivElement>>(null);
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);

  const {
    floatingStyles,
    refs: { setReference, setFloating },
  } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      shift(),
      offset(4),
      autoPlacement({
        allowedPlacements: [dropdownPlacement, 'top-start', 'bottom-start'],
      }),
    ],
  });

  /*
   * Set the dropdown height to the minimum height of the lists
   */
  useEffect(() => {
    if (open) {
      const heights = listsRefs.current.map((ref) => ref?.clientHeight || 0);
      const minHeight = Math.min(...heights.filter((height) => height > 0));
      setDropdownHeight(minHeight);
    }
  }, [open, lists.length]);

  // use left and right keys to focus the next or previous list
  useEffect(() => {
    if (open && tabbable) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          e.preventDefault();
          const openIndex = lists.findIndex((_, i) =>
            listsRefs.current[i]?.contains(document.activeElement)
          );
          if (openIndex !== -1) {
            const nextIndex =
              (lists.length + openIndex + (e.key === 'ArrowLeft' ? -1 : 1)) %
              lists.length;
            listsRefs.current[nextIndex]?.focus();
          }
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [open, lists, tabbable]);

  // open/close the dropdown with enter/escape keys
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // if target is not container or dropdown, ignore
      if (
        !containerRef.current?.contains(e.target as HTMLElement) &&
        !dropdownRef.current?.contains(e.target as HTMLElement)
      ) {
        return;
      }
      // pressing escape should close the dropdown
      if (e.key === 'Escape') {
        setOpen(false);
        // pressing enter should toggle the dropdown
      } else if (e.key === 'Enter') {
        setOpen(!open);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (
      !e.relatedTarget ||
      !(
        containerRef.current?.contains(e.relatedTarget as HTMLElement) ||
        dropdownRef.current?.contains(e.relatedTarget as HTMLElement)
      )
    ) {
      setOpen(false);
    }
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const overlayRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    if (!open) return;
    const handleMouseDown = (e: MouseEvent) => {
      // check if target element contains the container or dropdown
      if (
        containerRef.current?.contains(e.target as HTMLElement) ||
        dropdownRef.current?.contains(e.target as HTMLElement)
      ) {
        return;
      }
      overlayRef.current?.click();
      e.preventDefault();
    };
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [open]);

  return (
    <>
      <div
        ref={overlayRef}
        className={cn(
          'hidden fixed inset-0 z-10 pointer-events-none',
          open && 'block'
        )}
        onClick={() => {
          const activeElement = document.activeElement as HTMLInputElement;
          if (!isNil(activeElement.blur)) activeElement.blur();
          setOpen(false);
        }}
      ></div>

      <div
        ref={mergeRefs([containerRef, ref, setReference])}
        className={cn(
          'relative w-full cursor-pointer',
          className,
          open && 'z-20'
        )}
        onFocus={handleFocus}
        onClick={handleClick}
        onBlur={handleBlur}
      >
        {children}

        {open && (
          <FloatingPortal>
            <div
              ref={mergeRefs([dropdownRef, setFloating])}
              style={{
                height: dropdownHeight,
                ...floatingStyles,
              }}
              className={cn(
                ' z-20 box-content flex w-max flex-row overflow-hidden bg-white',
                'rounded-xs border border-neutral-400 text-neutral-900',
                'hover:border-neutral-500 hover:disabled:border-neutral-400',
                'focus:border-orange-500',
                'absolute',
                'shadow'
              )}
              onBlur={handleBlur}
            >
              {lists
                .map(
                  (list, i) =>
                    list.nrItems > 0 && (
                      <CircularInfiniteList
                        ariaLabel={list.ariaLabel}
                        tabbable={tabbable}
                        ref={(el) => (listsRefs.current[i] = el)}
                        key={i}
                        nrItems={list.nrItems}
                        selectedIndex={list.selectedIndex ?? 0}
                        renderListItem={list.renderItem}
                        onClickListItem={(index) => {
                          const newIndexes = lists
                            .map((list) => list.selectedIndex ?? 0)
                            .slice();
                          newIndexes[i] = index;
                          onChange?.(newIndexes);
                        }}
                      />
                    )
                )
                .filter(Boolean)
                .map((list, i) =>
                  cloneElement(list as JSX.Element, {
                    className: i !== 0 ? '-ml-1' : '',
                  })
                )}
            </div>
          </FloatingPortal>
        )}
      </div>
    </>
  );
});
