import { useMutation } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';

export const useRecalculateHealthScoreMutation = () => {
  const { apiUrl, httpClient } = useApi();
  const url = new URL('trigger-recalculate-health-score', apiUrl).toString();

  return useMutation({
    mutationKey: ['recalculate-health-score'],
    mutationFn: (zoneUid: string) =>
      httpClient
        .post(url, {
          body: JSON.stringify({ zoneUid }),
        })
        .json(),
  });
};
