import {
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';

import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { EditorState, LexicalEditor } from 'lexical';
import { TMention } from 'shared/interfaces/discussion';

import { captureException } from '@sentry/core';
import { cn } from 'shared/utils/cn';
import { getValidEditorState } from 'shared/utils/textEditor';
import { MentionsNodes, MentionsPlugin } from './MentionsPlugin';
import { ReadMoreNode, ReadMorePlugin } from './ReadMorePlugin';

// lexical read more node

function onEditorError(error: any) {
  console.error(error);
  captureException(error);
}

const beautifulMentionsTheme = {
  '@': 'text-orange-500 font-bold',
};
const theme = {
  beautifulMentions: beautifulMentionsTheme,
};

type TextEditorProps = Omit<
  ComponentPropsWithoutRef<'div'>,
  'onChange' | 'placeholder'
> & {
  placeholder?: ComponentProps<typeof PlainTextPlugin>['placeholder'];
  initialEditorState?: string;
  editable: boolean;
  onChange?: (value: string) => any;
  readMoreContentLength?: number;
  availableMentions?: TMention[];
};

export const TextEditor = forwardRef<LexicalEditor, TextEditorProps>(
  function TextEditor(
    {
      placeholder,
      initialEditorState,
      editable = true,
      onChange: onChangeFromProps,
      readMoreContentLength,
      availableMentions,
      ...props
    },
    ref
  ) {
    const initialConfig: InitialConfigType = useMemo(
      () => ({
        editable,
        editorState: getValidEditorState(initialEditorState).editorState,
        namespace: 'Mentions+ReadMore+PlainTextEditor',
        theme,
        onError: onEditorError,
        nodes: [
          ...(!editable ? [ReadMoreNode] : []),
          ...(availableMentions ? MentionsNodes : []),
        ],
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const onChange = useCallback(
      (editorState: EditorState) => {
        const editorStateJSON = editorState.toJSON();
        const editorString = JSON.stringify(editorStateJSON);
        onChangeFromProps?.(editorString);
      },
      [onChangeFromProps]
    );

    return (
      <LexicalComposer initialConfig={initialConfig}>
        {ref && <EditorRefPlugin editorRef={ref} />}
        <PlainTextPlugin
          contentEditable={
            <ContentEditable
              className={cn(props.className, 'outline-none h-full')}
              style={props.style}
              ariaLabel={props['aria-label']}
              role={editable ? 'textbox' : undefined}
            />
          }
          placeholder={placeholder ?? null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        {editable && <OnChangePlugin onChange={onChange} />}
        {!editable && (
          <ReadMorePlugin
            charLimit={readMoreContentLength || Number.MAX_VALUE}
          />
        )}
        {availableMentions && (
          <MentionsPlugin availableMentions={availableMentions} />
        )}
      </LexicalComposer>
    );
  }
);
