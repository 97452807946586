import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Pagination as MuiPagination, PaginationItem } from '@material-ui/lab';
import { FC } from 'react';
import { IPaginationProps } from './types';

const BOUNDARY_COUNT = 1;

const useStyles = makeStyles(({ breakpoints, palette, spacing }) =>
  createStyles({
    pagination: {
      '& .MuiPaginationItem-root': {
        margin: spacing(0, 2),

        [breakpoints.down('sm')]: {
          margin: spacing(0, 0.25),
          minWidth: spacing(4),
          height: spacing(4),
        },
      },
      '& .Mui-selected': {
        color: 'white !important',
        background: `${palette.orange3.main} !important`,
        borderColor: `${palette.orange3.main} !important`,
      },
    },
  })
);

export const Pagination: FC<IPaginationProps> = ({
  page,
  pageCount,
  onPageChange,
}) => {
  const classes = useStyles();

  return (
    <MuiPagination
      size="large"
      classes={{ root: classes.pagination }}
      count={pageCount}
      boundaryCount={BOUNDARY_COUNT}
      page={page}
      variant="outlined"
      onChange={(_event, value) => onPageChange(value)}
      renderItem={(item) => <PaginationItem {...item} />}
    />
  );
};
