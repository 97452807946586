import { useGetLocationById } from 'api/location';
import { useGetZoneMeasurements } from 'api/measurement';
import { Wrap } from 'components/common/Wrap/Wrap';
import { NoDataView } from 'components/growth_cycle/NoDataView';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { useScreenSize } from 'hooks/useScreenSize';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { ECropTypeCodes } from 'shared/interfaces/enumeration';
import { EImageLabelCategory } from 'shared/interfaces/image';
import { EMeasurementTypes } from 'shared/interfaces/measurement';
import { EnvironmentalHealth } from './EnvironmentalHealth';
import { PlantHealth } from './PlantHealth';
import { RoomConditions } from './RoomConditions';
import { RoomInfo } from './RoomInfo';
import { useLightHours } from './hooks/useLightHours';
import { useZoneDailyHealthLabelData } from './hooks/useZoneDailyHealthLabelData';
import { useZoneHealthData } from './hooks/useZoneHealthData';

export const Homepage = () => {
  const { isMobile } = useScreenSize();
  const { getMeasurementType } = useTypeConfig();
  const {
    zoneTimeZone,
    currentTimeInCurrentZone,
    regularlyUpdatedCurrentTimeInCurrentZone,
    currentZone,
  } = useCurrentZone();
  const { navigateToImageFeed, navigateToLineChart, zoneId } =
    useZoneDetailsPageURL();
  const { currentCycle, latestCycle } = useGrowthCycles();
  const { currentZoneMeasurements, measurements, loading } =
    useGetZoneMeasurements({
      zoneId: Number(zoneId),
      zoneTimeZone,
      start: subDays(zonedTimeToUtc(currentTimeInCurrentZone, zoneTimeZone), 3),
      end: zonedTimeToUtc(currentTimeInCurrentZone, zoneTimeZone),
      getMeasurementType,
    });
  const ongoingCycle = currentCycle ?? latestCycle;
  const timeRangeQuery = {
    start: startOfDay(subDays(currentTimeInCurrentZone, 3)),
    end: endOfDay(currentTimeInCurrentZone),
  };
  const timeRangeChart = {
    start: startOfDay(subDays(currentTimeInCurrentZone, 3)),
    end: currentTimeInCurrentZone,
  };
  const health = useZoneHealthData(Number(zoneId), timeRangeQuery);
  const dailyHealth = useZoneDailyHealthLabelData(Number(zoneId), {
    start: startOfDay(subDays(currentTimeInCurrentZone, 3)),
    end: endOfDay(subDays(currentTimeInCurrentZone, 1)),
  });

  const latestMeasurementDateTime = useMemo(() => {
    if (!isNil(measurements?.[0])) {
      return new Date(measurements[0].time);
    }

    return null;
  }, [measurements]);

  const { lightHours, currentTimeOffsetInHours } = useLightHours(
    ongoingCycle?.metadata?.light_info ?? []
  );

  const { location } = useGetLocationById(currentZone?.locationId!);

  const showPlantHealth = useMemo(() => {
    if (!location) {
      return false;
    }
    const metadata = location.metadata;
    if (
      isNil(metadata) || // Undefined metadata
      isNil(metadata.crop_types) || // Undefined crop types
      metadata.crop_types.length === 0 // Empty crop types
    ) {
      return true;
    }
    // Crop type array with data
    const cropTypes = metadata.crop_types;
    return cropTypes
      .map((cropType) => cropType.toUpperCase())
      .includes(ECropTypeCodes.CANNABIS);
  }, [location]);
  const handleOnClickMeasurementType = (measurementType: EMeasurementTypes) => {
    navigateToLineChart({
      signalIds: [measurementType],
      zonedStartTime: timeRangeQuery.start.valueOf(),
      zonedEndTime: timeRangeQuery.end.valueOf(),
      timeZone: zoneTimeZone,
    });
  };
  const handleOnClickLabel = (category: EImageLabelCategory) => {
    navigateToImageFeed({
      imageLabelCode: category,
      zonedStartTime: timeRangeQuery.start.valueOf(),
      zonedEndTime: timeRangeQuery.end.valueOf(),
      timeZone: zoneTimeZone,
    });
  };

  if (!ongoingCycle) {
    return (
      <NoDataView message="There is no data available for this zone from the past 3 days" />
    );
  }

  return (
    <div className="max-w-full grid gap-8 [grid-template-columns:1fr] lg:grid-cols-[minmax(240px,_320px)_auto]">
      <Wrap
        condition={!isMobile}
        with={(children) => (
          <div className="min-w-0 flex flex-col gap-8">{children}</div>
        )}
      >
        <RoomInfo
          className="row-start-1"
          latestMeasurementDateTime={latestMeasurementDateTime}
          currentTime={regularlyUpdatedCurrentTimeInCurrentZone}
          lightHours={lightHours}
          offset={currentTimeOffsetInHours}
          showHarvestProgress={
            ongoingCycle?.metadata?.show_harvest_progress !== null
          }
          cycle={ongoingCycle}
        />
        <RoomConditions
          className="row-start-4"
          currentTime={currentTimeInCurrentZone}
          growthCycle={ongoingCycle}
          measurements={currentZoneMeasurements}
          loading={loading}
        />
      </Wrap>

      <Wrap
        condition={!isMobile}
        with={(children) => (
          <div className="min-w-0 flex flex-col gap-8">{children}</div>
        )}
      >
        <EnvironmentalHealth
          className="row-start-3"
          onClickMeasurementType={handleOnClickMeasurementType}
          timeRange={timeRangeChart}
          {...health}
        />

        {showPlantHealth && (
          <PlantHealth
            className="row-start-2"
            onClickLabel={handleOnClickLabel}
            {...dailyHealth}
          />
        )}
      </Wrap>
    </div>
  );
};
