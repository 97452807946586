import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LeafTemperatureIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M36.3111 13.4913C36.2755 13.0077 35.8844 12.6277 35.3955 12.6018C34.9955 12.5759 25.5111 12.0491 21.0488 17.3345C18.8177 19.9772 18.2577 23.5441 19.3866 27.9486C18.0355 30.1595 17.0399 32.7936 16.9955 35.7904C16.9866 36.3172 17.4222 36.7491 17.9644 36.7577C17.9733 36.7577 17.9733 36.7577 17.9822 36.7577C18.5155 36.7577 18.9599 36.3432 18.9599 35.8163C18.9955 33.3118 19.8311 31.0663 20.9688 29.1404C22.3111 29.4341 23.5822 29.5895 24.7733 29.5895C27.3955 29.5895 29.6266 28.8641 31.4399 27.4218C36.8711 23.095 36.3377 13.88 36.3111 13.4913ZM30.1866 25.9622C28.1955 27.5427 25.4933 28.0263 22.1333 27.4304C24.7466 23.9759 28.0444 21.9377 28.0977 21.9032C28.5599 21.6268 28.7022 21.0395 28.4088 20.5904C28.1244 20.1413 27.5199 20.0118 27.0577 20.2882C26.8266 20.4263 23.6622 22.3782 20.9422 25.7636C20.4977 22.7927 21.0399 20.3745 22.5599 18.5609C25.6533 14.8991 32.0088 14.5104 34.3644 14.5018C34.3555 16.7732 33.9555 22.9482 30.1866 25.9622Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83325 0.263428C8.45254 0.263428 7.33325 1.4426 7.33325 2.89717V13.8296C6.39562 14.6207 5.83325 15.8205 5.83325 17.1194C5.83325 19.4467 7.62411 21.3334 9.83325 21.3334C12.0424 21.3334 13.8333 19.4467 13.8333 17.1194C13.8333 15.8205 13.2709 14.6207 12.3333 13.8296V2.89717C12.3333 1.4426 11.214 0.263428 9.83325 0.263428ZM8.1193 14.5251C8.25336 14.4266 8.33325 14.2653 8.33325 14.0931V2.89717C8.33325 2.02443 9.00482 1.31693 9.83325 1.31693C10.6617 1.31693 11.3333 2.02443 11.3333 2.89717V14.0931C11.3333 14.2653 11.4131 14.4266 11.5472 14.5251C12.3473 15.113 12.8333 16.0726 12.8333 17.1194C12.8333 18.8649 11.4901 20.2799 9.83325 20.2799C8.1764 20.2799 6.83325 18.8649 6.83325 17.1194C6.83325 16.0726 7.31925 15.113 8.1193 14.5251ZM9.33325 7.46233V15.0788C8.47064 15.3127 7.83325 16.1376 7.83325 17.1194C7.83325 18.2831 8.72868 19.2264 9.83325 19.2264C10.9378 19.2264 11.8333 18.2831 11.8333 17.1194C11.8333 16.1376 11.1959 15.3127 10.3333 15.0788V7.46233C10.3333 7.17141 10.1094 6.93558 9.83325 6.93558C9.55711 6.93558 9.33325 7.17141 9.33325 7.46233ZM10.8333 17.1194C10.8333 17.7012 10.3855 18.1729 9.83325 18.1729C9.28097 18.1729 8.83325 17.7012 8.83325 17.1194C8.83325 16.5376 9.28097 16.0659 9.83325 16.0659C10.3855 16.0659 10.8333 16.5376 10.8333 17.1194ZM14.1547 2.28575C13.8391 2.28575 13.5833 2.54159 13.5833 2.85718C13.5833 3.17277 13.8391 3.42861 14.1547 3.42861H16.7619C17.0775 3.42861 17.3333 3.17277 17.3333 2.85718C17.3333 2.54159 17.0775 2.28575 16.7619 2.28575H14.1547ZM13.5833 6.28574C13.5833 5.97015 13.8391 5.71431 14.1547 5.71431H15.2619C15.5775 5.71431 15.8333 5.97015 15.8333 6.28574C15.8333 6.60133 15.5775 6.85717 15.2619 6.85717H14.1547C13.8391 6.85717 13.5833 6.60133 13.5833 6.28574ZM14.1547 9.1429C13.8391 9.1429 13.5833 9.39874 13.5833 9.71433C13.5833 10.0299 13.8391 10.2858 14.1547 10.2858H16.7619C17.0775 10.2858 17.3333 10.0299 17.3333 9.71433C17.3333 9.39874 17.0775 9.1429 16.7619 9.1429H14.1547Z"
      fill={fill}
    />
  </svg>
);

export { LeafTemperatureIcon };
