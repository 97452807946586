import { Button } from 'components/common/Button/Button';
import {
  Dropdown,
  DropdownProps,
  Option,
} from 'components/common/Dropdown/Dropdown';
import { Menu, MenuProps } from 'components/common/Menu';
import { Modal } from 'components/common/Modal/Modal';
import { Radio } from 'components/common/Radio/Radio';
import { TabGroup } from 'components/common/TabGroup';
import { useDisclosure } from 'hooks/useDisclosure';
import { ChevronBackwardIcon } from 'icons/ChevronBackwardIcon';
import { ChevronForwardIcon } from 'icons/ChevronForwardIcon';
import { LogoFullIcon } from 'icons/LogoFullIcon';
import { MenuIcon } from 'icons/MenuIcon';
import { SettingsIcon } from 'icons/SettingsIcon';
import groupBy from 'lodash.groupby';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { TOrganization } from 'shared/interfaces/organization';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';

function findSelected<T>(
  options: DropdownProps<T>['options']
): Option<T> | undefined {
  if (!options) {
    return undefined;
  }

  for (const option of options) {
    if ('selected' in option && !!option.selected) {
      return option;
    }
    if ('options' in option) {
      const selected = findSelected(option.options);
      if (selected) {
        return selected;
      }
    }
  }

  return undefined;
}

export interface THeaderLink<HLINK> {
  id: HLINK;
  label: string;
  enabled?: boolean;
  isMenuItem?: boolean;
  onClick?: (headerLink: THeaderLink<HLINK>) => void;
}

export interface HeaderProps<HLINK> extends ComponentProps<'header'> {
  extraContent?: ReactNode;
  info?: ReactNode;
  orgs?: TOrganization[];
  zones?: TZone[];
  links: THeaderLink<HLINK>[];
  selectedOrg?: Maybe<TOrganization>;
  selectedZone?: Maybe<TZone>;
  selectedLink: THeaderLink<HLINK>;
  onChangeOrg: (org: TOrganization) => void;
  onChangeZone?: (zone: TZone) => void;
  onClickHome: () => void;
}

export const Header = <HLINK extends string>({
  className,
  extraContent,
  info,
  orgs,
  zones,
  links,
  selectedOrg,
  selectedZone,
  selectedLink,
  onChangeOrg,
  onChangeZone,
  onClickHome,
  ...props
}: HeaderProps<HLINK>) => {
  const sideNav = useDisclosure();
  const submenu = useDisclosure();
  const [activeSubmenu, setActiveSubmenu] = useState<Maybe<'org' | 'zone'>>();
  const orgOptions = useMemo(() => {
    if (!orgs) {
      return undefined;
    }
    return orgs
      .sort((a, b) => a.label.localeCompare(b.label))
      .map<Option<TOrganization>>((org) => ({
        label: org.label,
        value: org,
        selected: selectedOrg?.id === org.id,
      }));
  }, [selectedOrg?.id, orgs]);
  const selectedOrgOption = useMemo(
    () => findSelected<TOrganization>(orgOptions),
    [orgOptions]
  );
  const zoneOptions = useMemo(() => {
    if (!zones) {
      return undefined;
    }
    const groupedZones = groupBy(zones, (z) => z.locationName);
    const keys = Object.keys(groupedZones);
    return keys
      .sort((a, b) => a.localeCompare(b))
      .reduce(
        (optionsOrGroups = [], key) => {
          const options = groupedZones[key]!.sort((a, b) =>
            a.label.localeCompare(b.label)
          ).map<Option<TZone>>((zone) => ({
            label: zone.label,
            value: zone,
            selected: selectedZone?.id === zone.id,
          }));

          if (key) {
            optionsOrGroups.push({
              label: key,
              options,
            });
          } else {
            optionsOrGroups.push(...options);
          }

          return optionsOrGroups;
        },
        [] as DropdownProps<TZone>['options']
      );
  }, [selectedZone?.id, zones]);
  const selectedZoneOption = useMemo(
    () => findSelected<TZone>(zoneOptions),
    [zoneOptions]
  );
  const tabLinks = useMemo(
    () =>
      links.filter(
        ({ enabled, isMenuItem }) =>
          !isMenuItem && (enabled !== undefined ? enabled : true)
      ),
    [links]
  );
  const menuLinks = useMemo(
    () =>
      links.filter(
        ({ enabled, isMenuItem }) =>
          !!isMenuItem && (enabled !== undefined ? enabled : true)
      ),
    [links]
  );
  const menuOptions: MenuProps['items'] = useMemo(
    () =>
      menuLinks.map((link) => ({
        children: link.label,
        variant: 'flat',
        'aria-pressed': link.id === selectedLink.id,
        onClick: () => link.onClick?.(link),
      })),
    [menuLinks, selectedLink]
  );
  const handleChangeOrg: DropdownProps<TOrganization>['onChange'] = useCallback(
    (option) => {
      if (option && !Array.isArray(option)) {
        submenu.close();
        sideNav.close();
        setActiveSubmenu(null);
        onChangeOrg(option.value);
      }
    },
    [sideNav, submenu, onChangeOrg]
  );
  const handleChangeZone: DropdownProps<TZone>['onChange'] = useCallback(
    (option) => {
      if (option && !Array.isArray(option)) {
        submenu.close();
        sideNav.close();
        setActiveSubmenu(null);
        onChangeZone?.(option.value);
      }
    },
    [sideNav, submenu, setActiveSubmenu, onChangeZone]
  );
  const handleClickLink = useCallback(
    (id: string) => {
      const headerLink = links.find((link) => link.id === id)!;
      headerLink.onClick?.(headerLink);
    },
    [links]
  );

  return (
    <header {...props} className={cn('flex items-center gap-2', className)}>
      {!sideNav.isOpen && (
        <>
          <LogoFullIcon
            className="hidden lg:block cursor-pointer flex-shrink-0"
            onClick={onClickHome}
          />

          <nav className="hidden lg:flex px-4 rounded-full bg-neutral-200">
            {orgOptions && selectedOrgOption && (
              <Dropdown<TOrganization>
                embedded
                variant="secondary"
                value={selectedOrgOption}
                options={orgOptions}
                aria-label="selected organization"
                onChange={handleChangeOrg}
              />
            )}
            {info}
            {zoneOptions && selectedZoneOption && (
              <Dropdown<TZone>
                embedded
                variant="secondary"
                value={selectedZoneOption}
                options={zoneOptions}
                aria-label="selected zone"
                onChange={handleChangeZone}
              />
            )}
            {tabLinks.length > 0 && (
              <TabGroup
                className="px-2"
                onChange={handleClickLink}
                value={selectedLink.id}
                tabs={tabLinks}
              />
            )}
          </nav>
        </>
      )}

      {!sideNav.isOpen && (
        <Menu
          className="hidden lg:block ml-auto"
          floatProps={{ placement: 'bottom-end' }}
          items={menuOptions}
          button={
            <Button variant="secondary" size="icon" aria-label="Settings">
              <SettingsIcon />
            </Button>
          }
        />
      )}

      <Button
        className="lg:hidden"
        variant="secondary"
        size="icon"
        aria-label="Open navigation"
        onClick={sideNav.toggle}
      >
        <MenuIcon />
      </Button>

      <div
        id="filters-slot"
        className="lg:hidden flex-1 overflow-x-auto no-scrollbar"
      />

      {extraContent}

      <Modal
        open={sideNav.isOpen}
        dismissOnEscape
        mode="drawer-left"
        className="min-w-[320px] w-1/2"
        onClose={sideNav.close}
      >
        <Modal.Header
          leadingElement={
            submenu.isOpen ? (
              <Button
                variant="secondary"
                size="icon"
                aria-label="Go back"
                onClick={submenu.toggle}
              >
                <ChevronBackwardIcon />
              </Button>
            ) : (
              <LogoFullIcon className="flex-shrink-0" onClick={onClickHome} />
            )
          }
          closeButtonProps={{ onClick: sideNav.close }}
        />

        <Modal.Content className="h-full pb-4">
          <div
            className={cn(
              'w-full h-full flex flex-col gap-4 min-h-max',
              submenu.isOpen && 'hidden'
            )}
          >
            <div className="w-full flex flex-col gap-2">
              {orgs && selectedOrg && (
                <Button
                  variant="outline"
                  className="w-full justify-between font-normal"
                  aria-label="selected organization"
                  trailingIcon={<ChevronForwardIcon />}
                  onClick={() => {
                    setActiveSubmenu('org');
                    submenu.open();
                  }}
                >
                  {selectedOrg.label}
                </Button>
              )}

              {selectedZone && (
                <Button
                  variant="outline"
                  className="w-full justify-between font-normal"
                  aria-label="selected zone"
                  trailingIcon={<ChevronForwardIcon />}
                  onClick={() => {
                    setActiveSubmenu('zone');
                    submenu.open();
                  }}
                >
                  {selectedZone?.label}
                </Button>
              )}
            </div>

            <nav className="flex flex-col flex-1 gap-4 justify-between">
              {[tabLinks, menuLinks].map((linksGroup) => (
                <div
                  key={linksGroup.reduce(
                    (acc, group) => `${acc}-${group.id}`,
                    ''
                  )}
                  className="w-full flex flex-col gap-2"
                >
                  {linksGroup.map(({ id, label }) => (
                    <Button
                      key={id}
                      variant="flat"
                      className="justify-start font-normal"
                      aria-pressed={id === selectedLink.id}
                      onClick={() => handleClickLink(id)}
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              ))}
            </nav>
          </div>

          <div
            className={cn(
              'hidden w-full overflow-y-auto',
              submenu.isOpen && 'flex flex-col gap-4'
            )}
          >
            <p className="text-lg font-bold">
              {activeSubmenu === 'org' ? 'Customers' : 'Zones'}
            </p>

            {activeSubmenu === 'org' && orgOptions && (
              <div className="flex flex-col gap-2">
                {orgOptions.map((option) => (
                  <Radio.Input
                    variant="outline"
                    key={option.value.code}
                    label={option.label}
                    value={option.value.code}
                    checked={
                      option.value.code === selectedOrgOption?.value.code
                    }
                    onChange={() => handleChangeOrg(option)}
                  />
                ))}
              </div>
            )}

            {activeSubmenu === 'zone' && zoneOptions && (
              <div className="flex flex-col gap-4">
                {zoneOptions.map((optionOrGroup) => {
                  // narrow into a group
                  if ('options' in optionOrGroup) {
                    return (
                      <div key={optionOrGroup.label}>
                        <p className="font-semibold py-2">
                          {optionOrGroup.label}
                        </p>

                        <div className="flex flex-col gap-2">
                          {optionOrGroup.options.map((option) => (
                            <Radio.Input
                              variant="outline"
                              key={option.label}
                              label={option.label}
                              value={option.value.uid}
                              checked={
                                option.value.uid ===
                                selectedZoneOption?.value.uid
                              }
                              onChange={() => handleChangeZone(option)}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <Radio.Input
                      variant="outline"
                      key={optionOrGroup.label}
                      label={optionOrGroup.label}
                      value={optionOrGroup.value.uid}
                      checked={
                        optionOrGroup.value.uid ===
                        selectedZoneOption?.value.uid
                      }
                      onChange={() => handleChangeZone(optionOrGroup)}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal>
    </header>
  );
};
