import { createStyles, makeStyles } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { ConfirmDialog } from 'components/common/ConfirmDialog';
import { Typography } from 'components/common/Typography';
import {
  EditProfileForm,
  IEditProfileForm,
} from 'components/user/EditProfileForm';
import { useAuth } from 'contexts/AuthProvider';
import { useSettingsEditUserURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import {
  useDeleteUserOrganizationRoleMutation,
  useRegisterUserOrganizationRoleMutation,
  useUpdateUserMutation,
} from 'graphql/generated/react_apollo';
import { useUsers } from 'hooks/useUsers';
import isNil from 'lodash.isnil';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
      width: '100%',
    },

    deleteButton: {
      color: palette.orange2.main,
      cursor: 'pointer',
      fontWeight: 500,
    },

    cancelButton: {
      background: palette.white.main,
      color: palette.black.main,
      border: `2px solid ${palette.grey10.main}`,
    },

    confirmButton: {
      background: palette.orange1.main,
    },
  })
);

export const UserEdit: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userId } = useSettingsEditUserURL();
  const [showCodeResentDialog, setShowCodeResentDialog] = useState(false);

  const { currentlySelectedOrganization, user: currentUser } = useAuth();
  const currentUserId = currentUser?.id;
  const { users, refetchUserList, userRolesByCode } = useUsers();

  const user = useMemo(
    () => users.find((user) => user.id === Number(userId)),
    [users, userId]
  );
  const isSelfEditing = String(currentUserId) === userId;

  const defaultValues: IEditProfileForm | null = useMemo(() => {
    if (!user) {
      return null;
    }

    return {
      active: user?.active ?? true,
      email: user.email,
      firstName: user.first_name ?? '',
      lastName: user.last_name ?? '',
      phoneNumber: user.phone_number ?? '',
      roles: user.user_organization_roles
        .filter(
          ({ organization_id }) =>
            organization_id === currentlySelectedOrganization?.id
        )
        .map(({ role }) => role.code),
    };
  }, [currentlySelectedOrganization?.id, user]);

  const [updateUser] = useUpdateUserMutation();
  const [registerUserOrganizationRole] =
    useRegisterUserOrganizationRoleMutation();
  const [deleteUserOrganizationRole] = useDeleteUserOrganizationRoleMutation();

  const updateUserRoles = useCallback(
    async (roles: string[]) => {
      if (!user || isNil(currentlySelectedOrganization)) return;

      const originalUserRoles = user.user_organization_roles
        .filter(
          ({ organization_id }) =>
            organization_id == currentlySelectedOrganization.id
        )
        .map(({ role }) => role.code);

      const addedRoles = roles.filter((role) =>
        originalUserRoles.every((originalRole) => originalRole !== role)
      );
      const removedRoles = originalUserRoles.filter((originalRole) =>
        roles.every((role) => role !== originalRole)
      );

      await Promise.all(
        addedRoles.map((role) =>
          registerUserOrganizationRole({
            variables: {
              userId: user.id,
              organizationId: currentlySelectedOrganization.id,
              roleId: userRolesByCode[role]!.id,
            },
          })
        )
      );

      await Promise.all(
        removedRoles.map((role) =>
          deleteUserOrganizationRole({
            variables: {
              userId: user.id,
              organizationId: currentlySelectedOrganization.id,
              roleId: userRolesByCode[role]!.id,
            },
          })
        )
      );
    },
    [
      user,
      currentlySelectedOrganization,
      registerUserOrganizationRole,
      userRolesByCode,
      deleteUserOrganizationRole,
    ]
  );

  const handleCancelEdit = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSubmit = useCallback(
    async (data: IEditProfileForm) => {
      if (!user) return;

      try {
        await updateUser({
          variables: {
            id: user.id,
            active: data.active,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
          },
        });

        await updateUserRoles(data.roles);
      } finally {
        await refetchUserList();

        // NOTE: there're many dependants on self-user
        // to avoid blowing up the scope of managing the global state
        // we just reload the page to sync client state with BE
        if (isSelfEditing) {
          window.location.reload();
        } else {
          navigate(-1);
        }
      }
    },
    [
      isSelfEditing,
      user,
      navigate,
      updateUserRoles,
      updateUser,
      refetchUserList,
    ]
  );

  /** Callback to be called when the admin wants to resend code. */
  const handleResendCode = useCallback(async () => {
    if (!user) return;
    try {
      await Auth.resendSignUp(user.email);
      setShowCodeResentDialog(true);
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }, [user]);

  const handleCloseConfirmDialog = useCallback(() => {
    setShowCodeResentDialog(false);
  }, []);

  if (!defaultValues) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">Edit User</Typography>

      <EditProfileForm
        userId={userId}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        showRole
        showStatus
        onCancel={handleCancelEdit}
        successMessage="User is successfully updated!"
      />

      <Typography variant="h6">Verification Code</Typography>

      <Typography variant="body1" color="gray">
        Resend verification code.&nbsp;
        <span
          role="button"
          className={classes.deleteButton}
          onClick={handleResendCode}
        >
          Send.
        </span>
      </Typography>

      {showCodeResentDialog ? (
        <ConfirmDialog
          title="Code resent."
          open
          onClose={handleCloseConfirmDialog}
        />
      ) : null}
    </div>
  );
};
