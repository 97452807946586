import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { Button } from 'components/common/Button/Button';
import {
  Chart,
  ChartProps,
  getPlotBands,
  getPolygonSeriesAndZones,
} from 'components/common/Chart/Chart';
import { Radio } from 'components/common/Radio/Radio';
import { TimelineRange } from 'components/common/TimelineRange/TimelineRange';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import {
  useLineChartURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import {
  differenceInDays,
  endOfDay,
  hoursToMilliseconds,
  startOfDay,
} from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { useSignals } from 'hooks/useSignals';
import { PinchAndZoomIcon } from 'icons/PinchAndZoomIcon';
import isNil from 'lodash.isnil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TTimeRange } from 'shared/interfaces/general';
import {
  EMeasurementGroup,
  MeasurementAggregation,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { getLighCyclesRanges } from 'shared/utils/getters';
import { Annotations } from './Annotations';
import { useChartHints } from './hooks/useChartHints';
import { Sidebar } from './Sidebar';

const MAX_LINE_CHART_CONTINUOUS_WINDOW = 7;

const exceedsMaxAllowedWindow = (start: number, end: number) =>
  start && end
    ? differenceInDays(end, start) > MAX_LINE_CHART_CONTINUOUS_WINDOW
    : false;

const viewTypeTooltip = `Continuous line chart is only enabled for time frame windows less than or equal to ${MAX_LINE_CHART_CONTINUOUS_WINDOW} days`;

const { Environmental } = EMeasurementGroup;

const { AVERAGE, CONTINUOUS } = MeasurementAggregation;

const LineChart = ({
  zone,
  startTime,
  endTime,
  disabledViewType,
}: {
  zone: TZone;
  startTime: number;
  endTime: number;
  disabledViewType: boolean;
}) => {
  const [chart, setChart] = useState<Highcharts.Chart>();
  const { currentCycle: growthCycle } = useGrowthCycles();
  const { viewType, setViewType } = useLineChartURL();
  const start = useMemo(() => new Date(startTime), [startTime]);
  const end = useMemo(() => new Date(endTime), [endTime]);
  const timelineDates = useMemo(() => [start, end], [start, end]);
  const [timeRange, setTimeRange] = useState<TTimeRange>({
    start: startOfDay(start),
    end: endOfDay(end),
  });
  const { selectedSignal: typeConfig } = useSignals();
  const measurementsQuery = useGetMeasurementsByTypeAndTimeRange({
    zoneId: zone.id,
    zoneUid: zone.uid,
    zoneTimeZone: zone.timeZone,
    start,
    end,
    typeConfig,
    aggregation: viewType,
  });
  const showResetZoom =
    start.valueOf() < timeRange.start.valueOf() ||
    end.valueOf() > timeRange.end.valueOf();
  const ranges = useMemo(
    function computeRanges() {
      return getLighCyclesRanges({
        start,
        end,
        lightInfo: growthCycle?.metadata.light_info ?? [],
      });
    },
    [end, growthCycle?.metadata.light_info, start]
  );
  const { plotBands, polygonSeries, points, zones } = useMemo(
    function assembleChartData() {
      if (
        !growthCycle ||
        isNil(start) ||
        isNil(end) ||
        measurementsQuery.loading ||
        !measurementsQuery.called
      ) {
        return {
          plotBands: undefined,
          polygonSeries: [],
          points: [],
          zones: undefined,
        };
      }

      const plotBands = getPlotBands(ranges);

      let {
        polygonSeries = [],
        zones = undefined,
        points = measurementsQuery.data,
      } = typeConfig.group === Environmental
        ? getPolygonSeriesAndZones({
            data: measurementsQuery.data as [number, number][],
            end,
            growthCycle,
            start,
            ranges,
            typeConfig,
          })
        : {};

      return {
        plotBands,
        polygonSeries,
        zones,
        points,
      };
    },
    [
      end,
      growthCycle,
      measurementsQuery.called,
      measurementsQuery.data,
      measurementsQuery.loading,
      ranges,
      start,
      typeConfig,
    ]
  );
  const options = useMemo<ChartProps['options']>(
    function computeOptions() {
      if (measurementsQuery.loading || !measurementsQuery.called) {
        return undefined;
      }

      return {
        accessibility: {
          description: `Line chart for ${typeConfig.label}`,
        },
        chart:
          points.length > 0
            ? {
                panKey: 'shift',
                panning: { enabled: true, type: 'x' },
                zooming: {
                  type: 'x',
                  pinchType: 'x',
                  mouseWheel: { sensitivity: 2 },
                },
              }
            : undefined,
        tooltip: {
          enabled: true,
          valueDecimals: 2,
          valueSuffix: typeConfig.unit,
        },
        yAxis: {
          reversed: typeConfig.reversedYAxis,
          title: {
            text: `${typeConfig.label} <sup>${typeConfig.unit}</sup>`,
            useHTML: true,
          },
        },
        xAxis: {
          crosshair: true,
          max: end.valueOf(),
          min: start.valueOf(),
          events: {
            afterSetExtremes: (event) => {
              setTimeRange({
                start: new Date(event.min),
                end: new Date(event.max),
              });
            },
          },
          plotBands,
          // When there's no data, set minRange to a full day
          ...(points.length === 0
            ? { minRange: hoursToMilliseconds(24) }
            : undefined),
        },
        series: [
          ...polygonSeries,
          {
            data: points,
            name: typeConfig.label,
            type: 'line',
            enableMouseTracking: true,
            states: {
              hover: { enabled: true },
            },
            zoneAxis: 'x',
            zones,
          },
        ],
      };
    },
    [
      end,
      measurementsQuery.called,
      measurementsQuery.loading,
      plotBands,
      points,
      polygonSeries,
      start,
      typeConfig.label,
      typeConfig.reversedYAxis,
      typeConfig.unit,
      zones,
    ]
  );
  const handleChartInitialization = useCallback((instance) => {
    setChart(instance);
  }, []);

  useChartHints({ chart, showResetZoom });

  return (
    <>
      <div className="h-full flex gap-1">
        <Sidebar className="min-w-fit" />

        <div className="relative w-full min-w-0 flex flex-col gap-2 py-1">
          <div
            id="slot-chart-filters"
            className="flex gap-2 h-9 items-center justify-end"
          >
            {typeConfig.hasMultipleAggregations && (
              <Tooltip label={viewTypeTooltip} withArrow>
                <Radio.Group
                  value={viewType}
                  orientation="horizontal"
                  disabled={disabledViewType || measurementsQuery.loading}
                  onChange={(e) =>
                    setViewType(e.target.value as MeasurementAggregation)
                  }
                >
                  <Radio.Input
                    labelClassName="text-xs"
                    value={AVERAGE}
                    label={AVERAGE}
                  />
                  <Radio.Input
                    labelClassName="text-xs"
                    value={CONTINUOUS}
                    label={CONTINUOUS}
                  />
                </Radio.Group>
              </Tooltip>
            )}
          </div>

          <Chart
            className="flex-auto"
            role="figure"
            aria-label={`line chart for ${typeConfig.type}`}
            key={`${typeConfig.type}-${start.valueOf()}-${end.valueOf()}-${viewType}`}
            options={options}
            loading={measurementsQuery.loading}
            onInitialization={handleChartInitialization}
          />

          <div
            id="slot-chart-tools"
            className="highcharts-bindings-container absolute top-22 right-2 flex gap-2"
          >
            {showResetZoom && chart && (
              <Button
                size="responsive"
                variant="secondary"
                className="shadow font-normal text-xs"
                leadingIcon={<PinchAndZoomIcon />}
                onClick={() => chart.zoomOut()}
              >
                Reset Zoom
              </Button>
            )}
          </div>
        </div>
      </div>

      {chart?.hasLoaded && (
        <Annotations
          chart={chart}
          dates={measurementsQuery.dates}
          values={measurementsQuery.values}
          timeRange={timeRange}
          typeConfig={typeConfig}
          zone={zone}
          start={start}
          end={end}
          isLoading={measurementsQuery.loading}
        />
      )}

      <TimelineRange mode="range" dates={timelineDates} brush={timeRange} />
    </>
  );
};

export const NewLineChart = () => {
  const { getRangeStartTime, getRangeEndTime } = useZoneDetailsPageURL();
  const { viewType, setViewType } = useLineChartURL();
  const { currentZone, currentTimeInCurrentZone, zoneTimeZone } =
    useCurrentZone();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const rangeEndTime = getRangeEndTime(zoneTimeZone);
  const forceAverage =
    rangeStartTime && rangeEndTime
      ? exceedsMaxAllowedWindow(
          rangeStartTime,
          Math.min(rangeEndTime, currentTimeInCurrentZone.valueOf())
        )
      : false;

  useEffect(() => {
    if (forceAverage && viewType !== AVERAGE) {
      setViewType(AVERAGE);
    }
  }, [forceAverage, setViewType, viewType]);

  if (isNil(currentZone) || isNil(rangeStartTime) || isNil(rangeEndTime)) {
    return null;
  }

  return (
    <LineChart
      zone={currentZone}
      startTime={rangeStartTime}
      endTime={rangeEndTime}
      disabledViewType={forceAverage}
    />
  );
};
