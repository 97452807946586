import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    statusIcon: {
      display: 'inline-flex',
      width: 12,
      height: 12,
      borderRadius: 12,
    },
    activeIcon: {
      background: theme.palette.green1.main,
    },
    passiveIcon: {
      background: theme.palette.orange1.main,
    },
  })
);

interface IUserStatusIconProps {
  active: boolean;
}

export const UserStatusIcon: FC<IUserStatusIconProps> = ({ active }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.statusIcon,
        active ? classes.activeIcon : classes.passiveIcon
      )}
    />
  );
};
