import { useGetZonesByOrganizationCode } from 'api/zone';
import { useAuth } from 'contexts/AuthProvider';
import { useSettingsURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePermissions } from 'hooks/usePermissions';
import { useUserPermissions } from 'hooks/useUserPermissions';
import isNil from 'lodash.isnil';
import { FacilitySettings } from 'pages/settings/FacilitySettings';
import { FC } from 'react';
import { TLocation } from 'shared/interfaces/location';
import { ESettingsTabs } from 'shared/interfaces/settings';
import { Profile } from './Profile';
import { UserList } from './UserList';
import { UserNotificationSettings } from './UserNotificationSettings';

export const SettingsTab: FC = () => {
  const { tab, organization } = useSettingsURL();
  const { user } = useAuth();
  const { canEditCriticalEnvSettings } = usePermissions();
  const { hasAccessToZone } = useUserPermissions();
  const { currentZone } = useCurrentZone();
  const { zones } = useGetZonesByOrganizationCode({
    hideInactiveZones: true,
    organizationCode: organization,
    isVisible: hasAccessToZone,
  });
  const locations = zones.reduce((list, zone) => {
    if (!list.some(({ id }) => id === zone.locationId)) {
      list.push({ id: zone.locationId, name: zone.locationName });
    }
    return list;
  }, [] as Partial<TLocation>[]);

  if (isNil(currentZone) || isNil(user)) {
    return null;
  }

  switch (tab) {
    case ESettingsTabs.USERS:
      return <UserList />;
    case ESettingsTabs.PROFILE:
      return <Profile />;
    case ESettingsTabs.NOTIFICATIONS:
      return <UserNotificationSettings userId={user.id} zones={zones} />;
    case ESettingsTabs.CRITICAL_ENV_SETTINGS:
      return (
        <FacilitySettings
          locations={locations}
          zone={currentZone!}
          readOnly={!canEditCriticalEnvSettings}
        />
      );
    default:
      return null;
  }
};
