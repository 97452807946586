import { ApolloCache } from '@apollo/client';
import { useAuth } from 'contexts/AuthProvider';
import {
  GetUserByEmailDocument,
  GetUserNotificationsDocument,
  GetUserNotificationsQuery,
  GetUserNotificationsQueryVariables,
  GetUsersByOrganizationDocument,
  UserZoneNotificationSetting,
  useGetNonKioskUsersWithZoneViewPermissionQuery,
  useGetUserNotificationsQuery,
  useRemoveUserNotificationMutation,
  useUpdateUserMetadataMutation,
  useUpsertUserNotificationMutation,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { TMention } from 'shared/interfaces/discussion';
import { EUserNotificationTypeCodes } from 'shared/interfaces/enumeration';
import { TUserNotification, UserMetadata } from 'shared/interfaces/user';
export const useGetUserNotifications = ({
  userId,
  zoneIds,
}: {
  userId: number;
  zoneIds: number[];
}) => {
  const { data, ...result } = useGetUserNotificationsQuery({
    variables: { user_id: userId, zone_ids: zoneIds },
    skip: !userId || zoneIds.length === 0,
  });

  const userNotifications: TUserNotification[] = (
    (data?.user_zone_notification_setting ??
      []) as Array<UserZoneNotificationSetting>
  ).map((notificationSetting) => ({
    id: notificationSetting.id,
    userId: notificationSetting.user_id,
    zoneId: notificationSetting.zone_id,
    notificationSettingId: notificationSetting.notification_setting_id,
    notificationType: notificationSetting.notification_type!,
  }));

  return { ...result, userNotifications };
};

export const useUpsertUserNotifications = () => {
  const [mutation, result] = useUpsertUserNotificationMutation();
  interface UpsertProps {
    userId: number;
    zoneIds: number[];
    notificationZoneIds: number[];
    notificationSettingId: number;
    notificationType: EUserNotificationTypeCodes;
  }
  const upsert = ({
    userId,
    zoneIds,
    notificationZoneIds,
    notificationSettingId,
    notificationType,
  }: UpsertProps) => {
    const notifications = notificationZoneIds.map((zoneId) => ({
      user_id: userId,
      zone_id: zoneId,
      notification_setting_id: notificationSettingId,
      notification_type: notificationType,
    }));

    return mutation({
      variables: {
        notifications,
      },
      refetchQueries: [GetUserNotificationsDocument],
      optimisticResponse: {
        insert_user_zone_notification_setting: {
          affected_rows: 1,
        },
      },
      update: (cache: ApolloCache<any>) => {
        const variables = {
          user_id: userId,
          zone_ids: zoneIds,
        };
        const cachedData = cache.readQuery<
          GetUserNotificationsQuery,
          GetUserNotificationsQueryVariables
        >({
          query: GetUserNotificationsDocument,
          variables,
        });

        const cachedNotifications =
          cachedData?.user_zone_notification_setting ?? [];

        cache.writeQuery<GetUserNotificationsQuery>({
          query: GetUserNotificationsDocument,
          variables,
          data: {
            user_zone_notification_setting: [
              ...cachedNotifications.filter(
                (cachedNotification) =>
                  !notifications.some(
                    (notification) =>
                      notification.zone_id === cachedNotification.zone_id
                  )
              ),
              ...notifications.map((notification, index) => ({
                id: index,
                notification_setting_id: notification.notification_setting_id,
                user_id: notification.user_id,
                zone_id: notification.zone_id,
                notification_type: notification.notification_type,
              })),
            ],
          },
        });
      },
    });
  };

  return { ...result, upsert };
};

export const useRemoveUserNotifications = ({
  userId,
  zoneIds,
}: {
  userId: number;
  zoneIds: number[];
}) => {
  const [mutation, result] = useRemoveUserNotificationMutation();
  const remove = (notificationZoneIds: number[], notificationType: string) =>
    mutation({
      variables: {
        user_id: userId,
        zone_ids: notificationZoneIds,
        notification_type: notificationType,
      },
      refetchQueries: [GetUserNotificationsDocument],
      optimisticResponse: {
        delete_user_zone_notification_setting: {
          affected_rows: 1,
          __typename: 'user_zone_notification_setting_mutation_response',
        },
      },
      update: (cache) => {
        const variables = {
          user_id: userId,
          zone_ids: zoneIds,
        };
        const cachedData = cache.readQuery<
          GetUserNotificationsQuery,
          GetUserNotificationsQueryVariables
        >({
          query: GetUserNotificationsDocument,
          variables,
        });

        const cachedNotifications =
          cachedData?.user_zone_notification_setting ?? [];

        cache.writeQuery<GetUserNotificationsQuery>({
          query: GetUserNotificationsDocument,
          variables,
          data: {
            user_zone_notification_setting: cachedNotifications.filter(
              (notification) =>
                notification.user_id === userId &&
                !notificationZoneIds.includes(notification.zone_id)
            ),
          },
        });
      },
    });

  return { ...result, remove };
};

export const useUpdateUserMetadata = () => {
  const [mutation, result] = useUpdateUserMetadataMutation();
  const update = (userId: number, metadata: UserMetadata) => {
    return mutation({
      variables: {
        id: userId,
        metadata,
      },
      refetchQueries: [GetUserByEmailDocument, GetUsersByOrganizationDocument],
    });
  };
  return { ...result, update };
};

const useGetTaggableUsers = (zoneUid?: string) => {
  const { isNeatleafOrganizationMember, user } = useAuth();

  const { data: taggableUsersData, ...result } =
    useGetNonKioskUsersWithZoneViewPermissionQuery({
      variables: {
        zoneUid: zoneUid!,
        customAndCondition: isNeatleafOrganizationMember
          ? {}
          : {
              _and: [
                { id: { _neq: user?.id! } },
                {
                  _or: [
                    { email: { _nlike: '%neatleaf.com' } },
                    {
                      metadata: {
                        _contains: { alwaysVisibleForTagging: true },
                      },
                    },
                  ],
                },
              ],
            },
      },
      skip: isNil(zoneUid) || isNil(user?.id),
    });
  const taggableUsers = (taggableUsersData?.user || []).filter(
    (taggableUser) => !isNil(user?.id) && taggableUser.id !== user.id
  );

  return { taggableUsers, ...result };
};

export const useAvailableMentions = (zoneUid?: string): TMention[] => {
  const { taggableUsers } = useGetTaggableUsers(zoneUid);
  return taggableUsers?.map((user) => ({
    name: user.first_name + ' ' + user.last_name,
    email: user.email,
    user_id: user.id,
  }));
};
