import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const DuplicateIcon = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M9.91428 5.32812H1.8175C1.10726 5.32812 0.539062 5.89632 0.539062 6.60656V14.7211C0.539062 15.4136 1.10726 15.9995 1.8175 15.9995H9.93203C10.6245 15.9995 11.2105 15.4313 11.2105 14.7211V6.60656C11.1927 5.89632 10.6245 5.32812 9.91428 5.32812ZM10.1273 14.7033C10.1273 14.8099 10.0386 14.8987 9.93203 14.8987H1.8175C1.71096 14.8987 1.62218 14.8099 1.62218 14.7033V6.60656C1.62218 6.50003 1.71096 6.41125 1.8175 6.41125H9.93203C10.0386 6.41125 10.1273 6.50003 10.1273 6.60656V14.7033Z"
      fill={fill}
    />
    <path
      d="M14.1721 0H6.07531C5.36507 0 4.79688 0.568195 4.79688 1.27844V4.26146H5.86224V1.27844C5.86224 1.1719 5.95102 1.08312 6.05756 1.08312H14.1721C14.2786 1.08312 14.3674 1.1719 14.3674 1.27844V9.39297C14.3674 9.49951 14.2786 9.58829 14.1721 9.58829H12.2544V10.6537H14.1721C14.8646 10.6537 15.4505 10.0855 15.4505 9.37521V1.27844C15.4505 0.568195 14.8823 0 14.1721 0Z"
      fill={fill}
    />
    <line
      x1="6.03906"
      y1="8.5"
      x2="6.03906"
      y2="12.5"
      stroke={stroke}
      strokeLinecap="round"
    />
    <line
      x1="8.03906"
      y1="10.5"
      x2="4.03906"
      y2="10.5"
      stroke={stroke}
      strokeLinecap="round"
    />
  </svg>
);

export { DuplicateIcon };
