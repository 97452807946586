import { ComponentProps, forwardRef, useMemo } from 'react';
import { cn } from 'shared/utils/cn';
import { Button } from '../common/Button/Button';

type BoundingBoxAnnotationProps = ComponentProps<'div'> & {
  left: number;
  top: number;
  width: number;
  height: number;
  scale?: number;
  label: string;
  showBox?: boolean;
  onClick: (e: React.MouseEvent) => void;
  onMouseEvent?: (e: MouseEvent | WheelEvent) => void;
  ['data-testid']?: string;
};

export const BoundingBoxAnnotation = forwardRef<
  HTMLDivElement,
  BoundingBoxAnnotationProps
>(function BoundingBoxAnnotation(
  {
    left,
    top,
    width,
    height,
    label,
    scale = 1,
    onClick,
    showBox,
    onMouseEvent,
    'data-testid': dataTestId,
    ...props
  },
  ref
) {
  const propagateMouseEvent = useMemo(
    () => (e: React.MouseEvent | React.WheelEvent) => {
      const newEvent =
        e.type === 'wheel'
          ? new WheelEvent(e.type, e.nativeEvent)
          : new MouseEvent(e.type, e.nativeEvent);
      onMouseEvent?.(newEvent);
    },
    [onMouseEvent]
  );
  return (
    <div
      onWheel={propagateMouseEvent}
      onMouseDown={propagateMouseEvent}
      onMouseUp={propagateMouseEvent}
      onMouseMove={propagateMouseEvent}
      className="group/annotation pointer-events-none"
      {...props}
    >
      <div
        ref={ref}
        style={{
          left,
          top,
          width,
          height,
          borderWidth: 2 / scale,
        }}
        className={cn(
          'absolute border-orange-500 cursor-pointer bg-[#FFFFFF50] hover:bg-transparent',
          'bg-transparent group-hover/annotation:block hidden border-2',
          showBox && 'block'
        )}
      />
      <Button
        onClick={onClick}
        style={{
          top: top + height / 2,
          left: left + width / 2,
          transformOrigin: 'left bottom',
          transform: `translate(-0%, -100%) scale(${1 / scale})`,
        }}
        variant="secondary"
        className="pointer-events-auto absolute shadow font-normal text-xs h-4 px-2 py-3 rounded-bl-none"
        data-testid={dataTestId}
      >
        {label}
      </Button>
    </div>
  );
});
