import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { Wrap } from 'components/common/Wrap/Wrap';
import { EImageLabelCategory } from 'shared/interfaces/image';
import { cn } from 'shared/utils/cn';
import { PlantHealthCard } from './PlantHealthCard';
import { TDailyHealthLabelData } from './types';

const NUMBER_OF_DAYS = 3;

interface PlantHealthProps {
  /** Allows styling of this component root element */
  className?: string;
  /** The heath for every label caategory */
  healthLabelDataByCategory: Dictionary<TDailyHealthLabelData[]>;
  /** Called when the user clicks one of the card */
  onClickLabel: (category: EImageLabelCategory) => void;
}

/** Displays a plant health card per available label type */
export const PlantHealth = ({
  className,
  healthLabelDataByCategory,
  onClickLabel,
}: PlantHealthProps) => {
  const canHover = window?.matchMedia?.('(hover: hover)').matches;
  return (
    <div className={cn('w-full min-w-full gap-4 flex flex-col', className)}>
      <p
        className="text-xs font-bold text-neutral-600"
        id="plant-health-information"
      >
        Plant health (Past 3 days)
      </p>
      <Wrap
        condition={canHover}
        with={(children) => (
          <HorizontallyScrollable>{children}</HorizontallyScrollable>
        )}
      >
        <div
          className={cn(
            'flex gap-4 overflow-x-auto no-scrollbar',
            canHover && 'w-fit'
          )}
          role="list"
          aria-labelledby="plant-health-information"
        >
          {[
            EImageLabelCategory.YELLOWING,
            EImageLabelCategory.NECROSIS,
            EImageLabelCategory.ABNORMAL_SHAPE,
            EImageLabelCategory.OTHER,
          ].map((category) => {
            const labelCounts = (healthLabelDataByCategory[category] ?? []).map(
              (label) => label.count
            );

            return (
              <PlantHealthCard
                key={category}
                category={category as EImageLabelCategory}
                displayCount={NUMBER_OF_DAYS}
                labelCounts={labelCounts}
                onClick={() => onClickLabel(category as EImageLabelCategory)}
              />
            );
          })}
        </div>
      </Wrap>
    </div>
  );
};
