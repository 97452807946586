import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const MoonIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M8.11254 16.0547C11.8443 16.0547 15.0827 13.5262 15.9817 9.90949C16.0358 9.67963 15.9682 9.43626 15.7992 9.26723C15.6302 9.09821 15.3868 9.0306 15.157 9.09146C14.6432 9.22667 14.0888 9.29427 13.5209 9.29427C9.79584 9.29427 6.76044 6.25887 6.76044 2.53378C6.76044 1.9659 6.82804 1.41154 6.96325 0.897741C7.0241 0.667885 6.95649 0.424507 6.78748 0.255483C6.61847 0.0864603 6.3751 0.0188554 6.14522 0.0729502C2.52831 0.972085 0 4.21037 0 7.94216C0 12.4177 3.637 16.0547 8.11254 16.0547Z"
      fill={fill}
    />
  </svg>
);

export { MoonIcon };
