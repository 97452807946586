import { NavigateOptions } from 'react-router-dom';
import { AnalyticsEvent } from 'shared/analytics/AnalyticsEvent';
import { getAnalyticsServiceInstance } from 'shared/analytics/AnalyticsService';
import { NO_LABEL_CODE_SELECTION } from 'shared/constants/image';
import { ERoutePath } from 'shared/constants/url';
import { EImageTypes, EImageViewTypes } from 'shared/interfaces/image';
import { QueryParamsSchema, getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

/** TODO */
function mapImageLocationToSectionPosition(
  imageLocation: QueryParamsSchema<ERoutePath.IMAGE_FEED>['image-location']
): Optional<TPosition> {
  if (!imageLocation) {
    return undefined;
  }
  const positions = imageLocation.split('-').map((p) => p.trim());
  return {
    x: Number(positions[0]),
    y: Number(positions[1]),
  };
}

export const getImageFeedStore = (args: StoreArgs<ERoutePath.IMAGE_FEED>) => {
  const analyticsService = getAnalyticsServiceInstance();
  const { routePath, searchParams, setSearchParams, pathParams } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  type SchemaType = QueryParamsSchema<typeof routePath>;
  const state = {
    zoneId: pathParams?.zoneId,
    imageLabelCode: queryParams['image-label-code'] ?? NO_LABEL_CODE_SELECTION,
    imageLocation: queryParams['image-location'],
    imageType: queryParams['image-type'] ?? EImageTypes.RGB,
    imageViewType: queryParams['image-view-type'] ?? EImageViewTypes.GRID,
    x: queryParams['x'],
    y: queryParams['y'],
    scale: queryParams['scale'],
    sectionPosition: mapImageLocationToSectionPosition(
      queryParams['image-location']
    ),
    showGridInfo: queryParams['showGridInfo'],
    showCultivars: queryParams['show-cultivars'],
    showComments: queryParams['show-comments'],
    editCultivars: queryParams['edit-cultivars'],
    discussionUid: queryParams['discussion-uid'],
  };

  const actions = {
    setImageLabelCode: (value: SchemaType['image-label-code']) => {
      setSearchParams(
        {
          'image-label-code': value,
          'image-type': EImageTypes.RGB,
        },
        { replace: true }
      );
      analyticsService.sendEvent(AnalyticsEvent.ViewSingleImage, {
        label_filter: state.imageLabelCode,
      });
    },
    setSingleImageLocation: ({
      row,
      column,
      discussionUid,
    }: {
      row: number;
      column: number;
      discussionUid?: SchemaType['discussion-uid'];
    }) => {
      const imageLocation: SchemaType['image-location'] = `${column}-${row}`;
      setSearchParams({
        'image-location': imageLocation,
        'image-view-type': EImageViewTypes.SINGLE_IMAGE,
        'discussion-uid': discussionUid,
      });
      analyticsService.sendEvent(AnalyticsEvent.ViewSingleImage, {
        label_filter: state.imageLabelCode,
      });
    },
    setImageType: (value: SchemaType['image-type']) => {
      setSearchParams(
        {
          'image-type': value,
          'image-label-code':
            value === EImageTypes.NDVI ? undefined : state.imageLabelCode,
          'discussion-uid': undefined,
        },
        { replace: true }
      );
    },
    setImageViewTypeToGrid: () => {
      setSearchParams({
        'image-view-type': EImageViewTypes.GRID,
        'image-location': undefined,
        x: undefined,
        y: undefined,
        scale: undefined,
        'discussion-uid': undefined,
      });
    },
    setSingleImagePositionAndScale: (
      {
        x,
        y,
        scale,
      }: {
        x: SchemaType['x'];
        y: SchemaType['y'];
        scale: SchemaType['scale'];
      },
      navigateOptions?: NavigateOptions
    ) => {
      setSearchParams(
        {
          x,
          y,
          scale,
        },
        navigateOptions
      );
    },
    setShowGridInfo: (showGridInfo: boolean) => {
      setSearchParams(
        {
          showGridInfo: showGridInfo,
          'edit-cultivars': false,
          'show-cultivars': false,
        },
        { replace: true }
      );
    },
    setShowCultivars: (showCultivars: boolean) => {
      setSearchParams(
        {
          'show-cultivars': showCultivars,
          showGridInfo: false,
        },
        { replace: true }
      );
    },
    setShowComments: (showComments: boolean) => {
      setSearchParams(
        {
          'show-comments': showComments,
          showGridInfo: false,
          'discussion-uid': undefined,
        },
        { replace: true }
      );
    },
    setEditCultivars: (editCultivars: boolean) => {
      setSearchParams(
        {
          'edit-cultivars': editCultivars,
        },
        { replace: true }
      );
    },
    setDiscussionUid: (discussionUid: SchemaType['discussion-uid']) => {
      setSearchParams(
        {
          'discussion-uid': discussionUid,
        },
        { replace: true }
      );
    },
  };

  return {
    ...actions,
    ...state,
  };
};
