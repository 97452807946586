import {
  ChangeEvent,
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { cn } from 'shared/utils/cn';
import { v4 as uuidv4 } from 'uuid';
import { Label, LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';

export interface CheckboxInputProps extends ComponentPropsWithoutRef<'input'> {
  /** When true the checkbox is shown as indeterminate */
  indeterminate?: boolean;
  /**
   * Sets a label on the radio. When set the radio is wrapped in a HTML label element.
   * When not set the Radio is rendered without a HTML label element */
  label?: LabelProps['value'];
  /**
   * Sets the placement of the radio label.
   * Defaults to `right` when variant is `flat` or `right` when variant is `flat`.
   */
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
  /** When set disables the checkbox whilst leaving maintaining the label style */
  readOnly?: boolean;
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
}

/**
 * This component implements a tri-state checkbox, which allow an additional third state known as partially checked.
 *
 * Checkbox Pattern - https://www.w3.org/WAI/ARIA/apg/patterns/checkbox/
 */
const Input = forwardRef<HTMLInputElement, CheckboxInputProps>(
  function Checkbox(
    {
      className,
      indeterminate = false,
      readOnly,
      label,
      labelPlacement = 'right',
      labelClassName,
      id,
      disabled,
      helperText,
      errorText,
      ...props
    },
    ref
  ) {
    return (
      <fieldset className="group/checkbox flex flex-col gap-1 w-full">
        <Label
          value={label}
          placement={labelPlacement}
          disabled={disabled}
          className={cn(
            'group/checkboxlabel gap-0 whitespace-nowrap rounded-xs w-full',
            'hover:bg-neutral-200 active:bg-orange-100 active:text-orange-500',
            'has-[:focus]:outline outline-2 outline-blue-500 -outline-offset-2',
            labelPlacement === 'right' && 'pr-2',
            labelPlacement === 'left' && 'pl-2',
            labelClassName
          )}
        >
          <input
            ref={ref}
            {...props}
            id={id}
            disabled={disabled || readOnly}
            type="checkbox"
            className={cn(
              'relative h-8 w-8 appearance-none rounded-xs bg-transparent',
              'before:absolute before:left-2 before:top-2 before:h-4 before:w-4 before:rounded-xxs before:border-2',
              'before:border-neutral-700 before:bg-neutral-100 before:active:border-orange-500 group-active/checkboxlabel:before:active:border-orange-500',
              'after:left-2 after:top-2 after:h-4 after:w-4 after:bg-neutral-900',
              'invalid:before:border-red-500 invalid:before:bg-red-100 invalid:after:bg-red-500',
              'invalid:checked:before:border-red-500 invalid:before:hover:bg-red-100',
              'disabled:bg-neutral-200 disabled:bg-transparent disabled:before:border-neutral-500 disabled:before:bg-neutral-200 disabled:after:bg-neutral-500 disabled:after:active:bg-neutral-500',
              label && 'outline-none',
              !label &&
                'hover:bg-neutral-200 active:bg-orange-100 -outline-offset-2',
              !indeterminate &&
                'checked:after:absolute checked:after:checkbox-tick checked:before:border-neutral-900 checked:after:active:bg-orange-500 group-active/checkboxlabel:checked:after:active:bg-orange-500',
              indeterminate &&
                'after:absolute after:checkbox-indeterminate before:border-neutral-900 after:active:bg-orange-500 group-active/checkboxlabel:after:active:bg-orange-500',
              readOnly &&
                'disabled:before:border-neutral-900 disabled:after:bg-neutral-900',
              className
            )}
            {...(indeterminate && { 'aria-checked': 'mixed' })}
          />
        </Label>
        {helperText && (
          <StatusText className="pl-2 group-valid/checkbox:block">
            {helperText}
          </StatusText>
        )}
        {errorText && (
          <StatusText className="pl-2 text-red-500 group-invalid/checkbox:block">
            {errorText}
          </StatusText>
        )}
      </fieldset>
    );
  }
);

const isIndeterminate = (checkboxStates: CheckboxStates) => {
  const checkboxArr = Object.values(checkboxStates);

  const numOfChecked = checkboxArr.reduce(
    (accum, checked) => (checked ? accum + 1 : accum),
    0
  );

  return !(numOfChecked === 0 || numOfChecked === checkboxArr.length);
};

type CheckboxStates = Record<string, boolean>;

interface CheckboxGroupProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /** Sets the orientation of the child inputs */
  orientation?: 'horizontal' | 'vertical';
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** Sets controlling to select all  */
  control?: ReactElement<CheckboxInputProps>;
  /**
   * When any of the `Group` checkboxes are checked or unchecked via user interact this handler will present
   * the checked state of all the child checkboxes.When using the `Group` with a control checkbox it is
   * important to use this `Group` onChange handler as when selecting and deselecting all child checkboxes
   * using the control checkbox the child checkboxes onChange handlers will not be called.
   * */
  onChange?: (checkboxStates: Record<string, boolean>) => void;
  children:
    | ReactElement<CheckboxInputProps>
    | ReactElement<CheckboxInputProps>[];
}

/** Component to assist when working with multiple checkbox components. It provides a basic visual grouping of checkboxes and
 * can also provide a mechanism to select and deselect all checkboxes in that group. Note that when
 * used within the CheckboxGroup, Checkbox components are used as controlled inputs - therefore you
 * should use the `checked` prop rather than the `defaultChecked` prop */
const Group = forwardRef<HTMLDivElement, CheckboxGroupProps>(function Group(
  {
    control,
    children,
    orientation = 'vertical',
    className,
    helperText,
    errorText,
    onChange,
  },
  ref
) {
  const [checkboxStates, setCheckboxStates] = useState<CheckboxStates>({});

  useEffect(() => {
    const initialCheckboxStates: CheckboxStates = {};

    Children.forEach(children, (child: ReactElement) => {
      if (!child.props.name || initialCheckboxStates[child.props.name]) {
        throw new Error(
          'Checkbox.Input components must have a unique name prop to work with Checkbox.Group'
        );
      }
      initialCheckboxStates[child.props.name] = !!child.props.checked;
    });

    setCheckboxStates(initialCheckboxStates);
  }, [children]);

  const selectAllControl = control
    ? cloneElement(control, {
        ...control.props,
        checked: Object.values(checkboxStates).every((checked) => checked),
        indeterminate: isIndeterminate(checkboxStates),
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          control.props.onChange?.(e);
          const checkboxEl = e.target;
          const newCheckboxStates = Object.keys(
            checkboxStates
          ).reduce<CheckboxStates>((accum, name) => {
            accum[name] = checkboxEl.checked;
            return accum;
          }, {});
          setCheckboxStates(newCheckboxStates);
          onChange?.(newCheckboxStates);
        },
      })
    : null;

  const controlledChildren = Children.map(children, (child: ReactElement) => {
    return cloneElement(child, {
      ...child.props,
      checked: checkboxStates[child.props.name] ?? false,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        child.props.onChange?.(e);
        const checkboxEl = e.target;
        const newCheckboxStates = {
          ...checkboxStates,
          [checkboxEl.name]: checkboxEl.checked,
        };
        setCheckboxStates(newCheckboxStates);
        onChange?.(newCheckboxStates);
      },
    });
  });

  return (
    <fieldset
      className={cn(
        'group/checkbox inline-flex flex-col items-start',
        className
      )}
    >
      {control && <div className="inline-flex">{selectAllControl}</div>}

      <div
        ref={ref}
        id={`checkbox-group-${uuidv4()}`}
        className={cn(
          'inline-flex w-full',
          orientation === 'vertical' && 'flex-col',
          control && 'ml-6'
        )}
      >
        {controlledChildren}
      </div>
      {helperText && (
        <StatusText className="pl-2 group-valid/checkbox:block">
          {helperText}
        </StatusText>
      )}
      {errorText && (
        <StatusText className="pl-2 text-red-500 group-invalid/checkbox:block">
          {errorText}
        </StatusText>
      )}
    </fieldset>
  );
});

export const Checkbox = {
  Group,
  Input,
};
