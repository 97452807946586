import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect, useMemo } from 'react';

export function useChartHints({
  chart,
  showResetZoom,
  isDrawing,
}: {
  chart: Maybe<Highcharts.Chart>;
  showResetZoom?: boolean;
  isDrawing?: boolean;
}) {
  const { isMobile } = useScreenSize();
  const subtitle = useMemo<Highcharts.SubtitleOptions>(() => {
    let text = isMobile ? 'Use pinch-to-zoom' : 'Zoom by selecting an area';
    if (showResetZoom) {
      text = isMobile ? 'Use two fingers to pan' : 'Press Shift key to pan';
    }
    if (isDrawing) {
      text = isMobile
        ? 'Tap twice to define the start and end dates'
        : 'Click once to select time range start and end dates';
    }

    return { align: isMobile ? 'right' : 'center', text };
  }, [isDrawing, isMobile, showResetZoom]);

  useEffect(() => {
    if (chart && chart.subtitle && chart.series.length > 0) {
      chart.setSubtitle(subtitle);
    }
  }, [chart, subtitle]);
}
