import { Calendar, CalendarProps } from 'components/common/Calendar/Calendar';
import { Input } from 'components/common/Input/Input';
import { Radio } from 'components/common/Radio/Radio';
import { addDays, differenceInDays } from 'date-fns';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  useRef,
  useState,
} from 'react';
import { cn } from 'shared/utils/cn';
import { formatDateInMMMDD } from 'shared/utils/date';

export const DEFAULT_TOTAL_DAYS = 60;

const Day: FC<ComponentProps<'button'>> = ({
  className,
  children,
  ...props
}) => (
  <button
    {...props}
    className={cn(
      'flex cursor-pointer flex-col rounded-full px-5',
      'aria-pressed:bg-orange-500 aria-pressed:text-white',
      className
    )}
  >
    {children}
  </button>
);

type LocalState = Exclusive<
  { activeStart: true; activeEnd: false },
  { activeStart: false; activeEnd: true }
>;

interface EditCycleDurationProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /** The cycle duration start day */
  startDate: Date;
  /** The cycle duration end day */
  endDate: Date;
  /** Called when either the selected start day or total days changed */
  onChange: (startDate: Date, endDate: Date) => void;
}

/**
 * Displays a calendar and an number input that allows users to select a start day and total duration in days.
 * The users are also suggested with a list of days they can check to set the duration.
 */
export const EditCycleDuration = forwardRef<
  HTMLDivElement,
  EditCycleDurationProps
>(function EditCycleDuration(
  { className, startDate, endDate, onChange, ...props },
  ref
) {
  const [{ activeStart, activeEnd }, setState] = useState<LocalState>(() => {
    return {
      startDay: startDate,
      endDay:
        endDate > startDate
          ? endDate
          : addDays(startDate, DEFAULT_TOTAL_DAYS - 1),
      activeStart: true,
      activeEnd: false,
    };
  });
  const daysDiff = differenceInDays(endDate, startDate);
  const totalDays = daysDiff > 0 ? daysDiff + 1 : 1;
  const startRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const handleOnActiveStart = () => {
    startRef.current?.scrollIntoView({ behavior: 'smooth' });
    setState({ activeStart: true, activeEnd: false });
  };
  const handleOnActiveEnd = () => {
    endRef.current?.scrollIntoView({ behavior: 'smooth' });
    setState({ activeStart: false, activeEnd: true });
  };
  const handleOnChangeTotalDays = (days: string) => {
    const endDate = +days <= 1 ? startDate : addDays(startDate, +days - 1);
    onChange(startDate, endDate);
  };
  const handleOnDayClick: CalendarProps['onDayClick'] = (day) => {
    const endDate = totalDays - 1 === 1 ? day : addDays(day, totalDays - 1);
    onChange(day, endDate);
  };

  return (
    <div
      ref={ref}
      {...props}
      className={cn('flex flex-col items-center gap-2 text-sm', className)}
    >
      <h3 className="text-xl font-bold">Cycle duration</h3>

      <p className="text-center">Set when your cycle starts and ends.</p>

      <div className="flex items-center justify-between gap-2 rounded-full bg-neutral-200 p-1">
        <Day aria-pressed={activeStart} onClick={handleOnActiveStart}>
          <p>Starts on</p>
          <p>{formatDateInMMMDD(startDate) ?? '-'}</p>
        </Day>

        <ArrowRightIcon />

        <Day aria-pressed={activeEnd} onClick={handleOnActiveEnd}>
          <p>Ends on</p>
          <p>{formatDateInMMMDD(endDate) ?? '-'}</p>
        </Day>
      </div>

      <div className="gap mt-6 flex flex-wrap justify-center gap-4">
        <div
          ref={startRef}
          className={cn(
            'min-w-[288px] rounded border border-neutral-400',
            activeStart && 'border-orange-500'
          )}
        >
          <Calendar
            mode="single"
            selected={startDate}
            defaultMonth={startDate}
            onDayClick={handleOnDayClick}
            onDayFocus={handleOnActiveStart}
          />
        </div>

        <div
          ref={endRef}
          onFocus={handleOnActiveEnd}
          className={cn(
            'flex min-w-[288px] flex-col gap-4 rounded border border-neutral-400 p-4',
            activeEnd && 'border-orange-500'
          )}
        >
          <p className="text-neutral-500">Set the expected duration</p>
          <div className="w-fit">
            <Input
              type="number"
              min={1}
              label="days"
              labelPlacement="right"
              className="max-w-[64px]"
              value={totalDays.toString()}
              onChange={(e) => handleOnChangeTotalDays(e.target.value)}
            />
          </div>
          <Radio.Group
            name="days"
            variant="outline"
            value={totalDays.toString()}
            onChange={(e) => handleOnChangeTotalDays(e.target.value)}
          >
            <Radio.Input label="58 days" value="58" />
            <Radio.Input label="59 days" value="59" />
            <Radio.Input label="60 days" value="60" />
            <Radio.Input label="61 days" value="61" />
            <Radio.Input label="62 days" value="62" />
          </Radio.Group>
        </div>
      </div>
    </div>
  );
});
