import { Button } from 'components/common/Button/Button';
import {
  Dropdown,
  DropdownProps,
  Option,
} from 'components/common/Dropdown/Dropdown';
import { Radio } from 'components/common/Radio/Radio';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useHeatMapURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { InfoIcon } from 'icons/InfoIcon';
import { FC, useCallback, useMemo } from 'react';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import {
  EMeasurementTypes,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import {
  AGGREGATED,
  AirLeafTemperatureDifference,
  HEATMAP_TYPE_OPTIONS,
  HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE,
  SINGLE,
  tooltips,
} from './utils';

interface IHeatMapTypeSelectionProps {
  /** */
  aggregationType: EAggregationTypes;
  /** Heat map has optimal range */
  hasOptimalRange: boolean;
  /** */
  className?: string;
  /** */
  typeConfig: MeasurementTypeConfig;
}

export const HeatMapTypeSelection: FC<IHeatMapTypeSelectionProps> = ({
  className,
  hasOptimalRange,
  aggregationType,
  typeConfig,
}) => {
  const {
    setAggregationType,
    setSignalIds,
    setGradientType,
    setShowComments,
    showComments,
    gradientType,
  } = useHeatMapURL();
  const { environmentalMeasurementTypeFilters, getMeasurementType } =
    useTypeConfig();
  const localTypeConfig = !typeConfig.heatMapCodes
    ? getMeasurementType(AirLeafTemperatureDifference)
    : typeConfig;
  const heatMapOptions = useMemo(() => {
    if (!hasOptimalRange || !localTypeConfig.hasOptimalRange) {
      return HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE;
    }

    return HEATMAP_TYPE_OPTIONS;
  }, [hasOptimalRange, localTypeConfig.hasOptimalRange]);
  const selecteHeatMapType = heatMapOptions.find(
    (option) => option.value === gradientType
  );
  const handleChangeType: DropdownProps<EGradientTypes>['onChange'] =
    useCallback(
      (option) => {
        if (option && !Array.isArray(option)) {
          setGradientType(option.value);
        }
      },
      [setGradientType]
    );
  const measurementTypeOptions = useMemo(
    () =>
      environmentalMeasurementTypeFilters.map<Option<EMeasurementTypes>>(
        (option) => ({
          label: option.label,
          value: option.type,
          leadingElement: option.Icon,
        })
      ),
    [environmentalMeasurementTypeFilters]
  );
  const selectedMeasurementType = measurementTypeOptions.find(
    ({ value }) => value === localTypeConfig.type
  );
  const handleChangeMeasurementType: DropdownProps<EMeasurementTypes>['onChange'] =
    useCallback(
      (option) => {
        if (option && !Array.isArray(option)) {
          setSignalIds([option.value]);
        }
      },
      [setSignalIds]
    );

  return (
    <div
      className={cn('flex flex-wrap items-center justify-end gap-2', className)}
    >
      <Dropdown
        variant="secondary"
        value={selectedMeasurementType}
        onChange={handleChangeMeasurementType}
        options={measurementTypeOptions}
        aria-label="selected measurement"
        className="mr-auto"
      />

      <Radio.Group
        value={aggregationType}
        orientation="horizontal"
        disabled={localTypeConfig.hideHeatmapSingle}
        className=""
        onChange={(e) =>
          setAggregationType(e.target.value as EAggregationTypes)
        }
      >
        <Radio.Input
          labelClassName="text-xs"
          value={AGGREGATED}
          label={AGGREGATED}
        />
        <Radio.Input labelClassName="text-xs" value={SINGLE} label={SINGLE} />
      </Radio.Group>
      <Dropdown
        variant="secondary"
        value={selecteHeatMapType}
        onChange={handleChangeType}
        options={heatMapOptions}
        aria-label="selected heatmap type"
        className="yolo A"
      />
      <Tooltip label={tooltips[gradientType]} withArrow>
        <span>
          <InfoIcon className="w-9 h-9 p-2" />
        </span>
      </Tooltip>
      <Button
        key={'comments-button'}
        variant="secondary"
        role="checkbox"
        selected={showComments}
        onClick={() => setShowComments(!showComments)}
        className="select-none text-xs font-normal"
      >
        Show Comments
      </Button>
    </div>
  );
};
