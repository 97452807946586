import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const PpfdIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 36"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M34.3113 11.4913C34.2757 11.0077 33.8846 10.6277 33.3957 10.6018C32.9957 10.5759 23.5113 10.0491 19.0491 15.3345C16.818 17.9772 16.258 21.5441 17.3869 25.9486C16.0357 28.1595 15.0402 30.7936 14.9957 33.7904C14.9869 34.3172 15.4224 34.7491 15.9646 34.7577C15.9735 34.7577 15.9735 34.7577 15.9824 34.7577C16.5157 34.7577 16.9602 34.3432 16.9602 33.8163C16.9957 31.3118 17.8313 29.0663 18.9691 27.1404C20.3113 27.4341 21.5824 27.5895 22.7735 27.5895C25.3957 27.5895 27.6268 26.8641 29.4402 25.4218C34.8713 21.095 34.338 11.88 34.3113 11.4913ZM28.1868 23.9622C26.1957 25.5427 23.4935 26.0263 20.1335 25.4304C22.7468 21.9759 26.0446 19.9377 26.098 19.9032C26.5602 19.6268 26.7024 19.0395 26.4091 18.5904C26.1246 18.1413 25.5202 18.0118 25.058 18.2882C24.8268 18.4263 21.6624 20.3782 18.9424 23.7636C18.498 20.7927 19.0402 18.3745 20.5602 16.5609C23.6535 12.8991 30.0091 12.5104 32.3646 12.5018C32.3557 14.7732 31.9557 20.9482 28.1868 23.9622Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0_2365_9530)">
      <path
        d="M8.75553 12.6893C7.97751 12.6893 7.21696 12.4586 6.57006 12.0263C5.92315 11.5941 5.41895 10.9797 5.12122 10.2609C4.82348 9.54212 4.74558 8.75117 4.89737 7.9881C5.04915 7.22502 5.4238 6.52409 5.97395 5.97395C6.52409 5.4238 7.22502 5.04915 7.9881 4.89737C8.75117 4.74558 9.54212 4.82348 10.2609 5.12122C10.9797 5.41895 11.5941 5.92315 12.0263 6.57006C12.4586 7.21696 12.6893 7.97751 12.6893 8.75553C12.6893 9.79883 12.2748 10.7994 11.5371 11.5371C10.7994 12.2748 9.79883 12.6893 8.75553 12.6893ZM8.75553 5.94571C8.1998 5.94571 7.65655 6.1105 7.19448 6.41925C6.73241 6.728 6.37226 7.16683 6.15959 7.68026C5.94693 8.19369 5.89128 8.75865 5.9997 9.3037C6.10812 9.84876 6.37573 10.3494 6.76869 10.7424C7.16165 11.1353 7.66231 11.403 8.20736 11.5114C8.75242 11.6198 9.31738 11.5641 9.83081 11.3515C10.3442 11.1388 10.7831 10.7787 11.0918 10.3166C11.4006 9.85452 11.5654 9.31126 11.5654 8.75553C11.5654 8.01032 11.2693 7.29563 10.7424 6.76869C10.2154 6.24174 9.50074 5.94571 8.75553 5.94571Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M8.75532 16.5135C8.60628 16.5135 8.46334 16.4543 8.35795 16.3489C8.25257 16.2435 8.19336 16.1006 8.19336 15.9516V14.9372C8.19336 14.7882 8.25257 14.6452 8.35795 14.5398C8.46334 14.4345 8.60628 14.3752 8.75532 14.3752C8.90437 14.3752 9.04731 14.4345 9.15269 14.5398C9.25808 14.6452 9.31729 14.7882 9.31729 14.9372V15.9431C9.31841 16.0176 9.3047 16.0916 9.27696 16.1608C9.24922 16.2299 9.208 16.2929 9.1557 16.346C9.10341 16.399 9.04108 16.4412 8.97234 16.4699C8.90361 16.4987 8.82984 16.5135 8.75532 16.5135Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M8.75532 3.13604C8.60628 3.13604 8.46334 3.07684 8.35795 2.97145C8.25257 2.86606 8.19336 2.72312 8.19336 2.57408V1.45015C8.19336 1.30111 8.25257 1.15817 8.35795 1.05278C8.46334 0.947391 8.60628 0.888184 8.75532 0.888184C8.90437 0.888184 9.04731 0.947391 9.15269 1.05278C9.25808 1.15817 9.31729 1.30111 9.31729 1.45015V2.57408C9.31729 2.72312 9.25808 2.86606 9.15269 2.97145C9.04731 3.07684 8.90437 3.13604 8.75532 3.13604Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M16.0047 9.31754H14.937C14.7879 9.31754 14.645 9.25833 14.5396 9.15294C14.4342 9.04755 14.375 8.90461 14.375 8.75557C14.375 8.60653 14.4342 8.46359 14.5396 8.3582C14.645 8.25281 14.7879 8.1936 14.937 8.1936H16.0047C16.1537 8.1936 16.2967 8.25281 16.4021 8.3582C16.5075 8.46359 16.5667 8.60653 16.5667 8.75557C16.5667 8.90461 16.5075 9.04755 16.4021 9.15294C16.2967 9.25833 16.1537 9.31754 16.0047 9.31754Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M2.57379 9.31754H1.51167C1.36263 9.31754 1.21969 9.25833 1.1143 9.15294C1.00891 9.04755 0.949707 8.90461 0.949707 8.75557C0.949707 8.60653 1.00891 8.46359 1.1143 8.3582C1.21969 8.25281 1.36263 8.1936 1.51167 8.1936H2.57379C2.72283 8.1936 2.86577 8.25281 2.97115 8.3582C3.07654 8.46359 3.13575 8.60653 3.13575 8.75557C3.13575 8.90461 3.07654 9.04755 2.97115 9.15294C2.86577 9.25833 2.72283 9.31754 2.57379 9.31754Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M3.55423 14.4623C3.44343 14.4618 3.33524 14.4286 3.24326 14.3668C3.15128 14.3051 3.07961 14.2175 3.03727 14.1151C2.99493 14.0127 2.98381 13.9 3.0053 13.7913C3.02679 13.6826 3.07993 13.5827 3.15805 13.5041L3.93356 12.7286C4.04013 12.6236 4.18407 12.5651 4.33372 12.5662C4.48337 12.5672 4.62648 12.6277 4.73155 12.7343C4.83663 12.8408 4.89506 12.9848 4.89401 13.1344C4.89296 13.2841 4.8325 13.4272 4.72593 13.5322L3.95042 14.2965C3.84552 14.402 3.70304 14.4617 3.55423 14.4623Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M13.063 4.99344C12.9522 4.99298 12.844 4.95977 12.752 4.89799C12.6601 4.83621 12.5884 4.74861 12.5461 4.64622C12.5037 4.54382 12.4926 4.4312 12.5141 4.3225C12.5356 4.2138 12.5887 4.11388 12.6668 4.03529L13.4452 3.25697C13.5506 3.15153 13.6936 3.09229 13.8428 3.09229C13.9919 3.09229 14.1349 3.15153 14.2403 3.25697C14.3458 3.36242 14.405 3.50544 14.405 3.65456C14.405 3.80369 14.3458 3.9467 14.2403 4.05215L13.4592 4.82204C13.4077 4.87532 13.3462 4.91789 13.2782 4.9473C13.2102 4.97672 13.1371 4.9924 13.063 4.99344Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M4.44794 5.00173C4.29914 5.0011 4.15666 4.94149 4.05176 4.83595L3.29311 4.0773C3.18766 3.97185 3.12842 3.82883 3.12842 3.67971C3.12842 3.53058 3.18766 3.38757 3.29311 3.28212C3.39855 3.17667 3.54157 3.11743 3.6907 3.11743C3.83982 3.11743 3.98284 3.17667 4.08829 3.28212L4.84694 4.04077C4.92619 4.11937 4.98024 4.2198 5.0022 4.32924C5.02416 4.43868 5.01302 4.55218 4.97022 4.65527C4.92742 4.75836 4.85488 4.84637 4.76187 4.90807C4.66885 4.96977 4.55956 5.00238 4.44794 5.00173Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        d="M13.8412 14.4203C13.6924 14.4197 13.55 14.36 13.445 14.2545L12.7145 13.5324C12.6623 13.4802 12.6209 13.4182 12.5926 13.35C12.5643 13.2817 12.5498 13.2086 12.5498 13.1348C12.5498 13.0609 12.5643 12.9878 12.5926 12.9196C12.6209 12.8514 12.6623 12.7894 12.7145 12.7372C12.7667 12.685 12.8287 12.6436 12.8969 12.6153C12.9651 12.5871 13.0382 12.5725 13.1121 12.5725C13.1859 12.5725 13.259 12.5871 13.3273 12.6153C13.3955 12.6436 13.4575 12.685 13.5097 12.7372L14.2402 13.4677C14.3166 13.5468 14.3682 13.6465 14.3885 13.7545C14.4088 13.8625 14.397 13.9741 14.3545 14.0755C14.3121 14.1768 14.2408 14.2635 14.1496 14.3248C14.0583 14.3861 13.9511 14.4193 13.8412 14.4203Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2365_9530">
        <rect
          width="16.1778"
          height="16.1778"
          fill={fill}
          transform="translate(0.666504 0.666748)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { PpfdIcon };
