import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const EditIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M15.2066 0.793412C14.6925 0.285093 13.9987 0 13.2758 0C12.5529 0 11.8591 0.285093 11.345 0.793412L1.62675 10.4902C1.20563 10.909 0.908627 11.4362 0.768627 12.0134L0.0177678 15.2099C-0.00486259 15.3042 -0.00588856 15.4024 0.0147666 15.4972C0.0354218 15.5919 0.0772239 15.6808 0.137049 15.7571C0.196875 15.8335 0.273177 15.8953 0.360253 15.938C0.44733 15.9807 0.54293 16.0031 0.639909 16.0037H0.79008L3.9866 15.2314C4.56378 15.0914 5.09096 14.7944 5.50977 14.3732L15.2066 4.65498C15.7149 4.14092 16 3.44714 16 2.72419C16 2.00125 15.7149 1.30747 15.2066 0.793412ZM13.2758 1.28683C13.5548 1.28533 13.8281 1.36502 14.0626 1.5162C14.297 1.66738 14.4824 1.88351 14.5961 2.13823C14.7098 2.39296 14.7469 2.67526 14.703 2.95073C14.659 3.2262 14.5358 3.48292 14.3485 3.68959L12.3104 1.65154C12.576 1.41466 12.92 1.28471 13.2758 1.28683ZM4.58729 13.4508C4.33364 13.7048 4.01418 13.8831 3.6648 13.9656L1.49803 14.502L2.01291 12.3352C2.09544 11.9858 2.27374 11.6664 2.52778 11.4127L11.3879 2.55257L13.426 4.59062L4.58729 13.4508Z"
      fill={fill}
    />
  </svg>
);

export { EditIcon };
