import { useGetDiscussions } from 'api/discussion';
import { Button } from 'components/common/Button/Button';
import {
  DiscussionBox,
  DiscussionBoxProps,
} from 'components/discussions/DiscussionBox';
import { BoundingBoxAnnotation } from 'components/image_feed/BoundingBoxAnnotation';
import { useAuth } from 'contexts/AuthProvider';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useImageFeedURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { type useDrawBoundingBoxController } from 'hooks/useDrawBoundingBoxController';
import { usePermissions } from 'hooks/usePermissions';
import React, {
  ComponentProps,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  TAnnotationType,
  TBoundingBoxWithDiscussion,
  TDiscussion,
  TDiscussionDraft,
  TDiscussionMetadata,
} from 'shared/interfaces/discussion';
import { EGradientTypes } from 'shared/interfaces/heatmap';
import { EMeasurementTypes } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { Cluster, clusterRectangles } from 'shared/utils/geometry';
import { getDrawnBoundingBoxWithMetadataFromDiscussion } from 'shared/utils/image';

const DRAG_THRESHOLD = 5;

export type TDiscussionBoundingBoxAnnotationsProps = {
  annotationInfo: {
    type: TAnnotationType;
    id: string | number;
    startTime: Date;
    endTime: Date;
    measurementType?: EMeasurementTypes;
    gradientType?: EGradientTypes;
  };
  position?: TPosition;
  scale?: number;
  resolution: TSize;
  children: React.ReactNode;
  drawController: ReturnType<
    typeof useDrawBoundingBoxController<TDiscussionMetadata>
  >;
  onSelectionChange: (element: RefObject<HTMLElement>) => void;
};

const isLeftMouseButtonPressed = (e: React.MouseEvent<HTMLElement>) =>
  e.buttons & 1;

const getBoundingBoxClipPath = (boundingBox: {
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  return `polygon(
    0 0,
    100% 0,
    100% 100%,
    0 100%,
    0 0,
    ${boundingBox.x}px ${boundingBox.y}px,
    ${boundingBox.x}px ${boundingBox.y + boundingBox.height}px,
    ${boundingBox.x + boundingBox.width}px ${boundingBox.y + boundingBox.height}px,
    ${boundingBox.x + boundingBox.width}px ${boundingBox.y}px,
    ${boundingBox.x}px ${boundingBox.y}px
)`;
};

const getBoundingBoxStyle = (
  boundigBox: TBoundingBoxWithDiscussion,
  scale: number
) => {
  return {
    left: boundigBox.x,
    top: boundigBox.y,
    width: boundigBox.width,
    height: boundigBox.height,
    borderWidth: 2 / scale,
    fontSize: 12 / scale,
  };
};

export const DiscussionBoundingBoxAnnotations = ({
  scale = 1,
  position = { x: 0, y: 0 },
  annotationInfo,
  resolution,
  children,
  drawController,
  onSelectionChange,
}: TDiscussionBoundingBoxAnnotationsProps) => {
  const annotationElementRef = useRef<HTMLDivElement>(null);

  const {
    drawingMode,
    drawingDivRef,
    drawnBoundingBox,
    selectedBoundingBox,
    handleDrawStart,
    handleDrawMove,
    handleDrawEnd,
    setSelectedBoundingBox,
    handleRemoveBoundingBox,
    handleSaveBoundingBox,
  } = drawController;

  const dragRef = useRef({ startX: 0, startY: 0, x: 0, y: 0 });

  const { user } = useAuth();
  const { currentZone, zoneTimeZone } = useCurrentZone();
  const { insights } = usePermissions();

  const { getMeasurementType } = useTypeConfig();
  const typeConfig =
    annotationInfo.measurementType &&
    getMeasurementType(annotationInfo.measurementType);
  const signalIds = useMemo(
    () => typeConfig && [typeConfig.statisticsKeyV2],
    [typeConfig]
  );

  const { discussions } = useGetDiscussions({
    ...(annotationInfo.type === 'single_image_annotation'
      ? {
          measurementIds: [annotationInfo.id as number],
        }
      : annotationInfo.type === 'heatmap_annotation'
        ? {
            startTime: annotationInfo.startTime.valueOf(),
            endTime: annotationInfo.endTime.valueOf(),
            heatMapId: annotationInfo.id as number,
          }
        : annotationInfo.type === 'heatmap_aggregate_annotation'
          ? {
              startTime: annotationInfo.startTime.valueOf(),
              endTime: annotationInfo.endTime.valueOf(),
              heatMapAggregateUid: annotationInfo.id as string,
            }
          : {}),
    annotationTypes: [annotationInfo.type],
    signalIds,
    zone: currentZone,
    canViewInsighDraft: insights.canViewDraft,
    userId: user?.id!,
  });

  const annotations: TBoundingBoxWithDiscussion[] = useMemo(() => {
    return discussions.map((item) =>
      getDrawnBoundingBoxWithMetadataFromDiscussion(item, resolution)
    );
  }, [discussions, resolution]);

  const { discussionUid, setDiscussionUid } = useImageFeedURL();

  const [preSelectedDiscussion, setPreSelectedDiscussion] =
    useState<TDiscussion>();

  useEffect(() => {
    const annotation = annotations.find(
      (annotation) => annotation.metadata?.discussion?.uid === discussionUid
    );
    setPreSelectedDiscussion(annotation?.metadata?.discussion);
  }, [discussionUid, annotations]);

  const handleAnnotationMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    dragRef.current = {
      startX: e.clientX,
      startY: e.clientY,
      x: 0,
      y: 0,
    };
  };

  const handleAnnotationMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (isLeftMouseButtonPressed(e)) {
      dragRef.current = {
        ...dragRef.current,
        x: Math.abs(e.clientX - dragRef.current.startX),
        y: Math.abs(e.clientY - dragRef.current.startY),
      };
    }
  };

  const annotationDragHandlers = useMemo(
    () => ({
      onMouseDown: handleAnnotationMouseDown,
      onMouseMove: handleAnnotationMouseMove,
    }),
    []
  );

  useEffect(() => {
    if (selectedBoundingBox) {
      setSelectedCluster(null);
      setActiveDiscussionUid(undefined);
    }
  }, [selectedBoundingBox]);

  const selectedDiscussion: Optional<TDiscussionDraft> =
    selectedBoundingBox && currentZone
      ? (selectedBoundingBox.metadata?.discussion ?? {
          startTime: annotationInfo.startTime,
          endTime: annotationInfo.endTime,
          timeZone: zoneTimeZone,
          zoneId: currentZone.id,
          zoneUid: currentZone.uid,
          area: {
            shape: 'rectangle',
            points: [
              {
                x: selectedBoundingBox.x / resolution.width,
                y: selectedBoundingBox.y / resolution.height,
              },
              {
                x:
                  (selectedBoundingBox.x + selectedBoundingBox.width) /
                  resolution.width,
                y:
                  (selectedBoundingBox.y + selectedBoundingBox.height) /
                  resolution.height,
              },
            ],
            aspectRatio: resolution.width / resolution.height,
            ...(annotationInfo.type === 'single_image_annotation'
              ? { gridPosition: position }
              : annotationInfo.type === 'heatmap_annotation' ||
                  annotationInfo.type === 'heatmap_aggregate_annotation'
                ? {
                    signalIds,
                    gradientType: annotationInfo.gradientType,
                  }
                : { signalIds }),
          },
          type: 'comment',
          ...(annotationInfo.type === 'single_image_annotation'
            ? {
                measurementId: annotationInfo.id as number,
              }
            : annotationInfo.type === 'heatmap_annotation'
              ? {
                  heatMapId: annotationInfo.id as number,
                }
              : annotationInfo.type === 'heatmap_aggregate_annotation'
                ? {
                    heatMapAggregateUid: annotationInfo.id as string,
                  }
                : {}),
          annotationType: annotationInfo.type,
        })
      : undefined;

  const handleCloseDiscussionBox = () => {
    setSelectedBoundingBox(null);
    setSelectedCluster(null);
    setDiscussionUid(undefined);
    setActiveDiscussionUid(undefined);
    setPreSelectedDiscussion(undefined);
  };

  const handleOnSaveDiscussion: DiscussionBoxProps['onSaveDiscussion'] = async (
    promise
  ) => {
    try {
      await handleSaveBoundingBox(promise);
    } finally {
      setDiscussionUid(undefined);
    }
  };

  const [clusters, setClusters] = useState<Cluster[]>([]);

  const clusterDiscussions = useCallback(
    (zoomLevel: number) => {
      const rectangles = annotations
        .filter((annotation) => annotation.metadata?.discussion)
        .map((annotation) => ({
          x: annotation.x,
          y: annotation.y,
          width: annotation.width,
          height: annotation.height,
          uid: annotation.metadata?.discussion?.uid ?? '',
        }));

      setClusters(clusterRectangles(rectangles, zoomLevel));
    },
    [annotations]
  );
  useEffect(() => {
    setSelectedCluster(null);
    clusterDiscussions(scale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotations, scale]);

  const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);
  const selectedClusterDiscussionIds =
    selectedCluster?.rectangles.map((rectangle) => rectangle.uid) ?? [];
  const selectedClusterDiscussions = discussions.filter((discussion) =>
    selectedClusterDiscussionIds.includes(discussion.uid)
  );

  const [activeDiscussionUid, setActiveDiscussionUid] = useState<string>();
  const activeDiscussion = useMemo(
    () =>
      discussions.find((discussion) => discussion.uid === activeDiscussionUid),
    [discussions, activeDiscussionUid]
  );

  useEffect(() => {
    (preSelectedDiscussion || selectedBoundingBox) &&
      onSelectionChange(annotationElementRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedDiscussion, selectedBoundingBox]);

  const clusterRef = useRef<HTMLDivElement>(null);

  const handleToggleAnnotation = useCallback(
    (annotation: TBoundingBoxWithDiscussion) => {
      if (
        selectedBoundingBox &&
        selectedBoundingBox?.metadata?.discussion?.uid === activeDiscussion?.uid
      ) {
        setSelectedBoundingBox(null);
        setActiveDiscussionUid(undefined);
      } else {
        setSelectedBoundingBox(annotation);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBoundingBox, activeDiscussion]
  );

  const handleAnnotationClick = useCallback(
    (annotation: (typeof annotations)[0]) => {
      // avoid selecting annotation when dragging
      if (
        dragRef.current.x < DRAG_THRESHOLD &&
        dragRef.current.y < DRAG_THRESHOLD
      ) {
        handleToggleAnnotation(annotation);
      }
      dragRef.current = { startX: 0, startY: 0, x: 0, y: 0 };
    },
    [handleToggleAnnotation]
  );

  const discussionsInfo = useMemo(() => {
    return annotations.reduce(
      (acc, annotation, index) => {
        if (!annotation.metadata?.discussion) return acc;
        if (annotation.metadata?.discussion?.uid) {
          acc[annotation.metadata.discussion.uid] = {
            left: annotation.x,
            top: annotation.y,
            width: annotation.width,
            height: annotation.height,
            scale,
            label: annotation.metadata!.discussion.displayLabel,
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              handleAnnotationClick(annotation);
            },
            onTouchEnd: (e: React.TouchEvent) => {
              e.preventDefault();
              e.stopPropagation();
              if (
                activeDiscussion?.uid !==
                selectedBoundingBox?.metadata?.discussion?.uid
              ) {
                setSelectedBoundingBox(annotation);
              } else {
                setActiveDiscussionUid(annotation.metadata!.discussion.uid);
              }
            },
            'data-testid': `annotation-button-${index}`,
            ...annotationDragHandlers,
          };
        }
        return acc;
      },
      {} as Record<string, ComponentProps<typeof BoundingBoxAnnotation>>
    );
  }, [
    annotations,
    handleAnnotationClick,
    annotationDragHandlers,
    scale,
    activeDiscussion,
    setSelectedBoundingBox,
    selectedBoundingBox,
  ]);

  return (
    <div
      ref={drawingDivRef}
      className={cn('relative', drawingMode && 'cursor-comment')}
      onMouseDown={handleDrawStart}
      onTouchStart={handleDrawStart}
      onMouseMove={handleDrawMove}
      onMouseUp={handleDrawEnd}
      onTouchEnd={handleDrawEnd}
    >
      {children}

      {selectedBoundingBox && (
        <>
          <div
            ref={annotationElementRef}
            style={getBoundingBoxStyle(selectedBoundingBox, scale)}
            className={cn(
              'absolute border-orange-500 cursor-pointer bg-[#FFFFFF50] hover:bg-transparent',
              'bg-transparent'
            )}
            onClick={() => handleAnnotationClick(selectedBoundingBox)}
            {...annotationDragHandlers}
            data-testid={`selected-annotation`}
          />
          <div
            className="absolute inset-0 bg-black bg-opacity-60"
            style={{
              clipPath: getBoundingBoxClipPath(selectedBoundingBox),
            }}
            {...annotationDragHandlers}
          />
          <DiscussionBox
            referenceElement={annotationElementRef}
            onSaveDiscussion={handleOnSaveDiscussion}
            onSelectDiscussion={(discussionUid) =>
              setSelectedBoundingBox(
                annotations.find(
                  (annotation) =>
                    annotation.metadata?.discussion?.uid === discussionUid
                ) ?? null
              )
            }
            onClose={handleCloseDiscussionBox}
            onDelete={handleRemoveBoundingBox}
            selectedDiscussion={selectedDiscussion}
          />
        </>
      )}

      {!selectedBoundingBox &&
        !preSelectedDiscussion &&
        clusters
          .map((cluster, index) => {
            if (selectedCluster && selectedCluster !== cluster) return null;
            if (cluster.rectangles.length === 1) {
              const discussionUid = cluster.rectangles[0]!.uid;
              if (!discussionsInfo[discussionUid]) return null;
              return (
                <BoundingBoxAnnotation
                  key={discussionUid}
                  {...discussionsInfo[discussionUid]!}
                />
              );
            }
            return (
              <>
                <Button
                  key={index}
                  variant={
                    selectedCluster === cluster ? 'primary' : 'secondary'
                  }
                  className="cursor-pointer font-normal p-0 min-w-6 shadow absolute  text-xs rounded-full  h-6 flex items-center justify-center rounded-bl-none"
                  style={{
                    left: cluster.centroid.x,
                    top: cluster.centroid.y,
                    transformOrigin: 'left bottom',
                    transform: `translate(0%, -100%) scale(${1 / scale})`,
                    opacity:
                      activeDiscussion && selectedCluster === cluster ? 0.5 : 1,
                  }}
                  onClick={() => setSelectedCluster(cluster)}
                >
                  {cluster.rectangles.length}
                </Button>
              </>
            );
          })
          .filter(Boolean)}

      {activeDiscussion && !selectedBoundingBox && !preSelectedDiscussion && (
        <BoundingBoxAnnotation
          key={activeDiscussion.uid}
          {...discussionsInfo[activeDiscussion.uid]!}
          showBox
        />
      )}
      {preSelectedDiscussion && !selectedBoundingBox && (
        <BoundingBoxAnnotation
          ref={annotationElementRef}
          key={preSelectedDiscussion.uid}
          {...discussionsInfo[preSelectedDiscussion.uid]!}
          showBox
        />
      )}
      {selectedCluster && (
        <>
          <div
            ref={clusterRef}
            className="opacity-0 pointer-events-none absolute"
            style={(() => {
              const clusterRangeLeft = Math.min(
                ...selectedCluster.rectangles.map((rectangle) => rectangle.x)
              );
              const clusterRangeRight = Math.max(
                ...selectedCluster.rectangles.map(
                  (rectangle) => rectangle.x + rectangle.width
                )
              );
              const clusterRangeTop = Math.min(
                ...selectedCluster.rectangles.map((rectangle) => rectangle.y)
              );
              const clusterRangeBottom = Math.max(
                ...selectedCluster.rectangles.map(
                  (rectangle) => rectangle.y + rectangle.height
                )
              );
              const clusterRangeWidth = clusterRangeRight - clusterRangeLeft;
              const clusterRangeHeight = clusterRangeBottom - clusterRangeTop;
              return {
                left: clusterRangeLeft + position.x,
                top: clusterRangeTop + position.y,
                width: clusterRangeWidth,
                height: clusterRangeHeight,
              };
            })()}
          />

          <DiscussionBox
            referenceElement={clusterRef}
            onSelectDiscussion={(discussionUid) =>
              setSelectedBoundingBox(
                annotations.find(
                  (annotation) =>
                    annotation.metadata?.discussion?.uid === discussionUid
                ) ?? null
              )
            }
            onClose={handleCloseDiscussionBox}
            discussions={selectedClusterDiscussions}
            onChangeActiveDiscussion={setActiveDiscussionUid}
            closeOnWheel
          />
        </>
      )}
      {/* TODO: see if we can get rid of the following code */}
      {drawnBoundingBox.drawing && (
        <>
          <div
            className="absolute inset-0 bg-black bg-opacity-60 pointer-events-none z-10"
            style={{
              clipPath: getBoundingBoxClipPath(drawnBoundingBox),
            }}
          />
          <div
            style={getBoundingBoxStyle(drawnBoundingBox, scale)}
            className="absolute border-orange-500 pointer-events-none"
            data-testid={`drawn-annotation`}
          />
        </>
      )}
    </div>
  );
};
