import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const AirTempLeafTempIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M14.6707 2.41671C14.6555 2.211 14.4892 2.04937 14.2812 2.03835C14.111 2.02732 10.076 1.80324 8.17761 4.05141C7.22842 5.17549 6.99017 6.69264 7.47044 8.56611C6.89563 9.50652 6.47209 10.6269 6.45318 11.9016C6.4494 12.1257 6.6347 12.3094 6.86538 12.3131C6.86916 12.3131 6.86916 12.3131 6.87294 12.3131C7.09984 12.3131 7.28892 12.1367 7.28892 11.9126C7.30405 10.8473 7.65952 9.89224 8.14357 9.07305C8.7146 9.19795 9.25537 9.26407 9.76211 9.26407C10.8777 9.26407 11.8269 8.9555 12.5983 8.34203C14.9089 6.50162 14.682 2.58202 14.6707 2.41671ZM12.0651 7.72121C11.218 8.39346 10.0684 8.59917 8.63897 8.3457C9.75077 6.87631 11.1538 6.00937 11.1764 5.99468C11.3731 5.87713 11.4336 5.62733 11.3088 5.43631C11.1878 5.24529 10.9306 5.19019 10.734 5.30774C10.6357 5.36651 9.28941 6.19672 8.13223 7.63672C7.94315 6.37305 8.17383 5.34447 8.82049 4.57304C10.1365 3.01549 12.8404 2.85018 13.8425 2.84651C13.8387 3.81263 13.6685 6.43917 12.0651 7.72121Z"
      fill={fill}
    />
    <path
      d="M4.39821 0.75C3.18653 0.75 2.21964 1.76601 2.21964 3V11.1872C1.49299 11.8353 1.0625 12.7817 1.0625 13.8C1.0625 15.6967 2.54746 17.25 4.39821 17.25C6.24897 17.25 7.73393 15.6967 7.73393 13.8C7.73393 12.7817 7.30344 11.8353 6.57679 11.1872V3C6.57679 1.76601 5.6099 0.75 4.39821 0.75ZM3.22255 12.0325C3.39282 11.9093 3.49107 11.7105 3.49107 11.5019V3C3.49107 2.46668 3.9057 2.05 4.39821 2.05C4.89072 2.05 5.30536 2.46668 5.30536 3V11.5019C5.30536 11.7105 5.40361 11.9093 5.57388 12.0325C6.12504 12.4312 6.4625 13.0841 6.4625 13.8C6.4625 14.9961 5.5298 15.95 4.39821 15.95C3.26663 15.95 2.33393 14.9961 2.33393 13.8C2.33393 13.0841 2.67139 12.4312 3.22255 12.0325ZM3.7625 6.46667V12.0697C3.08222 12.3357 2.60536 13.0137 2.60536 13.8C2.60536 14.8131 3.39955 15.65 4.39821 15.65C5.39687 15.65 6.19107 14.8131 6.19107 13.8C6.19107 13.0137 5.7142 12.3357 5.03393 12.0697V6.46667C5.03393 6.11633 4.7578 5.81667 4.39821 5.81667C4.03863 5.81667 3.7625 6.11633 3.7625 6.46667ZM4.91964 13.8C4.91964 14.1124 4.6777 14.35 4.39821 14.35C4.11873 14.35 3.87679 14.1124 3.87679 13.8C3.87679 13.4876 4.11873 13.25 4.39821 13.25C4.6777 13.25 4.91964 13.4876 4.91964 13.8Z"
      fill={fill}
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M10.7115 13.9964C10.1387 14.5737 10.1387 15.5131 10.7115 16.0904C10.7882 16.1676 10.7882 16.2928 10.7115 16.37C10.635 16.4472 10.5109 16.4475 10.434 16.37C9.70814 15.6386 9.70814 14.4482 10.434 13.7167C11.0068 13.1394 11.0068 12.2001 10.434 11.6228C10.3573 11.5455 10.3573 11.4203 10.434 11.3431C10.5106 11.2658 10.6349 11.2658 10.7115 11.3431C11.4375 12.0746 11.4375 13.2648 10.7115 13.9964ZM9.33756 11.3431C9.26091 11.2658 9.13666 11.2658 9.06002 11.3431C8.98337 11.4203 8.98337 11.5455 9.06002 11.6228C9.63287 12.2001 9.63287 13.1393 9.06002 13.7167C8.33416 14.4482 8.33416 15.6386 9.06002 16.37C9.13676 16.4473 9.26081 16.4473 9.33756 16.37C9.41421 16.2928 9.41421 16.1676 9.33756 16.0904C8.76471 15.5131 8.76471 14.5738 9.33756 13.9964C10.0635 13.2648 10.0635 12.0746 9.33756 11.3431ZM12.0855 13.9964C12.8114 13.2648 12.8114 12.0746 12.0855 11.3431C12.0089 11.2658 11.8846 11.2658 11.808 11.3431C11.7313 11.4203 11.7313 11.5455 11.808 11.6228C12.3808 12.2001 12.3808 13.1393 11.808 13.7167C11.0821 14.4482 11.0821 15.6386 11.808 16.37C11.8846 16.4473 12.0089 16.4473 12.0855 16.37C12.1622 16.2928 12.1622 16.1676 12.0855 16.0904C11.5127 15.5131 11.5127 14.5737 12.0855 13.9964Z"
      fill={fill}
    />
  </svg>
);

export { AirTempLeafTempIcon };
