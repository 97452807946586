import { CloseIcon } from 'icons/CloseIcon';
import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  MouseEventHandler,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useRef,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Button } from '../Button/Button';
import { Label, LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';

const CANCEL_BUTTON_HEIGHT = 44;
const MIN_HEIGHT_WITH_CANCEL_BUTTON = 96;

import { LexicalEditor } from 'lexical';
import isNil from 'lodash.isnil';
import { mergeRefs } from 'react-merge-refs';
import { TMention } from 'shared/interfaces/discussion';
import { TextEditor } from '../TextEditor/TextEditor';

type IsLexicalEditorOnChange = (value: string) => any;

type DefaultOnChange = ChangeEventHandler<HTMLTextAreaElement>;

const Placeholder = ({ children }: { children?: ReactNode }) => {
  return children ? (
    <div className="absolute top-2 left-2 pointer-events-none text-neutral-500 font-normal">
      {children}
    </div>
  ) : null;
};

export type TextareaProps = Omit<
  ComponentPropsWithoutRef<'textarea'>,
  'onChange'
> & {
  /** The textarea value to set  */
  value?: string;
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** An optional field label */
  label?: LabelProps['value'];
  /** Sets the placement of the label. Defaults to `left`*/
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
  /** An optional element displayed within the textarea */
  actionIcon?: ReactElement<HTMLElement>;
  /** Ensure that `actionIcon` is defined when `onClickAction` is also defined */
  onClickAction?: MouseEventHandler<HTMLButtonElement>;
  /** When defined, the button will be rendered with a cancel icon */
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  /** The minimum height of the textarea */
  minHeight?: number;
  /** The maximum height of the textarea */
  maxHeight?: number;
} & (
    | {
        onChange: IsLexicalEditorOnChange;
        isLexicalEditor: true;
        availableMentions?: TMention[];
      }
    | {
        onChange: DefaultOnChange;
        isLexicalEditor: false | undefined | null;
        availableMentions?: never;
      }
  );

/**
 * An HTML textarea is used to create a multi-line text input field.
 */
export const Textarea = forwardRef<
  HTMLTextAreaElement | LexicalEditor,
  TextareaProps
>(function Textarea(
  {
    value,
    disabled,
    readOnly,
    helperText,
    errorText,
    actionIcon,
    className,
    label,
    labelPlacement = 'left',
    labelClassName,
    'aria-label': ariaLabelFromProps,
    onClickAction,
    onCancel,
    minHeight = 52,
    maxHeight = 150,
    onChange,
    isLexicalEditor = false,
    ...props
  },
  ref
) {
  const localRef = useRef<HTMLTextAreaElement | LexicalEditor>(null);
  const mergedRef = mergeRefs([ref, localRef]);
  const editorIsInitialized = isLexicalEditor && !isNil(localRef.current);
  const isEmpty =
    isLexicalEditor && editorIsInitialized
      ? (localRef as Maybe<MutableRefObject<LexicalEditor>>)?.current
          ?.getRootElement()
          ?.innerText.trim() === ''
      : !value;

  const style = {
    minHeight:
      onCancel && !isEmpty
        ? Math.min(
            minHeight + CANCEL_BUTTON_HEIGHT,
            MIN_HEIGHT_WITH_CANCEL_BUTTON
          )
        : minHeight,
    maxHeight,
    ...props.style,
  };

  const textareaClasses = cn(
    'h-full w-full p-2 outline-none resize-none overflow-y-auto',
    'text-base sm:text-sm font-normal text-neutral-900',
    'bg-white p-2 text-neutral-900 outline-none',
    disabled && 'bg-neutral-200 text-neutral-500',
    !isLexicalEditor && 'placeholder:text-neutral-500',
    !isLexicalEditor && 'read-only:bg-neutral-200',
    'group-has-[button:active]/textarea:border group-has-[button:active]/textarea:border-orange-500',
    'invalid:bg-red-100',
    actionIcon && '@[112px]/textarea:pr-10',
    className
  );

  const ariaLabel = ariaLabelFromProps
    ? ariaLabelFromProps
    : typeof label === 'string'
      ? label
      : '';
  return (
    <fieldset className="group/textarea flex w-full h-full flex-col gap-1">
      <Label
        value={label}
        placement={labelPlacement}
        disabled={disabled}
        className={cn('w-full h-full', labelClassName)}
      >
        <div
          className={cn(
            'relative flex flex-grow w-full h-full',
            'overflow-hidden',
            'rounded-xs border border-neutral-400',
            'group-focus-within/textarea:border-orange-500',
            'group-invalid/textarea:border-red-500 group-invalid/textarea:hover:border-red-500 group-invalid/textarea:focus:border-red-500',
            actionIcon && '@container/textarea'
          )}
        >
          {!isLexicalEditor ? (
            <textarea
              ref={mergedRef as Maybe<Ref<HTMLTextAreaElement>>}
              value={value}
              disabled={disabled}
              readOnly={readOnly}
              aria-label={ariaLabel}
              rows={1}
              className={textareaClasses}
              style={style}
              onChange={onChange as DefaultOnChange}
              {...props}
            />
          ) : (
            <TextEditor
              ref={mergedRef as Maybe<Ref<LexicalEditor>>}
              placeholder={<Placeholder>{props.placeholder}</Placeholder>}
              editable={!disabled && !readOnly}
              initialEditorState={value}
              onChange={onChange as IsLexicalEditorOnChange}
              className={textareaClasses}
              style={style}
              aria-label={ariaLabel}
              availableMentions={props.availableMentions}
            />
          )}

          {actionIcon && (
            <Button
              size="icon"
              disabled={disabled || readOnly || isEmpty}
              onClick={onClickAction}
              className="absolute bottom-2 right-2"
              variant={isEmpty ? 'secondary' : 'primary'}
              aria-label="Submit"
            >
              {actionIcon}
            </Button>
          )}
          {onCancel && !isEmpty && (
            <Button
              size="icon"
              onClick={onCancel}
              className="absolute top-2 right-2"
              variant={'secondary'}
              aria-label="Cancel"
            >
              <CloseIcon />
            </Button>
          )}
        </div>
      </Label>
      {helperText && (
        <StatusText className="group-valid/textarea:block">
          {helperText}
        </StatusText>
      )}
      {errorText && (
        <StatusText className="text-red-500 group-invalid/textarea:block">
          {errorText}
        </StatusText>
      )}
    </fieldset>
  );
});
