import {
  Typography as MuiTypography,
  TypographyProps,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import type { Colors } from 'shared/constants/color';

interface ITypographyProps extends HTMLAttributes<HTMLSpanElement> {
  /** className to be applied to the component */
  className?: string;
  /** Color of text to be displayed */
  color?:
    | (typeof Colors)[keyof typeof Colors]
    | 'default'
    | 'black'
    | 'gray'
    | 'inherit';
  /** Variant of fonts to be used */
  variant?: TypographyProps['variant'];
  /** Font style to be applied directly */
  fontWeight?: string;
  /** Line height to be applied */
  lineHeight?: number | string;
}

type TStyleProps = Pick<
  ITypographyProps,
  'color' | 'fontWeight' | 'lineHeight'
>;

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      fontWeight: (props: TStyleProps) => props.fontWeight as any,
      lineHeight: (props: TStyleProps) => props.lineHeight,
      color: (props: TStyleProps) =>
        props.color === 'gray'
          ? theme.palette.dark7.main
          : props.color === 'black'
            ? theme.palette.black.main
            : props.color === 'inherit'
              ? 'currentColor'
              : props.color === 'default'
                ? undefined
                : props.color,
    },
  })
);

/**
 * @deprecated Use tailwind instead for example use Typeography stories to see which design tokens to use Typograph.stories.tsx
 */
export const Typography: FC<ITypographyProps> = ({
  className,
  children,
  color,
  fontWeight,
  lineHeight,
  variant,
  ...props
}) => {
  const classes = useStyles({ color, fontWeight, lineHeight });

  return (
    <MuiTypography
      className={clsx(classes.typography, className)}
      variant={variant}
      {...props}
    >
      {children}
    </MuiTypography>
  );
};
