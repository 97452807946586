import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const CommentIcon = ({
  fill = 'none',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      stroke={stroke}
      fill={fill}
      d="M0.75 8.00391C0.75 3.99984 3.99594 0.753906 8 0.753906C12.0041 0.753906 15.25 3.99984 15.25 8.00391C15.25 12.008 12.0041 15.2539 8 15.2539H0.75V8.00391Z"
      strokeWidth="1.5"
    />
  </svg>
);

export { CommentIcon };
