import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Radio } from 'components/common/Radio/Radio';
import { useLineChartURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useDisclosure } from 'hooks/useDisclosure';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSignals } from 'hooks/useSignals';
import { ChevronDownIcon } from 'icons/ChevronDownIcon';
import { ComponentProps, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { cn } from 'shared/utils/cn';

export const Sidebar = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  function Sidebar({ className, ...props }, ref) {
    const disclosure = useDisclosure();
    const { isMobile } = useScreenSize();
    const { setSignalIds } = useLineChartURL();
    const { groups, selectedSignal: typeConfig } = useSignals();

    return (
      <>
        {!isMobile && (
          <div
            ref={ref}
            {...props}
            className={cn(
              'flex flex-col gap-2 overflow-y-auto scrollbar-thin',
              className
            )}
          >
            {groups.map(([group, types]) => (
              <div key={group} className="flex flex-col gap-1">
                <h2 className="font-semibold">{group}</h2>
                <hr className="h-px" />
                <Radio.Group
                  orientation="vertical"
                  name="MeasurementType"
                  value={typeConfig.type}
                  onChange={(e) => setSignalIds([e.target.value])}
                >
                  {types.map((type) => (
                    <Radio.Input
                      key={type.type}
                      value={type.type}
                      label={type.label}
                      labelClassName="w-full"
                    />
                  ))}
                </Radio.Group>
              </div>
            ))}
          </div>
        )}

        {isMobile &&
          createPortal(
            <>
              <div className="min-w-fit flex flex-row gap-2 justify-between">
                <Button
                  trailingIcon={<ChevronDownIcon />}
                  className="select-none font-normal text-xs"
                  variant="secondary"
                  onClick={disclosure.open}
                >
                  {typeConfig.label}
                </Button>
              </div>
              <Modal open={disclosure.isOpen}>
                <Modal.Header
                  title="Signal Sources"
                  closeButtonProps={{ onClick: disclosure.close }}
                ></Modal.Header>
                <Modal.Content>
                  <div className="flex flex-col gap-4">
                    {groups.map(([group, types]) => (
                      <div key={group} className="flex flex-col gap-2">
                        <h2 className="font-semibold">{group}</h2>

                        <div className="flex flex-wrap gap-2">
                          {types.map((type) => (
                            <Button
                              key={type.type}
                              role="radio"
                              variant="secondary"
                              selected={type.type === typeConfig.type}
                              onClick={() => {
                                setSignalIds([type.type]);
                                disclosure.close();
                              }}
                              className="select-none font-normal text-xs"
                            >
                              {type.label}
                            </Button>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Content>
              </Modal>
            </>,
            document.getElementById('filters-slot')!
          )}
      </>
    );
  }
);
