import { createStyles, makeStyles } from '@material-ui/core';
import { FC } from 'react';

interface IStyleProps {
  /** The image size in pixels. */
  size: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    imgAvatar: ({ size }: IStyleProps) => ({
      borderRadius: size / 2,
      height: size / 2,
      width: size / 2,
    }),
    wordAvatar: ({ size }: IStyleProps) => ({
      borderRadius: size,
      width: size,
      height: size,
      textTransform: 'uppercase',
      backgroundColor: theme.palette.orange3.main,
      textAlign: 'center',
      fontSize: 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  })
);

interface IUserAvatarProps {
  /** The image url. */
  imageUrl?: string;
  /** The user's name initials. */
  nameInitials: string;
  /** The image size in pixels. */
  size: number;
}

/**
 * Represents user's profile picture.
 *
 * @param {IUserAvatarProps} props imgageUrl, size
 * @returns {JSX.Element} UserAvatar component
 */
export const UserAvatar: FC<IUserAvatarProps> = ({
  imageUrl,
  nameInitials,
  size,
}) => {
  const classes = useStyles({ size });

  return (
    <>
      {imageUrl ? (
        <img className={classes.imgAvatar} src={imageUrl} />
      ) : (
        <div className={classes.wordAvatar}>{nameInitials}</div>
      )}
    </>
  );
};
