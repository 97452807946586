import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const BadgeIcon = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <mask
      id="path-1-outside-1_13567_27372"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0.00390625"
      width="18"
      height="18"
      fill="black"
    >
      <rect fill="white" y="0.00390625" width="18" height="18" />
      <path d="M9.00049 2.46942C6.47516 2.46942 4.42076 4.52383 4.42076 7.04915C4.42076 9.57448 6.47516 11.6289 9.00049 11.6289C11.5258 11.6289 13.5802 9.57448 13.5802 7.04915C13.5802 4.52383 11.5258 2.46942 9.00049 2.46942ZM9.00049 11.2625C6.67716 11.2625 4.78713 9.37248 4.78713 7.04915C4.78713 4.72583 6.67716 2.8358 9.00049 2.8358C11.3238 2.8358 13.2138 4.72583 13.2138 7.04915C13.2138 9.37248 11.3238 11.2625 9.00049 11.2625Z" />
      <path d="M15.0457 7.04915C15.0457 3.71584 12.3338 1.00391 9.00049 1.00391C5.66717 1.00391 2.95524 3.71584 2.95524 7.04915C2.95524 8.92233 3.81159 10.5991 5.15351 11.709V16.8193C5.15351 16.8837 5.18722 16.9433 5.24242 16.9766C5.27149 16.9936 5.30397 17.0024 5.3367 17.0024C5.36626 17.0024 5.39606 16.9954 5.42292 16.9809L9.00049 15.0726L12.5781 16.9809C12.6049 16.9954 12.6347 17.0024 12.6643 17.0024C12.697 17.0024 12.7295 16.9936 12.7586 16.9766C12.8138 16.9433 12.8475 16.8837 12.8475 16.8193V11.709C14.1891 10.5991 15.0457 8.92233 15.0457 7.04915ZM5.51989 16.5139V11.9872C6.45807 12.6506 7.59213 13.0529 8.8173 13.0898V14.7551L5.51989 16.5139ZM12.4811 16.5139L9.18368 14.7551V13.0898C10.4088 13.0531 11.5429 12.6506 12.4811 11.9872V16.5139ZM9.00049 12.728C5.86917 12.728 3.32162 10.1805 3.32162 7.04915C3.32162 3.91784 5.86917 1.37028 9.00049 1.37028C12.1318 1.37028 14.6794 3.91784 14.6794 7.04915C14.6794 10.1805 12.1318 12.728 9.00049 12.728Z" />
      <path d="M1.68122 7.58773C1.68415 7.58773 1.68708 7.58773 1.69001 7.58749C1.79113 7.58285 1.86905 7.49687 1.86417 7.39575C1.85855 7.28119 1.85586 7.16566 1.85586 7.04915C1.85586 5.83082 2.16777 4.62812 2.75788 3.57124C2.80722 3.48307 2.77572 3.37145 2.6873 3.32186C2.59937 3.27301 2.48725 3.30428 2.43791 3.3927C1.81727 4.50404 1.48924 5.76854 1.48924 7.04915C1.48924 7.17128 1.49217 7.29267 1.49803 7.41333C1.50316 7.51152 1.58401 7.58773 1.68122 7.58773Z" />
      <path d="M3.13599 2.83824C3.19168 2.83824 3.24663 2.81284 3.28278 2.76497C3.4374 2.55833 3.60569 2.35804 3.78228 2.16948C3.8514 2.09547 3.84774 1.97969 3.77373 1.91033C3.69972 1.84145 3.58395 1.84511 3.51482 1.91888C3.32895 2.11721 3.15235 2.32824 2.98968 2.54489C2.92886 2.62599 2.94547 2.74079 3.02632 2.80136C3.05929 2.82627 3.09764 2.83824 3.13599 2.83824Z" />
      <path d="M15.5953 14.1948C15.1576 14.1948 14.8015 13.8386 14.8015 13.4009C14.8015 13.2998 14.7194 13.2177 14.6183 13.2177C14.5172 13.2177 14.4351 13.2998 14.4351 13.4009C14.4351 13.8386 14.079 14.1948 13.6413 14.1948C13.5402 14.1948 13.4581 14.2768 13.4581 14.3779C13.4581 14.4791 13.5402 14.5611 13.6413 14.5611C14.079 14.5611 14.4351 14.9173 14.4351 15.355C14.4351 15.4561 14.5172 15.5381 14.6183 15.5381C14.7194 15.5381 14.8015 15.4561 14.8015 15.355C14.8015 14.9173 15.1576 14.5611 15.5953 14.5611C15.6964 14.5611 15.7785 14.4791 15.7785 14.3779C15.7785 14.2768 15.6967 14.1948 15.5953 14.1948ZM14.6183 14.7299C14.5277 14.5887 14.4075 14.4686 14.2663 14.3779C14.4075 14.2873 14.5277 14.1672 14.6183 14.026C14.7089 14.1672 14.8291 14.2873 14.9703 14.3779C14.8293 14.4686 14.7089 14.5887 14.6183 14.7299Z" />
      <path d="M16.8168 10.042C16.3791 10.042 16.023 9.68586 16.023 9.24816C16.023 9.14704 15.9409 9.06497 15.8398 9.06497C15.7387 9.06497 15.6566 9.14704 15.6566 9.24816C15.6566 9.68586 15.3005 10.042 14.8628 10.042C14.7617 10.042 14.6796 10.124 14.6796 10.2252C14.6796 10.3263 14.7617 10.4084 14.8628 10.4084C15.3005 10.4084 15.6566 10.7645 15.6566 11.2022C15.6566 11.3033 15.7387 11.3854 15.8398 11.3854C15.9409 11.3854 16.023 11.3033 16.023 11.2022C16.023 10.7645 16.3791 10.4084 16.8168 10.4084C16.9179 10.4084 17 10.3263 17 10.2252C17 10.124 16.9179 10.042 16.8168 10.042ZM15.8398 10.5771C15.7492 10.436 15.629 10.3158 15.4878 10.2252C15.629 10.1346 15.7492 10.0144 15.8398 9.8732C15.9304 10.0144 16.0506 10.1346 16.1918 10.2252C16.0506 10.3158 15.9304 10.436 15.8398 10.5771Z" />
      <path d="M13.6413 2.34729C14.079 2.34729 14.4351 2.70341 14.4351 3.14112C14.4351 3.24224 14.5172 3.3243 14.6183 3.3243C14.7194 3.3243 14.8015 3.24224 14.8015 3.14112C14.8015 2.70341 15.1576 2.34729 15.5953 2.34729C15.6964 2.34729 15.7785 2.26523 15.7785 2.16411C15.7785 2.06298 15.6964 1.98092 15.5953 1.98092C15.1576 1.98092 14.8015 1.6248 14.8015 1.1871C14.8015 1.08598 14.7194 1.00391 14.6183 1.00391C14.5172 1.00391 14.4351 1.08598 14.4351 1.1871C14.4351 1.6248 14.079 1.98092 13.6413 1.98092C13.5402 1.98092 13.4581 2.06298 13.4581 2.16411C13.4581 2.26523 13.5402 2.34729 13.6413 2.34729ZM14.6183 1.81214C14.7089 1.95332 14.8291 2.07349 14.9703 2.16411C14.8291 2.25472 14.7089 2.3749 14.6183 2.51607C14.5277 2.3749 14.4075 2.25472 14.2663 2.16411C14.4075 2.07349 14.5277 1.95332 14.6183 1.81214Z" />
      <path d="M2.34339 11.2022C2.34339 10.7645 2.69951 10.4084 3.13721 10.4084C3.23833 10.4084 3.3204 10.3263 3.3204 10.2252C3.3204 10.124 3.23833 10.042 3.13721 10.042C2.69951 10.042 2.34339 9.68586 2.34339 9.24816C2.34339 9.14704 2.26132 9.06497 2.1602 9.06497C2.05908 9.06497 1.97701 9.14704 1.97701 9.24816C1.97701 9.68586 1.62089 10.042 1.18319 10.042C1.08207 10.042 1 10.124 1 10.2252C1 10.3263 1.08207 10.4084 1.18319 10.4084C1.62089 10.4084 1.97701 10.7645 1.97701 11.2022C1.97701 11.3033 2.05908 11.3854 2.1602 11.3854C2.26132 11.3854 2.34339 11.3033 2.34339 11.2022ZM1.80848 10.2252C1.94941 10.1346 2.06983 10.0144 2.1602 9.8732C2.25082 10.0141 2.37099 10.1343 2.51192 10.2252C2.37099 10.3158 2.25057 10.436 2.1602 10.5769C2.06958 10.436 1.94941 10.3158 1.80848 10.2252Z" />
      <path d="M4.35994 15.6603C3.92224 15.6603 3.56612 15.3044 3.56612 14.8667C3.56612 14.7656 3.48405 14.6835 3.38293 14.6835C3.28181 14.6835 3.19974 14.7656 3.19974 14.8667C3.19974 15.3042 2.84362 15.6603 2.40592 15.6605C2.3048 15.6605 2.22273 15.7426 2.22273 15.8437C2.22273 15.9448 2.3048 16.0269 2.40592 16.0269C2.84362 16.0269 3.19974 16.383 3.19974 16.8207C3.19974 16.9218 3.28181 17.0039 3.38293 17.0039C3.48405 17.0039 3.56612 16.9218 3.56612 16.8207C3.56612 16.383 3.92224 16.0269 4.36018 16.0267C4.4613 16.0267 4.54313 15.9446 4.54313 15.8435C4.54313 15.7423 4.46106 15.6603 4.35994 15.6603ZM3.38293 16.1954C3.29231 16.0545 3.17214 15.9341 3.03096 15.8437C3.17214 15.7531 3.29231 15.6329 3.38293 15.4917C3.47354 15.6329 3.59372 15.7531 3.73489 15.8437C3.59372 15.9341 3.47354 16.0545 3.38293 16.1954Z" />
      <path d="M11.9992 6.07703C11.9777 6.01059 11.9203 5.96223 11.8514 5.95222L9.9946 5.68207L9.16414 3.99966C9.10259 3.8746 8.89717 3.8746 8.83562 3.99966L8.00516 5.68207L6.14835 5.95197C6.07923 5.96199 6.02207 6.01035 6.00058 6.07678C5.97909 6.14298 5.99692 6.21576 6.04699 6.26437L7.39062 7.57381L7.07334 9.42304C7.06161 9.49168 7.0897 9.56129 7.14612 9.60208C7.17812 9.62553 7.21598 9.63725 7.25384 9.63725C7.28315 9.63725 7.31222 9.63041 7.33908 9.61625L9 8.74353L10.6607 9.61674C10.7225 9.64922 10.7972 9.64409 10.8536 9.60257C10.9101 9.56178 10.9384 9.49217 10.9264 9.42353L10.6091 7.5743L11.9528 6.26486C12.0028 6.21601 12.0207 6.14322 11.9992 6.07703ZM10.2845 7.37914C10.2413 7.42115 10.2218 7.48197 10.2318 7.54132L10.5024 9.11968L9.085 8.37422C9.03151 8.34614 8.968 8.34614 8.91451 8.37422L7.49712 9.11968L7.76775 7.54132C7.77801 7.48197 7.75822 7.42115 7.71499 7.37914L6.56822 6.26144L8.15293 6.03111C8.21253 6.02256 8.26431 5.98519 8.29069 5.93097L9 4.49476L9.70882 5.93072C9.73544 5.98495 9.78698 6.02232 9.84658 6.03087L11.4313 6.2612L10.2845 7.37914Z" />
    </mask>
    <path
      d="M9.00049 2.46942C6.47516 2.46942 4.42076 4.52383 4.42076 7.04915C4.42076 9.57448 6.47516 11.6289 9.00049 11.6289C11.5258 11.6289 13.5802 9.57448 13.5802 7.04915C13.5802 4.52383 11.5258 2.46942 9.00049 2.46942ZM9.00049 11.2625C6.67716 11.2625 4.78713 9.37248 4.78713 7.04915C4.78713 4.72583 6.67716 2.8358 9.00049 2.8358C11.3238 2.8358 13.2138 4.72583 13.2138 7.04915C13.2138 9.37248 11.3238 11.2625 9.00049 11.2625Z"
      fill={fill}
    />
    <path
      d="M15.0457 7.04915C15.0457 3.71584 12.3338 1.00391 9.00049 1.00391C5.66717 1.00391 2.95524 3.71584 2.95524 7.04915C2.95524 8.92233 3.81159 10.5991 5.15351 11.709V16.8193C5.15351 16.8837 5.18722 16.9433 5.24242 16.9766C5.27149 16.9936 5.30397 17.0024 5.3367 17.0024C5.36626 17.0024 5.39606 16.9954 5.42292 16.9809L9.00049 15.0726L12.5781 16.9809C12.6049 16.9954 12.6347 17.0024 12.6643 17.0024C12.697 17.0024 12.7295 16.9936 12.7586 16.9766C12.8138 16.9433 12.8475 16.8837 12.8475 16.8193V11.709C14.1891 10.5991 15.0457 8.92233 15.0457 7.04915ZM5.51989 16.5139V11.9872C6.45807 12.6506 7.59213 13.0529 8.8173 13.0898V14.7551L5.51989 16.5139ZM12.4811 16.5139L9.18368 14.7551V13.0898C10.4088 13.0531 11.5429 12.6506 12.4811 11.9872V16.5139ZM9.00049 12.728C5.86917 12.728 3.32162 10.1805 3.32162 7.04915C3.32162 3.91784 5.86917 1.37028 9.00049 1.37028C12.1318 1.37028 14.6794 3.91784 14.6794 7.04915C14.6794 10.1805 12.1318 12.728 9.00049 12.728Z"
      fill={fill}
    />
    <path
      d="M1.68122 7.58773C1.68415 7.58773 1.68708 7.58773 1.69001 7.58749C1.79113 7.58285 1.86905 7.49687 1.86417 7.39575C1.85855 7.28119 1.85586 7.16566 1.85586 7.04915C1.85586 5.83082 2.16777 4.62812 2.75788 3.57124C2.80722 3.48307 2.77572 3.37145 2.6873 3.32186C2.59937 3.27301 2.48725 3.30428 2.43791 3.3927C1.81727 4.50404 1.48924 5.76854 1.48924 7.04915C1.48924 7.17128 1.49217 7.29267 1.49803 7.41333C1.50316 7.51152 1.58401 7.58773 1.68122 7.58773Z"
      fill={fill}
    />
    <path
      d="M3.13599 2.83824C3.19168 2.83824 3.24663 2.81284 3.28278 2.76497C3.4374 2.55833 3.60569 2.35804 3.78228 2.16948C3.8514 2.09547 3.84774 1.97969 3.77373 1.91033C3.69972 1.84145 3.58395 1.84511 3.51482 1.91888C3.32895 2.11721 3.15235 2.32824 2.98968 2.54489C2.92886 2.62599 2.94547 2.74079 3.02632 2.80136C3.05929 2.82627 3.09764 2.83824 3.13599 2.83824Z"
      fill={fill}
    />
    <path
      d="M15.5953 14.1948C15.1576 14.1948 14.8015 13.8386 14.8015 13.4009C14.8015 13.2998 14.7194 13.2177 14.6183 13.2177C14.5172 13.2177 14.4351 13.2998 14.4351 13.4009C14.4351 13.8386 14.079 14.1948 13.6413 14.1948C13.5402 14.1948 13.4581 14.2768 13.4581 14.3779C13.4581 14.4791 13.5402 14.5611 13.6413 14.5611C14.079 14.5611 14.4351 14.9173 14.4351 15.355C14.4351 15.4561 14.5172 15.5381 14.6183 15.5381C14.7194 15.5381 14.8015 15.4561 14.8015 15.355C14.8015 14.9173 15.1576 14.5611 15.5953 14.5611C15.6964 14.5611 15.7785 14.4791 15.7785 14.3779C15.7785 14.2768 15.6967 14.1948 15.5953 14.1948ZM14.6183 14.7299C14.5277 14.5887 14.4075 14.4686 14.2663 14.3779C14.4075 14.2873 14.5277 14.1672 14.6183 14.026C14.7089 14.1672 14.8291 14.2873 14.9703 14.3779C14.8293 14.4686 14.7089 14.5887 14.6183 14.7299Z"
      fill={fill}
    />
    <path
      d="M16.8168 10.042C16.3791 10.042 16.023 9.68586 16.023 9.24816C16.023 9.14704 15.9409 9.06497 15.8398 9.06497C15.7387 9.06497 15.6566 9.14704 15.6566 9.24816C15.6566 9.68586 15.3005 10.042 14.8628 10.042C14.7617 10.042 14.6796 10.124 14.6796 10.2252C14.6796 10.3263 14.7617 10.4084 14.8628 10.4084C15.3005 10.4084 15.6566 10.7645 15.6566 11.2022C15.6566 11.3033 15.7387 11.3854 15.8398 11.3854C15.9409 11.3854 16.023 11.3033 16.023 11.2022C16.023 10.7645 16.3791 10.4084 16.8168 10.4084C16.9179 10.4084 17 10.3263 17 10.2252C17 10.124 16.9179 10.042 16.8168 10.042ZM15.8398 10.5771C15.7492 10.436 15.629 10.3158 15.4878 10.2252C15.629 10.1346 15.7492 10.0144 15.8398 9.8732C15.9304 10.0144 16.0506 10.1346 16.1918 10.2252C16.0506 10.3158 15.9304 10.436 15.8398 10.5771Z"
      fill={fill}
    />
    <path
      d="M13.6413 2.34729C14.079 2.34729 14.4351 2.70341 14.4351 3.14112C14.4351 3.24224 14.5172 3.3243 14.6183 3.3243C14.7194 3.3243 14.8015 3.24224 14.8015 3.14112C14.8015 2.70341 15.1576 2.34729 15.5953 2.34729C15.6964 2.34729 15.7785 2.26523 15.7785 2.16411C15.7785 2.06298 15.6964 1.98092 15.5953 1.98092C15.1576 1.98092 14.8015 1.6248 14.8015 1.1871C14.8015 1.08598 14.7194 1.00391 14.6183 1.00391C14.5172 1.00391 14.4351 1.08598 14.4351 1.1871C14.4351 1.6248 14.079 1.98092 13.6413 1.98092C13.5402 1.98092 13.4581 2.06298 13.4581 2.16411C13.4581 2.26523 13.5402 2.34729 13.6413 2.34729ZM14.6183 1.81214C14.7089 1.95332 14.8291 2.07349 14.9703 2.16411C14.8291 2.25472 14.7089 2.3749 14.6183 2.51607C14.5277 2.3749 14.4075 2.25472 14.2663 2.16411C14.4075 2.07349 14.5277 1.95332 14.6183 1.81214Z"
      fill={fill}
    />
    <path
      d="M2.34339 11.2022C2.34339 10.7645 2.69951 10.4084 3.13721 10.4084C3.23833 10.4084 3.3204 10.3263 3.3204 10.2252C3.3204 10.124 3.23833 10.042 3.13721 10.042C2.69951 10.042 2.34339 9.68586 2.34339 9.24816C2.34339 9.14704 2.26132 9.06497 2.1602 9.06497C2.05908 9.06497 1.97701 9.14704 1.97701 9.24816C1.97701 9.68586 1.62089 10.042 1.18319 10.042C1.08207 10.042 1 10.124 1 10.2252C1 10.3263 1.08207 10.4084 1.18319 10.4084C1.62089 10.4084 1.97701 10.7645 1.97701 11.2022C1.97701 11.3033 2.05908 11.3854 2.1602 11.3854C2.26132 11.3854 2.34339 11.3033 2.34339 11.2022ZM1.80848 10.2252C1.94941 10.1346 2.06983 10.0144 2.1602 9.8732C2.25082 10.0141 2.37099 10.1343 2.51192 10.2252C2.37099 10.3158 2.25057 10.436 2.1602 10.5769C2.06958 10.436 1.94941 10.3158 1.80848 10.2252Z"
      fill={fill}
    />
    <path
      d="M4.35994 15.6603C3.92224 15.6603 3.56612 15.3044 3.56612 14.8667C3.56612 14.7656 3.48405 14.6835 3.38293 14.6835C3.28181 14.6835 3.19974 14.7656 3.19974 14.8667C3.19974 15.3042 2.84362 15.6603 2.40592 15.6605C2.3048 15.6605 2.22273 15.7426 2.22273 15.8437C2.22273 15.9448 2.3048 16.0269 2.40592 16.0269C2.84362 16.0269 3.19974 16.383 3.19974 16.8207C3.19974 16.9218 3.28181 17.0039 3.38293 17.0039C3.48405 17.0039 3.56612 16.9218 3.56612 16.8207C3.56612 16.383 3.92224 16.0269 4.36018 16.0267C4.4613 16.0267 4.54313 15.9446 4.54313 15.8435C4.54313 15.7423 4.46106 15.6603 4.35994 15.6603ZM3.38293 16.1954C3.29231 16.0545 3.17214 15.9341 3.03096 15.8437C3.17214 15.7531 3.29231 15.6329 3.38293 15.4917C3.47354 15.6329 3.59372 15.7531 3.73489 15.8437C3.59372 15.9341 3.47354 16.0545 3.38293 16.1954Z"
      fill={fill}
    />
    <path
      d="M11.9992 6.07703C11.9777 6.01059 11.9203 5.96223 11.8514 5.95222L9.9946 5.68207L9.16414 3.99966C9.10259 3.8746 8.89717 3.8746 8.83562 3.99966L8.00516 5.68207L6.14835 5.95197C6.07923 5.96199 6.02207 6.01035 6.00058 6.07678C5.97909 6.14298 5.99692 6.21576 6.04699 6.26437L7.39062 7.57381L7.07334 9.42304C7.06161 9.49168 7.0897 9.56129 7.14612 9.60208C7.17812 9.62553 7.21598 9.63725 7.25384 9.63725C7.28315 9.63725 7.31222 9.63041 7.33908 9.61625L9 8.74353L10.6607 9.61674C10.7225 9.64922 10.7972 9.64409 10.8536 9.60257C10.9101 9.56178 10.9384 9.49217 10.9264 9.42353L10.6091 7.5743L11.9528 6.26486C12.0028 6.21601 12.0207 6.14322 11.9992 6.07703ZM10.2845 7.37914C10.2413 7.42115 10.2218 7.48197 10.2318 7.54132L10.5024 9.11968L9.085 8.37422C9.03151 8.34614 8.968 8.34614 8.91451 8.37422L7.49712 9.11968L7.76775 7.54132C7.77801 7.48197 7.75822 7.42115 7.71499 7.37914L6.56822 6.26144L8.15293 6.03111C8.21253 6.02256 8.26431 5.98519 8.29069 5.93097L9 4.49476L9.70882 5.93072C9.73544 5.98495 9.78698 6.02232 9.84658 6.03087L11.4313 6.2612L10.2845 7.37914Z"
      fill={fill}
    />
    <path
      d="M9.00049 2.46942C6.47516 2.46942 4.42076 4.52383 4.42076 7.04915C4.42076 9.57448 6.47516 11.6289 9.00049 11.6289C11.5258 11.6289 13.5802 9.57448 13.5802 7.04915C13.5802 4.52383 11.5258 2.46942 9.00049 2.46942ZM9.00049 11.2625C6.67716 11.2625 4.78713 9.37248 4.78713 7.04915C4.78713 4.72583 6.67716 2.8358 9.00049 2.8358C11.3238 2.8358 13.2138 4.72583 13.2138 7.04915C13.2138 9.37248 11.3238 11.2625 9.00049 11.2625Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M15.0457 7.04915C15.0457 3.71584 12.3338 1.00391 9.00049 1.00391C5.66717 1.00391 2.95524 3.71584 2.95524 7.04915C2.95524 8.92233 3.81159 10.5991 5.15351 11.709V16.8193C5.15351 16.8837 5.18722 16.9433 5.24242 16.9766C5.27149 16.9936 5.30397 17.0024 5.3367 17.0024C5.36626 17.0024 5.39606 16.9954 5.42292 16.9809L9.00049 15.0726L12.5781 16.9809C12.6049 16.9954 12.6347 17.0024 12.6643 17.0024C12.697 17.0024 12.7295 16.9936 12.7586 16.9766C12.8138 16.9433 12.8475 16.8837 12.8475 16.8193V11.709C14.1891 10.5991 15.0457 8.92233 15.0457 7.04915ZM5.51989 16.5139V11.9872C6.45807 12.6506 7.59213 13.0529 8.8173 13.0898V14.7551L5.51989 16.5139ZM12.4811 16.5139L9.18368 14.7551V13.0898C10.4088 13.0531 11.5429 12.6506 12.4811 11.9872V16.5139ZM9.00049 12.728C5.86917 12.728 3.32162 10.1805 3.32162 7.04915C3.32162 3.91784 5.86917 1.37028 9.00049 1.37028C12.1318 1.37028 14.6794 3.91784 14.6794 7.04915C14.6794 10.1805 12.1318 12.728 9.00049 12.728Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M1.68122 7.58773C1.68415 7.58773 1.68708 7.58773 1.69001 7.58749C1.79113 7.58285 1.86905 7.49687 1.86417 7.39575C1.85855 7.28119 1.85586 7.16566 1.85586 7.04915C1.85586 5.83082 2.16777 4.62812 2.75788 3.57124C2.80722 3.48307 2.77572 3.37145 2.6873 3.32186C2.59937 3.27301 2.48725 3.30428 2.43791 3.3927C1.81727 4.50404 1.48924 5.76854 1.48924 7.04915C1.48924 7.17128 1.49217 7.29267 1.49803 7.41333C1.50316 7.51152 1.58401 7.58773 1.68122 7.58773Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M3.13599 2.83824C3.19168 2.83824 3.24663 2.81284 3.28278 2.76497C3.4374 2.55833 3.60569 2.35804 3.78228 2.16948C3.8514 2.09547 3.84774 1.97969 3.77373 1.91033C3.69972 1.84145 3.58395 1.84511 3.51482 1.91888C3.32895 2.11721 3.15235 2.32824 2.98968 2.54489C2.92886 2.62599 2.94547 2.74079 3.02632 2.80136C3.05929 2.82627 3.09764 2.83824 3.13599 2.83824Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M15.5953 14.1948C15.1576 14.1948 14.8015 13.8386 14.8015 13.4009C14.8015 13.2998 14.7194 13.2177 14.6183 13.2177C14.5172 13.2177 14.4351 13.2998 14.4351 13.4009C14.4351 13.8386 14.079 14.1948 13.6413 14.1948C13.5402 14.1948 13.4581 14.2768 13.4581 14.3779C13.4581 14.4791 13.5402 14.5611 13.6413 14.5611C14.079 14.5611 14.4351 14.9173 14.4351 15.355C14.4351 15.4561 14.5172 15.5381 14.6183 15.5381C14.7194 15.5381 14.8015 15.4561 14.8015 15.355C14.8015 14.9173 15.1576 14.5611 15.5953 14.5611C15.6964 14.5611 15.7785 14.4791 15.7785 14.3779C15.7785 14.2768 15.6967 14.1948 15.5953 14.1948ZM14.6183 14.7299C14.5277 14.5887 14.4075 14.4686 14.2663 14.3779C14.4075 14.2873 14.5277 14.1672 14.6183 14.026C14.7089 14.1672 14.8291 14.2873 14.9703 14.3779C14.8293 14.4686 14.7089 14.5887 14.6183 14.7299Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M16.8168 10.042C16.3791 10.042 16.023 9.68586 16.023 9.24816C16.023 9.14704 15.9409 9.06497 15.8398 9.06497C15.7387 9.06497 15.6566 9.14704 15.6566 9.24816C15.6566 9.68586 15.3005 10.042 14.8628 10.042C14.7617 10.042 14.6796 10.124 14.6796 10.2252C14.6796 10.3263 14.7617 10.4084 14.8628 10.4084C15.3005 10.4084 15.6566 10.7645 15.6566 11.2022C15.6566 11.3033 15.7387 11.3854 15.8398 11.3854C15.9409 11.3854 16.023 11.3033 16.023 11.2022C16.023 10.7645 16.3791 10.4084 16.8168 10.4084C16.9179 10.4084 17 10.3263 17 10.2252C17 10.124 16.9179 10.042 16.8168 10.042ZM15.8398 10.5771C15.7492 10.436 15.629 10.3158 15.4878 10.2252C15.629 10.1346 15.7492 10.0144 15.8398 9.8732C15.9304 10.0144 16.0506 10.1346 16.1918 10.2252C16.0506 10.3158 15.9304 10.436 15.8398 10.5771Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M13.6413 2.34729C14.079 2.34729 14.4351 2.70341 14.4351 3.14112C14.4351 3.24224 14.5172 3.3243 14.6183 3.3243C14.7194 3.3243 14.8015 3.24224 14.8015 3.14112C14.8015 2.70341 15.1576 2.34729 15.5953 2.34729C15.6964 2.34729 15.7785 2.26523 15.7785 2.16411C15.7785 2.06298 15.6964 1.98092 15.5953 1.98092C15.1576 1.98092 14.8015 1.6248 14.8015 1.1871C14.8015 1.08598 14.7194 1.00391 14.6183 1.00391C14.5172 1.00391 14.4351 1.08598 14.4351 1.1871C14.4351 1.6248 14.079 1.98092 13.6413 1.98092C13.5402 1.98092 13.4581 2.06298 13.4581 2.16411C13.4581 2.26523 13.5402 2.34729 13.6413 2.34729ZM14.6183 1.81214C14.7089 1.95332 14.8291 2.07349 14.9703 2.16411C14.8291 2.25472 14.7089 2.3749 14.6183 2.51607C14.5277 2.3749 14.4075 2.25472 14.2663 2.16411C14.4075 2.07349 14.5277 1.95332 14.6183 1.81214Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M2.34339 11.2022C2.34339 10.7645 2.69951 10.4084 3.13721 10.4084C3.23833 10.4084 3.3204 10.3263 3.3204 10.2252C3.3204 10.124 3.23833 10.042 3.13721 10.042C2.69951 10.042 2.34339 9.68586 2.34339 9.24816C2.34339 9.14704 2.26132 9.06497 2.1602 9.06497C2.05908 9.06497 1.97701 9.14704 1.97701 9.24816C1.97701 9.68586 1.62089 10.042 1.18319 10.042C1.08207 10.042 1 10.124 1 10.2252C1 10.3263 1.08207 10.4084 1.18319 10.4084C1.62089 10.4084 1.97701 10.7645 1.97701 11.2022C1.97701 11.3033 2.05908 11.3854 2.1602 11.3854C2.26132 11.3854 2.34339 11.3033 2.34339 11.2022ZM1.80848 10.2252C1.94941 10.1346 2.06983 10.0144 2.1602 9.8732C2.25082 10.0141 2.37099 10.1343 2.51192 10.2252C2.37099 10.3158 2.25057 10.436 2.1602 10.5769C2.06958 10.436 1.94941 10.3158 1.80848 10.2252Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M4.35994 15.6603C3.92224 15.6603 3.56612 15.3044 3.56612 14.8667C3.56612 14.7656 3.48405 14.6835 3.38293 14.6835C3.28181 14.6835 3.19974 14.7656 3.19974 14.8667C3.19974 15.3042 2.84362 15.6603 2.40592 15.6605C2.3048 15.6605 2.22273 15.7426 2.22273 15.8437C2.22273 15.9448 2.3048 16.0269 2.40592 16.0269C2.84362 16.0269 3.19974 16.383 3.19974 16.8207C3.19974 16.9218 3.28181 17.0039 3.38293 17.0039C3.48405 17.0039 3.56612 16.9218 3.56612 16.8207C3.56612 16.383 3.92224 16.0269 4.36018 16.0267C4.4613 16.0267 4.54313 15.9446 4.54313 15.8435C4.54313 15.7423 4.46106 15.6603 4.35994 15.6603ZM3.38293 16.1954C3.29231 16.0545 3.17214 15.9341 3.03096 15.8437C3.17214 15.7531 3.29231 15.6329 3.38293 15.4917C3.47354 15.6329 3.59372 15.7531 3.73489 15.8437C3.59372 15.9341 3.47354 16.0545 3.38293 16.1954Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
    <path
      d="M11.9992 6.07703C11.9777 6.01059 11.9203 5.96223 11.8514 5.95222L9.9946 5.68207L9.16414 3.99966C9.10259 3.8746 8.89717 3.8746 8.83562 3.99966L8.00516 5.68207L6.14835 5.95197C6.07923 5.96199 6.02207 6.01035 6.00058 6.07678C5.97909 6.14298 5.99692 6.21576 6.04699 6.26437L7.39062 7.57381L7.07334 9.42304C7.06161 9.49168 7.0897 9.56129 7.14612 9.60208C7.17812 9.62553 7.21598 9.63725 7.25384 9.63725C7.28315 9.63725 7.31222 9.63041 7.33908 9.61625L9 8.74353L10.6607 9.61674C10.7225 9.64922 10.7972 9.64409 10.8536 9.60257C10.9101 9.56178 10.9384 9.49217 10.9264 9.42353L10.6091 7.5743L11.9528 6.26486C12.0028 6.21601 12.0207 6.14322 11.9992 6.07703ZM10.2845 7.37914C10.2413 7.42115 10.2218 7.48197 10.2318 7.54132L10.5024 9.11968L9.085 8.37422C9.03151 8.34614 8.968 8.34614 8.91451 8.37422L7.49712 9.11968L7.76775 7.54132C7.77801 7.48197 7.75822 7.42115 7.71499 7.37914L6.56822 6.26144L8.15293 6.03111C8.21253 6.02256 8.26431 5.98519 8.29069 5.93097L9 4.49476L9.70882 5.93072C9.73544 5.98495 9.78698 6.02232 9.84658 6.03087L11.4313 6.2612L10.2845 7.37914Z"
      stroke={stroke}
      strokeWidth="0.244252"
      mask="url(#path-1-outside-1_13567_27372)"
    />
  </svg>
);

export { BadgeIcon };
