import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const SparklesIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 20"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M10.3853 16.4438C10.3194 16.4438 10.2549 16.4245 10.1997 16.3883C10.1446 16.3522 10.1011 16.3007 10.0748 16.2403L9.33009 14.5319C8.98301 13.7329 8.34553 13.0954 7.54649 12.7485L5.8381 12.0038C5.77741 11.9775 5.72574 11.934 5.68943 11.8788C5.65313 11.8235 5.63379 11.7588 5.63379 11.6927C5.63379 11.6265 5.65313 11.5618 5.68943 11.5066C5.72574 11.4513 5.77741 11.4078 5.8381 11.3816L7.54649 10.6369C8.34534 10.29 8.98279 9.65298 9.33009 8.85434L10.0748 7.1463C10.1012 7.08597 10.1446 7.03464 10.1997 6.9986C10.2549 6.96255 10.3193 6.94336 10.3852 6.94336C10.451 6.94336 10.5155 6.96255 10.5706 6.9986C10.6257 7.03464 10.6691 7.08597 10.6956 7.1463L11.4401 8.85488C11.7874 9.65369 12.425 10.2909 13.224 10.6378L14.9324 11.3824C14.9931 11.4087 15.0448 11.4522 15.0811 11.5075C15.1174 11.5627 15.1367 11.6274 15.1367 11.6936C15.1367 11.7597 15.1174 11.8244 15.0811 11.8796C15.0448 11.9349 14.9931 11.9784 14.9324 12.0047L13.224 12.7493C12.4252 13.0962 11.7879 13.7333 11.4406 14.5319L10.6956 16.2403C10.6692 16.3006 10.6258 16.352 10.5707 16.3882C10.5156 16.4243 10.4512 16.4437 10.3853 16.4438Z"
      fill={fill}
    />
    <path
      d="M6.96135 6.29427C6.90848 6.29424 6.85677 6.27883 6.81251 6.24992C6.76825 6.221 6.73337 6.17983 6.71211 6.13143L6.2643 5.10373C6.06236 4.63877 5.69142 4.26783 5.22646 4.06589L4.19894 3.61808C4.15054 3.59692 4.10936 3.5621 4.08045 3.5179C4.05153 3.47369 4.03613 3.42202 4.03613 3.3692C4.03613 3.31638 4.05153 3.2647 4.08045 3.2205C4.10936 3.17629 4.15054 3.14148 4.19894 3.12032L5.22664 2.67233C5.69165 2.47016 6.06253 2.09896 6.2643 1.63378L6.71211 0.605899C6.73333 0.557566 6.76817 0.516457 6.81236 0.487594C6.85656 0.45873 6.90821 0.443359 6.96099 0.443359C7.01378 0.443359 7.06542 0.45873 7.10962 0.487594C7.15382 0.516457 7.18865 0.557566 7.20987 0.605899L7.65786 1.6336C7.85985 2.09853 8.23078 2.46945 8.6957 2.67144L9.7234 3.11943C9.7718 3.14059 9.81297 3.1754 9.84189 3.21961C9.87081 3.26381 9.88621 3.31549 9.88621 3.36831C9.88621 3.42113 9.87081 3.47281 9.84189 3.51701C9.81297 3.56121 9.7718 3.59603 9.7234 3.61719L8.69605 4.06553C8.23122 4.26759 7.86037 4.6385 7.65839 5.10337L7.21041 6.13143C7.18922 6.17985 7.15437 6.22104 7.11014 6.24996C7.0659 6.27888 7.0142 6.29428 6.96135 6.29427Z"
      fill={fill}
    />
    <path
      d="M2.92426 10.2072C2.8803 10.2072 2.8373 10.1943 2.80052 10.1703C2.76374 10.1462 2.73477 10.1119 2.71716 10.0716L2.4073 9.36051C2.27132 9.04779 2.02177 8.7983 1.70901 8.6624L0.997919 8.3529C0.957613 8.33532 0.923313 8.30636 0.899225 8.26957C0.875136 8.23278 0.862305 8.18976 0.862305 8.14579C0.862305 8.10182 0.875136 8.0588 0.899225 8.02201C0.923313 7.98523 0.957613 7.95627 0.997919 7.93869L1.70901 7.62883C2.02173 7.49287 2.27127 7.2434 2.4073 6.93072L2.71716 6.21963C2.73475 6.17933 2.76372 6.14504 2.8005 6.12094C2.83728 6.09685 2.88029 6.08401 2.92426 6.08398C2.96826 6.08397 3.01131 6.0968 3.04813 6.1209C3.08495 6.14499 3.11394 6.1793 3.13155 6.21963L3.44123 6.93072C3.57709 7.24341 3.82661 7.49287 4.13934 7.62865L4.85043 7.93851C4.89074 7.95609 4.92504 7.98505 4.94912 8.02184C4.97321 8.05862 4.98604 8.10164 4.98604 8.14561C4.98604 8.18959 4.97321 8.2326 4.94912 8.26939C4.92504 8.30618 4.89074 8.33514 4.85043 8.35272L4.13934 8.6624C3.82658 8.79814 3.57705 9.04761 3.44123 9.36033L3.13155 10.0714C3.11395 10.1118 3.08496 10.1461 3.04815 10.1702C3.01133 10.1944 2.96828 10.2072 2.92426 10.2072Z"
      fill={fill}
    />
  </svg>
);

export { SparklesIcon };
