import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import {
  addDays,
  areIntervalsOverlapping,
  differenceInDays,
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { useSignals } from 'hooks/useSignals';
import { useZones } from 'hooks/useZones';
import isNil from 'lodash.isnil';
import { TDiscussionDraft } from 'shared/interfaces/discussion';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import { MeasurementAggregation } from 'shared/interfaces/measurement';

const getOverlappingTimeRange = (
  discussion: TDiscussionDraft,
  growthCycles: TGrowthCycle[]
) =>
  growthCycles.find(({ start_time, end_time }) =>
    areIntervalsOverlapping(
      { start: start_time, end: end_time },
      { start: discussion.startTime, end: discussion.endTime }
    )
  );

const computeLineChartTimeRange = (
  discussion: TDiscussionDraft,
  overlappingCycle: Maybe<TGrowthCycle>
) => {
  const { startTime, endTime } = discussion;
  const daysDifference = differenceInDays(endTime, startTime);
  const findInterval = (targetInterval: number) => {
    const remainingDays = targetInterval - daysDifference - 1;
    const extraDaysOnEachSide = Math.floor(remainingDays / 2);
    const extraDaysRemainder = remainingDays % 2;
    const intervalStart = startOfDay(subDays(startTime, extraDaysOnEachSide));
    const intervalEnd = endOfDay(
      addDays(endTime, extraDaysOnEachSide + extraDaysRemainder)
    );

    return {
      zonedStartTime: intervalStart.valueOf(),
      zonedEndTime: intervalEnd.valueOf(),
    };
  };

  if (discussion.area?.viewType === MeasurementAggregation.CONTINUOUS) {
    const interval = daysDifference <= 1 ? 1 : daysDifference <= 3 ? 3 : 7;
    return findInterval(interval);
  }

  if (overlappingCycle) {
    return {
      zonedStartTime: overlappingCycle.start_time,
      zonedEndTime: overlappingCycle.end_time.valueOf(),
    };
  }

  return {
    zonedStartTime: startTime.valueOf(),
    zonedEndTime: endTime.valueOf(),
  };
};

export const useNavigateToDiscussion = (skipNavigation = false) => {
  const {
    navigateToImageFeedDiscussion,
    navigateToLineChartDiscussion,
    navigateToHeatMapDiscussion,
    navigateToTimeLineDiscussion,
  } = useZoneDetailsPageURL();
  const { zones } = useZones();
  const { refetch } = useGrowthCycles();
  const { getSignal } = useSignals();

  return async (discussion: TDiscussionDraft) => {
    const { id, organizationCode } =
      zones.find((zone) => zone.id === discussion.zoneId) ?? zones[0]!;
    const growthCycles = await refetch(id);
    const overlappingCycle =
      getOverlappingTimeRange(discussion, growthCycles) ?? growthCycles.at(-1);

    if (isNil(overlappingCycle)) {
      return;
    }

    if (discussion.annotationType === 'single_image_annotation') {
      const section = discussion.area?.gridPosition;

      if (isNil(section)) {
        return;
      }

      return navigateToImageFeedDiscussion(
        {
          organizationCode,
          zoneId: discussion.zoneId,
          timeZone: discussion.timeZone,
          zonedMeasurementRunStartTime: discussion.startTime.valueOf(),
          discussionUid: discussion.uid,
          imageLocation: `${section.x}-${section.y}`,
          zonedStartTime: overlappingCycle.start_time.valueOf(),
          zonedEndTime: overlappingCycle.end_time.valueOf(),
        },
        undefined,
        skipNavigation
      );
    } else if (discussion.annotationType === 'time_range_annotation') {
      const { type: signalId } =
        getSignal(discussion.area!.signalIds![0]!) ?? {};

      if (isNil(signalId)) {
        return;
      }

      const { zonedStartTime, zonedEndTime } = computeLineChartTimeRange(
        discussion,
        overlappingCycle
      );

      return navigateToLineChartDiscussion(
        {
          organizationCode,
          zoneId: discussion.zoneId,
          timeZone: discussion.timeZone,
          discussionUid: discussion.uid,
          signalIds: [signalId],
          viewType: discussion.area?.viewType,
          zonedStartTime,
          zonedEndTime,
        },
        undefined,
        skipNavigation
      );
    } else if (
      discussion.annotationType === 'heatmap_annotation' ||
      discussion.annotationType === 'heatmap_aggregate_annotation'
    ) {
      const { type: signalId } =
        getSignal(discussion.area!.signalIds![0]!) ?? {};

      if (isNil(signalId)) {
        return;
      }

      const aggregationType =
        discussion.annotationType === 'heatmap_annotation'
          ? EAggregationTypes.SINGLE
          : EAggregationTypes.AGGREGATED;
      const gradientType =
        discussion.area?.gradientType || EGradientTypes.MICROCLIMATES;

      return navigateToHeatMapDiscussion(
        {
          organizationCode,
          zoneId: discussion.zoneId,
          timeZone: discussion.timeZone,
          zonedMeasurementStartTime: discussion.startTime.valueOf(),
          discussionUid: discussion.uid,
          signalIds: [signalId],
          aggregationType,
          gradientType,
          zonedStartTime: overlappingCycle.start_time.valueOf(),
          zonedEndTime: overlappingCycle.end_time.valueOf(),
        },
        undefined,
        skipNavigation
      );
    } else if (discussion.annotationType === 'event') {
      return navigateToTimeLineDiscussion(
        {
          organizationCode,
          zoneId: discussion.zoneId,
          timeZone: discussion.timeZone,
          zonedMeasurementRunStartTime: discussion.startTime.valueOf(),
          discussionUid: discussion.uid,
          zonedStartTime: overlappingCycle.start_time.valueOf(),
          zonedEndTime: overlappingCycle.end_time.valueOf(),
        },
        undefined,
        skipNavigation
      );
    }
  };
};
