import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from 'components/common/Typography';
import { Table } from 'components/table/Table';
import { ITableColumn, ITableRow } from 'components/table/types';
import { StatusCell } from 'components/user/StatusCell';
import { useAuth } from 'contexts/AuthProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { useUsers } from 'hooks/useUsers';
import { FC, useCallback, useMemo } from 'react';
import { TUser } from 'shared/interfaces/user';
import { getUserName } from 'shared/utils/user';

const ROWS_PER_PAGE = 10;
const MOBILE_ROWS_PER_PAGE = 5;

const useStyles = makeStyles(
  createStyles({
    mobileNameCell: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

interface IUserTableProps {
  page: number;
  users: TUser[];
  onClick: (userId: number) => void;
  onPageChange: (page: number) => void;
}

interface IUserTableRow extends ITableRow {
  id: number;
  name: string;
  role: string;
  phone: Maybe<string>;
  email: string;
  isActive: boolean;
  user: TUser;
}

export const UserTable: FC<IUserTableProps> = ({
  page,
  users,
  onClick,
  onPageChange,
}) => {
  const { isMobile } = useScreenSize();
  const classes = useStyles({ isMobile });
  const { userRoleTypes } = useUsers();
  const { currentlySelectedOrganization } = useAuth();

  const getUserRole = useCallback(
    (user: TUser) =>
      userRoleTypes
        .filter(({ code }) => {
          return user.user_organization_roles.find(
            (userOrganizationRole) =>
              userOrganizationRole.role.code === code &&
              (userOrganizationRole.organization_id ===
                currentlySelectedOrganization?.id ||
                userOrganizationRole.location?.organization_id ===
                  currentlySelectedOrganization?.id ||
                userOrganizationRole.zone?.location.organization_id ===
                  currentlySelectedOrganization?.id)
          );
        })
        .map((roleType) => {
          if (
            user.user_organization_roles.some(
              (userOrganizationRole) =>
                userOrganizationRole.organization_id ===
                currentlySelectedOrganization?.id
            )
          ) {
            return roleType.label;
          } else {
            const roleWithLocation = user.user_organization_roles.find(
              (userOrganizationRole) =>
                userOrganizationRole.location?.organization_id ===
                currentlySelectedOrganization?.id
            );

            if (roleWithLocation) {
              return `${roleType.label} (${roleWithLocation.location!.enumeration.description})`;
            }

            const roleWithZone = user.user_organization_roles.find(
              (userOrganizationRole) =>
                userOrganizationRole.zone?.location.organization_id ===
                currentlySelectedOrganization?.id
            );

            if (roleWithZone)
              return `${roleType.label} (${roleWithZone.zone!.enumeration.description})`;
          }
        })
        .join(', '),
    [userRoleTypes, currentlySelectedOrganization?.id]
  );

  const columns: ITableColumn<IUserTableRow>[] = [
    {
      field: 'name',
      renderHeader: () => <b>Name</b>,
      renderCell: (row) => <b>{row.name}</b>,
    },
    {
      field: 'role',
      renderHeader: () => <b>Role</b>,
    },
    {
      field: 'phone',
      renderHeader: () => <b>Phone</b>,
    },
    {
      field: 'email',
      renderHeader: () => <b>Email</b>,
    },
    {
      field: 'active',
      headerAlign: 'right',
      align: 'right',
      renderHeader: () => <b>Status</b>,
      renderCell: (row) => <StatusCell isActive={row.isActive} />,
    },
  ];

  const mobileColumns: ITableColumn<IUserTableRow>[] = [
    {
      field: 'name',
      header: 'Name',
      renderCell: (row) => (
        <div className={classes.mobileNameCell}>
          <Typography variant="body2">{row.name}</Typography>
          <Typography variant="caption">{getUserRole(row.user)}</Typography>
        </div>
      ),
    },
    {
      field: 'active',
      header: 'Status',
      headerAlign: 'right',
      align: 'right',
      renderCell: (row) => <StatusCell isActive={row.isActive} />,
    },
  ];

  const rows: IUserTableRow[] = useMemo(() => {
    return users.map((user) => ({
      id: user.id,
      name: getUserName(user),
      role: getUserRole(user),
      phone: user.phone_number,
      email: user.email,
      isActive: user.active,
      user: user,
    }));
  }, [users, getUserRole]);

  return (
    <Table<IUserTableRow>
      rows={rows}
      columns={isMobile ? mobileColumns : columns}
      pageSize={isMobile ? MOBILE_ROWS_PER_PAGE : ROWS_PER_PAGE}
      page={page}
      onRowClick={onClick}
      onPageChange={onPageChange}
    />
  );
};
