import { Button } from 'components/common/Button/Button';
import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { useImageFeedLabelsCounts } from 'components/image_feed/hooks/useImageFeedLabelsCounts';
import { useImageFeedURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { usePermissions } from 'hooks/usePermissions';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect, useRef } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import {
  EImageLabelCategory,
  EImageTypes,
  EImageViewTypes,
} from 'shared/interfaces/image';
import { capitalizeWord } from 'shared/utils/string';

const SPLIT_SYMBOL = '|||';

export const ImageFeedFilters = () => {
  const { labelsCounts } = useImageFeedLabelsCounts();
  const {
    setImageLabelCode,
    imageLabelCode,
    showCultivars,
    showComments,
    showGridInfo,
    setShowCultivars,
    setShowComments,
    setShowGridInfo,
    imageType,
    setImageType,
    imageViewType,
  } = useImageFeedURL();
  const { canViewNdvi, canEditCultivarAssignment } = usePermissions();

  const containerRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenSize();

  const handleClickLabel = (labelCode: EImageLabelCategory) => {
    if (imageLabelCode === labelCode) {
      setImageLabelCode(undefined);
    } else {
      setImageLabelCode(labelCode);
    }
  };

  const scrollTriggers = [
    imageLabelCode,
    isMobile && imageType,
    isMobile && showCultivars,
    isMobile && showGridInfo,
  ].join('-');

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo?.({
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollTriggers]);

  const handleImageTypeChange = () => {
    if (imageType === EImageTypes.NDVI) {
      setImageType(EImageTypes.RGB);
    } else {
      setImageType(EImageTypes.NDVI);
    }
  };

  const cultivarsButton = canEditCultivarAssignment &&
    imageViewType === EImageViewTypes.GRID && (
      <Button
        key={'cultivars-button'}
        variant="secondary"
        role="checkbox"
        selected={showCultivars}
        onClick={() => setShowCultivars(!showCultivars)}
        className="select-none text-xs font-normal"
      >
        Show Cultivars
      </Button>
    );

  const commentsButton = (
    <Button
      key={'comments-button'}
      variant="secondary"
      role="checkbox"
      selected={showComments}
      onClick={() => setShowComments(!showComments)}
      className="select-none text-xs font-normal"
    >
      Show Comments
    </Button>
  );

  const gridInfoButton = imageViewType === EImageViewTypes.GRID && (
    <Button
      key={'grid-info-button'}
      variant="secondary"
      role="checkbox"
      selected={showGridInfo}
      onClick={() => setShowGridInfo(!showGridInfo)}
      className="select-none text-xs font-normal"
    >
      Grid Info
    </Button>
  );

  const ndviButton = canViewNdvi && (
    <Button
      key={'ndvi-button'}
      variant="secondary"
      role="checkbox"
      selected={imageType === EImageTypes.NDVI}
      onClick={handleImageTypeChange}
      className="select-none text-xs font-normal"
    >
      NDVI
    </Button>
  );

  const generalFilters = (
    isMobile
      ? [commentsButton, cultivarsButton, gridInfoButton, ndviButton]
      : []
  ).filter(Boolean) as JSX.Element[];

  const labelIdentifierToTotalCountDictionary = labelsCounts.reduce(
    (accumulator, entry) => {
      if (entry.category && entry.subcategory && entry.code) {
        return {
          ...accumulator,
          [`${entry.category}${SPLIT_SYMBOL}${entry.subcategory}`]: {
            code: entry.code as EImageLabelCategory,
            count:
              (accumulator[
                `${entry.category}${SPLIT_SYMBOL}${entry.subcategory}`
              ]?.count ?? 0) + (entry.labelCount ?? 0),
          },
        };
      }

      return accumulator;
    },
    {} as Record<string, { code: EImageLabelCategory; count: number }>
  );

  const labelFilters = Object.entries(labelIdentifierToTotalCountDictionary)
    .map(([name, label]) => ({
      name,
      label,
    }))
    .sort((a, b) => (a.label.count > b.label.count ? -1 : 1))
    .sort((a) => (a.label.code === imageLabelCode ? -1 : 1))
    .map(({ name, label }) => (
      <Button
        key={name}
        role="checkbox"
        variant="secondary"
        selected={label.code.includes(imageLabelCode)}
        onClick={() => handleClickLabel(label.code)}
        className="select-none font-normal text-xs"
      >
        <span>
          {name
            .toLowerCase()
            .split('_') // some of our labels have underscores
            .map(capitalizeWord)
            .join(' ')
            .split(SPLIT_SYMBOL)
            .map(capitalizeWord)
            .join(', ')}
        </span>
        <span
          className={imageLabelCode === label.code ? '' : 'text-neutral-500'}
        >
          {' '}
          {label.count}
        </span>
      </Button>
    ))
    .sort((a, b) => (b.props.selected ? 1 : -1) - (a.props.selected ? 1 : -1));

  const toggles = ([] as JSX.Element[])
    .concat(generalFilters.filter((btn) => btn.props.selected))
    .concat(labelFilters)
    .concat(generalFilters.filter((btn) => !btn.props.selected));

  return (
    <div className="flex flex-row gap-2 w-full justify-between">
      <HorizontallyScrollable ref={containerRef}>
        <Flipper
          className="min-w-fit flex flex-row gap-2 "
          flipKey={toggles.map((toggle) => toggle.key).join()}
        >
          {toggles.map((toggle) => (
            <Flipped key={toggle.key} flipId={toggle.key!}>
              {toggle}
            </Flipped>
          ))}
        </Flipper>
      </HorizontallyScrollable>
      {!isMobile && (
        <div className="flex flex-row gap-2  ">
          {commentsButton}
          {cultivarsButton}
          {gridInfoButton}
          {ndviButton}
        </div>
      )}
    </div>
  );
};
