import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LeafVpdIcon = ({
  stroke = 'currentColor',
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 36"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M34.3113 11.4913C34.2757 11.0077 33.8846 10.6277 33.3957 10.6018C32.9957 10.5759 23.5113 10.0491 19.0491 15.3345C16.818 17.9772 16.258 21.5441 17.3869 25.9486C16.0357 28.1595 15.0402 30.7936 14.9957 33.7904C14.9869 34.3172 15.4224 34.7491 15.9646 34.7577C15.9735 34.7577 15.9735 34.7577 15.9824 34.7577C16.5157 34.7577 16.9602 34.3432 16.9602 33.8163C16.9957 31.3118 17.8313 29.0663 18.9691 27.1404C20.3113 27.4341 21.5824 27.5895 22.7735 27.5895C25.3957 27.5895 27.6268 26.8641 29.4402 25.4218C34.8713 21.095 34.338 11.88 34.3113 11.4913ZM28.1868 23.9622C26.1957 25.5427 23.4935 26.0263 20.1335 25.4304C22.7468 21.9759 26.0446 19.9377 26.098 19.9032C26.5602 19.6268 26.7024 19.0395 26.4091 18.5904C26.1246 18.1413 25.5202 18.0118 25.058 18.2882C24.8268 18.4263 21.6624 20.3782 18.9424 23.7636C18.498 20.7927 19.0402 18.3745 20.5602 16.5609C23.6535 12.8991 30.0091 12.5104 32.3646 12.5018C32.3557 14.7732 31.9557 20.9482 28.1868 23.9622Z"
      fill={fill}
    />
    <path
      d="M3.33317 1.3335L3.90793 2.19564C5.12429 4.02017 4.88372 6.44961 3.33317 8.00016V8.00016C1.78262 9.55071 1.54205 11.9802 2.75841 13.8047L3.33317 14.6668"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66667 1.3335L9.24143 2.19564C10.4578 4.02017 10.2172 6.44961 8.66667 8.00016V8.00016C7.11611 9.55071 6.87555 11.9802 8.0919 13.8047L8.66667 14.6668"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9997 1.3335L14.5744 2.19564C15.7908 4.02017 15.5502 6.44961 13.9997 8.00016V8.00016C12.4491 9.55071 12.2086 11.9802 13.4249 13.8047L13.9997 14.6668"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { LeafVpdIcon };
