import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const AirVPDIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      d="M8.43151 8.43893C6.6355 10.2349 6.6355 13.1574 8.43151 14.9534C8.67182 15.1937 8.67182 15.5833 8.43151 15.8236C8.19151 16.0636 7.80258 16.0645 7.56135 15.8236C5.28565 13.5479 5.28565 9.84447 7.56135 7.56877C9.35736 5.77277 9.35736 2.8503 7.56135 1.05429C7.32104 0.813985 7.32104 0.424446 7.56135 0.184137C7.80166 -0.0561708 8.1912 -0.0561708 8.43151 0.184137C10.7075 2.45984 10.7075 6.16292 8.43151 8.43893ZM4.12381 0.184137C3.8835 -0.0561708 3.49396 -0.0561708 3.25365 0.184137C3.01334 0.424446 3.01334 0.813985 3.25365 1.05429C5.04965 2.8503 5.04965 5.77246 3.25365 7.56877C0.977951 9.84447 0.977951 13.5479 3.25365 15.8236C3.49426 16.0639 3.88319 16.0639 4.12381 15.8236C4.36411 15.5833 4.36411 15.1937 4.12381 14.9534C2.3278 13.1574 2.3278 10.2352 4.12381 8.43893C6.39981 6.16292 6.39981 2.45984 4.12381 0.184137ZM12.7392 8.43893C15.0149 6.16292 15.0149 2.45984 12.7392 0.184137C12.4989 -0.0561708 12.1094 -0.0561708 11.8691 0.184137C11.6287 0.424446 11.6287 0.813985 11.8691 1.05429C13.6651 2.8503 13.6651 5.77246 11.8691 7.56877C9.59336 9.84447 9.59336 13.5479 11.8691 15.8236C12.1094 16.0639 12.4989 16.0639 12.7392 15.8236C12.9795 15.5833 12.9795 15.1937 12.7392 14.9534C10.9432 13.1574 10.9432 10.2349 12.7392 8.43893Z"
    />
  </svg>
);

export { AirVPDIcon };
