import { Button } from 'components/common/Button/Button';
import { ChevronForwardIcon } from 'icons/ChevronForwardIcon';
import { ExternalLinkIcon } from 'icons/ExternalLinkIcon';
import { useContext, useState } from 'react';
import { TDiscussion } from 'shared/interfaces/discussion';
import { formatTimeInSemiFullStyle } from 'shared/utils/date';
import { DiscussionBoxContext } from './DiscussionBoxContext';

export const DiscussionList = () => {
  const { listState } = useContext(DiscussionBoxContext);
  const { discussions, onChangeActiveDiscussion, selectDiscussion } =
    listState!;
  const [activeDiscussion, setActiveDiscussion] = useState<TDiscussion>();

  return (
    <div className="flex flex-col gap-2">
      {discussions.map((discussion) => {
        const { uid, startTime, annotationType } = discussion;

        const Icon =
          annotationType === 'event' ? ChevronForwardIcon : ExternalLinkIcon;

        return (
          <Button
            key={uid}
            variant="secondary"
            className="font-normal justify-between"
            trailingIcon={<Icon />}
            aria-pressed={uid === activeDiscussion?.uid}
            onClick={() => {
              selectDiscussion(discussion.uid);
            }}
            onMouseEnter={() => {
              setActiveDiscussion(discussion);
              onChangeActiveDiscussion?.(discussion.uid);
            }}
            onMouseLeave={() => {
              setActiveDiscussion(undefined);
              onChangeActiveDiscussion?.(undefined);
            }}
          >
            <div className="truncate flex gap-2">
              <p className="text-sm truncate">{discussion.displayLabel}</p>
              <p className="text-sm text-neutral-500">
                {formatTimeInSemiFullStyle(startTime)}
              </p>
            </div>
          </Button>
        );
      })}
    </div>
  );
};
