import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const MoreVerticalIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4 16"
    fill="none"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <rect x="0.5" y="0.5" width="3" height="3" rx="1.5" fill={fill} />
    <rect x="0.5" y="6.5" width="3" height="3" rx="1.5" fill={fill} />
    <rect x="0.5" y="12.5" width="3" height="3" rx="1.5" fill={fill} />
  </svg>
);

export { MoreVerticalIcon };
