import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { Button } from 'components/common/Button/Button';
import { Grid } from 'components/common/Grid';
import { Link } from 'components/common/Link';
import { TextInput } from 'components/common/TextInput';
import { useAuth } from 'contexts/AuthProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import isNil from 'lodash.isnil';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnalyticsEvent } from 'shared/analytics/AnalyticsEvent';
import { useAnalytics } from 'shared/analytics/useAnalytics';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { cn } from 'shared/utils/cn';

type TLoginForm = {
  email: string;
  password: string;
};

export const LoginPage: FC = () => {
  const { sendEvent } = useAnalytics();
  const [error, setError] = useState('');
  const { signIn } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isMobile, isPortrait } = useScreenSize();

  const { handleSubmit, register } = useForm<TLoginForm>();

  const onSubmit = handleSubmit(async ({ email, password }) => {
    // Clear out error.
    setError('');
    email = email.toLocaleLowerCase();
    if (email.indexOf(' ') !== -1) {
      setError('Please enter a valid email.');
      return;
    }

    setIsSubmitting(true);
    try {
      await signIn(email, password);

      sendEvent(AnalyticsEvent.Login);
    } catch (e) {
      const error = e as Error;
      const errorMessage =
        isNil(error.message) || error.message.length === 0
          ? 'Unknown error. Please try again later.'
          : error.message;
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  });

  const errorIndicator = (
    <div className="rounded-[10px] border border-red-700 bg-red-100 p-5 text-red-700">
      {error}
    </div>
  );

  return (
    <NonAuthLayout>
      <NonAuthForm
        header={
          <>
            <h1 className="text-3xl font-bold">Hey,</h1>
            <h1 className="text-3xl font-bold">Welcome back!</h1>
          </>
        }
        footer={
          <Link
            to={PATH_PATTERNS[ERoutePath.FORGOT_PASSWORD]}
            text="Forgot password?"
          />
        }
      >
        <form
          className={`${isMobile && isPortrait ? 'mt-[30px]' : ''}`}
          onSubmit={onSubmit}
        >
          <Grid container spacing={3}>
            {error && !isMobile && (
              <Grid item xs={12}>
                {errorIndicator}
              </Grid>
            )}

            <Grid item xs={12}>
              <div
                className={cn('mx-0 my-[30px]', isMobile && 'mb-[unset] mt-0')}
              >
                <h2 className="text-xl font-bold">Login</h2>
              </div>
            </Grid>

            {error && isMobile && (
              <Grid item xs={12}>
                {errorIndicator}
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Email"
                    data-testid="email"
                    {...register('email', { required: true })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Password"
                    type="password"
                    data-testid="password"
                    {...register('password', { required: true })}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={isSubmitting}
                className="h-12 w-full text-base"
                type="submit"
                id="sign_in_button"
              >
                Sign in
              </Button>
            </Grid>
          </Grid>
        </form>
      </NonAuthForm>
    </NonAuthLayout>
  );
};
