import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { NavigateOptions } from 'react-router-dom';
import { ERoutePath } from 'shared/constants/url';
import { EImageViewTypes } from 'shared/interfaces/image';
import { EZoneDetailTabs } from 'shared/interfaces/zone';
import { navigateToFactory } from './NavigateToFactory';
import { QueryParamsSchema, getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

const sanitizedTime = (time: number | undefined, timeZone: string) =>
  time ? zonedTimeToUtc(time, timeZone).valueOf().toString() : undefined;

export const getZoneDetailsPageStore = (
  args: StoreArgs<ERoutePath.ZONE_DETAILS_PAGE>
) => {
  const {
    routePath,
    searchParams,
    setSearchParams,
    navigate,
    pathParams,
    location,
  } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  const navigateTo = navigateToFactory(
    navigate,
    location.pathname,
    searchParams,
    routePath
  );
  type SchemaType = QueryParamsSchema<typeof routePath>;

  const actions = {
    setMeasurementRunStartTime: ({
      zonedDate,
      timeZone,
    }: {
      zonedDate: SchemaType['mr-ts'];
      timeZone: string;
    }) => {
      const date = zonedDate && zonedTimeToUtc(zonedDate, timeZone).valueOf();
      setSearchParams({ 'mr-ts': date }, { replace: true });
    },
    setRangeTime: (
      {
        zonedStart,
        zonedEnd,
        timeZone,
      }: {
        zonedStart: SchemaType['ts-start'];
        zonedEnd: SchemaType['ts-end'];
        timeZone: string;
      },
      navigateOptions: NavigateOptions = {
        replace: true,
      }
    ) => {
      let measurementTime = queryParams['mr-ts'];
      const start =
        zonedStart && zonedTimeToUtc(zonedStart, timeZone).valueOf();
      const end = zonedEnd && zonedTimeToUtc(zonedEnd, timeZone).valueOf();

      if (measurementTime && start && end) {
        measurementTime = Math.max(
          start.valueOf(),
          Math.min(measurementTime, end.valueOf())
        );
      }

      setSearchParams(
        {
          'ts-start': start,
          'ts-end': end,
          'mr-ts': measurementTime,
          'discussion-uid': undefined,
        },
        navigateOptions
      );
    },
    navigateTo: (
      params: {
        organizationCode: string;
        zoneId?: number;
        tab?: EZoneDetailTabs;
      },
      navigateOptions?: NavigateOptions
    ) => {
      navigateTo(
        {
          pathParams: { tab: undefined, zoneId: undefined, ...params },
          clearPreviousQueryParams: true,
        },
        navigateOptions
      );
    },
    navigateToLineChart: (
      params: {
        signalIds: QueryParamsSchema<ERoutePath.LINE_CHART>['signal-ids'];
        zonedStartTime: QueryParamsSchema<ERoutePath.LINE_CHART>['ts-start'];
        zonedEndTime: QueryParamsSchema<ERoutePath.LINE_CHART>['ts-end'];
        timeZone: string;
      },
      navigateOptions?: NavigateOptions
    ) => {
      const startTime = sanitizedTime(params.zonedStartTime, params.timeZone);
      const endTime = sanitizedTime(params.zonedEndTime, params.timeZone);

      navigateTo(
        {
          pathParams: {
            tab: EZoneDetailTabs.LINE_CHART,
          },
          queryParams: {
            'signal-ids': params.signalIds?.toString(),
            'ts-start': startTime,
            'ts-end': endTime,
          },
        },
        navigateOptions
      );
    },
    navigateToImageFeed: (
      params: {
        imageLabelCode: QueryParamsSchema<ERoutePath.IMAGE_FEED>['image-label-code'];
        zonedStartTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['ts-start'];
        zonedEndTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['ts-end'];
        timeZone: string;
      },
      navigateOptions?: NavigateOptions
    ) => {
      const startTime = sanitizedTime(params.zonedStartTime, params.timeZone);
      const endTime = sanitizedTime(params.zonedEndTime, params.timeZone);

      navigateTo(
        {
          pathParams: {
            tab: EZoneDetailTabs.IMAGE_FEED,
          },
          queryParams: {
            'image-label-code': params.imageLabelCode,
            'ts-start': startTime,
            'ts-end': endTime,
          },
        },
        navigateOptions
      );
    },
    navigateToZone: (zoneId: number, navigateOptions?: NavigateOptions) => {
      navigateTo(
        { pathParams: { zoneId }, clearPreviousQueryParams: true },
        navigateOptions
      );
    },
    navigateToTab: (
      tab: EZoneDetailTabs,
      navigateOptions?: NavigateOptions
    ) => {
      const clearPreviousQueryParams = [
        EZoneDetailTabs.HOMEPAGE,
        EZoneDetailTabs.INSIGHTS,
      ].includes(tab);
      navigateTo(
        { pathParams: { tab }, clearPreviousQueryParams },
        navigateOptions
      );
    },
    navigateToImageFeedDiscussion: (
      params: {
        organizationCode?: string;
        zoneId?: number;
        timeZone: string;
        zonedMeasurementRunStartTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['mr-ts'];
        discussionUid: QueryParamsSchema<ERoutePath.IMAGE_FEED>['discussion-uid'];
        imageLocation: QueryParamsSchema<ERoutePath.IMAGE_FEED>['image-location'];
        zonedStartTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['ts-start'];
        zonedEndTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['ts-end'];
      },
      navigateOptions?: NavigateOptions,
      skipNavigation?: boolean
    ) => {
      const measurementRunStartTime = sanitizedTime(
        params.zonedMeasurementRunStartTime,
        params.timeZone
      );
      const startTime = sanitizedTime(params.zonedStartTime, params.timeZone);
      const endTime = sanitizedTime(params.zonedEndTime, params.timeZone);

      return navigateToFactory(
        navigate,
        location.pathname,
        searchParams,
        ERoutePath.IMAGE_FEED
      )(
        {
          pathParams: {
            organizationCode:
              params.organizationCode ?? pathParams?.organizationCode,
            zoneId: params.zoneId ?? pathParams?.zoneId,
            tab: EZoneDetailTabs.IMAGE_FEED,
          },
          queryParams: {
            'mr-ts': measurementRunStartTime,
            'ts-start': startTime,
            'ts-end': endTime,
            'discussion-uid': params.discussionUid,
            'image-view-type': EImageViewTypes.SINGLE_IMAGE,
            'show-comments': 'true',
            'image-location': params.imageLocation,
            x: undefined,
            y: undefined,
            scale: undefined,
          },
          skipNavigation,
        },
        navigateOptions
      );
    },
    navigateToLineChartDiscussion: (
      params: {
        organizationCode?: string;
        zoneId?: number;
        discussionUid: QueryParamsSchema<ERoutePath.LINE_CHART>['discussion-uid'];
        signalIds: QueryParamsSchema<ERoutePath.LINE_CHART>['signal-ids'];
        viewType: QueryParamsSchema<ERoutePath.LINE_CHART>['view-type'];
        zonedStartTime: QueryParamsSchema<ERoutePath.LINE_CHART>['ts-start'];
        zonedEndTime: QueryParamsSchema<ERoutePath.LINE_CHART>['ts-end'];
        timeZone: string;
      },
      navigateOptions?: NavigateOptions,
      skipNavigation?: boolean
    ) => {
      const startTime = sanitizedTime(params.zonedStartTime, params.timeZone);
      const endTime = sanitizedTime(params.zonedEndTime, params.timeZone);

      return navigateToFactory(
        navigate,
        location.pathname,
        searchParams,
        ERoutePath.LINE_CHART
      )(
        {
          pathParams: {
            organizationCode:
              params.organizationCode ?? pathParams?.organizationCode,
            zoneId: params.zoneId ?? pathParams?.zoneId,
            tab: EZoneDetailTabs.LINE_CHART,
          },
          queryParams: {
            'ts-start': startTime,
            'ts-end': endTime,
            'discussion-uid': params.discussionUid,
            'show-comments': 'true',
            'signal-ids': params.signalIds?.toString(),
            'view-type': params.viewType,
          },
          skipNavigation,
        },
        navigateOptions
      );
    },
    navigateToHeatMapDiscussion: (
      params: {
        organizationCode?: string;
        zoneId?: number;
        zonedMeasurementStartTime: QueryParamsSchema<ERoutePath.HEAT_MAP>['mr-ts'];
        timeZone: string;
        discussionUid: QueryParamsSchema<ERoutePath.HEAT_MAP>['discussion-uid'];
        signalIds: QueryParamsSchema<ERoutePath.HEAT_MAP>['signal-ids'];
        aggregationType: QueryParamsSchema<ERoutePath.HEAT_MAP>['aggregation-type'];
        gradientType: QueryParamsSchema<ERoutePath.HEAT_MAP>['gradient-type'];
        zonedStartTime: QueryParamsSchema<ERoutePath.HEAT_MAP>['ts-start'];
        zonedEndTime: QueryParamsSchema<ERoutePath.HEAT_MAP>['ts-end'];
      },
      navigateOptions?: NavigateOptions,
      skipNavigation?: boolean
    ) => {
      const measurementStartTime = sanitizedTime(
        params.zonedMeasurementStartTime,
        params.timeZone
      );
      const startTime = sanitizedTime(params.zonedStartTime, params.timeZone);
      const endTime = sanitizedTime(params.zonedEndTime, params.timeZone);

      return navigateToFactory(
        navigate,
        location.pathname,
        searchParams,
        ERoutePath.HEAT_MAP
      )(
        {
          pathParams: {
            organizationCode:
              params.organizationCode ?? pathParams?.organizationCode,
            zoneId: params.zoneId ?? pathParams?.zoneId,
            tab: EZoneDetailTabs.HEAT_MAP,
          },
          queryParams: {
            'mr-ts': measurementStartTime,
            'ts-start': startTime,
            'ts-end': endTime,
            'discussion-uid': params.discussionUid,
            'show-comments': 'true',
            'signal-ids': params.signalIds,
            'aggregation-type': params.aggregationType,
            'gradient-type': params.gradientType,
          },
          skipNavigation,
        },
        navigateOptions
      );
    },
    navigateToTimeLineDiscussion: (
      params: {
        organizationCode?: string;
        zoneId?: number;
        timeZone: string;
        zonedMeasurementRunStartTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['mr-ts'];
        discussionUid: QueryParamsSchema<ERoutePath.IMAGE_FEED>['discussion-uid'];
        zonedStartTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['ts-start'];
        zonedEndTime: QueryParamsSchema<ERoutePath.IMAGE_FEED>['ts-end'];
      },
      navigateOptions?: NavigateOptions,
      skipNavigation?: boolean
    ) => {
      const measurementRunStartTime = sanitizedTime(
        params.zonedMeasurementRunStartTime,
        params.timeZone
      );
      const startTime = sanitizedTime(params.zonedStartTime, params.timeZone);
      const endTime = sanitizedTime(params.zonedEndTime, params.timeZone);

      return navigateToFactory(
        navigate,
        location.pathname,
        searchParams,
        ERoutePath.IMAGE_FEED
      )(
        {
          pathParams: {
            organizationCode:
              params.organizationCode ?? pathParams?.organizationCode,
            zoneId: params.zoneId ?? pathParams?.zoneId,
            tab: EZoneDetailTabs.IMAGE_FEED,
          },
          queryParams: {
            'mr-ts': measurementRunStartTime,
            'ts-start': startTime,
            'ts-end': endTime,
            'discussion-uid': params.discussionUid,
            'image-view-type': EImageViewTypes.GRID,
            'show-comments': 'true',
          },
          skipNavigation,
        },
        navigateOptions
      );
    },
  };

  const state = {
    getMeasurementRunStartTime: (timeZone: string) =>
      queryParams['mr-ts'] === undefined
        ? undefined
        : utcToZonedTime(queryParams['mr-ts'], timeZone).valueOf(),
    getRangeStartTime: (timeZone: string) =>
      queryParams['ts-start'] === undefined
        ? undefined
        : utcToZonedTime(queryParams['ts-start'], timeZone).valueOf(),
    getRangeEndTime: (timeZone: string) =>
      queryParams['ts-end'] === undefined
        ? undefined
        : utcToZonedTime(queryParams['ts-end'], timeZone).valueOf(),
    organizationCode: pathParams?.organizationCode,
    zoneId: pathParams?.zoneId,
    tab: pathParams?.tab as EZoneDetailTabs,
    rangeStartTime: queryParams['ts-start'],
    rangeEndTime: queryParams['ts-end'],
  };
  return {
    ...actions,
    ...state,
  };
};
