import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const CheckIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      d="M15.099 3a.95.95 0 0 0-.635.229l-9.19 7.817-3.74-3.003a.9.9 0 0 0-.308-.179.98.98 0 0 0-.713.03.9.9 0 0 0-.29.202.8.8 0 0 0-.18.292.77.77 0 0 0 .067.653q.089.15.235.256l4.351 3.497a.96.96 0 0 0 .61.206.95.95 0 0 0 .603-.222l9.79-8.326a.82.82 0 0 0 .276-.423.77.77 0 0 0-.034-.492.85.85 0 0 0-.33-.388.96.96 0 0 0-.512-.149"
    />
  </svg>
);

export { CheckIcon };
