import { useMarkUnreadCommentsAsRead } from 'api/discussion';
import { useNotifications } from 'api/notifications';
import { useAvailableMentions } from 'api/user';
import { Button } from 'components/common/Button/Button';
import { CircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { Popover } from 'components/common/Popover/Popover';
import { TextEditor } from 'components/common/TextEditor/TextEditor';
import * as toast from 'components/common/Toast/Toast';
import { Tooltip, topPlacements } from 'components/common/Tooltip/Tooltip';
import { useNavigateToDiscussion } from 'components/discussions/hooks/useNavigateToDiscussion';
import { useAuth } from 'contexts/AuthProvider';
import { usePermissions } from 'hooks/usePermissions';
import { useScreenSize } from 'hooks/useScreenSize';
import { BellNotificationsIcon } from 'icons/BellNotificationsIcon';
import { CheckCheckIcon } from 'icons/CheckCheckIcon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TDiscussion } from 'shared/interfaces/discussion';
import { TNotification } from 'shared/interfaces/notifications';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import { Notifications } from './Notifications';

const DiscussionNotificationItem = (
  notification: TNotification<{ discussion: TDiscussion }>
) => {
  const zoneUid = notification.data?.discussion?.zoneUid;
  const availableMentions = useAvailableMentions(zoneUid);
  return (
    <TextEditor
      key={notification.message}
      initialEditorState={notification.message}
      editable={false}
      readMoreContentLength={100}
      availableMentions={availableMentions}
    />
  );
};

export const NotificationSideBar = ({
  zones,
  currentZone,
}: {
  zones: TZone[];
  currentZone: TZone;
}) => {
  const { user } = useAuth();
  const {
    insights: { canViewDraft },
  } = usePermissions();
  const { isMobileXs } = useScreenSize();
  const navigate = useNavigateToDiscussion();

  const notificationsButtonContainerRef = useRef<HTMLDivElement>(null);
  const [refreshId, setRefreshId] = useState<number>(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [limit, setLimit] = useState(10);

  const { notifications, loading, nrUnreadComments } = useNotifications({
    limit,
    userId: user?.id!,
    canViewDraft,
    zones,
  });
  const { markCommentsAsRead, loading: saving } = useMarkUnreadCommentsAsRead({
    canViewDraft,
    userId: user?.id!,
    zones,
  });

  const handleMarkAllAsRead = useCallback(async () => {
    const toastId = 'update-facility-notification-success';
    try {
      await markCommentsAsRead();

      toast.success(
        { content: 'All comments mark as read successfully.' },
        { autoClose: 3000, toastId }
      );
    } catch (_error) {
      toast.error(
        {
          content:
            'Something went wrong while marking all comments as read. Please try again.',
        },
        { toastId }
      );
    }
  }, [markCommentsAsRead]);

  useEffect(() => {
    setLimit(10);
  }, [currentZone?.organizationCode]);

  useEffect(() => {
    // create a resize listener and set the refreshId to trigger a re-render
    const handleResize = () => setRefreshId((id) => id + 1);
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={notificationsButtonContainerRef}>
      <Popover
        refreshId={`${refreshId}`}
        title="Notifications"
        showScrollbar
        withBackground={false}
        className={cn(!showNotifications && 'pointer-events-none')}
        style={!isMobileXs ? { maxHeight: 'calc(100svh - 80px)' } : undefined}
        contentClassName="p-0 flex flex-col gap-2 items-center "
        referenceElement={notificationsButtonContainerRef}
        isOpen={showNotifications}
        close={() => setShowNotifications(false)}
        headerActions={
          nrUnreadComments > 0 ? (
            <Tooltip
              label="Mark all as read"
              allowedPlacements={topPlacements}
              withArrow
            >
              <Button
                variant="secondary"
                size="icon"
                loading={saving}
                onClick={handleMarkAllAsRead}
              >
                <CheckCheckIcon />
              </Button>
            </Tooltip>
          ) : null
        }
      >
        <>
          <Notifications
            onReachEnd={() => setLimit((prev) => prev + 10)}
            items={notifications}
            renderContent={(notification) => (
              <DiscussionNotificationItem {...notification} />
            )}
            onClickItem={(data) => {
              navigate(data.discussion);
            }}
          />
          {loading && <CircularProgress className="py-4" />}
        </>
      </Popover>
      <Button
        variant="secondary"
        size="icon"
        aria-label="Notifications"
        className="relative"
        onClick={() => setShowNotifications(!showNotifications)}
      >
        <div className="flex items-center">
          <BellNotificationsIcon />
          {nrUnreadComments > 0 && (
            <div className="absolute -top-1 -right-1 px-1 h-4 min-w-4 bg-orange-500 text-white text-xxs font-thin flex items-center justify-center rounded-full">
              {nrUnreadComments}
            </div>
          )}
        </div>
      </Button>
    </div>
  );
};
