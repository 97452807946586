import { useSignalsQuery } from 'api/signals';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import {
  useLineChartURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePermissions } from 'hooks/usePermissions';
import { useCallback, useEffect, useMemo } from 'react';
import {
  EMeasurementGroup,
  MeasurementTypeConfig,
  MeasurementUnit,
  Signal,
} from 'shared/interfaces/measurement';
import { usePrevious } from './usePrevious';

const mapSignalToType = (signal: Signal) => ({
  source: signal.measurementInformation.source,
  group: EMeasurementGroup.Others,
  type: signal.id,
  statisticsKeyV2: signal.id,
  unit: MeasurementUnit[signal.measurementInformation.measurementUnit],
  label: `${signal.sensorInformation.sensorName || signal.sensorInformation.sensorTypeName} | ${signal.name}`,
  hasMultipleAggregations: true,
});

const { Environmental, Labels, Soil, Others } = EMeasurementGroup;

export function useSignals({
  rawSignalId,
  zoneUid,
}: Optional<{ rawSignalId?: string; zoneUid?: string }> = {}) {
  const { allMeasurementTypeFilters, getMeasurementType } = useTypeConfig();
  const permissions = usePermissions();
  const { rangeEndTime, rangeStartTime } = useZoneDetailsPageURL();
  const { currentZone } = useCurrentZone();
  const { signalIds, viewType: aggregation, setSignalIds } = useLineChartURL();
  const [signalId] = signalIds;
  const previousAggregation = usePrevious(aggregation);
  const {
    data: signals = [],
    isFetching,
    isFetched,
  } = useSignalsQuery({
    enabled: permissions.canViewDynamicSignals,
    zoneUid: zoneUid ?? currentZone?.uid,
    start: rangeStartTime,
    end: rangeEndTime,
  });
  const environmentals = useMemo(
    () =>
      allMeasurementTypeFilters.filter((type) => type.group === Environmental),
    [allMeasurementTypeFilters]
  );
  const soilSensors = useMemo(
    () => permissions.soilSensors.map(getMeasurementType),
    [getMeasurementType, permissions.soilSensors]
  );
  const labels = useMemo(
    () => permissions.labels.map(getMeasurementType),
    [getMeasurementType, permissions.labels]
  );
  const others = useMemo(() => signals.map(mapSignalToType), [signals]);
  const othersByAggregation = useMemo(
    () =>
      signals
        .filter(
          ({ measurementInformation }) =>
            measurementInformation.aggregation === aggregation.toLowerCase()
        )
        .map(mapSignalToType),
    [signals, aggregation]
  );
  const groups = useMemo(
    () =>
      (
        [
          [Environmental, environmentals],
          [Soil, soilSensors],
          [Labels, labels],
          [Others, othersByAggregation],
        ] as [EMeasurementGroup, MeasurementTypeConfig[]][]
      ).filter(([_, types]) => types.length > 0) as [
        EMeasurementGroup,
        MeasurementTypeConfig[],
      ][],
    [environmentals, labels, othersByAggregation, soilSensors]
  );
  const allSignals = useMemo(
    () => [...environmentals, ...soilSensors, ...labels, ...others],
    [environmentals, labels, others, soilSensors]
  );
  const groupedSignals = useMemo(
    () => groups.flatMap(([_, types]) => types),
    [groups]
  );
  const selectedSignal = useMemo(() => {
    let signal = groupedSignals.find(({ type }) => type === signalId);

    if (previousAggregation !== aggregation && signalId) {
      signal = othersByAggregation.find(
        ({ type }) => type.endsWith(signalId) || signalId.endsWith(type)
      ) as MeasurementTypeConfig;
    }

    return signal ?? groupedSignals[0];
  }, [
    aggregation,
    groupedSignals,
    othersByAggregation,
    previousAggregation,
    signalId,
  ])!;
  const getSignal = useCallback(
    (id: string): Optional<MeasurementTypeConfig> => {
      return (
        getMeasurementType(id) ?? allSignals.find(({ type }) => type === id)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allSignals]
  );
  const rawSignal = useMemo(() => {
    return rawSignalId ? getSignal(rawSignalId) : undefined;
  }, [getSignal, rawSignalId]);

  useEffect(() => {
    if (
      signalId &&
      selectedSignal.type !== signalId &&
      !isFetching &&
      isFetched
    ) {
      setSignalIds([selectedSignal.type], { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, isFetching, selectedSignal.type, signalId]);

  return { getSignal, groups, rawSignal, selectedSignal };
}
