import {
  useGetEnumerationByCodeAndTypeQuery,
  useGetEnumerationsByTypeQuery,
} from 'graphql/generated/react_apollo';
import { EEnumerationTypes } from 'shared/interfaces/enumeration';

export const useGetEnumerationsByType = (types: EEnumerationTypes[]) => {
  const { data, ...result } = useGetEnumerationsByTypeQuery({
    variables: {
      types,
    },
  });

  return { ...result, enumerations: data?.enumeration };
};

export const useGetEnumerationByCodeAndType = (
  type: EEnumerationTypes,
  code: string
) => {
  const { data, ...result } = useGetEnumerationByCodeAndTypeQuery({
    variables: {
      type,
      code,
    },
  });

  return { ...result, enumerations: data?.enumeration ?? [] };
};
