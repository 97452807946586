import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const PlusIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M13 8H3M8 3v10"
    />
  </svg>
);

export { PlusIcon };
