import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1600,
    },
  },
  typography: {
    fontFamily: `Poppins, Inter, Quicksand, Sans Serif`,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    fontSize: 16,
    h1: {
      fontWeight: 'bold',
      fontSize: 56,
      lineHeight: 1.15,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 44,
      lineHeight: 1.1,
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 36,
      lineHeight: 1.1,
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 22,
      lineHeight: 1.2,
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.25,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.428,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.67,
      color: '#5F5F5F',
    },
  },
  palette: {
    primary: {
      main: '#F48E42',
      light: 'rgba(244, 142, 66, 0.07)',
    },
    secondary: {
      main: '#F2F6FF',
    },
    black: {
      main: '#000000',
      light: 'rgba(0, 0, 0, 0.07)',
    },
    blue: {
      dark: '#0072B2',
      main: '#2986D2',
      light: '#56B4E9',
    },
    dark5: {
      main: '#5F5F5F',
    },
    dark6: {
      main: '#747474',
    },
    dark7: {
      main: '#808080',
    },
    darkTransparent5: {
      light: 'rgba(0, 0, 0, 0.04)',
      main: 'rgba(0, 0, 0, 0.05)',
      dark: 'rgba(0, 0, 0, 0.07)',
    },
    darkTransparent10: {
      main: 'rgba(0, 0, 0, 0.14)',
    },
    darkTransparent20: {
      light: 'rgba(0, 0, 0, 0.4)',
      main: 'rgba(0, 0, 0, 0.5)',
      dark: 'rgba(0, 0, 0, 0.7)',
    },
    darkTransparent30: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    grey2: {
      main: '#F7F7F7',
    },
    grey4: {
      main: '#EDEDED',
    },
    grey5: {
      main: '#E0E0E0',
    },
    grey10: {
      main: '#CFCFCF',
    },
    grey20: {
      main: '#B4B4B4',
    },
    grey30: {
      main: '#808080',
    },
    hb5: {
      main: '#F0F1F4',
    },
    hb6: {
      main: '#F8F8FC',
    },
    hb7: {
      main: '#F3F3F3',
    },
    orange1: {
      main: '#DD382F',
    },
    orange2: {
      main: '#E86339',
    },
    orange3: {
      main: '#F48E42',
    },
    orange4: {
      main: '#FFB94C',
      light: `rgba(255, 185, 76, 0.25)`,
    },
    orange7: {
      main: '#F7E8D0',
    },
    pink1: {
      main: '#FFAABB',
    },
    green1: {
      main: '#31CA71',
      light: 'rgba(49, 202, 113, 0.25)',
      dark: '#31CA71', // TBD
    },
    green3: {
      main: 'rgba(99, 149, 19, 0.36)',
      light: 'rgba(99, 149, 19, 0.03)',
    },
    white: {
      main: '#FFFFFF',
    },
    yellow1: {
      main: 'rgba(255, 252, 76)',
      light: 'rgba(255, 252, 76, 0.25)',
      dark: '#BBCC33', // TBD
    },
  },
});
