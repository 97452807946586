import { useTheme } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

interface IScreenSizeInterface {
  /** Shows if the current screen is desktop. */
  isDesktop: boolean;
  /** Shows if the current screen is mobile. */
  isMobile: boolean;
  /** Shows if the current screen is mobile no bigger than breakpoint xs. */
  isMobileXs: boolean;
  /** Shows if the current screen is tablet. */
  isTablet: boolean;
  /** Tells if the screenis landscape or portrait */
  isPortrait: boolean;
}

/**
 * Custom hook to see if the screen is mobile/desktop based on the screen width.
 * @returns {IScreenSizeInterface} shows the current platform.
 */
export const useScreenSize = (): IScreenSizeInterface => {
  const theme = useTheme();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.values.xl}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.values.md}px)`,
  });

  const isMobileXs = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.values.xs}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.xl}px)`,
  });

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  return { isDesktop, isMobile, isMobileXs, isTablet, isPortrait };
};
