import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const ManageCycleIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M3 0a.333.333 0 0 0-.333.333v.334H1.333C.601.667 0 1.267 0 2v10c0 .732.6 1.333 1.333 1.333h7.532q.141.343.365.633L7.764 15.43a.333.333 0 1 0 .472.472l1.465-1.465a2.65 2.65 0 0 0 1.632.563 2.67 2.67 0 0 0 2.47-1.667h.864c.732 0 1.333-.6 1.333-1.333V2c0-.732-.6-1.333-1.333-1.333h-1.334V.333a.333.333 0 1 0-.666 0v.334H10V.333a.333.333 0 1 0-.667 0v.334H6.667V.333a.333.333 0 1 0-.667 0v.334H3.333V.333A.333.333 0 0 0 3 0M1.333 1.333h1.334v.334a.333.333 0 1 0 .666 0v-.334H6v.334a.333.333 0 1 0 .667 0v-.334h2.666v.334a.333.333 0 1 0 .667 0v-.334h2.667v.334a.333.333 0 1 0 .666 0v-.334h1.334c.372 0 .666.295.666.667v10a.66.66 0 0 1-.666.667h-.69q.022-.164.023-.334a2.67 2.67 0 0 0-2.667-2.666 2.67 2.67 0 0 0-2.666 2.666q.002.17.023.334H1.333A.66.66 0 0 1 .667 12V2c0-.372.294-.667.666-.667m1.012 2A.34.34 0 0 0 2 3.667c0 .184.154.333.345.333h1.31A.34.34 0 0 0 4 3.667a.34.34 0 0 0-.345-.334zm3.322 0a.333.333 0 1 0 0 .667H7a.333.333 0 1 0 0-.667zm3.333 0A.333.333 0 1 0 9 4h1.333a.333.333 0 1 0 0-.667zm3.333 0a.333.333 0 1 0 0 .667h1.334a.333.333 0 1 0 0-.667zM7.334 5a.4.4 0 0 0-.235.098L6.001 6.197l-.433-.433a.332.332 0 1 0-.47.47l.667.667a.33.33 0 0 0 .474-.001l1.33-1.332c.13-.13.13-.34 0-.47A.33.33 0 0 0 7.334 5m-5 .667a.333.333 0 1 0 0 .666h1.333a.333.333 0 1 0 0-.666zm6.666 0a.333.333 0 1 0 0 .666h1.333a.333.333 0 1 0 0-.666zm3.333 0a.333.333 0 1 0 0 .666h1.334a.333.333 0 1 0 0-.666zM2.333 8a.333.333 0 1 0 0 .667h1.334a.333.333 0 1 0 0-.667zm3.334 0a.333.333 0 1 0 0 .667H7A.333.333 0 1 0 7 8zM9 8a.333.333 0 1 0 0 .667h1.333a.333.333 0 1 0 0-.667zm3.333 0a.333.333 0 1 0 0 .667h1.334a.333.333 0 1 0 0-.667zm-10 2.333a.333.333 0 1 0 0 .667h1.334a.333.333 0 1 0 0-.667zm3.334 0a.333.333 0 1 0 0 .667H7a.333.333 0 1 0 0-.667zm5.666 0c1.109 0 2 .892 2 2s-.891 2-2 2-2-.891-2-2 .892-2 2-2"
      clipRule="evenodd"
    />
  </svg>
);

export { ManageCycleIcon };
