import { Auth } from 'aws-amplify';
import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { PasswordRules } from 'components/auth/PasswordRules';
import { Button } from 'components/common/Button/Button';
import { ConfirmDialog } from 'components/common/ConfirmDialog';
import { Grid } from 'components/common/Grid';
import { Link } from 'components/common/Link';
import { TextInput } from 'components/common/TextInput';
import { Typography } from 'components/common/Typography';
import { useAuth } from 'contexts/AuthProvider';
import { useURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PASSWORD_RULES } from 'shared/constants/password';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { EAuthExceptionCodes } from 'shared/interfaces/auth';

export enum EVerificationStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  IN_PROGRESS = 'inProgress',
  NONE = 'none',
}
type TVerifyUserForm = {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
};

export const VerifyUserPage: FC = () => {
  const { changeInitialPassword, confirmInitialPasswordChange } = useAuth();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldShowRules, setShouldShowRules] = useState(false);
  const [showChangedDialog, setShowChangedDialog] = useState(false);
  const { navigateToLogin } = useURL();

  const { isMobile } = useScreenSize();
  const { handleSubmit, register, watch } = useForm<TVerifyUserForm>();

  const values = watch();

  const checkPasswordRules = (password: string, confirmPassword: string) =>
    PASSWORD_RULES.every((rule) => rule.check(password, confirmPassword));

  enum ESubmitType {
    CONFIRM_SIGN_UP = 'confirmSignUp',
    CONFIRM_SIGN_UP_FAIL = 'confirmSignUpFail',
    RESEND_SIGN_UP = 'resendSignUp',
    FORGOT_PASSWORD_SUBMIT = 'forgotPasswordSubmit',
  }

  const getSubmitType = async () => {
    return Auth.confirmSignUp(values.email, values.code.trim())
      .then((confirmSignupResponse) => {
        return { type: ESubmitType.CONFIRM_SIGN_UP, ...confirmSignupResponse };
      })
      .catch(({ code, message }) => {
        let type = ESubmitType.CONFIRM_SIGN_UP_FAIL;
        if (EAuthExceptionCodes.CODE_MISMATCH === code) {
          /**
           * If the confirm signup failed, that code could be mismatched.
           * Then we need to resend signup.
           */
          type = ESubmitType.RESEND_SIGN_UP;
        } else if (EAuthExceptionCodes.CODE_EXPIRED === code) {
          /**
           * If the confirm signup failed, that code could be expired.
           * Then we need to try the forgot password path.
           */
          type = ESubmitType.FORGOT_PASSWORD_SUBMIT;
        } else if (
          EAuthExceptionCodes.INVALID_PARAMETER === code &&
          message.includes(
            'failed to satisfy constraint: Member must satisfy regular expression pattern: [\\S]+'
          )
        ) {
          message = 'Code must be only numbers, no spaces. Try again.';
        }
        return { type, message, code };
      });
  };

  function doVerify() {
    return changeInitialPassword(
      values.email.toLowerCase(),
      import.meta.env.VITE_INITIAL_PASSWORD ?? '',
      values.password
    );
  }

  const doForgotPassword = async () => {
    return Auth.forgotPasswordSubmit(
      values.email.toLowerCase() || '',
      values.code.trim(),
      values.password
    );
  };

  const onSubmit = handleSubmit(async (values) => {
    const isValid = checkPasswordRules(values.password, values.confirmPassword);
    if (!isValid) {
      setShouldShowRules(true);
      return;
    }
    setError('');
    setIsSubmitting(true);

    const { type, message } = await getSubmitType();
    let action: Promise<any> = new Promise(() => {});
    if (ESubmitType.CONFIRM_SIGN_UP === type) {
      sessionStorage.setItem(
        'verificationStatus',
        EVerificationStatus.IN_PROGRESS
      );
      action = doVerify();
    } else if (ESubmitType.CONFIRM_SIGN_UP_FAIL === type) {
      action = new Promise(() => {
        throw new Error(message || 'Internal Error');
      });
    } else if (ESubmitType.RESEND_SIGN_UP === type) {
      action = Auth.resendSignUp(values.email.toLocaleLowerCase());
    } else if (ESubmitType.FORGOT_PASSWORD_SUBMIT === type) {
      action = doForgotPassword();
    }
    action
      .then(() => {
        if (ESubmitType.CONFIRM_SIGN_UP === type) {
          sessionStorage.setItem(
            'verificationStatus',
            EVerificationStatus.SUCCESS
          );
        }
        setShowChangedDialog(true);
      })
      .catch((error) => {
        if (ESubmitType.CONFIRM_SIGN_UP === type) {
          sessionStorage.setItem(
            'verificationStatus',
            EVerificationStatus.FAILURE
          );
        }
        setError((error as Error).message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  const handleGoToLogIn = () => {
    setShowChangedDialog(false);
    sessionStorage.removeItem('verificationStatus');
    navigateToLogin();
    confirmInitialPasswordChange();
  };

  useEffect(() => {
    const status = sessionStorage.getItem('verificationStatus');
    if (status === EVerificationStatus.SUCCESS) {
      setShowChangedDialog(true);
    }
  }, [setShowChangedDialog]);

  return (
    <NonAuthLayout formClassName="min-h-[600px]">
      <NonAuthForm
        instructionHeader={
          <>
            <Typography fontWeight="normal" variant={isMobile ? 'h3' : 'h2'}>
              Set
            </Typography>
            <Typography variant={isMobile ? 'h3' : 'h2'}>Password</Typography>
          </>
        }
        instructions={
          <Typography color="gray" lineHeight="30px" variant="body1">
            Enter the verification code you received and the new password you
            would like to use.
          </Typography>
        }
        footer={
          <>
            <Typography variant="body2">Has an existing account?</Typography>
            <Link to={PATH_PATTERNS[ERoutePath.LOGIN]} text="Login" />
          </>
        }
      >
        <form role="form" onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="Email"
                data-testid="email"
                {...register('email', {
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="Verification code"
                data-testid="verification-code"
                {...register('code', { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="New Password"
                type="password"
                data-testid="new-password"
                {...register('password', {
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="Confirm Password"
                type="password"
                data-testid="confirm-password"
                {...register('confirmPassword', {
                  required: true,
                })}
              />
            </Grid>

            {shouldShowRules && (
              <Grid item xs={12}>
                <PasswordRules
                  confirmPassword={values.confirmPassword}
                  password={values.password}
                  rules={PASSWORD_RULES}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button disabled={isSubmitting} type="submit" className="w-full">
                Set password
              </Button>
            </Grid>

            {error && (
              <Grid item xs={12}>
                {error}
              </Grid>
            )}
          </Grid>
        </form>
      </NonAuthForm>
      {showChangedDialog ? (
        <ConfirmDialog
          title="Password changed!"
          open
          buttonText="Continue"
          onClose={handleGoToLogIn}
        />
      ) : null}
    </NonAuthLayout>
  );
};
