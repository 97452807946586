import { useGrowthCyclesByDates } from 'api/growth-cycle';
import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { Button } from 'components/common/Button/Button';
import { CenteredLoader } from 'components/common/CenteredLoader';
import {
  Chart,
  ChartProps,
  getPlotBands,
  getPolygonSeriesAndZones,
} from 'components/common/Chart/Chart';
import { Dropdown, Option } from 'components/common/Dropdown/Dropdown';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { HeatMapGradient } from 'components/heat_map/HeatMapGradient';
import { useHeatMap } from 'components/heat_map/hooks/useHeatMap';
import {
  HEATMAP_TYPE_OPTIONS,
  HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE,
  PLOT_CONFIG,
  tooltips,
} from 'components/heat_map/utils';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { hoursToMilliseconds } from 'date-fns';
import { useSignals } from 'hooks/useSignals';
import { ExternalLinkIcon } from 'icons/ExternalLinkIcon';
import { InfoIcon } from 'icons/InfoIcon';
import isNil from 'lodash.isnil';
import Plotly from 'plotly.js-cartesian-dist-min';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useResizeDetector } from 'react-resize-detector';
import {
  InsightCommentContent,
  TDiscussion,
} from 'shared/interfaces/discussion';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { getLighCyclesRanges } from 'shared/utils/getters';

const Plot = createPlotlyComponent(Plotly);

const PreviewHeatMap = ({
  discussion,
  zoneId,
  width,
  height,
  typeConfig,
  growthCycle,
}: {
  discussion: TDiscussion;
  zoneId: number;
  width: number;
  height: number;
  typeConfig: MeasurementTypeConfig;
  growthCycle: Optional<TGrowthCycle>;
}) => {
  const aggregationType = !isNil(discussion.heatMapId)
    ? EAggregationTypes.SINGLE
    : EAggregationTypes.AGGREGATED;
  const [gradientType, setGradientType] = useState<EGradientTypes>(
    discussion.area?.gradientType || EGradientTypes.MICROCLIMATES
  );
  const { heatMapPlotInformation, heatMapScale, gradientScaleValues, loading } =
    useHeatMap({
      typeConfig,
      selectedTime: discussion.startTime,
      gradientType,
      aggregationType,
      width,
      height: Math.min(296, height),
      zoneId,
      growthCycle,
    });

  const hasOptimalRange =
    !isNil(heatMapScale.optimalRangeLowerBound) &&
    !isNil(heatMapScale.optimalRangeUpperBound);

  const heatMapOptions = useMemo(() => {
    if (!hasOptimalRange || !typeConfig.hasOptimalRange) {
      return HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE;
    }
    return HEATMAP_TYPE_OPTIONS;
  }, [hasOptimalRange, typeConfig.hasOptimalRange]);

  if (loading) return <CenteredLoader />;

  return (
    <div className="relative flex-1 flex flex-col gap-2 items-center justify-between">
      {heatMapPlotInformation && (
        <div className="relative h-fit w-fit">
          <Plot
            className="!flex flex-1 items-center justify-center"
            config={PLOT_CONFIG}
            data={[heatMapPlotInformation.data]}
            layout={heatMapPlotInformation.layout}
            useResizeHandler={true}
          />
          <div
            className="pointer-events-none border-orange-500 border-2 absolute"
            style={{
              top: discussion.area!.points![0]!.y * 100 + '%',
              left: discussion.area!.points![0]!.x * 100 + '%',
              width:
                (discussion.area!.points![1]!.x -
                  discussion.area!.points![0]!.x) *
                  100 +
                '%',
              height:
                (discussion.area!.points![1]!.y -
                  discussion.area!.points![0]!.y) *
                  100 +
                '%',
            }}
          />
        </div>
      )}
      <HeatMapGradient
        gradientType={gradientType}
        heatMapScale={heatMapScale}
        typeConfig={typeConfig}
        scaleValues={gradientScaleValues}
        visible={!!heatMapPlotInformation}
      />
      <div
        className={cn(
          'flex absolute bottom-2',
          !isNil(heatMapScale.setPoint) &&
            heatMapScale.setPoint <
              (heatMapScale.lowerBound + heatMapScale.upperBound) / 2
            ? 'right-2'
            : 'left-2'
        )}
      >
        <Dropdown
          variant="secondary"
          value={heatMapOptions.find((option) => option.value === gradientType)}
          onChange={(option) =>
            setGradientType((option as Option<EGradientTypes>).value)
          }
          options={heatMapOptions}
          aria-label="selected heatmap type"
        />
        <Tooltip label={tooltips[gradientType]} withArrow>
          <span>
            <InfoIcon className="w-7 h-9 py-2 " />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const PreviewHealth = ({
  discussion,
  width,
  height,
}: {
  discussion: TDiscussion;
  width: number;
  height: number;
}) => {
  const { area, previewHealth, firstComment } = discussion;
  const insight = firstComment.content as InsightCommentContent;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !canvasRef.current ||
      !previewHealth ||
      !area ||
      !area.points ||
      area.points.length !== 2
    ) {
      return;
    }

    const bottomLeft = {
      x: area.points[0].x * previewHealth.resolution[0],
      y: area.points[0].y * previewHealth.resolution[1],
    };
    const topRight = {
      x: area.points[1].x * previewHealth.resolution[0],
      y: area.points[1].y * previewHealth.resolution[1],
    };
    const sourceWidth = topRight.x - bottomLeft.x;
    const sourceHeight = topRight.y - bottomLeft.y;
    const aspectRatio = sourceWidth / sourceHeight;

    let destWidth, destHeight;
    if (width / height > aspectRatio) {
      // Taller than wider or square
      destHeight = height;
      destWidth = height * aspectRatio;
    } else {
      // Wider than tall
      destWidth = width;
      destHeight = width / aspectRatio;
    }

    // Center the image in the canvas
    const destX = (width - destWidth) / 2;
    const destY = (height - destHeight) / 2;

    const context = canvasRef.current.getContext('2d');
    if (context) {
      // Retain the pixels' sharpness
      context.imageSmoothingEnabled = false;

      // clear canvas because of re-renders
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      const image = new Image();
      image.onload = () => {
        context.drawImage(
          image,
          bottomLeft.x,
          bottomLeft.y,
          sourceWidth,
          sourceHeight,
          destX,
          destY,
          destWidth,
          destHeight
        );

        setLoading(false);
      };

      setLoading(true);

      // Set the source of the image
      image.src = previewHealth.url;
    }
  }, [area, height, previewHealth, width]);

  return (
    <>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        aria-label={`Health image for ${insight.title}`}
      />
      {loading && <CenteredLoader />}
    </>
  );
};

const PreviewEnvironment = ({
  discussion,
  zoneId,
  zoneUid,
  zoneTimeZone,
  height,
  typeConfig,
  growthCycle,
}: {
  discussion: TDiscussion;
  zoneId: number;
  zoneUid: string;
  zoneTimeZone: string;
  height: number;
  typeConfig: MeasurementTypeConfig;
  growthCycle: Optional<TGrowthCycle>;
}) => {
  const { startTime: start, endTime: end, area, firstComment } = discussion;
  const insight = firstComment.content as InsightCommentContent;
  const {
    data = [],
    called,
    loading,
  } = useGetMeasurementsByTypeAndTimeRange({
    zoneId,
    zoneUid,
    zoneTimeZone,
    start,
    end,
    typeConfig,
    aggregation: area?.viewType,
  });
  const options = useMemo<ChartProps['options']>(() => {
    if (loading || !called) {
      return undefined;
    }

    const { plotBands, polygonSeries, zones } = (() => {
      if (!growthCycle) {
        return {
          plotBands: undefined,
          polygonSeries: [],
          zones: undefined,
        };
      }

      const ranges = getLighCyclesRanges({
        start,
        end,
        lightInfo: growthCycle?.metadata.light_info ?? [],
      });

      const plotBands = getPlotBands(ranges);

      const { polygonSeries, zones } = getPolygonSeriesAndZones({
        data: data as [number, number][],
        end,
        growthCycle,
        start,
        ranges,
        typeConfig,
      });

      return {
        plotBands,
        polygonSeries,
        zones,
      };
    })();

    return {
      accessibility: {
        description: `Environment chart for ${insight.title}`,
      },
      boost: {
        pixelRatio: 2,
      },
      series: [
        ...polygonSeries,
        {
          data,
          name: typeConfig.label,
          type: 'line',
          zoneAxis: 'x',
          zones,
        },
      ],
      yAxis: {
        reversed: typeConfig.reversedYAxis,
        title: {
          text: `${typeConfig.label} <sup>${typeConfig.unit}</sup>`,
          useHTML: true,
        },
      },
      xAxis: {
        plotBands,
        // When there's no data, set minRange to a full day
        ...(data.length === 0
          ? { minRange: hoursToMilliseconds(24) }
          : undefined),
      },
    };
  }, [
    called,
    data,
    end,
    growthCycle,
    insight.title,
    loading,
    start,
    typeConfig,
  ]);

  return <Chart style={{ height }} options={options} loading={loading} />;
};

export const InsightPreview = ({
  discussion,
  zoneId,
  zoneUid,
  zoneTimeZone,
  onClick,
}: {
  discussion: TDiscussion;
  zoneId: number;
  zoneUid: string;
  zoneTimeZone: string;
  onClick: () => void;
}) => {
  const {
    annotationType,
    area,
    firstComment,
    uid,
    startTime: start,
    endTime: end,
  } = discussion;
  const insight = firstComment.content as InsightCommentContent;
  const { rawSignal: typeConfig } = useSignals({
    rawSignalId: area?.signalIds?.[0]!,
    zoneUid,
  });
  const { presetTypes } = useTypeConfig();
  const { growthCycle } = useGrowthCyclesByDates({
    zoneId,
    start,
    end,
    zoneTimeZone,
    presetTypes,
  });
  const { width = 576, height = 300, ref } = useResizeDetector();

  if (annotationType === 'event') {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn(
        'relative rounded-sm overflow-hidden group border border-neutral-400'
      )}
    >
      <>
        <InView>
          {({ ref, inView }) => (
            <div
              ref={ref}
              className={cn(
                'h-[300px] bg-neutral-200 flex flex-col',
                ['heatmap_annotation', 'heatmap_aggregate_annotation'].includes(
                  annotationType
                ) && 'h-auto min-h-[300px] max-h-[400px]'
              )}
            >
              {inView && annotationType === 'single_image_annotation' && (
                <PreviewHealth
                  key={uid}
                  discussion={discussion}
                  width={width}
                  height={height}
                />
              )}

              {inView &&
                annotationType === 'time_range_annotation' &&
                typeConfig && (
                  <PreviewEnvironment
                    key={uid}
                    discussion={discussion}
                    height={height}
                    zoneId={zoneId}
                    zoneUid={zoneUid}
                    zoneTimeZone={zoneTimeZone}
                    typeConfig={typeConfig}
                    growthCycle={growthCycle}
                  />
                )}

              {inView &&
                (annotationType === 'heatmap_annotation' ||
                  annotationType === 'heatmap_aggregate_annotation') &&
                typeConfig && (
                  <PreviewHeatMap
                    key={uid}
                    discussion={discussion}
                    height={height}
                    width={width}
                    zoneId={zoneId}
                    typeConfig={typeConfig}
                    growthCycle={growthCycle}
                  />
                )}
            </div>
          )}
        </InView>

        <div
          className="absolute top-0 right-0 bg-transparent cursor-pointer hidden group-hover:block p-4"
          onClick={onClick}
        >
          <Button
            size="icon"
            variant="outline"
            aria-label={`Go to ${insight.title}`}
          >
            <ExternalLinkIcon />
          </Button>
        </div>
      </>
    </div>
  );
};
