import { ReactComponent as LabelAbnormalInActive } from 'assets/svg/label-abnormal-inactive.svg';
import { ReactComponent as LabelAbnormalSelected } from 'assets/svg/label-abnormal-selected.svg';
import { ReactComponent as LabelAbnormalDefault } from 'assets/svg/label-abnormal.svg';
import { ReactComponent as LabelNecrosisInActive } from 'assets/svg/label-necrosis-inactive.svg';
import { ReactComponent as LabelNecrosisSelected } from 'assets/svg/label-necrosis-selected.svg';
import { ReactComponent as LabelNecrosisDefault } from 'assets/svg/label-necrosis.svg';
import { ReactComponent as LabelOtherInActive } from 'assets/svg/label-other-inactive.svg';
import { ReactComponent as LabelOtherSelected } from 'assets/svg/label-other-selected.svg';
import { ReactComponent as LabelOtherDefault } from 'assets/svg/label-other.svg';
import { ReactComponent as LabelYellowingInActive } from 'assets/svg/label-yellowing-inactive.svg';
import { ReactComponent as LabelYellowingSelected } from 'assets/svg/label-yellowing-selected.svg';
import { ReactComponent as LabelYellowingDefault } from 'assets/svg/label-yellowing.svg';
import {
  EImageLabelCategory,
  TImageLabelWithIcon,
} from 'shared/interfaces/image';

export const imageLabelWithIcons: TImageLabelWithIcon[] = [
  {
    category: EImageLabelCategory.YELLOWING,
    title: 'Yellowing',
    defaultIcon: <LabelYellowingDefault />,
    selectedIcon: <LabelYellowingSelected />,
    inactiveIcon: <LabelYellowingInActive />,
  },
  {
    category: EImageLabelCategory.NECROSIS,
    title: 'Necrosis',
    defaultIcon: <LabelNecrosisDefault />,
    selectedIcon: <LabelNecrosisSelected />,
    inactiveIcon: <LabelNecrosisInActive />,
  },
  {
    category: EImageLabelCategory.ABNORMAL_SHAPE,
    title: 'Abnormal Shape',
    defaultIcon: <LabelAbnormalDefault />,
    selectedIcon: <LabelAbnormalSelected />,
    inactiveIcon: <LabelAbnormalInActive />,
  },
  {
    category: EImageLabelCategory.OTHER,
    title: 'Other',
    defaultIcon: <LabelOtherDefault />,
    selectedIcon: <LabelOtherSelected />,
    inactiveIcon: <LabelOtherInActive />,
  },
];
