import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button/Button';
import { Menu } from 'components/common/Menu';
import { Popover, PopoverProps } from 'components/common/Popover/Popover';
import { TabGroup } from 'components/common/TabGroup';
import * as toast from 'components/common/Toast/Toast';
import { useDisclosure } from 'hooks/useDisclosure';
import { usePermissions } from 'hooks/usePermissions';
import { EditIcon } from 'icons/EditIcon';
import { GarbageIcon } from 'icons/GarbageIcon';
import { MoreVerticalIcon } from 'icons/MoreVerticalIcon';
import { RefObject, useCallback, useContext, useMemo, useState } from 'react';
import { TDiscussionType } from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { DiscussionBoxProps } from './DiscussionBox';
import { DiscussionBoxContext } from './DiscussionBoxContext';
import { DiscussionList } from './DiscussionList';
import { EditComment } from './EditComment';
import { EditInsight } from './EditInsight';
import { ViewComment } from './ViewComment';
import { ViewInsight } from './ViewInsight';

const defaultFloatProps: DiscussionBoxProps['floatProps'] = {
  shift: {
    mainAxis: true,
    crossAxis: true,
  },
  flip: false,
  offset: 8,
  autoPlacement: {
    allowedPlacements: [
      'top-start',
      'bottom-start',
      'top-end',
      'bottom-end',
      'left-start',
      'right-start',
      'left-end',
      'right-end',
    ],
  },
};

const tabs = [
  {
    label: 'Regular',
    id: 'comment',
  },
  {
    label: 'Insights',
    id: 'insight',
  },
];

export interface DiscussionBoxPopoverProps {
  referenceElement?: Maybe<RefObject<HTMLElement>>;
  floatProps?: PopoverProps['floatProps'];
  closeOnWheel?: boolean;
}

export const DiscussionBoxPopover = ({
  referenceElement,
  floatProps,
  closeOnWheel = false,
}: DiscussionBoxPopoverProps) => {
  const permissions = usePermissions();
  const confirmDismissal = useDisclosure();
  const confirmRemoval = useDisclosure();
  const {
    canEditOrDelete,
    editState,
    listState,
    mode,
    showBackButton,
    title,
    selectedDiscussion,
    deleteDiscussion,
    onClose,
    onDelete,
    showList,
    startEditing,
    saveDiscussion,
  } = useContext(DiscussionBoxContext);
  const { discussionDraft, isDirty, isSaving, isValid, updateDiscussionType } =
    editState ?? {};
  const showTabs = mode === 'create' && permissions.insights.canCreate;
  const [tab, setTab] = useState<TDiscussionType>(
    discussionDraft?.type ?? 'comment'
  );
  const discussionType = mode === 'create' ? tab : discussionDraft?.type;
  const headerActions = useMemo(
    () =>
      mode === 'view' &&
      canEditOrDelete && (
        <Menu
          button={
            <Button variant="secondary" size="icon" aria-label="More">
              <MoreVerticalIcon />
            </Button>
          }
          items={[
            {
              children: 'Edit',
              variant: 'flat',
              leadingIcon: <EditIcon />,
              className: cn(!permissions.discussions.canUpdate && 'hidden'),
              onClick: startEditing,
            },
            {
              children: 'Delete',
              variant: 'error',
              disabled: !onDelete,
              leadingIcon: <GarbageIcon />,
              className: cn(!permissions.discussions.canDelete && 'hidden'),
              onClick: confirmRemoval.open,
            },
          ]}
        />
      ),
    [
      canEditOrDelete,
      confirmRemoval,
      mode,
      onDelete,
      permissions.discussions.canDelete,
      permissions.discussions.canUpdate,
      startEditing,
    ]
  );

  const save = useCallback(async () => {
    try {
      await saveDiscussion();
    } catch (_e) {
      console.error(_e);
      toast.error(
        {
          content:
            'Something went wrong while saving the comment. Please try again.',
        },
        { toastId: 'comment-box-error' }
      );
    }
  }, [saveDiscussion]);

  const changeTab = useCallback(
    (tab: TDiscussionType) => {
      setTab(tab);
      updateDiscussionType?.(tab);
    },
    [updateDiscussionType]
  );

  const handleClose = () => {
    if (isDirty) {
      confirmDismissal.open();
    } else {
      onClose();
    }
  };

  const handleClickBackButton = showBackButton ? showList : undefined;

  const handleDelete = async () => {
    await deleteDiscussion();
    confirmRemoval.close();
  };

  return (
    <>
      <Popover
        isOpen
        title={title}
        aria-label={title}
        referenceElement={referenceElement}
        floatProps={{ ...defaultFloatProps, ...floatProps }}
        headerActions={headerActions}
        close={handleClose}
        contentClassName={cn('pt-0', mode === 'view' && 'p-0')}
        onClickBackButton={handleClickBackButton}
        showScrollbar={true}
        closeOnWheel={closeOnWheel}
        refreshId={`${referenceElement?.current?.getBoundingClientRect().x}-${referenceElement?.current?.getBoundingClientRect().y}`}
      >
        {listState ? (
          <DiscussionList />
        ) : (
          <>
            {selectedDiscussion && (
              <>
                {selectedDiscussion.type === 'comment' && (
                  <ViewComment discussion={selectedDiscussion} />
                )}
                {selectedDiscussion.type === 'insight' && (
                  <ViewInsight
                    discussion={selectedDiscussion}
                    showStatus={permissions.insights.canPublish}
                  />
                )}
              </>
            )}
            {editState && (
              <div className="flex flex-col gap-4">
                {showTabs && (
                  <TabGroup
                    tabs={tabs}
                    value={tab}
                    onChange={(tab) => changeTab(tab as TDiscussionType)}
                    shouldScrollTabIntoView={false}
                    className="h-auto"
                  />
                )}

                {discussionType === 'comment' && <EditComment />}

                {discussionType === 'insight' && <EditInsight />}

                <Button
                  onClick={save}
                  disabled={!isDirty || !isValid}
                  loading={isSaving}
                  className="flex-none"
                >
                  {discussionType === 'insight'
                    ? 'Submit for review'
                    : 'Submit'}
                </Button>
              </div>
            )}
          </>
        )}
      </Popover>

      {confirmDismissal.isOpen && (
        <Alert
          open={confirmDismissal.isOpen}
          onCancel={confirmDismissal.close}
          onConfirm={() => {
            onClose();
            confirmDismissal.close();
          }}
        >
          Are you sure you want to exit?
          <br />
          Your changes will not be saved.
        </Alert>
      )}

      {confirmRemoval.isOpen && (
        <div className="inset-0 fixed">
          <Alert
            open={confirmRemoval.isOpen}
            onCancel={confirmRemoval.close}
            onConfirm={handleDelete}
          >
            Are you sure you want to remove the annotation and its corresponding
            comments? This action cannot be undone.
          </Alert>
        </div>
      )}
    </>
  );
};
