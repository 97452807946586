import { Auth } from 'aws-amplify';
import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { Button } from 'components/common/Button/Button';
import { Grid } from 'components/common/Grid';
import { Link } from 'components/common/Link';
import { TextInput } from 'components/common/TextInput';
import { Typography } from 'components/common/Typography';
import { useURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import isNil from 'lodash.isnil';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AWS_API_CODE } from 'shared/constants/code';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';

type TForgotPasswordForm = {
  email: string;
};

export const ForgotPasswordPage: FC = () => {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isMobile } = useScreenSize();

  const { navigateToResetPassword } = useURL();
  const { handleSubmit, register } = useForm<TForgotPasswordForm>();

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true);

    Auth.forgotPassword(values.email)
      .then(() => {
        setError('');
        navigateToResetPassword(values.email);
      })
      .catch((error) => {
        const errorMessage =
          !isNil(error.message) && error.message.length > 0
            ? error.message
            : '';
        if ((error as any)?.code === AWS_API_CODE.user_not_found_exception) {
          setError(`There was error during forgot password: ${errorMessage}`);
        } else {
          setError('Server is unreachable: ' + errorMessage);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  return (
    <NonAuthLayout>
      <NonAuthForm
        instructionHeader={
          <>
            <Typography fontWeight="normal" variant={isMobile ? 'h4' : 'h2'}>
              Forgot
            </Typography>
            <Typography variant={isMobile ? 'h4' : 'h2'}>password</Typography>
          </>
        }
        instructions={
          <Typography color="gray" lineHeight="30px" variant="body1">
            Enter your registered email below to receive password reset
            instructions. Please note that if you have never previously verified
            your account you will not receive an email. If you need a new
            verification link, please reach out to an organization
            administrator, or email support@neatleaf.com.
          </Typography>
        }
        footer={
          <>
            <Typography variant="body2">Remember password?</Typography>
            <Link to={PATH_PATTERNS[ERoutePath.LOGIN]} text="Login" />
          </>
        }
      >
        <form role="form" onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label="Email"
                {...register('email', { required: true })}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={isSubmitting}
                className="h-12 w-full text-base"
                type="submit"
              >
                Send code
              </Button>
            </Grid>

            {error && (
              <Grid item xs={12}>
                {error}
              </Grid>
            )}
          </Grid>
        </form>
      </NonAuthForm>
    </NonAuthLayout>
  );
};
