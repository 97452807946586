import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  forwardRef,
} from 'react';
import { cn } from 'shared/utils/cn';

const _variants = ['danger', 'warning', 'info', 'tip500', 'tip900'] as const;

type Variant = (typeof _variants)[number];

export type TagProps<T extends ElementType = 'span'> =
  ComponentPropsWithoutRef<T> & {
    as?: T;
    variant: Variant;
  };

/** Can be used to represent a caption for an item in a user interface. Especially useful when used with form fields. */
const Tag = forwardRef(
  <T extends ElementType = 'span'>(
    { as, className, children, variant, ...props }: TagProps<T>,
    ref: ComponentPropsWithRef<T>['ref']
  ) => {
    const Component = as || 'span';
    return (
      <Component
        ref={ref}
        className={cn(
          'inline-flex gap-2 text-sm rounded-full px-2 items-center',
          variant === 'danger' && 'bg-red-100 text-red-400',
          variant === 'warning' && 'bg-yellow-200 text-yellow-900',
          variant === 'info' && 'bg-blue-100 text-blue-900',
          variant === 'tip500' && 'bg-neutral-200 text-neutral-500',
          variant === 'tip900' && 'bg-neutral-200 text-neutral-900',
          className
        )}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

Tag.displayName = 'Tag';

export { Tag };
