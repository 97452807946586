import { Placement } from '@floating-ui/react-dom';
import { ChevronDownIcon } from 'icons/ChevronDownIcon';
import isNil from 'lodash.isnil';
import {
  AriaAttributes,
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import { cn } from 'shared/utils/cn';
import { InfiniteListsPicker, ListOptions } from './InfiniteListsPicker';
import { Input } from './Input/Input';

const pad2 = (n: number) => n.toString().padStart(2, '0');

const useForceUpdate = () => {
  const [, setToggle] = useState(false);
  return () => setToggle((toggle) => !toggle);
};

const getInputTextWidth = (input: HTMLInputElement | null) => {
  if (!input) return 0;
  const tempElement = document.createElement('span');
  document.body.appendChild(tempElement);

  // Copy style properties from the input to the temp element
  const styles = window.getComputedStyle(input);
  tempElement.style.fontFamily = styles.fontFamily;
  tempElement.style.fontSize = styles.fontSize;
  tempElement.style.fontWeight = styles.fontWeight;
  tempElement.style.letterSpacing = styles.letterSpacing;

  // Ensure the temp element is not visible but still rendered
  tempElement.style.position = 'absolute';
  tempElement.style.visibility = 'hidden';
  tempElement.style.height = 'auto';
  tempElement.style.width = 'auto';
  tempElement.style.whiteSpace = 'nowrap';

  // Set the text content of the temp element
  tempElement.textContent = input.value;

  // Measure the temp element's width
  const width = tempElement.offsetWidth;

  // remove the temp element
  document.body.removeChild(tempElement);

  return width;
};

type TimePickerProps = AriaAttributes &
  Omit<ComponentProps<typeof Input>, 'onChange'> & {
    onChange: (e: string) => void;
    type?: 'timeOfDay' | 'duration' | 'weeksAndDays';
    firstStep?: number;
    secondStep?: number;
    firstMaxValue?: number;
    secondMaxValue?: number;
    firstValue?: number;
    secondValue?: number;
    className?: string;
    inputClassName?: string;
    dropdownPlacement?: Placement;
    useNativeTimeInput?: boolean;
  };

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  function TimePicker(
    {
      onChange,
      type = 'timeOfDay',
      firstStep = 1,
      secondStep = 1,
      firstMaxValue = type === 'weeksAndDays' ? 53 : 24,
      secondMaxValue = type === 'weeksAndDays' ? 7 : 60,
      firstValue = 0,
      secondValue = 0,
      dropdownPlacement = 'bottom-start',
      className,
      inputClassName,
      useNativeTimeInput = type === 'timeOfDay',
      ...props
    },
    ref
  ) {
    const forceUpdate = useForceUpdate();

    const inputRef = useRef<HTMLInputElement>(null);
    const nativeInputRef = useRef<HTMLInputElement>(null);
    const lastChangedRef = useRef<null | 'firstValue' | 'secondValue'>(null);
    const typingNumber = useRef(false);
    const usingShift = useRef(false);
    const clicking = useRef(false);

    const nativeInputIsActive = () =>
      useNativeTimeInput &&
      nativeInputRef.current &&
      nativeInputRef.current.offsetWidth;

    const firstValueInputCursorRange = { start: 0, end: 2 };
    const secondValueCursorStartPosition = type === 'timeOfDay' ? 3 : 4;
    const secondValueInputCursorRange = {
      start: secondValueCursorStartPosition,
      end: secondValueCursorStartPosition + 2,
    };
    const firstValueIsHighlighted = () =>
      !isNil(inputRef.current?.selectionStart) &&
      inputRef.current?.selectionStart! <= (type === 'timeOfDay' ? 2 : 3);
    const secondValueIsHighlighted = () =>
      !isNil(inputRef.current?.selectionStart) &&
      inputRef.current?.selectionStart! > (type === 'timeOfDay' ? 2 : 3);

    const lists = [
      {
        ariaLabel: type === 'weeksAndDays' ? 'weeks' : 'hours',
        nrItems: firstMaxValue / firstStep,
        renderItem: (index: number) =>
          index * firstStep +
          (type === 'weeksAndDays' ? 1 : 0) +
          (type === 'weeksAndDays' ? 'w' : 'h'),
        selectedIndex: firstValue / firstStep,
      },
      {
        ariaLabel: type === 'weeksAndDays' ? 'days' : 'minutes',
        nrItems: secondMaxValue / secondStep,
        renderItem: (index: number) =>
          index * secondStep +
          (type === 'weeksAndDays' ? 1 : 0) +
          (type === 'weeksAndDays' ? 'd' : 'm'),
        selectedIndex: secondValue / secondStep,
      },
    ];

    const nativeInputValue =
      pad2(lists[0]!.selectedIndex!) + ':' + pad2(lists[1]!.selectedIndex!);
    const defaultValue = useRef(nativeInputValue);

    const timeOfDayFormat = useCallback(
      (lists: ListOptions[]) => {
        const isInputFocused = document.activeElement === inputRef.current;
        const hourIndex = lists[0]!.selectedIndex!;
        const minuteIndex = lists[1]!.selectedIndex!;
        const hour = isInputFocused
          ? hourIndex
          : hourIndex % 12 === 0
            ? 12
            : hourIndex % 12; // Use 12 instead of 0 for noon and midnight
        const ampm = isInputFocused ? '' : hourIndex < 12 ? ' am' : ' pm';
        return `${pad2(hour * firstStep)}:${pad2(minuteIndex * secondStep)}${ampm}`;
      },
      [firstStep, secondStep]
    );

    const durationFormat = useCallback(
      (lists: ListOptions[]) =>
        !lists[0]!.selectedIndex! && !lists[1]!.selectedIndex!
          ? '00h 00m'
          : pad2(lists[0]!.selectedIndex! * firstStep) +
            'h ' +
            pad2(lists[1]!.selectedIndex! * secondStep) +
            'm',
      [firstStep, secondStep]
    );

    const weeksAndDaysFormat = useCallback(
      (lists: ListOptions[]) =>
        !lists[0]!.selectedIndex! && !lists[1]!.selectedIndex!
          ? '01w 01d'
          : pad2(lists[0]!.selectedIndex! * firstStep + 1) +
            'w ' +
            pad2(lists[1]!.selectedIndex! * secondStep + 1) +
            'd',
      [firstStep, secondStep]
    );

    let format;
    switch (type) {
      case 'timeOfDay':
        format = timeOfDayFormat;
        break;
      case 'duration':
        format = durationFormat;
        break;
      case 'weeksAndDays':
        format = weeksAndDaysFormat;
        break;
    }

    const handlePickerChange = (
      firstListIndex: number,
      secondListIndex: number
    ) => {
      const value =
        type === 'timeOfDay'
          ? `${pad2((firstListIndex % 24) * firstStep)}:${pad2(secondListIndex * secondStep)}`
          : `${pad2(firstListIndex * firstStep)}:${pad2(secondListIndex * secondStep)}`;
      handleInputChange(value);
    };

    const setInputCursorRange = (range: { start: number; end: number }) => {
      if (nativeInputIsActive()) {
        return; // do not set a selection range while using native input
      }
      requestAnimationFrame(() => {
        if (
          inputRef.current &&
          document.activeElement === inputRef.current &&
          range
        ) {
          if (
            window?.matchMedia &&
            !window?.matchMedia('(hover: hover)').matches
          ) {
            // on Safari/iOS, selecting a range shows a tooltip that we want to avoid
            inputRef.current.setSelectionRange(range.end, range.end);
          } else {
            inputRef.current.setSelectionRange(range.start, range.end);
          }
        }
      });
    };

    const handleInputChange = (value: string) => {
      const [newFirstValue, newSecondValue] = value.split(':').map(Number);
      if (isNil(newFirstValue) || isNil(newSecondValue)) {
        throw new Error('Invalid time format');
      }
      if (firstValue !== newFirstValue) lastChangedRef.current = 'firstValue';
      else if (secondValue !== newSecondValue)
        lastChangedRef.current = 'secondValue';
      else lastChangedRef.current = null;
      onChange(value);

      if (isNil(inputRef.current?.selectionStart)) return;
      const secondValueChanged = lastChangedRef.current === 'secondValue';
      let newCursorRange;
      if (typingNumber.current) {
        newCursorRange =
          newFirstValue * 10 <= firstMaxValue && !secondValueChanged
            ? firstValueInputCursorRange
            : secondValueInputCursorRange;
      } else {
        newCursorRange = secondValueChanged
          ? secondValueInputCursorRange
          : firstValueInputCursorRange;
      }
      setInputCursorRange(newCursorRange);
      typingNumber.current = false;
    };

    const handleInputBlur = () => {
      lastChangedRef.current = null;
      typingNumber.current = false;
      forceUpdate();
    };

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      // if the cursor is not in the input, ignore
      if (!firstValueIsHighlighted() && !secondValueIsHighlighted()) return;
      if (
        !(
          event.key === 'ArrowUp' ||
          event.key === 'ArrowDown' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight' ||
          event.key === 'Tab'
        )
      )
        return;

      if (event.key !== 'Tab') {
        event.preventDefault();
        event.stopPropagation();
      }

      // on key up/down, change the selected list item
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        const step = event.key === 'ArrowUp' ? 1 : -1;
        let newHours = lists[0]!.selectedIndex!;
        let newMinutes = lists[1]!.selectedIndex!;
        if (firstValueIsHighlighted()) {
          newHours =
            (lists[0]!.selectedIndex! + step + firstMaxValue / firstStep) %
            (firstMaxValue / firstStep);
        } else if (secondValueIsHighlighted()) {
          newMinutes =
            (lists[1]!.selectedIndex! + step + secondMaxValue / secondStep) %
            (secondMaxValue / secondStep);
        }
        handlePickerChange(newHours, newMinutes);
      }

      // on key left/right, change the cursor position
      if (event.key === 'ArrowLeft') {
        setInputCursorRange(firstValueInputCursorRange);
      } else if (event.key === 'ArrowRight') {
        setInputCursorRange(secondValueInputCursorRange);
      }

      // on tab, change the cursor position
      if (event.key === 'Tab') {
        let usedTabToChangeCursorPosition = false;
        if (event.shiftKey) {
          if (secondValueIsHighlighted()) {
            usedTabToChangeCursorPosition = true;
            setInputCursorRange(firstValueInputCursorRange);
          }
        } else {
          if (firstValueIsHighlighted()) {
            usedTabToChangeCursorPosition = true;
            setInputCursorRange(secondValueInputCursorRange);
          }
        }
        if (usedTabToChangeCursorPosition) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    };

    // We use onBeforeInput to handle the input event because it allows us to
    // validate/tranform the user input before it is applied to the input value.
    const handleBeforeInput = (
      event: React.CompositionEvent<HTMLInputElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();

      let typedNumber = Number((event as unknown as InputEvent).data);

      if (type === 'weeksAndDays') {
        --typedNumber; // weeks and days are 1-indexed
        if (typedNumber === -1) return;
      }

      const element = event.target as HTMLInputElement;
      const selectionStart = element.selectionStart;

      if (isNil(selectionStart) || isNil(typedNumber) || isNaN(typedNumber))
        return;

      let newFirstValue = firstValue;
      let newSecondValue = secondValue;
      if (selectionStart <= firstValueInputCursorRange.end) {
        const oldFirstValue = lists[0]!.selectedIndex! * firstStep + 1;
        const firstValueSum = oldFirstValue * 10 + typedNumber;
        newFirstValue =
          firstValueSum < firstMaxValue &&
          lastChangedRef.current === 'firstValue'
            ? firstValueSum
            : typedNumber;
      } else {
        const oldSecondValue = lists[1]!.selectedIndex! * secondStep;
        const minutesSum = oldSecondValue * 10 + typedNumber;
        newSecondValue =
          minutesSum < secondMaxValue &&
          lastChangedRef.current === 'secondValue'
            ? minutesSum
            : typedNumber;
      }
      if (isNil(newFirstValue) || isNil(newSecondValue))
        throw new Error('Invalid time format');
      const closestFirstValue = Math.max(
        0,
        Math.min(
          firstMaxValue,
          Math.round(newFirstValue / firstStep) * firstStep
        )
      );
      const closestSecondValue = Math.max(
        0,
        Math.min(
          secondMaxValue,
          Math.round(newSecondValue / secondStep) * secondStep
        )
      );
      typingNumber.current = true;
      handleInputChange(
        pad2(closestFirstValue) + ':' + pad2(closestSecondValue)
      );
    };

    const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      // determine from click position which value to highlight
      const clickX = event.clientX;
      const inputRect = inputRef.current?.getBoundingClientRect();
      if (isNil(inputRef.current) || !inputRect) return;
      const clickPosition = clickX - inputRect.left;

      const width = getInputTextWidth(inputRef.current);

      // compute character index
      const characterIndex = Math.floor(
        (clickPosition / width) * inputRef.current.value.length
      );

      if (characterIndex <= 3) {
        setInputCursorRange(firstValueInputCursorRange);
      } else {
        setInputCursorRange(secondValueInputCursorRange);
      }
    };

    const handleInfiniteListsPickerChange = (indexes: number[]) => {
      handlePickerChange(...(indexes as [number, number]));
    };

    const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (nativeInputRef.current && nativeInputIsActive()) {
        inputRef.current?.blur();
        nativeInputRef.current.click();
        nativeInputRef.current.tabIndex = -1;
        event.preventDefault();
        event.stopPropagation();
      } else {
        if (clicking.current) return;
        let start;
        if (!usingShift.current) start = 0;
        else if (type === 'timeOfDay') start = 3;
        else start = 4;
        setInputCursorRange({ start, end: start + 2 });
      }
    };

    const handleContextMenu = (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
    };

    const handleInputMouseDown = () => {
      clicking.current = true;
    };

    const handleInputMouseUp = () => {
      clicking.current = false;
    };

    const handleNativeInputClick = (
      event: React.MouseEvent<HTMLInputElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();
      defaultValue.current =
        pad2(lists[0]!.selectedIndex!) + ':' + pad2(lists[1]!.selectedIndex!);
      (event.target as HTMLInputElement).showPicker();
      (event.target as HTMLInputElement).focus();
    };

    const handleNativeInputChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      let value = event.target.value;
      if (event.target.value === '') value = defaultValue.current;
      handleInputChange(value);
    };

    // if tab is pressed, focus on the next input in the page
    const handleNativeInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        const inputs = document.querySelectorAll('input');
        const index = Array.from(inputs).indexOf(
          event.target as HTMLInputElement
        );
        const step = event.shiftKey ? -1 : useNativeTimeInput ? 2 : 1; // 2 becase when going back we need to skip the default input
        const nextInput = inputs[index + step];
        if (nextInput) nextInput.focus();
      }
    };

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Shift' || event.shiftKey) {
          usingShift.current = true;
        }
      };
      const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Shift') {
          usingShift.current = false;
        }
      };
      const handleWindowBlur = () => {
        usingShift.current = false;
      };
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
      window.addEventListener('blur', handleWindowBlur);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
        window.removeEventListener('blur', handleWindowBlur);
      };
    }, []);

    // Set native input value only after componend updated.
    // This allows clearing the input on safari's iOS time native widget.
    useLayoutEffect(() => {
      if (!nativeInputRef.current) return;
      nativeInputRef.current.value = nativeInputValue;
    }, [nativeInputValue]);

    const value = format(lists);

    return (
      <div
        className={cn(
          'relative',
          props.readOnly && 'pointer-events-none',
          className
        )}
      >
        {useNativeTimeInput && (
          <input
            {...props}
            aria-label={`native ${props['aria-label']}`}
            tabIndex={props.readOnly ? -1 : 0}
            ref={nativeInputRef}
            className={
              `absolute bottom-0 left-0 right-0 top-0 block` +
              `h-full w-full appearance-none opacity-0 hoverable:hidden hoverable:pointer-events-none`
            }
            type="time"
            inputMode="numeric"
            onChange={handleNativeInputChange}
            onClick={handleNativeInputClick}
            onKeyDown={handleNativeInputKeyDown}
          />
        )}

        <InfiniteListsPicker
          lists={lists}
          tabbable={false}
          onChange={handleInfiniteListsPickerChange}
          dropdownPlacement={dropdownPlacement}
        >
          <Input
            {...props}
            ref={mergeRefs([inputRef, ref])}
            tabIndex={props.readOnly ? -1 : 0}
            value={value}
            inputMode="numeric" // show the native numeric keyboard on mobile
            actionIcon={<ChevronDownIcon />}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            onClick={handleInputClick}
            onMouseDown={handleInputMouseDown}
            onMouseUp={handleInputMouseUp}
            onBeforeInput={handleBeforeInput}
            onContextMenu={handleContextMenu}
            onChange={() => {}} // silence react warning. We are handling the change in onBeforeInput
            className={cn(
              'select-none focus:cursor-text',
              !props.readOnly && 'cursor-pointer',
              inputClassName
            )}
          />
        </InfiniteListsPicker>
      </div>
    );
  }
);
