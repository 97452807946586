import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const SearchIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      d="M15.8273 14.9623L11.9793 11.1133C13.0951 9.77389 13.6514 8.0558 13.5327 6.31661C13.414 4.57739 12.6294 2.95085 11.3418 1.77532C10.0545 0.599935 8.36342 -0.0338737 6.62033 0.00564955C4.87753 0.0453229 3.217 0.755304 1.98421 1.98807C0.751418 3.22069 0.0414578 4.88127 0.00174151 6.62422C-0.0377603 8.36712 0.596027 10.0581 1.77147 11.3457C2.94701 12.6333 4.57348 13.4179 6.31272 13.5366C8.05204 13.6553 9.77013 13.0989 11.1094 11.9832L14.9578 15.8316C15.114 15.9825 15.3383 16.0398 15.5477 15.9825C15.757 15.925 15.9206 15.7614 15.9781 15.552C16.0355 15.3426 15.9781 15.1185 15.8273 14.9623ZM10.6979 10.7015C9.66008 11.7392 8.25255 12.3223 6.7849 12.3223C5.31767 12.3206 3.911 11.7371 2.87358 10.6996C1.83615 9.66227 1.2525 8.25558 1.25087 6.78829C1.25087 5.32057 1.83409 3.91326 2.87182 2.87543C3.90972 1.8377 5.3172 1.25465 6.7849 1.25465C8.25255 1.25465 9.66008 1.8377 10.6979 2.87543C11.7356 3.91328 12.3187 5.32078 12.3187 6.78846C12.3187 8.25616 11.7356 9.66369 10.6979 10.7015Z"
    />
  </svg>
);

export { SearchIcon };
