import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const GarbageIcon = ({
  fill = 'currentColor',
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 18"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      fill={fill}
      stroke={stroke}
      strokeWidth={0.15}
      d="M6.576 4.532a.5.5 0 0 0-.492.51l.17 9.678a.5.5 0 0 0 .501.492h.009a.5.5 0 0 0 .492-.51l-.17-9.678m-.51-.492a.497.497 0 0 1 .51.492m-.51-.492.001.075-.001-.075Zm.51.492-.075.001.075-.001Zm2.341-.492a.5.5 0 0 0-.51.492l-.17 9.678a.5.5 0 0 0 .492.51h.008a.5.5 0 0 0 .502-.492l.17-9.679a.5.5 0 0 0-.492-.51Zm0 0-.001.075.001-.075Zm3.175 0H12.6a.505.505 0 0 0-.516.484l-.341 10.606v.002a.45.45 0 0 1-.448.449H4.708a.453.453 0 0 1-.449-.463v-.002l-.34-10.592a.501.501 0 0 0-1.002.032l.34 10.577c0 .8.651 1.45 1.451 1.45h6.588c.798 0 1.449-.65 1.45-1.436l.34-10.59a.5.5 0 0 0-.484-.517Zm.857-1.705H2.543a.501.501 0 1 0 0 1.002H13.46a.501.501 0 1 0 0-1.002ZM5.354 2.61a.5.5 0 0 0 .501-.501c0-.1.081-.18.18-.18h3.932c.1 0 .18.08.18.18a.501.501 0 1 0 1.003 0c0-.652-.531-1.183-1.183-1.183H6.036c-.653 0-1.183.53-1.183 1.183 0 .277.224.501.5.501Z"
    />
  </svg>
);

export { GarbageIcon };
