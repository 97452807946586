import { Grid, createStyles, makeStyles } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Typography } from 'components/common/Typography';
import { UserAvatar } from 'components/common/UserAvatar';
import {
  ChangePasswordForm,
  IChangePasswordForm,
} from 'components/user/ChangePasswordForm';
import {
  EditProfileForm,
  IEditProfileForm,
} from 'components/user/EditProfileForm';
import { useAuth } from 'contexts/AuthProvider';
import { useSettingsEditProfileURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useUpdateUserMutation } from 'graphql/generated/react_apollo';
import { useScreenSize } from 'hooks/useScreenSize';
import isNil from 'lodash.isnil';
import { FC, useCallback, useMemo } from 'react';
import { EProfileTabs } from 'shared/interfaces/settings';

const useStyles = makeStyles((theme) =>
  createStyles({
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2.5),
    },
  })
);

export const Profile: FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const { nameInitials, email, firstName, user, lastName, phoneNumber } =
    useAuth();

  const { setProfileTab, profileTab } = useSettingsEditProfileURL();

  const profileDefaultValues: IEditProfileForm = useMemo(() => {
    return {
      active: user?.active ?? true,
      email,
      firstName,
      lastName,
      phoneNumber,
      roles: [],
    };
  }, [email, firstName, lastName, phoneNumber, user]);

  const [updateUser] = useUpdateUserMutation();

  const handleOpenChangePassword = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setProfileTab(EProfileTabs.CHANGE_PASSWORD);
    },
    [setProfileTab]
  );

  const handleCloseChangePassword = useCallback(() => {
    setProfileTab(EProfileTabs.EDIT_PROFILE);
  }, [setProfileTab]);

  const handleChangePassword = async (data: IChangePasswordForm) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.changePassword(user, data.currentPassword, data.newPassword)
    );
  };
  const handleChangeProfile = useCallback(
    async (data: IEditProfileForm) => {
      if (isNil(user?.id)) {
        return;
      }

      await updateUser({
        variables: {
          id: user.id,
          active: true,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
        },
      });
    },
    [user?.id, updateUser]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={2}>
        {!isMobile || profileTab === EProfileTabs.EDIT_PROFILE ? (
          <div className={classes.avatarWrapper}>
            <UserAvatar
              size={isMobile ? 80 : 100}
              nameInitials={nameInitials}
            />
            {isMobile && (
              <Typography variant="h6">
                {[firstName, lastName].join(' ')}
              </Typography>
            )}
          </div>
        ) : (
          <Typography variant="h6">Change password</Typography>
        )}
      </Grid>

      <Grid item xs={12} lg={10}>
        {profileTab === EProfileTabs.EDIT_PROFILE && (
          <EditProfileForm
            defaultValues={profileDefaultValues}
            onClickChangePassword={handleOpenChangePassword}
            onSubmit={handleChangeProfile}
          />
        )}

        {profileTab === EProfileTabs.CHANGE_PASSWORD && (
          <ChangePasswordForm
            onClose={handleCloseChangePassword}
            onSubmit={handleChangePassword}
          />
        )}
      </Grid>
    </Grid>
  );
};
